import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import UserWalletLedger from '../../Components/UserWalletLedger/UserWalletLedger'

const UserWalletLedgerScreen = () => {
  return (
    <>
        <Appbar />
        <UserWalletLedger />
    </>
  )
}

export default UserWalletLedgerScreen