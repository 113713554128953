 
import { callAjax } from "../../MIS/Global";

export const SaveGamemaster = async (data) => {
  let ret = {};
  if (data?.gsubname == undefined || data?.gsubname == "") {
    return { msg: "Please Enter Game Name.", code: 100 };
  }
  let url = "savegamemaster";
  if (data.gmid > 0) {
    url = "updatesavemaster";
  }
  ret = await callAjax(url,data);
  return ret;
};

export const DeleteGamemaster = async (data) => {
  let ret = {};

  let url = "delgamemaster";

  ret = await callAjax(url,data);
  return ret;
};
export const GetGamemasterData = async (data) => {
  let ret = {};
  let url = "getgamemaster";
   ret = await callAjax(url,data);
  return ret;   
};

export const GetAllGameData = async (data) => {
  let ret = {};
  ret = await callAjax("searchgame", data);
  return ret;
};
