import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import AdminPlayMasterReport from '../../Components/AdminPlayMasterReport/AdminPlayMasterReport'

const AdminPlayMasterReportScreen = () => {
  return (
    <div>
        <Appbar />
        <AdminPlayMasterReport />
    </div>
  )
}

export default AdminPlayMasterReportScreen