import { configureStore } from "@reduxjs/toolkit";
import holidayReducer from '../Reducer/holidayReducer';
import confirmDialogReducer from '../Reducer/confirmDialogReducer';
import alertReducer from "../Reducer/alertReducer";
import playReducer from "../Reducer/playReducer";
import userReducer from "../Reducer/userReducer";
import doublePanReducer from "../Reducer/doublePanReducer";
import predictionChartReducer from "../Reducer/predictionChartReducer";

const store = configureStore({
    reducer: {
        confirm:confirmDialogReducer,
        alert:alertReducer,
        holiday:holidayReducer,
        play:playReducer,
        user:userReducer,
        doublepan:doublePanReducer,
        predictionchart:predictionChartReducer,
    }
});

export default store;