import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Slide from "@mui/material/Slide";
import style from "./Appbar.module.css";
import PersonIcon from "@mui/icons-material/Person";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import LogoutIcon from "@mui/icons-material/Logout";
import CloseIcon from "@mui/icons-material/Close";
import useMenumaster from "../../API/Hooks/useMenumaster";
import GamesIcon from "@mui/icons-material/Games";
import PolylineIcon from "@mui/icons-material/Polyline";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import { isSmallScreen } from "../../MIS/Global";
import img from "../../Assets/logo2.png";

const drawerWidth = 240;
const navItems = [];

function HideOnScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const textstyle = {
  fontSize: "16px",
  fontWeight: 500,
};

const Appbar = (props) => {
  const menuMaster = { ...useMenumaster() };
  const { windows } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const navigate = useNavigate();

  const [scrolled, setScrolled] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openMaster, setOpenMaster] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClickMaster = () => {
    setOpenMaster(!openMaster);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if(isSmallScreen()===false){
    if(localStorage.getItem("loginid")==="" || localStorage.getItem("loginid")===null){
      navigate("/")
    }
  }
  }, [])
  

  const drawer = (
    <Box
      // onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
        backgroundColor: "whitesmoke",
        color: "black",
        height: "100vh",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          my: 2,
          display: "flex",
          justifyContent: "space-between !important",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
        onClick={() => {
          // navigate("/home");
        }}
      >
        <div className={style.logomain}>
          <div className={style.webheading}>SPGame</div>
        </div>
        <div style={{ cursor: "pointer" }} onClick={handleDrawerToggle}>
          <CloseIcon sx={{ fontSize: "20px" }} />
        </div>
      </Typography>
      <Divider />
      <List>
        <ListItemButton sx={{ display: "none" }}>
          <ListItemText
            onClick={() => {
              navigate("/");
            }}
            className={style.menuitem}
            style={{ marginTop: "80px" }}
            primary="Home"
          />
        </ListItemButton>
        <ListItemButton onClick={handleClickMaster}>
          <GamesIcon />
          <ListItemText
            className={style.menuitem}
            primary="Master"
            primaryTypographyProps={textstyle}
          />
          {open ? (
            <ExpandLess className="exlm" />
          ) : (
            <ExpandMore className="exlm" />
          )}
        </ListItemButton>

        
        <Collapse in={openMaster} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          <ListItemButton>
           <ListItemText
            className={style.menuitem}
            primary="Game Master"
            primaryTypographyProps={textstyle}
            onClick={() => {
              menuMaster?.navigateGameMaster();
            }}
          />
        </ListItemButton>
        <ListItemButton>
          {/* <PolylineIcon /> */}
          <ListItemText
            className={style.menuitem}
            primary="Draw Master"
            primaryTypographyProps={textstyle}
            onClick={() => {
              menuMaster?.navigateDrawMaster();
            }}
          />
        </ListItemButton>
        <ListItemButton>
          {/* <LinkOffIcon /> */}
          <ListItemText
            className={style.menuitem}
            primary="Holiday Master"
            primaryTypographyProps={textstyle}
            onClick={() => {
              menuMaster?.navigateHoliday();
            }}
          />
        </ListItemButton>
        <ListItemButton>
          {/* <LinkOffIcon /> */}
          <ListItemText
            className={style.menuitem}
            primary="Forgot Password List"
            primaryTypographyProps={textstyle}
            onClick={() => {
              navigate("/forgotpasswordlits");          
             }}
          />
        </ListItemButton>
          </List>
        </Collapse>                 
        <ListItemButton>
          <SportsBasketballIcon />
          <ListItemText
            className={style.menuitem}
            primary="Play Master"
            primaryTypographyProps={textstyle}
            onClick={() => {
              // menuMaster?.navigateAdminPlay();
              window.location.href="adminplay";
            }}
          />
        </ListItemButton>        
        <ListItemButton>
          <AutoGraphIcon/>
          <ListItemText
            className={style.menuitem}
            primary="Draw Result"
            primaryTypographyProps={textstyle}
            onClick={() => {
              menuMaster?.navigateDrawresult();
            }}
          />
        </ListItemButton>
        <ListItemButton sx={{display:"none"}}>
          <AutoGraphIcon/>
          <ListItemText
            className={style.menuitem}
            primary="Result Analytics"
            primaryTypographyProps={textstyle}
            onClick={() => {
              menuMaster?.navigateResultAnalytics();
            }}
          />
        </ListItemButton>
        <ListItemButton>
          <AcUnitIcon/>
          <ListItemText
            className={style.menuitem}
            primary="Today's Play"
            primaryTypographyProps={textstyle}
            onClick={() => {
              menuMaster?.navigateAdminPlayHistory();
            }}
          />
        </ListItemButton>
        
        <ListItemButton>
          <PersonIcon />
          <ListItemText
            className={style.menuitem}
            primary="User List"
            primaryTypographyProps={textstyle}
            onClick={() => {
              menuMaster?.navigateadminuserlist();
            }}
          />
        </ListItemButton>
        <ListItemButton>
          <DeviceHubIcon/>
          <ListItemText
            className={style.menuitem}
            primary="Cutpan Configuration"
            primaryTypographyProps={textstyle}
            onClick={() => {
              menuMaster?.navigateCutpanConfig();
            }}
          />
        </ListItemButton>
        <ListItemButton>
          <RequestQuoteIcon />
          <ListItemText
            className={style.menuitem}
            primary="Wallet Request"
            primaryTypographyProps={textstyle}
            onClick={() => {
              menuMaster?.navigateadminwallet();
            }}
          />
        </ListItemButton>
        <ListItemButton onClick={handleClick}>
          <AssessmentIcon />
          <ListItemText
            className={style.menuitem}
            primary="Reports"
            primaryTypographyProps={textstyle}
          />
          {open ? (
            <ExpandLess className="exlm" />
          ) : (
            <ExpandMore className="exlm" />
          )}
        </ListItemButton>       
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton onClick={() => {}} sx={{ pl: 4, display:"none" }}>
              <ListItemText
                className={style.menuitem}
                primary="Ledger Report"
                primaryTypographyProps={textstyle}
                onClick={() => {
                  menuMaster?.navigateUserAcLedger();
                }}
              />
            </ListItemButton>
            <ListItemButton onClick={() => {}} sx={{ pl: 4 }}>
              <ListItemText
                className={style.menuitem}
                primary="Admin Play List"
                primaryTypographyProps={textstyle}
                onClick={() => {
                  navigate("/adminplaylist");
                }}
              />
            </ListItemButton>
            <ListItemButton onClick={() => {}} sx={{ pl: 4 }}>
              <ListItemText
                className={style.menuitem}
                primary="Commission Report"
                primaryTypographyProps={textstyle}
                onClick={() => {
                  navigate("/commissionreport");
                }}
              />
            </ListItemButton>
            <ListItemButton onClick={() => {}} sx={{ pl: 4 }}>
              <ListItemText
                className={style.menuitem}
                primary="Play History"
                primaryTypographyProps={textstyle}
                onClick={() => {
                  navigate("/adminplayhistoryy");
                }}
              />
            </ListItemButton>            
            <ListItemButton onClick={() => {}} sx={{ pl: 4 }}>
              <ListItemText
                className={style.menuitem}
                primary="User Reports"
                primaryTypographyProps={textstyle}
                onClick={() => {
                  menuMaster?.navigateAdminPL();
                }}
              />
            </ListItemButton>

          </List>
        </Collapse>
       

        <Divider />
        <ListItemButton sx={{ marginTop: "10px" }}>
          <LogoutIcon />
          <ListItemText
            className={style.menuitem}
            primary="LogOut"
            primaryTypographyProps={textstyle}
            onClick={() => {
              // alert(localStorage.getItem("loginid1"));
              localStorage.setItem("loginid","")
              menuMaster?.navigateadminlogout();
              // navigate("/")
            }}
          />
        </ListItemButton>
      </List>
    </Box>
  );

  const container =
    windows !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <React.Fragment>
        <CssBaseline />
        <HideOnScroll {...props}>
          <AppBar
            className={`${
              isSmallScreen() == false ? style.appbar : style.appbartr
            } ${scrolled ? `${style.scrolled}` : ""}`}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{
                  mr: 2,
                  color: "white",
                  display: `${isSmallScreen() == true ? "none" : ""}`,
                }}
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{
                  mr: 0,
                  color: "white",
                }}
              >
                <img src={img} style={{ width: "24px", height: "24px" }} />
              </IconButton>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  color: `${isSmallScreen() == true ? "#2267b4" : "#fff"}`,
                }}
              >
                <div className={style.logomain}>
                  <div className={style.logocon}>
                    {/* <img src={img} className={style.logosty}/> */}
                    <div style={{ fontSize: "20px", fontWeight: "700" }}>
                      SP Games
                    </div>
                  </div>
                </div>
              </Typography>
            </Toolbar>
          </AppBar>
        </HideOnScroll>
        <Toolbar />
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              // display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </React.Fragment>
    </div>
  );
};

export default Appbar;
