import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css";
import { Grid, TableContainer, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import AddIcon from "@mui/icons-material/Add";

import style from "../../src/Components/FundRequest/FundRequest.module.css";

const FundRequestTable = (props) => {
  return (
    <>
      <div>
        <Paper className="">
          <TableContainer sx={{ maxHeight: "64vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableBody className="bodycon">
                {props?.data?.fundsearchState?.map((element, index) => {
                  let bgcolor = "#F1F0E8";
                  let stscolor = "#6699CC";
                  if (index % 2 == 0) {
                    bgcolor = "#fff";
                  }
                  if (element?.isstatus == 0) {
                    stscolor = "#358ab7";
                  } else if (element?.isstatus == 1) {
                    stscolor = "#009933 ";
                  } else {
                    stscolor = "#FF0000";
                  }
                  return (
                    <TableRow
                      className="tblerowdata"
                      style={{ backgroundColor: bgcolor }}
                    >
                      <TableCell>
                        <Grid container spacing={1}>
                          <Grid item xs={2} md={3}>
                            <div
                              className={style.addfundicon}
                              style={{
                                backgroundColor: `${
                                  element?.isdrcr == 0 ? "#009933" : "#358ab7"
                                }`,
                              }}
                            >
                              {element?.isdrcr == 0 ? (
                                <AddIcon sx={{ color: "white" }} />
                              ) : (
                                <RestartAltIcon sx={{ color: "white" }} />
                              )}
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={7}
                            md={3}
                            className={style.addreqcontainer}
                          >
                            <div className={style.addreq}>
                              {element?.isdrcr == 0
                                ? "Add Fund Request"
                                : "Withdrawal Request"}
                            </div>
                            <div className={style.addreqdate}>
                              {props?.data?.formatDate(element?.edate)}
                            </div>
                          </Grid>
                          <Grid item xs={3} md={3} className={style.amountcon}>
                            <div
                              className={style.deliconcon}
                              onClick={() => {
                                props?.data?.deleteFundRequest(element);
                              }}
                            >
                              {element?.isstatus == 0 ? (
                                <DeleteIcon
                                  sx={{ fontSize: "20px", color: "#FF2400" }}
                                />
                              ) : null}
                            </div>
                            <div className={style.usercounttxt}>
                              {element?.points}
                            </div>
                            <div
                              className={style.pendingcon}
                              style={{ backgroundColor: stscolor }}
                            >
                              {element?.isstatus == 0
                                ? "Pending"
                                : element?.isstatus == 1
                                ? "Complete"
                                : element?.isstatus == 2
                                ? "Cancel"
                                : ""}
                            </div>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
};
export default FundRequestTable;
