import React from 'react'
import GameMaster from '../../Components/GameMaster/GameMaster'
import Appbar from '../../Components/Appbar/Appbar'

const GameMasterScreen = () => {
  return (
    <div>
        <Appbar />
        <GameMaster />
    </div>
  )
}

export default GameMasterScreen