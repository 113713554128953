import React, { useContext, useEffect, useReducer, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css";
import { Checkbox, TableContainer, TextField } from "@mui/material";




 
const AdminAccordionTable = (props) => {
 
  return (
    <>
      <div>
        <Paper className="contner1">
          <TableContainer sx={{ maxHeight: "50vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableHead>
                <TableRow>

                  <TableCell style={{textAlign:"center"}}>Name</TableCell>
                  <TableCell style={{textAlign:"center"}}>Number</TableCell>
                  <TableCell style={{textAlign:"right"}}>Total Points</TableCell>

                </TableRow>
              </TableHead>

              <TableBody className="bodycon">
                {props?.data?.map((element,index) => {
                  let npoints=element?.npoints.split('X');
                  let bgcolor = "#F1F0E8";
                  if (index % 2 == 0) {
                    bgcolor = "#fff";
                  }
                  return (
                    <TableRow className="tblerowdata" style={{backgroundColor:bgcolor}}>
                      <TableCell style={{textAlign:"center"}}>
                        {element?.gname}
                      </TableCell>
                      <TableCell component="th" scope="row" style={{textAlign:"center"}}>
                      {npoints.length>1? `${npoints[0]}  X  ${npoints[1]}` :element?.npoints}
                      </TableCell>
                      <TableCell component="th" scope="row" style={{textAlign:"right"}}>
                      {Number(element?.points).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  )
                }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
};
export default AdminAccordionTable;
