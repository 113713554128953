import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import RegisterUsersList from '../../Components/RegisterUsersList/RegisterUsersList'

const RegisterUserListScreen = () => {
  return (
    <div>
        <Appbar />
        <RegisterUsersList />
    </div>
  )
}

export default RegisterUserListScreen