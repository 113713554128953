import React, { useEffect } from 'react'
import { Box, Grid } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import style from "./Resultchart.module.css"
import ResultChartTable from './ResultChartTable';
import CloseResultChartTable from './CloseResultChartTable';
import useResultAnyalytics from '../../API/Hooks/useResultAnyalytics';
import { useDispatch, useSelector } from 'react-redux';
import { getPredictionChart } from '../../API/Reducer/predictionChartReducer';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const ResultPredictionChart = (props) => {
  const useResult = {...useResultAnyalytics() };
  useEffect(() => {        
    useResult?.setdrawid(props?.data?.drawid);
  }, [props?.data?.drawid])
 
  
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
      setValue(index);
    };

  return (
    <div className={style.resultmain}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <div className={style.resulthead}>
          Result Prediction Chart
        </div>
      </Grid>
        <Grid item xs={12} md={12}>
        <Box sx={{ maxWidth: { xs: "100%", sm: "100%"}, bgcolor: 'whitesmoke' }}>
            <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
        
    >
        <Tab label="Open Result" />
        <Tab label="Close Result" />
        </Tabs>
        </Box>
        <Box>
        <SwipeableViews
    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
    index={value}
    onChangeIndex={handleChangeIndex}
  >
    <TabPanel value={value} index={0} dir={theme.direction}>
     {props?.data?.tbldata.length>0?
      (
        <ResultChartTable data={{calculateSum:useResult?.calculateSum,tbldata:props?.data?.tbldata}}/>
      )
     :
     (<h1>Data not found</h1>)}
     
    </TabPanel>
    <TabPanel value={value} index={1} dir={theme.direction}>
    {props?.data?.tbldata.length>0?
      (
        <CloseResultChartTable data={{calculateSum:useResult?.calculateSum,tbldata:props?.data?.tbldata}}/>
      )
     :
     (<h1>Data not found</h1>)}
     
    </TabPanel>
  </SwipeableViews>
  </Box>
        </Grid>
    </Grid>
</div>
  )
}

export default ResultPredictionChart