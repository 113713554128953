import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css";
import {
  TableContainer,
  TableHead,
  TablePagination,
} from "@mui/material";

import style from "../../src/Components/AdminPLStatment/AdminPL.module.css";
import { formatDatetime_DMY, getHourTime, isSmallScreen } from "../MIS/Global";
import UserAcStatmentRpt from "../Components/Reports/UserAcStatmentRpt";
const PLstatementTable = (props) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const rowsPerPageOptions = [
        50,100,125,150,200,300,500,1000,
        { value: props?.data?.length, label: "All" },
      ];
      const [userBalDet, setUserBalDet] = useState({cr:0,dr:0,drcl:0,crcl:0});

      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(+event.target.value, 10));
        setPage(0);
      };

      useEffect(() => {        
        let dr,cr,calCl;
        if(props?.useropening?.[0]?.opening < 0){
          dr=Number(props?.userdrcr?.[0]?.dr) + Number(props?.useropening?.[0]?.opening*-1);
          cr=props?.userdrcr?.[0]?.cr;
          calCl=Number(cr)-Number(dr);
          if(calCl<0){
            calCl=calCl*-1;
            setUserBalDet({cr:cr,dr:dr,
              drcl:calCl,crcl:0});
          }else{
            setUserBalDet({cr:cr,dr:dr,
              drcl:0,crcl:calCl});
          }         
        }else{
          cr=Number(props?.userdrcr?.[0]?.cr)+Number(props?.useropening?.[0]?.opening);
          dr=props?.userdrcr?.[0]?.dr;
          calCl=Number(cr)-Number(dr);
          if(calCl<0){
            calCl=calCl*-1;
            setUserBalDet({cr:cr,dr:dr,
              drcl:calCl,crcl:0});
          }else{
            setUserBalDet({cr:cr,dr:dr,
              drcl:0,crcl:calCl});
          }
        }
      }, [props?.userdrcr])
      
    
  return (
    <>
     <div style={{display:"none"}}>
       <UserAcStatmentRpt data={props?.data} useropening={props?.useropening} cldata={userBalDet} fdate={props?.fdate} tdate={props?.tdate}/>
      </div>
      <div>
        <Paper className="">
          <TableContainer sx={{ maxHeight: "55vh" }}>
            <Table
              id="content"
              size="small"
              stickyHeader
              aria-label="sticky table"
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell className={style.tblHead} style={{display:isSmallScreen()===true?"none":""}}>User</TableCell>                  
                  <TableCell className={style.tblHead}>Particular</TableCell>
                  <TableCell className={style.tblHeadR} >Debit</TableCell>
                  <TableCell className={style.tblHeadR}>Credit</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={style.tblHead} style={{display:isSmallScreen()===true?"none":""}}></TableCell>                  
                  <TableCell className={style.tblHeadR}>Opening</TableCell>
                  <TableCell className={style.tblHeadR}>{props?.useropening?.[0]?.opening < 0 ? Number(props?.useropening?.[0]?.opening).toFixed(2):""}</TableCell>
                  <TableCell className={style.tblHeadR}>{props?.useropening?.[0]?.opening > 0 ? Number(props?.useropening?.[0]?.opening).toFixed(2):""}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="bodycon">
                {props?.data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((element, index) => {               
                  let bgcolor = "#F1F0E8";
                  let stscolor = "#6699CC";
                  if (index % 2 == 0) {
                    bgcolor = "#fff";
                  }
                  if(element?.isentry===1){
                    bgcolor = "#00ff00";
                  }
                  if (element?.isdrcr == 0) {
                    stscolor = "#009933";
                  } else if (element?.isdrcr == 1) {
                    stscolor = "#6699CC ";
                  }
                  const string = element?.remark || "";
                  const separatedStrings = string.split("$");
                  return (
                    <TableRow
                      className="tblerowdata"
                      style={{ backgroundColor: bgcolor }}
                    >
                      <TableCell className={style.tblRow} style={{display:isSmallScreen()===true?"none":""}}>
                       <div style={{display:"flex",flexDirection:"column"}}>
                          <span>{element?.uname} - {element?.mob}</span>
                          <span style={{fontStyle:"italic"}}>{formatDatetime_DMY(element?.edate,0,"")}&nbsp;&nbsp;{getHourTime(element?.edate,12)}</span>
                       </div>                      
                      </TableCell>                      
                      <TableCell className={style.tblRow}>
                      <div style={{display:isSmallScreen()===false?"none":"flex",flexDirection:"column"}}>
                          <span>{element?.remark}</span>
                          <span style={{fontStyle:"italic"}}>{formatDatetime_DMY(element?.edate,0,"")}&nbsp;&nbsp;{getHourTime(element?.edate,12)}</span>
                       </div> 
                       <div style={{display:isSmallScreen()===true?"none":"flex",flexDirection:"column"}}>
                          <span>{element?.plyid>0?separatedStrings?.[0]:""}</span>
                          <span>{element?.plyid>0?separatedStrings?.[1]:""}</span>
                          <span>{element?.plyid<=0?element?.remark:""}</span>
                       </div>                         
                      </TableCell>
                      <TableCell className={style.tblRowR}>{Number(element?.dr).toFixed(2)}</TableCell>
                      <TableCell className={style.tblRowR}>
                      {Number(element?.cr).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
               <TableRow>
                  <TableCell className={style.tblHead} style={{display:isSmallScreen()===true?"none":""}}></TableCell>                  
                  <TableCell className={style.tblHead}></TableCell>
                  <TableCell className={style.tblHeadR}>{Number(userBalDet?.dr).toFixed(2)}</TableCell>
                  <TableCell className={style.tblHeadR}>{Number(userBalDet?.cr).toFixed(2)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={style.tblHead} style={{display:isSmallScreen()===true?"none":""}}></TableCell>                  
                  <TableCell className={style.tblHeadR}>Closing</TableCell>
                  <TableCell className={style.tblHeadR}>{Number(userBalDet?.drcl).toFixed(2)}</TableCell>
                  <TableCell className={style.tblHeadR}>{Number(userBalDet?.crcl).toFixed(2)}</TableCell>
                </TableRow>

            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={props?.data?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
       </div>
    </>
  );
};
export default PLstatementTable;
