import { useEffect, useReducer, useState, React } from "react";
import { tostmsg } from "../../MIS/Global";  
import PlayMasterReducer from "../Reducer/PlayMasterReducer";
import { useParams } from 'react-router-dom';
import {  SearchAllPlaydata } from "../Model/PlaymasterModel";

function usePlayhistoryResult() {
  const { gname } = useParams();
  const [alertdata, setAlertdata] = useState({});
  const [alertopen, setAlertopen] = useState(false);
  const [sumpoints, setSumpoints] = useState(0);
  const [profitsum, setProfitsum] = useState(0);
  const [sumloss, setSumloss] = useState(0);
  const [fdate, setFdate] = useState(new Date());
  const [tdate, setTdate] = useState(new Date());
  const [trid, setTrid] = useState("");
  const [searchplaymasterstate, searchplaymasterDispach] = useReducer(PlayMasterReducer, []);
  const [inputCtrlSetting, setInputCtrlSetting] = useState({fdatedisable:false,tdatehide:"",fdatemin:""});

  const handleplaymaster = (e, value) => {
    setTrid(value);
  };

  const handleFplaydate = (e, value) => {
    setFdate(value);
  };
  
  const handleTplaydate = (e, value) => {
    setTdate(value);
  };

  function formatDate(date) {
    const options = {
     day: "2-digit",
    month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
  };
    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      new Date(date)
    );
    const formattedDateWithoutComma = formattedDate.replace(",", "");
    return formattedDateWithoutComma.replace(/(\s[ap]m)/i, (match) =>
      match.toUpperCase()
    );
  }
  const formatDatetime_YMD = (date, pmday) => {
    const currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + pmday);
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")} ${currentDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${currentDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;

    return formattedDate;
  };

  const formatDatetime_DMY = (date, pmday) => {
    const currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + pmday);

    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear();

    const hours = currentDate.getHours() % 12 || 12; // Convert to 12-hour format
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");
    const period = currentDate.getHours() >= 12 ? "PM" : "AM";

    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes} ${period}`;

    return formattedDate;
  };
  const SearchPlaydata = async (data) => {
    try {        
        // alert(JSON.stringify(data));
      let getplaydata = await SearchAllPlaydata(data);
      if (getplaydata.code === 200) {
        const sum = getplaydata.data.reduce((sum, item) => sum + item.points, 0);
        setSumpoints(sum);
        const profit = getplaydata.data.reduce((sum, item) => sum + parseFloat(item.wamount), 0);
        setProfitsum(profit);
        const sumloss = getplaydata.data
        .filter(item => parseFloat(item.wamount) === 0.00)
        .reduce((sum, item) => sum + item.points, 0);
        setSumloss(sumloss);
        // alert(`Sum of profit: ${profit}`);
        const sortedArray = getplaydata?.data.sort((a, b) => b.playid - a.playid);

        const groupedData = sortedArray?.reduce((acc, entry) => {
            const { trid, playdate, gname, gsubname, npoints, points,wamount } = entry;
    
            if (!acc[trid]) {
              acc[trid] = { trid, playdate, gname, gsubname, npoints, points, wamount, entries: [] };
            }
    
            acc[trid].entries.push(entry);
    
            return acc;
          }, {});
    
          const resultArray = Object.values(groupedData);

        searchplaymasterDispach({
          type: "details",
          data: resultArray,
        });
      }
    } catch (error) {
      // Handle any errors here
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };
  useEffect(() => {    
    let logdata=localStorage.getItem("logindata");
    const jsonObject = JSON.parse(logdata);        
    if(window.location.pathname==="/position"){      
      setInputCtrlSetting({fdatedisable:true,tdatehide:"none",fdatemin:formatDatetime_YMD(jsonObject?.adate,0),hname:"Today's Orders"});
    }else{
      setInputCtrlSetting({fdatedisable:false,tdatehide:"",fdatemin:formatDatetime_YMD(jsonObject?.adate,0),hname:"Order History"});
    }
    SearchPlaydata({
      pfdate:formatDatetime_YMD(new Date(),0),
      ptdate:formatDatetime_YMD(new Date(),1),
      loginid:localStorage.getItem("loginId"),
    });
  }, [])

  const filterPlayData=()=>{
    SearchPlaydata({
      pfdate:formatDatetime_YMD(fdate,0),
      ptdate:formatDatetime_YMD(tdate,1),
      loginid:localStorage.getItem("loginId"),
    });
  }


  return {    
    alertdata,
    alertopen,
    setAlertopen,
    searchplaymasterstate,
    sumpoints,
    profitsum,
    sumloss,
    formatDate,
    fdate,
    tdate,
    handleFplaydate,
    handleTplaydate,
    handleplaymaster,
    trid,filterPlayData,inputCtrlSetting,
   };
}

export default usePlayhistoryResult;