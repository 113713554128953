import React, { useContext, useEffect, useReducer, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css";
import {
  Checkbox,
  Grid,
  TableContainer,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles, styled } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";
import style from "../Components/UserPlayMaster/UserPlay.module.css";
import { handleNumInputChange } from "../MIS/Global";

const arr = [1, 2, 3, 4, 5, 6];

const useStyles = makeStyles({
  noBorder: {
    border: "none !important",
    "& .MuiInput-underline:before": {
      // borderBottom: `${props.border=="none"?"none !important":""}`,
      borderBottom: "none !important",
    },
  },
});

const PlaymasterTable = (props) => {
  const classes = useStyles();

  return (
    <>
      <div>
        <Paper className="contner1">
          <TableContainer sx={{ maxHeight: "50vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableBody className="bodycon">
                {props?.data?.playmasterstate
                  ?.filter(
                    (element) => element.gtmid === props?.data?.getGtmid()
                  )
                  ?.map((element, index) => {
                    let bgcolor = "#F1F0E8";
                    if (index % 2 == 0) {
                      bgcolor = "#fff";
                    }
                    let isNumVisibal = {
                      num1: "",
                      numX: "none",
                      num2: "none",
                      num1Width: "50%",
                      num2Width: "50%",
                    };
                    const npoints = element?.npoints.split("X");
                    if (npoints.length >= 2) {
                      isNumVisibal = { num1: "", numX: "", num2: "" };
                    }
                    return (
                      <TableRow
                        className="tblerowdata"
                        style={{ backgroundColor: bgcolor }}
                      >
                        <TableCell>
                          <Grid container spacing={1}>
                            <Grid item xs={7} md={8}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <TextField
                                  size="small"
                                  variant="standard"
                                  inputMode="numeric"
                                  label="Number"
                                  value={npoints[0]}
                                  onChange={(e) => {
                                    props?.data?.handleUpdatePlay(
                                      element?.id,
                                      "npoints",
                                      e.target.value,
                                      element?.num2,
                                      e.target.value
                                    );
                                  }}
                                  sx={{
                                    marginBottom: "5px",
                                    display: isNumVisibal?.num1,
                                    textAlign: "center",
                                  }}
                                  InputProps={{
                                    classes: {
                                      input: style.textinp_left,
                                    },
                                  }}
                                  InputLabelProps={{
                                    className: style.textinplbl,
                                    shrink: true,
                                  }}
                                />
                                <Typography
                                  variant="body1"
                                  component="div"
                                  style={{
                                    minWidth: "20px",
                                    textAlign: "center",
                                    marginTop: "12%",
                                    display: `${isNumVisibal?.numX}`,
                                  }}
                                >
                                  X
                                </Typography>
                                <TextField
                                  size="small"
                                  variant="standard"
                                  inputMode="numeric"
                                  label="Number"
                                  value={npoints.length >= 2 ? npoints[1] : ""}
                                  onChange={(e) => {
                                    props?.data?.handleUpdatePlay(
                                      element?.id,
                                      "npoints",
                                      element?.num1,
                                      e.target.value,
                                      e.target.value
                                    );
                                  }}
                                  sx={{
                                    marginBottom: "5px",
                                    display: isNumVisibal?.num2,
                                    textAlign: "center",
                                  }}
                                  InputProps={{
                                    classes: {
                                      input: style.textinp_left,
                                    },
                                  }}
                                  InputLabelProps={{
                                    className: style.textinplbl,
                                    shrink: true,
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={3} md={4}>
                              <TextField
                                size="small"
                                variant="standard"
                                inputMode="numeric"
                                fullWidth
                                label="Points"
                                value={element?.points}
                                onInput={handleNumInputChange}
                                onChange={(e) => {
                                  props?.data?.handleUpdatePlay(
                                    element?.id,
                                    "points",
                                    "",
                                    "",
                                    e.target.value
                                  );
                                }}
                                InputProps={{
                                  classes: {
                                    input: style.textinp,
                                  },
                                }}
                                InputLabelProps={{
                                  className: style.textinplbl,
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              md={2}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <div className="actionbtn">
                                <Tooltip title="Delete" placement="top" arrow>
                                  <div
                                    className="edde"
                                    onClick={(e) => {
                                      props?.data?.handleDeletePlay(
                                        element?.id
                                      );
                                    }}
                                  >
                                    <DeleteIcon sx={{ fontSize: "18px" }} />
                                  </div>
                                </Tooltip>
                              </div>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="collectiontbl">
            <div className="collectiontotal">Total</div>
            <div className="collectiontotal">
              {props?.data?.playmasterstate
                ?.filter((element) => element.gtmid === props?.data?.getGtmid())
                ?.reduce((acc, obj) => Number(acc) + Number(obj.points), 0)
                .toFixed(2)}
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
};
export default PlaymasterTable;
