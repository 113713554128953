import { Button, Grid, TextField } from "@mui/material";
import React from "react";
import style from "./AdminPlay.module.css";
import AddIcon from "@mui/icons-material/Add";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { handleNumInputChange } from "../../MIS/Global";
import { AddBtnPlay } from "../../API/Reducer/playReducer";

const PlayAdminInput = () => {
  const dispatch = useDispatch();
  const {playBtnSelect,gameList,playData,playUser} = useSelector((state) => state.play);    
  const [numinp, setNuminp] = useState({num1:true,num2:false,num1len:1,num2len:1,num1val:"",num2val:"",point:""});
  
  useEffect(() => {
    const trueKey = Object.keys(playBtnSelect).find((key) => playBtnSelect[key] === true);
    if(trueKey!==undefined){
      if(trueKey==="o"){
        setNuminp({num1:true,num2:false,num1len:1,num2len:1,num1val:"",num2val:"",point:"",gname:"Open",gtmid:1})
      }else if(trueKey==="c"){
        setNuminp({num1:true,num2:false,num1len:1,num2len:1,num1val:"",num2val:"",point:"",gname:"Close",gtmid:2})
      }else if(trueKey==="j"){
        setNuminp({num1:true,num2:false,num1len:2,num2len:1,num1val:"",num2val:"",point:"",gname:"Jodi",gtmid:3})
      }else if(trueKey==="g"){
        setNuminp({num1:true,num2:true,num1len:3,num2len:1,num1val:"",num2val:"",point:"",gname:"Gunule",gtmid:7})
      }else if(trueKey==="op"){
        setNuminp({num1:true,num2:false,num1len:3,num2len:1,num1val:"",num2val:"",point:"",gname:"Open Panna",gtmid:4})
      }else if(trueKey==="cp"){
        setNuminp({num1:true,num2:false,num1len:3,num2len:1,num1val:"",num2val:"",point:"",gname:"Close Panna",gtmid:5})
      }else if(trueKey==="jk"){
        setNuminp({num1:true,num2:true,num1len:3,num2len:3,num1val:"",num2val:"",point:"",gname:"Jackpot",gtmid:7})
      }else if(trueKey==="ug"){
        setNuminp({num1:true,num2:true,num1len:1,num2len:3,num1val:"",num2val:"",point:"",gname:"Ulta Gunule",gtmid:8})
      }
    }
  },[playBtnSelect,playData])
  const handleNumPointChange=(e,index)=>{
    if(index===0){
    setNuminp((prevNuminp) => ({
      ...prevNuminp,   
      num1val: e?.target.value, 
    }));
    }else if(index===1){
      setNuminp((prevNuminp) => ({
        ...prevNuminp,   
        num2val: e?.target.value, 
      }));

    }else if(index===2){
      setNuminp((prevNuminp) => ({
        ...prevNuminp,   
        point: e?.target.value, 
      }));
      
    }
  }
  return (
    <div>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={6} md={4} sx={{display:numinp?.num1===false?"none":""}}>
          <TextField
            type="tel"
            inputMode="numeric"
            label="Number-1"
            fullWidth
            size="small"
            variant="standard"
            value={numinp?.num1val}
            onChange={(e)=>{handleNumPointChange(e,0)}}
            InputProps={{
              classes: {
                input: style.textinp_left,
              },
              style: {
                padding: "1px",
              },
              inputProps: {                    
                  maxLength: numinp?.num1len,
              },  
            }}
            InputLabelProps={{
              className: style.textinplbl,
            }}
            onInput={handleNumInputChange}
          />
        </Grid>
        <Grid item xs={6} md={4} sx={{display:numinp?.num2===false?"none":""}}>
          <TextField
            className={style?.no_spin_buttons}
            type="text"
            inputMode="numeric"
            label="Number-2"
            fullWidth
            size="small"
            variant="standard"
            value={numinp?.num2val}
            onChange={(e)=>{handleNumPointChange(e,1)}}
            InputProps={{
              classes: {
                input: style.textinp_left,
              },
              style: {
                padding: "1px",
              },
              inputProps: {                    
                  maxLength: numinp?.num2len,
              }, 
            }}
            InputLabelProps={{
              className: style.textinplbl,
            }}
            onInput={handleNumInputChange}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            type="text"
            id="phone"
            inputMode="numeric"
            label="Points"
            fullWidth
            size="small"
            variant="standard"
            value={numinp?.point}
            onChange={(e)=>{handleNumPointChange(e,2)}}
            InputProps={{
              classes: {
                input: style.textinp_left,    
              },
              style: {
                padding: "1px",                
              },     
              inputProps: {                    
                  maxLength: 6,
              },       
            }}
            InputLabelProps={{
              className: style.textinplbl,
            }}
            onInput={handleNumInputChange}
          />
        </Grid>
        <Grid item xs={2} md={12} className={style.btnposition}>
         <Button type="button" variant="contained" color="primary" size="small" onClick={()=>{
          dispatch(AddBtnPlay(playData,numinp,playUser,gameList,playBtnSelect))            
         }}>
          <AddIcon />
         </Button>
 
        </Grid>
      
      </Grid>
    </div>
  );
};

export default PlayAdminInput;
