 import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import AdminGameMaster from '../../Components/AdminGameMaster/AdminGameMaster'
 
 const AdminGameMasterScreen = () => {
   return (
     <div>
        <Appbar />
        <AdminGameMaster />
     </div>
   )
 }
 
 export default AdminGameMasterScreen