import React from "react";
import style from "./PlayHistoryTable.module.css";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { TableHead } from "@mui/material";

const PlayHistoryTable = (props) => {
  let sumPL=0;
  const getHourTime = (dateTime, ishour) => {
    let bal = false;
    if (ishour.toString() === "12".toString()) {
      bal = true;
    } else {
      bal = false;
    }
    let curDate = new Date(dateTime);
    const timeString = curDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: bal,
    });
    return timeString;
  };
  return (
    <TableContainer>
      <Table
        size="small"
        stickyHeader
        aria-label="sticky table"
        style={{
          fontSize: "100pt!important",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell style={{ textAlign: "left", fontWeight: "bold" }}>
              Type{" "}
            </TableCell>
            <TableCell style={{ textAlign: "left", fontWeight: "bold" }}>
              Number{" "}
            </TableCell>
            <TableCell style={{ textAlign: "center", fontWeight: "bold" }}>
              P/L{" "}
            </TableCell>
            <TableCell style={{ textAlign: "right", fontWeight: "bold" }}>
              Price{" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.data?.map((element, index) => {
            
            let isResult=false;
            if(element?.gtmid===1 || element?.gtmid===4){
              if(element?.isresult?.toString()==="1"){isResult=true}
            }else{
              if(element?.isresult?.toString() ==="2"){isResult=true}
            }
            sumPL=isResult===false?sumPL+0:
                  element?.wamount !== undefined &&
                  Number(element?.wamount) !== 0
                    ? sumPL + element?.wamount
                    : sumPL + (element?.points*-1);
            return (
              <TableRow className="">
                <TableCell style={{ fontWeight: "bold", textAlign: "left" }}>
                  {element?.gname}
                </TableCell>
                <TableCell style={{ fontWeight: "", textAlign: "left" }}>
                  {element?.npoints} <span style={{fontWeight:"bold"}}>#{element?.points}</span>
                </TableCell>
                <TableCell style={{ fontWeight: "", textAlign: "center" }}>
                  {isResult===false?"NA":
                    element?.wamount !== undefined &&
                  Number(element?.wamount) !== 0 ? (
                    <div>
                      <CheckIcon style={{ color: "green" }} />
                    </div>
                  ) : (
                    <div>
                      <ClearIcon style={{ color: "red" }} />
                    </div>
                  )}
                </TableCell>
                <TableCell style={{ fontWeight: "", textAlign: "right" }}>
                  { isResult===false?"0.00":
                  element?.wamount !== undefined &&
                  Number(element?.wamount) !== 0
                    ? element?.wamount
                    : `-${element?.points}`}
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell  style={{fontWeight:"bold"}}></TableCell>
            <TableCell  style={{fontWeight:"bold"}}></TableCell>
            <TableCell  style={{fontWeight:"bold"}} align="right">Closing P/L</TableCell>
            <TableCell  style={{fontWeight:"bold"}} align="right">{Number(sumPL).toFixed(2)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PlayHistoryTable;
