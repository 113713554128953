import { useEffect, useReducer, useState, React } from "react";
import { useNavigate } from "react-router-dom";
import UserSignupReducer from "../Reducer/UserSignupReducer";
import { GetUserdata } from "../Model/UserSignupModel";
import GamemasterReducer from "../Reducer/GamemasterReducer";
import { GetGamemasterData } from "../Model/GamemasterModel.js";
function useMenumaster() {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [userdatastate, userdataDispatch] = useReducer(UserSignupReducer, []);
  const [gamedatastate, gamedataDispatch] = useReducer(GamemasterReducer, []);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();

  const navigateFreqList = () => {
    navigate("/walletreqlist");
  };
  const navigateFundRequest = () => {
    navigate("/walletreq");
  };
  const navigateLogin = () => {
    localStorage.removeItem("loginId");
    navigate("/login");
  };
  const navigateHome = () => {
    navigate("/home");
  };

  const navigateadminuserlist = () => {
    navigate("/userlist");
  };

  const navigateadminwallet = () => {
    navigate("/adminwalletreqlist");
  };
  const navigateadminlogout = () => {
    navigate("/");
  };
  const navigateuserwalletledger = () => {
    navigate("/userwalletledger");
  };

  const navigateTrade = () => {
    navigate("/trade");
  };

  const navigateUserProfile = () => {
    navigate("/userprofile");
  };
  const navigateGameMaster = () => {
    navigate("/gamemaster");
  };
  const navigateUserAcLedger = () => {
    navigate("/useracledger");
  };
  const navigateDrawMaster = () => {
    navigate("/draw");
  };
  const navigateUserplayMaster = (gname) => {
    navigate("/userplay/" + gname);
  };
  const navigateUserResult = () => {
    navigate("/uplayresult");
  };
  const navigateTodayPlay = () => {
    navigate("/todayhistory");
  };
  const navigateUserPlayHistory = () => {
    navigate("/uplayhistory");
  };

  const navigateDrawresult = () => {
    navigate("/drawresult");
  };
  const navigateAdminPlayHistory = () => {
    navigate("/adminplayhistory");
  };
  const navigateUserPosition = () => {
    window.location.href="/position";
    // navigate("/position");
  };
  const navigateCutpanConfig = () => {
    navigate("/cutpanconfig");
  };
  const navigateResultAnalytics = () => {
    navigate("/resultanalytics");
  };
  const navigateOrderHistory = () => {
    window.location.href="/historyposition";
    // navigate("/historyposition");

  };
  const navigateHoliday = () => {
    navigate("/holiday");
  };
  const navigateAdminPL = () => {
    navigate("/adminpl");
  };
  const navigateAdminPlay = () => {
    navigate("/adminplay");
  };
  
  const getUserDetail = async (data) => {
    try {
      let getuserdata = await GetUserdata(data);
      if (getuserdata.code === 200) {
        // alert(JSON.stringify(getuserdata.data))
        userdataDispatch({
          type: "userDetails",
          data: getuserdata?.data,
        });
      }
    } catch (error) {
      // Handle any errors here
    }
  };
  const formatDatetime_YMD = (date,pmday) => {
    const currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + pmday);
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")} ${currentDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${currentDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;

    return formattedDate;
  };
  const getGameDetail = async (data) => {
    try {
      let getGameData = await GetGamemasterData(data);
      // alert(JSON.stringify(getGameData))
      if (getGameData.code === 200) {
        gamedataDispatch({
          type: "gamemasterDetails",
          data: getGameData?.data,
        });
      }
    } catch (error) {
      // Handle any errors here
    }
  };
  useEffect(() => {
    getUserDetail({ loginid: localStorage.getItem("loginId") });
    getGameDetail({fdate:formatDatetime_YMD(new Date(),0), tdate:formatDatetime_YMD(new Date(),1)});
  }, []);

  useEffect(() => {
    let strPath = window.location.pathname;
    switch (strPath) {
      case "/home":
        setValue(0);
        break;
      case "/walletreq":
        setValue(2);
        break;
      case "/userprofile":
        setValue(1);
        break;
      default:
        setValue(3);
        break;
    }
  }, []);

  return {
    open,
    setOpen,
    value,
    setValue,
    handleDrawerOpen,
    handleDrawerClose,
    navigateFreqList,
    navigateFundRequest,
    navigateLogin,
    navigateHome,
    navigateadminuserlist,
    navigateadminwallet,
    navigateadminlogout,
    navigateuserwalletledger,
    userdatastate,
    navigateTrade,
    navigateUserProfile,
    navigateGameMaster,
    navigateUserAcLedger,
    navigateDrawMaster,
    navigateUserplayMaster,
    gamedatastate,
    navigateUserResult,
    navigateTodayPlay,
    navigateUserPlayHistory,
    navigateDrawresult,navigateAdminPlayHistory,navigateUserPosition,navigateCutpanConfig,
    navigateResultAnalytics,navigateOrderHistory,navigateHoliday,navigateAdminPL,navigateAdminPlay,
    
  };
}

export default useMenumaster;
