import React from 'react'
import PlayHistoryResult from '../../Components/PlayWinLossHistory/PlayHistoryResult'
import Appbar from '../../Components/Appbar/Appbar'

const PlayWinLossHistoryScreen = () => {
  return (
    <div>
        <Appbar/>
        <PlayHistoryResult/>
    </div>
  )
}

export default PlayWinLossHistoryScreen