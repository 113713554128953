import { useEffect, useReducer, useState } from "react";
import PlayMasterReducer from "../Reducer/PlayMasterReducer";
import { SearchAllPlaydata } from "../Model/PlaymasterModel";
import { tostmsg } from "../../MIS/Global";
import { useParams } from "react-router-dom";

function usePlayslip() {
  const { id } = useParams();
  const [alertdata, setAlertdata] = useState({});
  const [alertopen, setAlertopen] = useState(false);

  const [playslipmasterstate, playslipmasterDispach] = useReducer(
    PlayMasterReducer,
    []
  );
  const formatDatetime_DMY = (date,pmday) => {
    const currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + pmday);
    const formattedDate = `${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${currentDate.getFullYear()}`;
  
    return formattedDate;
  };
  const geHourTime = (dateTime,isType) => {
    let hourVal=false;
    if(isType.toString()==="12"){hourVal=true}
    let curDate=new Date(dateTime);
    const timeString = curDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: hourVal,
    });
    return timeString;
  };
  const groupByJsonData = (array, property) => {
    return Object.values(array.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = { gname: key, totalPoints: 0, items: [] };
      }
      acc[key].totalPoints += obj.points;
      acc[key].items.push(obj);
      return acc;
    }, {}));
  };
  const GetPlaySlipdata = async (data) => {
    try {
      let getplayslip = await SearchAllPlaydata(data);
      if (getplayslip.code === 200) {
         const sortedData = getplayslip?.data.slice().sort((a, b) => {
          const nameA = a.gname.toUpperCase(); // Ignore case
          const nameB = b.gname.toUpperCase(); // Ignore case
        
          if (nameA < nameB) {
            return -1;
          }
        
          if (nameA > nameB) {
            return 1;
          }
        
          return 0; // Names are equal
        });
        const groupedData = groupByJsonData(sortedData, "gname");
         playslipmasterDispach({
          type: "details",
          data: groupedData,
        });
        window.print();
      }
    } catch (error) {
      // Handle any errors here
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };

    useEffect(() => {      
      if(id!==undefined){        
        GetPlaySlipdata({trid:id});
     }
    }, [])



  return {
    alertdata,
    alertopen,
    setAlertopen,
    GetPlaySlipdata,
    playslipmasterstate,
    formatDatetime_DMY,
    geHourTime,
  };
}

export default usePlayslip;
