import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css";
import {
  Button,
  Grid,
  TableContainer,
  TableHead,
  TablePagination,
  TextField,
} from "@mui/material";

import style from "../../src/Components/AdminFundRequest/AdminFundRequestList.module.css";


const arr = [1, 2, 3, 4, 5, 6,7,8,9,10];
const LedgerReportTable = (props) => {
   
      

  return (
    <>
      <div>
        <Paper className="container">
          <TableContainer className="container" sx={{ maxHeight: "55vh" }}>
            <Table
            className="tblclass"
              size="small"
              stickyHeader
              aria-label="sticky table"
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableHead>
                <TableRow className="tblrows">
                  <TableCell className={style.tblHead}>SrNo.</TableCell>
                  <TableCell className={style.tblHead}>Name</TableCell>
                  <TableCell className={style.tblHead}>Date</TableCell>
                  <TableCell className={style.tblHead}>Credit</TableCell>
                  <TableCell className={style.tblHead}>Debit</TableCell>
                  <TableCell className={style.tblHead}>Closing</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="bodycon">
                {props?.data?.reportstate?.slice(props?.data?.page * props?.data?.rowsPerPage, props?.data?.page * props?.data?.rowsPerPage + props?.data?.rowsPerPage)?.map((element, index) => {               
                  let bgcolor = "#F1F0E8";
                  let stscolor = "#6699CC";
                  if (index % 2 == 0) {
                    bgcolor = "#fff";
                  }
                  return (
                    <TableRow
                      className="tblerowdata"
                      style={{ backgroundColor: bgcolor }}
                    >
                      <TableCell>{index+1}</TableCell>
                      <TableCell>{element?.uname}-{element?.mob}</TableCell>
                      <TableCell>
                      {props?.data?.formatDate(element?.edate)}
                      </TableCell>
                      <TableCell >{element?.cr}</TableCell>
                      <TableCell>
                      {element?.dr}
                      </TableCell>
                      <TableCell>
                       {element?.closing}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={props?.data?.rowsPerPageOptions}
            component="div"
            count={props?.data?.reportstate?.length}
            rowsPerPage={props?.data?.rowsPerPage}
            page={props?.data?.page}
            onPageChange={props?.data?.handleChangePage}
            onRowsPerPageChange={props?.data?.handleChangeRowsPerPage}
          />
        </Paper>
       </div>
    </>
  );
};
export default LedgerReportTable;
