import React from 'react'

function UserSignupReducer(state,action) {
    switch(action.type){
        case 'Change':{
            return {
            ...state,
            [action.payload.name]: action.payload.value,
            };
        }
        case 'userDetails': {
            // alert( "23"+action.data)
            return action.data
          }

}
}
export default UserSignupReducer