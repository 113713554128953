import { useEffect, useReducer, useState } from "react";
import { tostmsg } from "../../MIS/Global";
import { GetGamemasterData } from "../Model/GamemasterModel";
import GamemasterReducer from "../Reducer/GamemasterReducer";

function useUserAdminResult() {
  const [alertdata, setAlertdata] = useState({});
  const [alertopen, setAlertopen] = useState(false);
  const [gamesearchState, gamesearchDispach] = useReducer(
    GamemasterReducer,
    []
  );
  const [filterState, filterDispach] = useReducer(GamemasterReducer, []);
  const [fdate, setFdate] = useState(new Date());

  const handleFilterChnage = (e, value) => {
     if(e==="fdate" || e==="tdate"){
        value=e==="fdate"?formatDatetime_YMD(value,0):formatDatetime_YMD(value,1);
        filterDispach({
            type: "Change",
            payload: { name: e, value: value },
          });
    } else if (e && e.target) {
 
    }
  };

  const formatDatetime_YMD = (date, pmday) => {
    const currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + pmday);
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")} ${currentDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${currentDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;

    return formattedDate;
  };

  const formatDate_dmy = (date)=>{
    const cdate=new Date(date);
    const formattedDate=`${cdate.getDate()}-${cdate.getMonth() + 1}-${cdate.getFullYear()}`;
    return formattedDate;
  }

  const fetchGamemasterdata = async (data) => {     
    try {
      let getGamedata = await GetGamemasterData(data);
      if (getGamedata.code === 200) { 
        gamesearchDispach({
          type: "gamemasterDetails",
          data: getGamedata?.data,
        });
      }
    } catch (error) {
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };

  useEffect(() => {
    fetchGamemasterdata({
      fdate: formatDatetime_YMD(fdate, 0),
      tdate: formatDatetime_YMD(fdate, 1),
    });
  }, []);

  return {
    alertdata,
    alertopen,
    setAlertopen,
    gamesearchState,gamesearchDispach,
    handleFilterChnage,
    fetchGamemasterdata,formatDate_dmy,formatDatetime_YMD,
    fdate, setFdate,
  };
}

export default useUserAdminResult;
