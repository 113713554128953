import React, { useContext, useEffect, useReducer, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css";
import { Checkbox, TableContainer, TextField } from "@mui/material";
import { formatDatetime_YMD } from "../MIS/Global";




 
const AdminTodayplayTable = (props) => {

  return (
    <>
      <div>
        <Paper className="contner1">
          <TableContainer sx={{ maxHeight: "60vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableHead>
                <TableRow>

                  <TableCell style={{textAlign:"center",fontWeight:"bold"}}>Name</TableCell>
                  <TableCell style={{textAlign:"center",fontWeight:"bold"}}>Mobile</TableCell>
                  <TableCell style={{textAlign:"right",fontWeight:"bold"}}>Total Points</TableCell>

                </TableRow>
              </TableHead>

              <TableBody className="bodycon">
                {props?.data?.playhistorystate?.map((element,index) => {
                  let bgcolor = "#F1F0E8";
                  if (index % 2 == 0) {
                    bgcolor = "#fff";
                  }
                  const isSelected = index === props?.data?.selectedRow;
                  return (
                    <TableRow className="tblerowdata" 
                    // style={{backgroundColor:bgcolor}}
                    style={{ backgroundColor: isSelected ? '#cfddf3' : bgcolor }}
                    onClick={() =>{
                      props?.data?.handleRowClick(index);
                      if(window.location.pathname==='/adminplayhistory'){
                        props?.data?.searchPlayHistory({loginid:element?.loginid,pfdate:element?.playdate,ptdate:element?.playdate})
                      }else{
                        props?.data?.searchPlayHistory({isorderplayid:"1",loginid:element?.loginid,pfdate:formatDatetime_YMD(props?.whr?.pfdate,0,"ymd"),ptdate:formatDatetime_YMD(props?.whr?.ptdate,0,"")})
                      }
                      
                    }}
                    >
                      <TableCell style={{textAlign:"center"}}>
                      {element?.uname}
                      </TableCell>
                      <TableCell component="th" scope="row" style={{textAlign:"right"}}>
                      {element?.mob}
                      </TableCell>
                      <TableCell component="th" scope="row" style={{textAlign:"right"}}>
                      {Number(element?.points).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  )
                }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
};
export default AdminTodayplayTable;
