 import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import Loader from "../Components/loader/Loader";

export const BASE_URL = "http://localhost:4000/api/";

export const BASE_URL_Local = "http://localhost:4000/api/";
// export const BASE_URL_Local = "https://spbackend.playspgames.com/api/";
export const BASE_URL_SERVER = "https://spbackend.playspgames.com/api/";
 

export const genotp = Math.floor(Math.random()*(9*Math.pow(10,6-1)))+Math.pow(10,6-1);

export const callAjax = async (url,data) => {
  try {  
    let BASE_URL=window.location.hostname==="localhost"?BASE_URL_Local:BASE_URL_SERVER;      
    const config = {
      method: "post",
      url: BASE_URL + url,
      maxBodyLength: Infinity,
      xsrfCookieName: "XSRF-TOKEN", // Specify the name of the cookie to use as a value for xsrf token
      xsrfHeaderName: "X-XSRF-TOKEN",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };    

    ReactDOM.render(<Loader />, document.getElementById("root1")); // Show Loader before API call
    const response = await axios(config);
    // alert(JSON.stringify(response));
    // console.log(response)
    ReactDOM.render(<Loader bal={false} />, document.getElementById("root1")); // Hide Loader after API call
    let ret;
    ret = { msg:"", code: 200, data: response.data.data };    
    return ret;
  } catch (error) {
    ReactDOM.render(<Loader bal={false} />, document.getElementById("root1")); // Hide Loader on error
    return { msg: "something went wrong! please try again ", code: 500 };
  }
};

export const callAjax_File = async (url,data) => {
  try {
    let BASE_URL=window.location.hostname==="localhost"?BASE_URL_Local:BASE_URL_SERVER;
    const config = {
      method: "post",
      url: BASE_URL + url,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    ReactDOM.render(<Loader />, document.getElementById("root1")); // Show Loader before API call
    const response = await axios(config);

    ReactDOM.render(<Loader bal={false} />, document.getElementById("root1")); // Hide Loader after API call
    let ret;
    ret = { msg:"", code: 200, data: response.data.data };    
    return ret;
  } catch (error) {
    ReactDOM.render(<Loader bal={false} />, document.getElementById("root1")); // Hide Loader on error
    return { msg: "something went wrong! please try again ", code: 500 };
  }
};


export const tostmsg = (data) => {
  if (data.code == 100) {
    return {
      type: "error",
      msg: data.msg,
      vertical: "top",
      horizontal: "center",
      open: true,
    };
  } else if (data.code == 200) {
    return {
      type: "success",
      msg: data.msg,
      vertical: "top",
      horizontal: "center",
      open: true,
    };
  } else if (data.code == 400) {
    return {
      type: "warning",
      msg: data.msg,
      vertical: "top",
      horizontal: "center",
      open: true,
    };
  } else if (data.code == 300) {
    return {
      type: "error",
      msg: data.msg,
      vertical: "top",
      horizontal: "center",
      open: true,
    };
  } else if (data.code == 500) {
    return {
      type: "error",
      msg: data.msg,
      vertical: "top",
      horizontal: "center",
      open: true,
    };
  }
};

export const INITIAL_STATE = {
  loading: "",
  post: {},
};

export const isSmallScreen=()=>{
  return window.innerWidth<=768;
}

export const handleNumInputChange = (event) => {
  const numericValue = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
  event.target.value = numericValue; // Update the input value
};
export const handleDecimalInputChange = (event) => {
  const numericValue = event.target.value.replace(/[^0-9.]+/g, '');
  const parts = numericValue.split('.');
  if (parts.length > 2) {
    const integerPart = parts.shift();
    const decimalPart = parts.join('');
    event.target.value = `${integerPart}.${decimalPart}`;
  } else {
    event.target.value = numericValue;
  }
};
export const handleAlphanumericInputChange = (event) => {
  const alphanumericValue = event.target.value.replace(/[^a-zA-Z0-9]+/g, ''); // Remove non-alphanumeric characters
  event.target.value = alphanumericValue; // Update the input value
};

export const handleAlphanumericInputwithSpaceChange = (event) => {
  const alphanumericValue = event.target.value.replace(/[^a-zA-Z0-9\s]+/g, ''); // Remove non-alphanumeric characters except spaces
  event.target.value = alphanumericValue; // Update the input value
};

export const formatDatetime_YMD = (date,pmday,isformat) => {
  const currentDate = new Date(date);
  currentDate.setDate(currentDate.getDate() + pmday);

  let formattedDate = `${currentDate.getFullYear()}-${(
    currentDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${currentDate
    .getDate()
    .toString()
    .padStart(2, "0")} ${currentDate
    .getHours()
    .toString()
    .padStart(2, "0")}:${currentDate
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;
    if(isformat==="ymd"){
      formattedDate = `${currentDate.getFullYear()}-${(
        currentDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${currentDate
        .getDate()}`;
    }
  return formattedDate;
};

export const formatDatetime_DMY = (date, pmday,format) => {
  const currentDate = new Date(date);
  currentDate.setDate(currentDate.getDate() + pmday);
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July',
    'August', 'September', 'October', 'November', 'December'
  ];
  let year = currentDate.getFullYear();
  let month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  let day = currentDate.getDate().toString().padStart(2, "0");

  if(format==="mmm"){
    month = months[currentDate.getMonth()];
  }
  const formattedDate = `${day}-${month}-${year}`;
  
  return formattedDate;
  
};
 
export const getHourTime = (dateTime, ishour) => {
  let bal = false;
  if (ishour.toString() === "12".toString()) {
    bal = true;
  } else {
    bal = false;
  }
  let curDate = new Date(dateTime);
  const timeString = curDate.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: bal,
  });
  return timeString;
};

export const getMonthName = (date,istype) => {
  const newDate = new Date(date);
  let monthName="";let options={};
  if(istype==0){
    monthName =  newDate.getMonth() + 1;
  }else if(istype=="long"){
    options = { month: 'long' };
    monthName = new Intl.DateTimeFormat('en-US', options).format(newDate);
  }else if(istype=="short"){
    options = { month: 'short' };
    monthName = new Intl.DateTimeFormat('en-US', options).format(newDate);
  }
   return monthName;
};

export const formatUTCDate = (date, isFormat, daysAgo = 0) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (daysAgo !== 0) {
    date.setUTCDate(date.getUTCDate() - daysAgo);
  }
  date = new Date(date + "Z");
  let year = date.getUTCFullYear();
  let month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
  let day = String(date.getUTCDate()).padStart(2, "0");
  let hours = String(date.getUTCHours()).padStart(2, "0");
  let minutes = String(date.getUTCMinutes()).padStart(2, "0");
  let seconds = String(date.getUTCSeconds()).padStart(2, "0");
  if (isFormat === "ymd") {
    return `${year}-${month}-${day}`;
  } else if (isFormat === "dmy") {
    return `${day}-${month}-${year}`;
  } else if (isFormat === "d3my") {
    const monthIndex = date.getUTCMonth();
    month = monthNames[monthIndex];
    return `${day}-${month}-${year}`;
  } else if (isFormat === "ymdhm") {
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  } else if (isFormat === "dmyhm") {
    let period = "AM";
    if (hours >= 12) {
      period = "PM";
      hours = hours > 12 ? hours - 12 : hours;
    } else if (hours === 0) {
      hours = 12;
    }
    hours = String(hours).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes} ${period}`;
  }
};

export const formatNumberToIndianStyle=(number)=> {
  const fixedNumber = parseFloat(number).toFixed(2);
  const [integerPart, decimalPart] = fixedNumber.split(".");
  let lastThree = integerPart.slice(-3);
  const otherNumbers = integerPart.slice(0, -3);
  
  if (otherNumbers !== "") {
    lastThree = "," + lastThree;
  }
  
  const formattedIntegerPart = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

  return formattedIntegerPart + "." + decimalPart;


}
