import React, { useContext, useEffect, useReducer, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css";
import { Checkbox, TableContainer, TextField } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";
import useGameMaster from "../API/Hooks/useGameMaster";

const arr = [
  "Open",
  "Close",
  "Jodi",
  "Open Panna",
  "Close Panna",
  "Gunule",
  "Jackpot",
  "Ulta Gunule"
];

const DrawMasterTable = (props) => {
  return (
    <>
      <div>
        <Paper className="contner">
          <TableContainer sx={{ maxHeight: "60vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableHead sx={{ backgroundColor: "red" }}>
                <TableRow>
                  <TableCell>Game Type</TableCell>
                  <TableCell>Start Time</TableCell>
                  <TableCell>Opening Time</TableCell>
                  <TableCell>Opening CutOff Time</TableCell>
                  <TableCell>Closing Time</TableCell>
                  <TableCell>Closing CutOff Time</TableCell>
                  <TableCell>Result Opening CutOff Time</TableCell>
                  <TableCell>Result Closing CutOff Time</TableCell>
                </TableRow>
              </TableHead>

              <TableBody className="bodycon">
                {props?.data?.tbldata?.map((element, index) => {
                  return arr?.map((element1, index1) => {
                    let bgcolor = "#F1F0E8";
                    if (index1 % 2 == 0) {
                      bgcolor = "#fff";
                    }
                    return (
                      <TableRow
                        className="tblerowdata"
                        style={{ backgroundColor: bgcolor }}
                      >
                        <TableCell>{element1}</TableCell>
                        <TableCell component="th" scope="row">
                          {props?.data?.get12HourTime(element?.starttime)}
                        </TableCell>
                        <TableCell>
                          {props?.data?.get12HourTime(element?.optime)}
                        </TableCell>
                        <TableCell>
                          {props?.data?.get12HourTime(element?.cutoptime)}
                        </TableCell>
                        <TableCell>
                          {props?.data?.get12HourTime(element?.cltime)}
                        </TableCell>
                        <TableCell>
                          {props?.data?.get12HourTime(element?.cutcltime)}
                        </TableCell>
                        <TableCell>
                          {props?.data?.get12HourTime(element?.cutrestime)}
                        </TableCell>
                        <TableCell>
                          {props?.data?.get12HourTime(element?.cutrescltime)}
                        </TableCell>
                      </TableRow>
                    );
                  });
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
           component="div"
           count={props?.data?.tbldata?.length}
           rowsPerPage={props?.data?.rowsPerPage1}
           page={props?.data?.page1}
           rowsPerPageOptions={props?.data?.rowsPerPageOptions1}
           onPageChange={props?.data?.handleChangePage1}
           onRowsPerPageChange={props?.data?.handleChangeRowsPerPage1}
          /> */}
        </Paper>
      </div>
    </>
  );
};
export default DrawMasterTable;
