import { Grid, InputAdornment, TextField } from "@mui/material";
import React from "react";
import style from "./UserProfile.module.css";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import { useNavigate } from "react-router-dom";
import {
  handleAlphanumericInputChange,
  handleAlphanumericInputwithSpaceChange,
  handleNumInputChange,
} from "../../MIS/Global";
import useMenumaster from "../../API/Hooks/useMenumaster";
import MobileMenu from "../MobileMenu/MobileMenu";
import useUserSigniupnmaster from "../../API/Hooks/useUserSigniupnmaster";

const UserProfile = () => {
  const { handleuser, Saveuser, alertdata, alertopen, setAlertopen } =
  useUserSigniupnmaster();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const navigate = useNavigate();
   const menuMaster={...useMenumaster()} ;
  return (
    <>
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <div className={style.pagecontainer}>
        <div className={style.userprofile1}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <div className={style.registersty}>Profile</div>
            </Grid>
          </Grid>
        </div>
        <div className={style.userprofile2}>
          <Grid container spacing={2} className={style.regmain}>
            <Grid item xs={12} md={4}>
              <TextField
                id="outlined-disabled"
                label="User Name"
                fullWidth
                size="small"
                name="uname"
                value={menuMaster?.userdatastate && menuMaster?.userdatastate[0]
                    ? menuMaster?.userdatastate[0]?.uname
                    : "-"}
                 InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon
                        sx={{ fontSize: "22px", marginRight: "5px" }}
                      />
                    </InputAdornment>
                  ),
                  classes: {
                    input: style.textinp_left,
                  },
                  style: {
                    padding: "2px",
                  },
                  inputProps: {
                    onInput: handleAlphanumericInputwithSpaceChange,
                    maxLength: 50,
                  },
                }}
                InputLabelProps={{
                  className: style.textinplbl,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                type="tel"
                inputMode="numeric"
                label="Mobile Number"
                fullWidth
                size="small"
                name="mob"
                disable
                value={menuMaster?.userdatastate && menuMaster?.userdatastate[0]
                    ? menuMaster?.userdatastate[0]?.mob
                    : "-"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIphoneIcon
                        sx={{ fontSize: "22px", marginRight: "5px" }}
                      />
                    </InputAdornment>
                  ),
                  classes: {
                    input: style.textinp_left,
                  },
                  style: {
                    padding: "2px",
                  },
                  inputProps: {
                    onInput: handleNumInputChange,
                    maxLength: 10,
                  },
                }}
                InputLabelProps={{
                  className: style.textinplbl,
                }}
              />
            </Grid>             
            <Grid item xs={12} md={4}>
              <TextField
                id="outlined-disabled"
                label="Address"
                fullWidth
                size="small"
                name="address"
                value={menuMaster?.userdatastate && menuMaster?.userdatastate[0]
                    ? menuMaster?.userdatastate[0]?.address
                    : "-"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnIcon
                        sx={{ fontSize: "22px", marginRight: "5px" }}
                      />
                    </InputAdornment>
                  ),
                  classes: {
                    input: style.textinp_left,
                  },
                  style: {
                    padding: "2px",
                  },
                  inputProps: {
                    onInput: handleAlphanumericInputwithSpaceChange,
                    // maxLength: 50,
                  },
                }}
                InputLabelProps={{
                  className: style.textinplbl,
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} className={style.regbtn_align}>
              <div
                className={style.regbtncon}
                onClick={() => {
                  Saveuser();
                }}
              >
                <button type="button" className={style.regbtn}>
                  Update Profile
                </button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={style.userprofile3}>
            <MobileMenu />      
        </div>
      </div>
    </>
  );
};

export default UserProfile;
