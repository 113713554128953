import React from 'react'
import style from "./UserAcLedger.module.css"
import { Grid, TextField } from '@mui/material'
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core"; 
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import SearchIcon from "@mui/icons-material/Search";
import MobileMenu from '../MobileMenu/MobileMenu';
import UserAcLedgerTable from '../../Tables/UserAcLedger';

const UserAcLedger = () => {
  const useStyles = makeStyles((theme) => ({
    smallDateTimePicker: {
      "& .MuiInputBase-input": {
        fontSize: "14px",
        fontWeight: "600 !important",
        width: "100%",
        height: "100%",
        padding: "10px",
        margin: 0,
      },
      "& .MuiInputLabel-root": {
        fontSize: "16px",
        fontWeight: "600 !important", // Adjust font size as needed
        display: "flex",
        alignItems: "center",
      },
    },
  }));
  const classes = useStyles();
  return (
    <>
     <div className={style.pagecontainer}>
     <div className={style.userac1}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              <div className={style.fundhead}>Account Transaction</div>
            </Grid>
          </Grid>
        </div>
        <div className={style.userac2}>
          <Grid container spacing={1}>
            <Grid item xs={5} md={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className={classes.smallDateTimePicker}
                  label="From Date"
                  value={dayjs(`${new Date()}`)}
                  renderInput={(props) => (
                    <TextField {...props} variant="standard" />
                  )}
                  format="DD/MM/YYYY" // Specify the desired format for date and time
                  renderTime={(props) => (
                    <TextField
                      {...props}
                      variant="outlined"
                      helperText={null}
                    />
                  )} // Customize the time input rendering
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={5} md={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className={classes.smallDateTimePicker}
                  label="To Date"
                  value={dayjs(`${new Date()}`)}                  
                  renderInput={(props) => (
                    <TextField {...props} variant="standard" />
                  )}
                  format="DD/MM/YYYY" // Specify the desired format for date and time
                  renderTime={(props) => (
                    <TextField
                      {...props}
                      variant="outlined"
                      helperText={null}
                    />
                  )} // Customize the time input rendering
                 
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1} md={12} >
              <div
                className={style.addfundicon}
                style={{
                  backgroundColor: "#358ab7",
                  right:0
                }}              
              >
                <SearchIcon sx={{ color: "white" }} />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={style.userac3}>
          <UserAcLedgerTable/>
        </div>
        <div className={style.userac4}>
          <MobileMenu />
        </div>
     </div>

    </>
  )
}

export default UserAcLedger