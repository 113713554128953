import { useEffect, useReducer, useState, React } from "react";
import { tostmsg } from "../../MIS/Global";
import FundRequestReducer from "../Reducer/FundRequestReducer";
import {
  DeleteFundreq,
  GetFundreqdata,
  SaveFundreq,
  UpdateFundreq,
} from "../Model/FundRequestModel";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

function useAdminFundReqmaster() {
  const [alertdata, setAlertdata] = useState({});
  const [alertopen, setAlertopen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [usearch, setUsearch] = useState([]);
  const [fundsearchState, fundsearchDispach] = useReducer(
    FundRequestReducer,
    []
  );
  const [fundsearch_orgState, fundsearch_orgDispach] = useReducer(
    FundRequestReducer,
    []
  );
  const rowsPerPageOptions = [
    25,50,100,125,150,200,300,500,1000,
    { value: fundsearchState?.length, label: "All" },
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(+event.target.value, 10));
    setPage(0);
  };

   const [ftdate, setFTdate] = useState({
    fdate: new Date(),
    tdate: new Date(),
  });

  
  const navigate = useNavigate();
  const useStyles = makeStyles((theme) => ({
    boldRow: {
      fontWeight: "bold",
    },
  }));
  const classes = useStyles();

  function formatDate(date) {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      new Date(date)
    );
    const formattedDateWithoutComma = formattedDate.replace(",", "");
    return formattedDateWithoutComma.replace(/(\s[ap]m)/i, (match) =>
      match.toUpperCase()
    );
  }

  const handleFTdate = (e, value) => {
    // const formattedDate = new Date(value).toISOString().split("T")[0];
    setFTdate({ ...ftdate, [e]: value });
  };

  const fetchufundData = async (data) => {
    try {
      let getfunddata = await GetFundreqdata(data);
      if (getfunddata.code === 100) {
        let rettostmsg = tostmsg(getfunddata);
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        return;
      }
      if (getfunddata.code === 200) {
        const sortedData = [...getfunddata?.data].sort((a, b) => {
          if (a.isstatus < b.isstatus) return -1;
          if (a.isstatus > b.isstatus) return 1;

          const dateA = new Date(b.edate).getTime();
          const dateB = new Date(a.edate).getTime();

          if (dateA < dateB) return -1;
          if (dateA > dateB) return 1;

          return 0;
        });

        fundsearchDispach({
          type: "fundreqDetails",
          data: sortedData,
        });
        fundsearch_orgDispach({
          type: "fundreqDetails",
          data: sortedData,
        });
      }
    } catch (error) {
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };

  useEffect(() => {
    fetchufundData({ trdate: `${new Date()}` });
  }, []);

  const updateFundRequest = async (data) => {
    try {
      let res = await UpdateFundreq(data);
      let rettostmsg = tostmsg(res);
      if (res.code == 200) {
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        fetchufundData({trdate: `${new Date()}`});
      } else {
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      }
    } catch (e) {
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };

  const udatasearch = (searchstr) => {
    const keysToSearch = ["uname", "mob", "city","points"];
    const filteredData = fundsearch_orgState?.filter((item) => {
      return keysToSearch?.some((key) => {
        return item[key]
          ?.toString()
          .toLowerCase()
          ?.includes(searchstr.toString().toLowerCase());
      });
    });
     fundsearchDispach({
      type: "fundreqDetails",
      data: filteredData,
    });
  };
  useEffect(() => {    
    udatasearch(usearch);
  }, [usearch]);

  return {
    updateFundRequest,
    alertdata,
    alertopen,
    setAlertopen,
    fundsearchState,
    fetchufundData,
    formatDate,
    handleFTdate,
    ftdate,
    classes,
    page,rowsPerPage,rowsPerPageOptions,handleChangePage,handleChangeRowsPerPage,
    setUsearch,
  };
}

export default useAdminFundReqmaster;
