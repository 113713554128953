 
import {  callAjax } from "../../MIS/Global";

export const SaveFundreq = async (data) => {
  let ret = {};
  if (data?.points == undefined || data?.points == "") {
    return { msg: "Please enter points", code: 100 };
  }
  if (data?.points <= 0) {
    return { msg: "Points must be greater than zero", code: 100 };
  }
  if (Number(data?.points) > Number(data?.isbal) && data?.isdrcr == 1) {
    return { msg: "Insufficient balance for withdrawal", code: 100 };
  }
  let url = "walletreqsave";
  ret = await callAjax(url,data);
  return ret;
};

export const UpdateFundreq = async (data) => {
  let ret = {};
   
  let url = "walletrequpdate";
  ret = await callAjax(url,data);
  return ret;
};

export const DeleteFundreq = async (data) => {
  let ret = {};
  let url = "walletreqdelete";
  ret = await callAjax(url,data);
  return ret;
};

export const GetFundreqdata = async (data) => {
  let ret = {};

  if (!data?.fdate == "" && !data?.tdate == "") {
    let fday = new Date(data?.fdate).getDate();
    let tday = new Date(data?.tdate).getDate();
    if (Number(fday) > Number(tday)) {
      return { msg: "From date should be less than to date", code: 100 };
    }
  }

  let url = "getwalletreq";

  ret = await callAjax(url,data);
  return ret;
};
