import React, { useEffect } from "react";
import MobileMenu from "../MobileMenu/MobileMenu";
import style from "./UserPlayHistory.module.css";
import { Grid, Paper, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { makeStyles } from "@material-ui/core";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import SearchIcon from "@mui/icons-material/Search";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PrintIcon from "@mui/icons-material/Print";
import UserPlayHistoryTable from "../../Tables/UserPlayHistoryTable";
import Tooltip from "@mui/material/Tooltip";
import useUserPlayHistory from "../../API/Hooks/useUserPlayHistory";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp"; 

const useStyles = makeStyles((theme) => ({
  smallDateTimePicker: {
    "& .MuiInputBase-input": {
      fontSize: "13px",
      fontWeight: "600 !important",
      width: "100%",
      height: "100%",
      padding: "10px",
      margin: 0,
    },
    "& .MuiInputLabel-root": {
      fontSize: "16px",
      fontWeight: "600 !important", // Adjust font size as needed
      display: "flex",
      alignItems: "center",
    },
  },
}));
 
const UserPlayHistoryMaster = () => {
  const playMaster = { ...useUserPlayHistory() };

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  
  return (
    <>
     <AlertMessageComp
        type={playMaster?.alertdata.type}
        msg={playMaster?.alertdata.msg}
        vertical={playMaster?.alertdata.vertical}
        horizontal={playMaster?.alertdata.horizontal}
        setOpen={playMaster?.setAlertopen}
        open={playMaster?.alertopen}
        duration={playMaster?.alertdata?.duration}
      />
    <div className={style.uplaycontainer}>
      <div className={style.uplaysec1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <div className={style.plhistoryheading}>
             {window.location.pathname==="/todayhistory"?"Today's Play History":"Play History"} 
            </div>
          </Grid>
          <Grid item xs={6} md={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disabled={playMaster?.ctrlsettingState?.tdate}
                className={classes.smallDateTimePicker}
                label="From Date"
                onChange={(e)=>{playMaster?.handlePlaySearch("pfdate",e)}}
                renderInput={(props) => (
                  <TextField {...props} variant="standard" />
                )}
                value={dayjs(playMaster?.filterState?.pfdate)}
                minDate={dayjs(playMaster?.ctrlsettingState?.fmindate)}
                openTo="day"
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} md={2} style={{ display:playMaster?.ctrlsettingState?.tdate}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className={classes.smallDateTimePicker}
                label="To Date"
                onChange={(e)=>{playMaster?.handlePlaySearch("ptdate",e)}}
                renderInput={(props) => (
                  <TextField {...props} variant="standard" />
                )}
                value={dayjs(playMaster?.filterState?.ptdate)}
                openTo="day"
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              id="outlined-disabled"
              label="Transaction Id"
              fullWidth
              size="small"
              onChange={(e)=>{playMaster?.handlePlaySearch("trid",e.target.value)}}
              InputProps={{
                classes: {
                  input: style.textinp_left,
                },
                style: {
                  padding: "1px",
                },
              }}
              InputLabelProps={{
                className: style.textinplbl,
              }}
            />
          </Grid>
          <Grid item xs={playMaster?.ctrlsettingState?.tdate==="" ?6:12} md={2} className={style.btnposition}>
            <div className={style.searchbtncon} onClick={(e)=>{playMaster?.handlePlaySearchClick()}}>
              <button type="button" className={style.searchbtn}>
                <SearchIcon style={{ fontSize: "32px", fontWeight: 700 }} />
              </button>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={style.uplaysec2}>
        <Grid container spacing={1} sx={{ padding: "5px" }}>
          {Object.keys(playMaster?.searchplaymaster_groupState).map((element, index) => {
            let bgcolor = "#F1F0E8";
            if (index % 2 == 0) {
              bgcolor = "#fff";
            }
            return (
              <Grid item xs={12} md={12}>
                <Accordion
                  component={Paper}
                  expanded={expanded === index}
                  onChange={handleChange(index)}
                >
                  <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{ backgroundColor: bgcolor }}
                  >
                    <Typography style={{ width: "80%" }}>
                      <div className={style.transid}>#{element} - {playMaster?.searchplaymaster_groupState[element][0]?.gsubname}</div>
                      <div className={style.historydetime}>{playMaster?.formatDatetime_DMY(playMaster?.searchplaymaster_groupState[element][0]?.playdate,0)}</div>
                    </Typography>
                    <Typography style={{ width: "20%" }}>
                      <div style={{ display: "flex", justifyContent: "end" }}
                       onClick={()=>{playMaster?.slipPrint(element)}}
                      >
                        <Tooltip title="Print" placement="top" arrow>
                          <PrintIcon
                          
                            style={{
                              fontSize: "32px",
                              fontWeight: 700,
                              color: "#358ab7",
                            }}
                          />
                        </Tooltip>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <UserPlayHistoryTable data={{tbldata:playMaster?.searchplaymaster_groupState[element],handleUpdatePlay:playMaster?.handleUpdatePlay,handlePlayStateChnage:playMaster?.handlePlayStateChnage,PlayMasterDataSave:playMaster?.PlayMasterDataSave,getGtmname:playMaster?.getGtmname,handleDeletePlay:playMaster?.handleDeletePlay,handleIsDisable:playMaster?.handleIsDisable,handleInputChange:playMaster?.handleInputChange}}/>
                        </Grid>
                      </Grid>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            );
          })}
        </Grid>
      </div>
      <div className={style.uplaysec3}>
        <MobileMenu />
      </div>
    </div>
    </>
  );
};

export default UserPlayHistoryMaster;
