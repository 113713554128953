import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import FundRequest from '../../Components/FundRequest/FundRequest'
 
const FundRequestScreen = () => {

  return (
    <div>
        <Appbar />
        <FundRequest />
    </div>
  )
}

export default FundRequestScreen