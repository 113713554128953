import { createSlice } from "@reduxjs/toolkit";
import { setConfirmDialogData, setRetAnswer } from "./confirmDialogReducer";
import { v4 as uuidv4 } from "uuid";

import {
  callAjax,
  formatDatetime_DMY,
  formatDatetime_YMD,
} from "../../MIS/Global";
import { setAlertData } from "./alertReducer";

const predictionChartReducer = createSlice({
  name: "predictionchart",
  initialState: {     
    predictionChartList: [],
    predictionChartSummaryList: [],
    predictionMESum:[],
  },

  reducers: {
    setPredictionChartList(state, action) {
      state.predictionChartList = action.payload;
    },
    setPredictionChartSummaryList(state, action) {
      state.predictionChartSummaryList = action.payload;
    },
    setPredictionMESum(state, action) {
      state.predictionMESum = action.payload;
    },
     
  },
});

export const { setPredictionChartList,setPredictionChartSummaryList,setPredictionMESum } = predictionChartReducer.actions;
export default predictionChartReducer.reducer;

export const getPredictionChart = (data) => {
  return async function actionData(dispatch) {
    try {
      let ret = await callAjax("getpredictionchart", data);
      if (ret.code == 200) {
        dispatch(setPredictionChartList(ret?.data));        
      }
    } catch (error) {
      dispatch(
        setAlertData({
          msg: "Something went wrong! Please try again",
          code: 100,
          open: true,
        })
      );
    }
  };
};

export const getPredictionChartSummary = (data) => {
  return async function actionData(dispatch) {
    try {
      let ret = await callAjax("predictionsummary", data);
      if (ret.code == 200) {
        // alert(JSON.stringify(ret?.data))
        dispatch(setPredictionChartSummaryList(ret?.data));        
      }
    } catch (error) {
      dispatch(
        setAlertData({
          msg: "Something went wrong! Please try again",
          code: 100,
          open: true,
        })
      );
    }
  };
};

export const getPredictionMESummary = (data) => {
  return async function actionData(dispatch) {
    try {
      let ret = await callAjax("getsumme", data);
      if (ret.code == 200) {
        // alert(JSON.stringify(ret?.data))
        dispatch(setPredictionMESum(ret?.data));        
      }
    } catch (error) {
      dispatch(
        setAlertData({
          msg: "Something went wrong! Please try again",
          code: 100,
          open: true,
        })
      );
    }
  };
};