import { useEffect, useReducer, useState, React } from "react";
import { tostmsg } from "../../MIS/Global";
import { useNavigate } from "react-router-dom";
import PlayMasterReducer from "../Reducer/PlayMasterReducer";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { SaveAllPlaymaster } from "../Model/PlaymasterModel";
import { GetGamemasterData } from "../Model/GamemasterModel";
import numJsonData from "../../MIS/Numdata.json";

function usePlayMaster() {
  const navigate = useNavigate();
  const { gname } = useParams();
  const [alertdata, setAlertdata] = useState({});
  const [alertopen, setAlertopen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [openmodal, setOpenmodal] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [num1, setNum1] = useState("");
  const [num2, setNum2] = useState("");
  const [points, setPoints] = useState("");
  const [cdg, setCdg] = useState({ open: false, msg: "", print: false });
  const [paragname, setParaGname] = useState(gname);
  const [printSlip, setPrintslip] = useState({
    transactionId: "CZTQHE",
    data: { Open: [{}] },
  });
  const [isnumvisible, setIsnumvisible] = useState({
    num1: false,
    num2: false,
    num1xs: 6,
    num2xs: 6,
  });
  const [opclCtrl, setopclCtrl] = useState({
    op: false,
    cl: false,
  });
  const [playmasterstate, playmasterDispach] = useReducer(
    PlayMasterReducer,
    []
  );
  const [playmaster_modalstate, playmaster_modalDispach] = useReducer(
    PlayMasterReducer,
    []
  );
  const [drawmasterState, drawmasterDispach] = useReducer(
    PlayMasterReducer,
    []
  );
  const [printopen, setPrintOpen] = useState(false);
  const [trid, setTrid] = useState("");

   const rowsPerPageOptions = [
    25,
    50,
    100,
    125,
    150,
    200,
    300,
    500,
    1000,
    { value: playmasterstate?.length, label: "All" },
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(+event.target.value, 10));
    setPage(0);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState("English");

  const languageOptions = {
    English: {
      open: "Open",
      close: "Close",
      jodi: "Jodi",
      gunule: "Gunule",
      openPanna: "Open Panna",
      closePanna: "Close Panna",
      jackpot: "Jackpot",
      ultaGunule: "Ulta Gunule",
    },
    Marathi: {
      open: "ओपन",
      close: "क्लोज",
      jodi: "जोडी",
      gunule: "गुणुले",
      openPanna: "ओपन पन्ना",
      closePanna: "क्लोज पन्ना",
      jackpot: "जॅकपॉट",
      ultaGunule: "उल्टा गुणुले",
    },
  };

  const translatedText = languageOptions[selectedOption];

  const open = Boolean(anchorEl);

  const valiDateNumData=(num1,num2)=>{    
    num1=parseInt(num1, 10);
    num2=parseInt(num2, 10);
    if (modalHeading === "Open") {
      return true;
    } else if (modalHeading === "Close") {
      return true;
    } else if (modalHeading === "Jodi") {
      return true;
    } else if (modalHeading === "Open Panna") {
      const hasElement = numJsonData?.numdata?.includes(num1);     
      return hasElement;
    } else if (modalHeading === "Close Panna") {
      const hasElement = numJsonData?.numdata?.includes(num1);
      return hasElement;
    } else if (modalHeading === "Gunule") {
      const hasElement = numJsonData?.numdata?.includes(num1);
      return hasElement;
    } else if (modalHeading === "Jackpot") {
      const hasElement = numJsonData?.numdata?.includes(num1);
      const hasElement1 = numJsonData?.numdata?.includes(num2);
      if(hasElement===true && hasElement1===true){
        return true
      }else{
        return false;
      }
    } else if (modalHeading === "Ulta Gunule") {
      const hasElement = numJsonData?.numdata.indexOf(num2) !== -1;    
      return hasElement;
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (optionLabel) => () => {
    // alert("1")
    setSelectedOption(optionLabel);
    setAnchorEl(null);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (event.target.name == "points") {
      setPoints(value);
      return;
    }
    const onlyNumsSingle = /^(\b|\b[0-9])$/;
    const onlyNumsDouble = /^(\b|\b[0-9]{1,2})$/;
    const onlyNumsTriple = /^(\b|\b[0-9]{1,3})$/;
    // setNum1("");setNum2("");
    if (
      ((modalHeading === "Open" || modalHeading === "Close") &&
        onlyNumsSingle.test(value)) ||
      event.key === "Backspace" ||
      event.key === "Delete" ||
      value == ""
    ) {
      setNum1(value);
    } else if (
      modalHeading === "Jodi" &&
      onlyNumsDouble.test(value) &&
      event.target.name == "num1"
    ) {
      setNum1(value);
    } else if (
      modalHeading === "Open Panna" &&
      onlyNumsTriple.test(value) &&
      event.target.name == "num1"
    ) {
      setNum1(value);
    } else if (
      modalHeading === "Open Panna" &&
      onlyNumsTriple.test(value) &&
      event.target.name == "num2"
    ) {
      setNum2(value);
    } else if (
      modalHeading === "Close Panna" &&
      onlyNumsTriple.test(value) &&
      event.target.name == "num1"
    ) {
      setNum1(value);
    } else if (
      modalHeading === "Close Panna" &&
      onlyNumsTriple.test(value) &&
      event.target.name == "num2"
    ) {
      setNum2(value);
    } else if (
      modalHeading === "Jackpot" &&
      onlyNumsTriple.test(value) &&
      event.target.name == "num1"
    ) {
      setNum1(value);
    } else if (
      modalHeading === "Jackpot" &&
      onlyNumsTriple.test(value) &&
      event.target.name == "num2"
    ) {
      setNum2(value);
    } else if (
      modalHeading === "Gunule" &&
      event.target.name == "num1" &&
      // onlyNumsSingle.test(value)
      onlyNumsTriple.test(value)
    ) {
      setNum1(value);
    } else if (
      modalHeading === "Gunule" &&
      event.target.name == "num2" &&
      // onlyNumsTriple.test(value)
      onlyNumsSingle.test(value)
    ) {
      setNum2(value);
    } else if (
      modalHeading === "Ulta Gunule" &&
      event.target.name == "num1" &&
      // onlyNumsTriple.test(value)
      onlyNumsSingle.test(value)
    ) {
      setNum1(value);
    } else if (
      modalHeading === "Ulta Gunule" &&
      event.target.name == "num2" &&
      // onlyNumsSingle.test(value)
      onlyNumsTriple.test(value)
    ) {
      setNum2(value);
    }
  };
  const getGtmid = () => {
    if (modalHeading === "Open") {
      return 1;
    } else if (modalHeading === "Close") {
      return 2;
    } else if (modalHeading === "Jodi") {
      return 3;
    } else if (modalHeading === "Open Panna") {
      return 4;
    } else if (modalHeading === "Close Panna") {
      return 5;
    } else if (modalHeading === "Gunule") {
      return 6;
    } else if (modalHeading === "Jackpot") {
      return 7;
    } else if (modalHeading === "Ulta Gunule") {
      return 8;
    }
  };

  const handlemodalOpen = (heading) => {
    if (
      heading === "Open" ||
      heading === "Close" ||
      heading === "Jodi" ||
      heading === "Open Panna" ||
      heading === "Close Panna"
    ) {
      setIsnumvisible({
        ...isnumvisible,
        num1: true,
        num2: false,
        num1xs: 5,
        num2xs: 6,
        pointsxs: 5,
      });
    } else {
      setIsnumvisible({
        ...isnumvisible,
        num1: true,
        num2: true,
        num1xs: 3.5,
        num2xs: 3.5,
        pointsxs: 3,
      });
    }

    setModalHeading(heading);
    setOpenmodal(true);
  };

  const handlemodalClose = () => {
    setNum1("");
    setNum2("");
    setPoints("");
    setOpenmodal(false);
  };
  const formatDatetime_YMD = (date, pmday) => {
    const currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + pmday);
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")} ${currentDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${currentDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;

    return formattedDate;
  };
  const geHourTime = (dateTime, isType) => {
    let hourVal = false;
    if (isType.toString() === "12") {
      hourVal = true;
    }
    let curDate = new Date(dateTime);
    const timeString = curDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: hourVal,
    });
    return timeString;
  };
  const calculateDigitSum = (number,istype) => {
    if(istype.toString().toUpperCase()==="jodi".toString().toUpperCase()){
      let numberString = number.split('').toString();
      return numberString[0];
    }
    const numberString = number.toString();
    const sum = Array.from(numberString).reduce(
      (acc, digit) => acc + parseInt(digit, 10),
      0
    );
    if (sum >= 10) {
      return sum % 10;
    }
    return sum;
  };

  const handleChangePlay = (gmid) => {
    //isdrcr=0=>dr:cr
    let npoints = `${num1}`;
    if (num2 != "") {
      npoints = `${num1}X${num2}`;
    }
    let rettostmsg = "";

    if (num1 == "") {
      rettostmsg = tostmsg({ code: 100, msg: "Please enter number" });
      setAlertdata(rettostmsg);
      setAlertopen(rettostmsg.open);
      return;
    } else if (num2 == "" && isnumvisible.num2 == true) {
      rettostmsg = tostmsg({ code: 100, msg: "Please enter number" });
      setAlertdata(rettostmsg);
      setAlertopen(rettostmsg.open);
      return;
    } else if (points == "" || Number(points) <= 0) {
      rettostmsg = tostmsg({
        code: 100,
        msg: "Please enter valid points.Points should be greater than zero.",
      });
      setAlertdata(rettostmsg);
      setAlertopen(rettostmsg.open);
      return;
    } else if (modalHeading === "Jodi" && num1.length < 2) {
      rettostmsg = tostmsg({
        code: 100,
        msg: "Number should be two digits",
      });
      setAlertdata(rettostmsg);
      setAlertopen(rettostmsg.open);
      return;
    } else if (modalHeading === "Open Panna" && num1.length < 3) {
      rettostmsg = tostmsg({
        code: 100,
        msg: "Number should be three digits",
      });
      setAlertdata(rettostmsg);
      setAlertopen(rettostmsg.open);
      return;
    } else if (modalHeading === "Close Panna" && num1.length < 3) {
      rettostmsg = tostmsg({
        code: 100,
        msg: "Number should be three digits",
      });
      setAlertdata(rettostmsg);
      setAlertopen(rettostmsg.open);
      return;
    } else if (modalHeading === "Gunule" && num1.length < 3) {
      rettostmsg = tostmsg({
        code: 100,
        msg: "First Number should be three digits",
      });
      setAlertdata(rettostmsg);
      setAlertopen(rettostmsg.open);
      return;
    } else if (modalHeading === "Gunule" && num2.length <= 0) {
      rettostmsg = tostmsg({
        code: 100,
        msg: "Second Number should be one digits",
      });
      setAlertdata(rettostmsg);
      setAlertopen(rettostmsg.open);
      return;
    } else if (
      modalHeading === "Jackpot" &&
      (num1.length < 3 || num2.length < 3)
    ) {
      rettostmsg = tostmsg({
        code: 100,
        msg: "Both Number should be three digits",
      });
      setAlertdata(rettostmsg);
      setAlertopen(rettostmsg.open);
      return;
    }else if(modalHeading === "Ulta Gunule" && num1.length <= 0){
      rettostmsg = tostmsg({
        code: 100,
        msg: "First Number should be one digits",
      });
      setAlertdata(rettostmsg);
      setAlertopen(rettostmsg.open);
      return;
    }else if(modalHeading === "Ulta Gunule" && num2.length < 3){
      rettostmsg = tostmsg({
        code: 100,
        msg: "Second Number should be three digits",
      });
      setAlertdata(rettostmsg);
      setAlertopen(rettostmsg.open);
      return;
    }else if(valiDateNumData(num1,num2)===false){
      rettostmsg = tostmsg({
        code: 100,
        msg: "Enter a valid Number",
      });
      setAlertdata(rettostmsg);
      setAlertopen(rettostmsg.open);
      return;
    }

    // valiDateNumData(num1,num2)

    let data = {
      id: uuidv4(),
      drawid: 1,
      loginid: localStorage.getItem("loginId"),
      gtmid: getGtmid(),
      gtname: modalHeading,
      gname: paragname,
      gmid: gmid,
      points: points,
      npoints: npoints,
      num1: num1,
      num2: num2,
      result: "",
      isdrcr: 0,
      trid: 0,
      playdate: new Date(),
      cmpid: 0,
      fdate: formatDatetime_YMD(new Date(), 0),
      tdate: formatDatetime_YMD(new Date(), 1),
      regpan: calculateDigitSum(num1,modalHeading),
      cutpan: "",
    };
    playmasterDispach({
      type: "addobject",
      payload: data,
    });
    setNum1("");
    setNum2("");
    setPoints("");
  };
  const handleDeletePlay = (id) => {
    playmasterDispach({
      type: "deletePlay",
      id: id,
    });
  };
  const handleUpdatePlay = (id, name, numT1, numT2, value) => {
    let values = value;
    let regpan = 0;
    let cutpan = "";
    regpan = calculateDigitSum(numT1);
    if (name === "npoints") {
      values = `${numT1}`;
      if (numT2 !== "") {
        values = `${numT1}X${numT2}`;
      }
    }
    playmasterDispach({
      type: "updatePlay",
      id: id,
      name: name,
      value: values,
      regpan: regpan,
      cutpan: cutpan,
    });
  };

  const generateAlphanumericId = (length) => {
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset[randomIndex];
    }

    return result;
  };
 
  const groupByJsonData = (array, property) => {
    return array.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  };
  useEffect(() => {
    if (cdg?.print === true) {
      slipPrint();
    } else if (cdg?.print === false && cdg?.reload === true) {
      window.location.reload();
    }
  }, [cdg?.print]);

  const savePlayMaster = () => {
    const uniqueId = generateAlphanumericId(6);
    const groupedData = groupByJsonData(playmasterstate, "gtname");
    let totPoints = playmasterstate
      ?.reduce((acc, obj) => Number(acc) + Number(obj.points), 0)
      .toFixed(2);
    const jsonObject = {
      transactionId: uniqueId.toUpperCase(),
      gname: paragname,
      totpoints: totPoints,
      data: groupedData,
    };
    setPrintslip(jsonObject);
    setCdg({
      open: true,
      print: "false",
      msg: "Play saved successfully.Do you want to print this play slip/receipt?",
    });
    setTimeout(() => {
      let savePlayData = JSON.stringify(jsonObject);
      localStorage.setItem("playdataprint", savePlayData);
    }, 100);
  };
  const handleClickPrintOpen = () => {
    setPrintOpen(true);
  };

  const handlePrintClose = () => {
    setPrintOpen(false);
  };
  const PlayMasterDataSave = async () => {
    try {
      const newArray = [];

      // Iterate through the original JSON and push objects into the new array
      for (const key in playmasterstate) {
        if (Object.prototype.hasOwnProperty.call(playmasterstate, key)) {
          newArray.push(playmasterstate[key]);
        }
      }

      let data = newArray;
      const totalSum = data.reduce((accumulator, item) => {
        const points = parseInt(item.points, 10);
        return accumulator + points;
      }, 0);

      // Update each object with the total sum of points
      data.forEach((item) => {
          item.totpoints = totalSum;
      });
      // return;
      let res = await SaveAllPlaymaster(data);

      let rettostmsg = tostmsg(res);
      if (res.code == 200 && res?.data[0]?.dt > 0) {
        rettostmsg = tostmsg({ msg: "Play Submitted Successfully", code: 200 });
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        // alert(JSON.stringify(res))
        // slipPrint();
        setTrid(res?.data[0]?.trid)
        handleClickPrintOpen();
        // window.location.reload();
      }else if (res.data?.[0]?.dt == -1) {
        rettostmsg = tostmsg({
          code: 100,
          msg: "Insufficient balance for play",
          duration: 3000,
        });        
        setAlertdata(rettostmsg);
        setAlertopen(true);
        return;
      }    
      else {
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      }
    } catch (e) {
      setAlertdata({ code: 100, msg: "Frontend failed please try again" });
      setAlertopen(true);
    }
  };

  const slipPrint = (id)=>{
    // navigate("/payslip/"+id)
    const url = `/payslip/${id}`;    
    // Open the URL in a new tab
    window.open(url, '_blank');
    window.location.reload();
  }

  const fetchDrawmasterdata = async (data) => {
    try {
      let getDrawdata = await GetGamemasterData(data);
      if (getDrawdata.code === 200) {
        let CutOpTime = geHourTime(getDrawdata?.data[0]?.cutoptime, 24);
        let CutClTime = geHourTime(getDrawdata?.data[0]?.cutcltime, 24);
        let currentTime = geHourTime(new Date(), 24);
         let opStatus = false;
        let clStatus = false;
        if (currentTime <= CutOpTime) {
          opStatus = false;
        } else {
          opStatus = true;
        }
        if (currentTime <= CutClTime) {
          clStatus = false;
        } else {
          clStatus = true;
        }
        setopclCtrl({ op: opStatus, cl: clStatus });
      }
    } catch (error) {
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };

  useEffect(() => {
    fetchDrawmasterdata({
      fdate: formatDatetime_YMD(new Date(), 0),
      tdate: formatDatetime_YMD(new Date(), 1),
      gsubname: paragname,
      limit: "1",
    });
  }, []);

  return {
    alertdata,
    alertopen,
    setAlertopen,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    handleChangePage,
    handleChangeRowsPerPage,
    handlemodalOpen,
    handleInputChange,
    handlemodalClose,
    openmodal,
    modalHeading,
    num1,
    num2,
    isnumvisible,
    playmasterstate,
    handleChangePlay,
    points,
    getGtmid,
    handleDeletePlay,
    handleUpdatePlay,
    savePlayMaster,
    paragname,
    printSlip,
    cdg,
    setCdg,
    anchorEl,
    selectedOption,
    open,
    handleClick,
    handleClose,
    handleOptionClick,
    translatedText,
    PlayMasterDataSave,
    opclCtrl,printopen,handleClickPrintOpen,handlePrintClose,trid,slipPrint,
  };
}

export default usePlayMaster;
