import { createSlice } from "@reduxjs/toolkit"; 

const confirmDialogReducer = createSlice({
    name: 'confirm',
    initialState: {
        msg: [],    
        yeslbl:[],
        nolbl:[],
        heading:[],   
        resans:false,
        openclose:false,
        type:"",
    },

    reducers: {
        setConfirm(state, action){          
            state.msg= action.payload.msg;
            state.nolbl= action.payload.nolbl;
            state.yeslbl= action.payload.yeslbl;
            state.heading= action.payload.heading;
            state.openclose = action.payload.openclose;  
            state.type= action.payload.type;  
        },
        setReturnAnswer(state, action){
            state.openclose = action.payload?.openclose;
            state.resans = action.payload?.resans;
        }, 
        setOpen(state, action){            
            state.openclose = action.payload;
        },         
    }
});

export const { setConfirm, setReturnAnswer,setOpen} = confirmDialogReducer.actions;
export default confirmDialogReducer.reducer;

export const setConfirmDialogData = (data) => {
    return function setData(dispatch){
         dispatch(setConfirm({msg:data?.msg,yeslbl:data?.yeslbl,nolbl:data?.nolbl,heading:data?.heading,
            openclose:data?.openclose,type:data?.type}));        
    }
}
export const setOpenClose = (data) => {
    return function setData(dispatch){
         dispatch(setOpen(data));        
    }
}

export const setRetAnswer = (data) => {
    return function setData(dispatch){
         dispatch(setReturnAnswer(data));        
    }
}

 