
import { callAjax } from "../../MIS/Global";

export const SaveDrawmaster = async (data) => {
  let ret = {};
  if (data?.gname == undefined || data?.gname == "") {
    return { msg: "Please select game", code: 100 };
  }
  let url = "drawsave";
  if (data.drawid > 0) {
    url = "drawupdate";
  }
  ret = await callAjax(url, data);
  return ret;
};
export const SaveDrawResultmaster = async (data) => {
  let ret = {};
  if (data?.gname == undefined || data?.gname == "") {
    return { msg: "Please select game", code: 100 };
  }

  let url = "drawressavesingle";
  ret = await callAjax(url, data);
  return ret;
};

export const GetDrawmaster = async (data) => {
  let ret = {};

  let url = "getdrawdata";
  ret = await callAjax(url, data);
  return ret;
};
export const DeleteDrawmaster = async (data) => {
  let ret = {};

  let url = "deldraw";

  ret = await callAjax(url, data);
  return ret;
};
export const GetPlaySumPoints = async (data) => {
  let ret = {};
  let url = "getplaysumpoints";
  ret = await callAjax(url, data);
  return ret;
};

export const DrawAutoEntry = async (data) => {
  let ret = {};
  let url = "drawautoentry";
  ret = await callAjax(url, data);
  return ret;
};