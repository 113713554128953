import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import ResultPredictionChart from '../../Components/ResultPrediction/ResultPredictionChart'

const ResultPredictionScreen = () => {
  return (
    <div>
        <Appbar/>
        <ResultPredictionChart/>
    </div>
  )
}

export default ResultPredictionScreen