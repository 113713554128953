import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import ForgotPasswordRequestList from '../../Components/SignInPage/ForgotPasswordRequestList'

const ForgotPsswordRequestScreen = () => {
  return (
    <>
       <Appbar/>
       <ForgotPasswordRequestList />
    </>
  )
}

export default ForgotPsswordRequestScreen