import React, { useContext, useEffect, useReducer, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "../../Tables/Table.css";
import { Checkbox, TableContainer, TextField } from "@mui/material";
import style from "./closechart.module.css"
import { useSelector } from "react-redux";



const CloseResultChartTable = (props) => {
  const {predictionChartList} = useSelector((state) => state.predictionchart);   
  const handleGetAmount=(num,type)=>{  
    let data = "";
    if(type==="c"){
      data =predictionChartList.filter(item => item?.num1.toString() === num.toString());      
      return data?.[0]?.c;
    }else if(type==="cp"){
      data =predictionChartList.filter(item => item?.num2.toString() === num.toString());
      return data?.[0]?.cp;
    }else if(type==="j"){
      data =predictionChartList.filter(item => item?.num1.toString() === num.toString());
      return data?.[0]?.j;
    }else if(type==="g"){
      data =predictionChartList.filter(item => item?.num1.toString() === num.toString());
      return data?.[0]?.g;
    }else if(type==="ug"){
      data =predictionChartList.filter(item => item?.num2.toString() === num.toString());
      return data?.[0]?.ug;
    }else if(type==="jk"){
      data =predictionChartList.filter(item => item?.num2.toString() === num.toString());
      return data?.[0]?.jk;
    }
  }
   const data = [
    [100,119, 128, 137, 146, 155, 227, 236, 245, 290, 335, 344, 380, 399, 470, 489, 560, 579, 588, 669, 678, 777],
    [110, 129, 138, 147, 156, 200, 228, 237, 246, 255, 336, 345, 390, 444, 480, 499, 570, 589, 660, 679, 688, 778],
    [111, 120, 139, 148, 157, 166, 229, 238, 247, 256, 300, 337, 346, 355, 445, 490, 580, 599, 670, 689, 779, 788],
    [112, 130, 149, 158, 167, 220, 239, 248, 257, 266, 338, 347, 356, 400, 446, 455, 590, 680, 699, 770, 789, 888],
    [113, 122, 140, 159, 168, 177, 230, 249, 258, 267, 339, 348, 357, 366, 447, 456, 500, 555, 690, 780, 799, 889],
    [114, 123, 150, 169, 178, 222, 240, 259, 268, 277, 330, 349, 358, 367, 448, 457, 466, 556, 600, 790, 880, 899],
    [115, 124, 133, 160, 179, 188, 223, 250, 269, 278, 340, 359, 368, 377, 449, 458, 467, 557, 566, 700, 890, 999],
    [116, 125, 134, 170, 189, 224, 233, 260, 279, 288, 350, 369, 378, 440, 459, 468, 477, 558, 567, 666, 800, 990],
    [117, 126, 135, 144, 180, 199, 225, 234, 270, 289, 333, 360, 379, 388, 450, 469, 478, 559, 568, 577, 667, 900],
    [0, 118, 127, 136, 145, 190, 226, 235, 244, 280, 299, 334, 370, 389, 460, 479, 488, 550, 569, 578, 668, 677],
  ];
 
  return (
    <>
      <div>
        <Paper className="contner1">
          <TableContainer sx={{ maxHeight: "65vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              style={{
                fontSize: "100pt!important",
                width:"100%"
              }}
            >
              <TableHead>
                <TableRow>

                  <TableCell className={style.rclosecontainer}>1</TableCell>
                  <TableCell className={style.rclosecontainer}>2</TableCell>
                  <TableCell className={style.rclosecontainer}>3</TableCell>
                  <TableCell className={style.rclosecontainer}>4</TableCell>
                  <TableCell className={style.rclosecontainer}>5</TableCell>
                  <TableCell className={style.rclosecontainer}>6</TableCell>
                  <TableCell className={style.rclosecontainer}>7</TableCell>
                  <TableCell className={style.rclosecontainer}>8</TableCell>
                  <TableCell className={style.rclosecontainer}>9</TableCell>
                  <TableCell className={style.rclosecontainer}>0</TableCell>

                </TableRow>
              </TableHead>

              <TableBody className="bodycon">
              {data[0].map((_, colIndex) => (
                <TableRow key={colIndex}>
                  {data.map((rowData, rowIndex) => {
                    let calcRowIndex=0;
                   calcRowIndex=rowIndex>=9?0:rowIndex+1;
                  return(
                    <TableCell key={rowIndex} align="center" className={style.maincellcontainer}>
                       <div className={style.columncontaint}>
                        <div className={style.points}>{rowData[colIndex]}</div>
                        <div className={style.clnumbercon}>
                            <div className={style.closesub}>
                            <div className={style.closenumber}></div>
                            <div className={style.closetxtsty}>
                            <div className={style.toppoint1}>C={handleGetAmount(`${calcRowIndex}`,'c')}</div>
                            <div className={style.closebottompoint}>CP={handleGetAmount(`${rowData[colIndex]}`,'cp')}</div>
                            </div>
                            </div>
                            <div className={style.closesub}>
                            <div className={style.closenocon1}></div>
                            <div className={style.closetxtsty}>
                            <div className={style.closebottompoint}>JO={handleGetAmount(`${calcRowIndex}`,'j')}</div>
                            </div>
                            </div>
                            <div className={style.closesub}>
                            <div className={style.closenocon1}></div>
                            <div className={style.closetxtsty}>
                            <div className={style.closebottompoint}>G={handleGetAmount(`${calcRowIndex}`,'g')}</div>
                            </div>
                            </div>
                            <div className={style.closesub}>
                            <div className={style.closenocon1}></div>
                            <div className={style.closetxtsty}>
                        <div className={style.closebottompoint}>UG={handleGetAmount(`${rowData[colIndex]}`,'ug')}</div>
                            </div>
                            </div>
                            <div className={style.closesub} style={{display:""}}>
                            <div className={style.closenocon1}></div>
                            <div className={style.closetxtsty}>
                             <div className={style.closebottompoint}>JK={handleGetAmount(`${rowData[colIndex]}`,'jk')}</div>
                            </div>
                            </div>
                        </div>
                        
                       </div>
                    </TableCell>
                  )
                  
                  })}
                </TableRow>
              ))}
            </TableBody>
            </Table>
          </TableContainer>

        </Paper>
      </div>
    </>
  );
};
export default CloseResultChartTable;
