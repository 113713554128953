import React from 'react'
import UserProfile from '../../Components/UserProfile/UserProfile'
import Appbar from '../../Components/Appbar/Appbar'

const UserProfileScreen = () => {
  return (
    <>
      <Appbar />
      <UserProfile />
    </>
  )
}

export default UserProfileScreen