import { useEffect, useReducer, useState, React } from "react";
import { useNavigate } from "react-router-dom";
import WalletmasterReducer from "../Reducer/WalletmasterReducer";
import { Getwalletclosing, Getwalletmaster } from "../Model/WalletmasterModel";
import { tostmsg } from "../../MIS/Global";
import { GetUserdata } from "../Model/UserSignupModel";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';


function useLedgerReport() {
  const [alertdata, setAlertdata] = useState({});
  const [alertopen, setAlertopen] = useState(false);
  const [reportsearch, setReportsearch] = useState("");
  const [reportstate, reportDispatch] = useReducer(WalletmasterReducer, []);
  const [reportorgstate, reportorgDispatch] = useReducer(
    WalletmasterReducer,
    []
  );

  const [uname, setUname] = useState("");
  const [loginid, setLoginid] = useState(0);
  const [fdate, setFdate] = useState(new Date());
  const [tdate, setTdate] = useState(new Date());
  const [savemember, setsavemember] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const rowsPerPageOptions = [
    25,
    50,
    100,
    200,
    300,
    500,
    1000,
    { value: reportstate?.length, label: "All" },
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(+event.target.value, 10));
    setPage(0);
  };

  const navigate = useNavigate();

  function formatDate(date) {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      new Date(date)
    );
    const formattedDateWithoutComma = formattedDate.replace(",", "");
    return formattedDateWithoutComma.replace(/(\s[ap]m)/i, (match) =>
      match.toUpperCase()
    );
  }

  const getdata = async () => {
    let data = {
      uname: uname,
    };
    // alert(JSON.stringify(data))
    let reportdata = await GetUserdata(data);
    // alert(JSON.stringify(reportdata))
    if (reportdata.code == 200) {
      let cetarr = [];
      reportdata.data.forEach((element) => {
        cetarr.push({
          id: element.loginid,
          label: `${element.uname}-${element.mob}`,
        });
      });
      //   alert(JSON.stringify(cetarr))
      setsavemember(cetarr);
    } else {
      setsavemember([]);
    }
  };
  useEffect(() => {
    getdata();
  }, [uname]);
  const formatDate_YMD = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const handlereportChange = (name, value) => {
     switch (name) {
      case "loginid":
        setLoginid(value);
        break;
        case "uname":
        setUname(value);
        break;
      case "fdate":
        let fFdate=formatDate_YMD(new Date(value));
        setFdate(fFdate);
        break;
      case "tdate":
        let tFdate=formatDate_YMD(new Date(value));       
         setTdate(tFdate);
        break;
        case "search":
        const tFdate_one = new Date(tdate);
        tFdate_one.setDate(tFdate_one.getDate() + 1);
        let data={loginid:loginid,fdate:fdate,tdate:tFdate_one,isgroup:"0"}
        getReportdata(data);
         break;
        case "searchlogid_props":
         const result = savemember.filter(item => item.id == value);
        setUname(result[0]?.label)
        setLoginid(result[0]?.id)       
        let data1={loginid:value,isgroup:"0"}
        getReportdata(data1);
         break;
      default:
        break;
    }
  };

  const getReportdata = async (data) => {
     try {
      let getledgerdata = await Getwalletclosing(data);
      if (getledgerdata.code === 200) {
        // alert(JSON.stringify(getledgerdata.data))
        reportDispatch({
          type: "walletDetails",
          data: getledgerdata?.data,
        });
        reportorgDispatch({
          type: "walletDetails",
          data: getledgerdata?.data,
        });
      }
    } catch (error) {
      // Handle any errors here
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };
  useEffect(() => {
    const tFdate_one = new Date(tdate);
    tFdate_one.setDate(tFdate_one.getDate() + 1);
    let data={fdate:fdate,tdate:tFdate_one,isgroup:"0"}
    getReportdata(data);
  }, []);

  const LedgerReportsearch = (searchstr) => {
    const keysToSearch = ["uname", "mob", "cr", "dr"];
    const filteredData = reportorgstate?.filter((item) => {
      return keysToSearch?.some((key) => {
        return item[key]
          ?.toString()
          .toLowerCase()
          ?.includes(searchstr.toString().toLowerCase());
      });
    });
    // alert(JSON.stringify(filteredData))
    reportDispatch({
      type: "walletDetails",
      data: filteredData,
    });
  };

  useEffect(() => {
    LedgerReportsearch(reportsearch);
  }, [reportsearch]);


  const exportToPDF = () => {
    const table = document.querySelector('.container'); // Replace '.your-table-class' with your table's CSS class

  if (table) {
    html2canvas(table)
      .then((canvas) => {
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgData = canvas.toDataURL('image/png');

        // Adjust the width and height as needed for the PDF
        const imgWidth = 210; // A4 size page width in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save('exported-pdf.pdf');
      });
  }
  };

  return {
    reportstate,
    navigate,
    alertdata,
    alertopen,
    setAlertopen,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    handleChangePage,
    handleChangeRowsPerPage,
    formatDate,
    setReportsearch,
    handlereportChange,
    savemember,    
    uname,
    fdate,
    tdate,
    exportToPDF,
  };
}

export default useLedgerReport;
 
