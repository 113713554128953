import React, { useRef } from "react";
import { useEffect, useReducer, useState } from "react";
import { tostmsg } from "../../MIS/Global";
import DrawmasterReducer from "../Reducer/DrawmasterReducer";
import {
  DeleteDrawmaster,
  GetDrawmaster,
  GetPlaySumPoints,
  SaveDrawResultmaster,
  SaveDrawmaster,
} from "../Model/DrawmasterModel";
import { GetAllGameData, GetGamemasterData } from "../Model/GamemasterModel";
import GamemasterReducer from "../Reducer/GamemasterReducer";
import { GetPlaymaster, SearchAllPlaydata } from "../Model/PlaymasterModel";
import numData from "../../MIS/Numdata.json";

function useDrawmaster() {
  const [alertdata, setAlertdata] = useState({});
  const [alertopen, setAlertopen] = useState(false);

  const [drawmasterstate, drawmasterDispach] = useReducer(
    DrawmasterReducer,
    []
  );
  const [drawresultmasterstate, drawresultmasterDispach] = useReducer(
    DrawmasterReducer,
    []
  );
  const [drawsearchState, drawsearchDispach] = useReducer(
    DrawmasterReducer,
    []
  );
  const [drawtableState, drawTableDispach] = useReducer(DrawmasterReducer, []);
  const [drawtablesummaryState, drawTableSummaryDispach] = useReducer(DrawmasterReducer, []);
  const [playdataState, playdataDispach] = useReducer(DrawmasterReducer, [] );
  const [gamesearchState, gamesearchDispach] = useReducer(
    GamemasterReducer,
    []
  );
  const [getallgameState, getallgameDispach] = useReducer(
    GamemasterReducer,
    []
  );
  const [gname, setGname] = useState("");
  const [drawid, setDrawid] = useState("");
  const [gmid, setGmid] = useState("");
  const [boxdata, setBoxdata] = useState([]);
  const [fdate, setFdate] = useState(new Date());

  const [value, setValue] = useState(false);
  const [cmdinfo, setCmdinfo] = useState({});
  const [btnname, setBtnname] = useState("");

  const [radioOC, setRadioOC] = useState({o:false,c:false});
  const [drawData, setDrawData] = useState({});

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [page1, setPage1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);

  const [meState, setMe] = React.useState({m:true,e:true});
  const [metotpredState, setMetotprd] = React.useState({m:0,e:0,t:0});
  const [alldate, setAlldate] = useState({
    drawdate: new Date(),
    starttime: new Date(0, 0, 0, 6, 0, 0, 0),
    optime: new Date(0, 0, 0, 6, 0, 0, 0),
    cltime: new Date(0, 0, 0, 6, 0, 0, 0),
    cutrestime: new Date(0, 0, 0, 14, 15, 0, 0),
    cutrescltime: new Date(0, 0, 0, 21, 15, 0, 0),
    cutoptime: new Date(0, 0, 0, 14, 0, 0, 0),
    cutcltime: new Date(0, 0, 0, 21, 0, 0, 0),
  });
  
  const rowsPerPageOptions = [
    10,
    25,
    50,
    100,
    { value: gamesearchState?.length, label: "All" },
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(+event.target.value, 10));
    setPage(0);
  };

  const rowsPerPageOptions1 = [
    10,
    25,
    50,
    100,
    { value: drawsearchState?.length, label: "All" },
  ];
  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(+event.target.value, 10));
    setPage1(0);
  };

  const calculateDigitSum = (e) => {
    let numberString="";let sum="";
    let openPan = `${drawresultmasterstate?.m1}${drawresultmasterstate?.m2}${drawresultmasterstate?.m3}`;
    let closePan = `${drawresultmasterstate?.m10}${drawresultmasterstate?.m11}${drawresultmasterstate?.m12}`;

    if (e && e.target) {
      if (e.target.name === "m1"){
        openPan = `${e.target.value}${drawresultmasterstate?.m2}${drawresultmasterstate?.m3}`;
      }else if(e.target.name === "m2"){
        openPan = `${drawresultmasterstate?.m1}${e.target.value}${drawresultmasterstate?.m3}`;
      }else if(e.target.name === "m3"){
        openPan = `${drawresultmasterstate?.m1}${drawresultmasterstate?.m2}${e.target.value}`;
      }else if(e.target.name === "m10"){
        closePan = `${e.target.value}${drawresultmasterstate?.m11}${drawresultmasterstate?.m12}`;
      }else if(e.target.name === "m11"){
        closePan = `${drawresultmasterstate?.m10}${e.target.value}${drawresultmasterstate?.m12}`;
      }else if(e.target.name === "m12"){
        closePan = `${drawresultmasterstate?.m10}${drawresultmasterstate?.m11}${e.target.value}`;
      }

      if (e.target.name === "m1" ||e.target.name === "m2" ||e.target.name === "m3"
      ) {
        numberString = openPan.toString();
        sum = Array.from(numberString).reduce(
          (acc, digit) => acc + parseInt(digit, 10),
          0
        );
        if (sum >= 10) {
          sum= sum % 10;
        }
        if (isNaN(sum)) {
          sum="";
        }
        drawresultmasterDispach({
          type: "Change",
          payload: { name: "m5", value: sum.toString() },
        });
      } else {
        // alert()
        numberString = closePan.toString();
        sum = Array.from(numberString).reduce(
          (acc, digit) => acc + parseInt(digit, 10),
          0
        );
        if (sum >= 10) {
          sum= sum % 10;
        }
        if (isNaN(sum)) {
          sum="";
        }
        drawresultmasterDispach({
          type: "Change",
          payload: { name: "m8", value: sum },
        });
      }
    }
  };

  const checkAscendingOrder = (rnum) => {
    const digits = rnum.toString().split("").map(Number);
    if (digits.length <= 2) {
      return false;
    } else if (digits[2] <= 0) {
      if (digits[0] <= digits[1]) {
        return true;
      } else {
        return false;
      }
    } else if (digits.length === 3) {
      if (digits[0] <= digits[1] && digits[1] <= digits[2]) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const formatDatetime_YMD = (date, pmday) => {
    const currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + pmday);
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")} ${currentDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${currentDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;

    return formattedDate;
  };

  const handledrawmaster = (e, value) => {
    // alert(e)
    if (e && e.target) {
      drawmasterDispach({
        type: "Change",
        payload: { name: e.target.name, value: e.target.value },
      });
    }
  };

  const handledrawresultmaster = (e, value) => {
    // alert(e)
    if (e && e.target) {
      calculateDigitSum(e);
      drawresultmasterDispach({
        type: "Change",
        payload: { name: e.target.name, value: e.target.value },
      });
    }
  };
  const addMinutesToTime = (originalTime, minutesToAdd) => {
    const [hours, minutes] = originalTime.split(':').map(Number);

    // Use a default date (1899-01-01) along with the given time
    const defaultDate = new Date('1899-01-01');
    defaultDate.setHours(hours);
    defaultDate.setMinutes(minutes);

    const newDate = new Date(defaultDate.getTime() + minutesToAdd * 60000);

    const newHours = newDate.getHours();
    const newMinutes = newDate.getMinutes();

    return `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;

  };

  const handleAlldate = (e, value) => {        
    let orgTime="";let resTime="";    
    setAlldate({ ...alldate, [e]: value });
    if(e=="cutoptime"){
      orgTime=getHourTime(value,24);
      resTime=addMinutesToTime(orgTime,15);         
      const [hours, minutes] = resTime.split(':').map(Number);            
      setAlldate({ ...alldate, [e]: value,cutrestime: new Date(0, 0, 0, hours, minutes, 0, 0)});
    }else if(e=="cutcltime"){
      orgTime=getHourTime(value,24);
      resTime=addMinutesToTime(orgTime,15);         
      const [hours, minutes] = resTime.split(':').map(Number);           
      setAlldate({ ...alldate, [e]: value,cutrescltime: new Date(0, 0, 0, hours, minutes, 0, 0)});
    }
  };
 
  

  const getPlaySumPoints = async (data) => {
    try {
      if (data?.gmid == "" || data?.gmid == undefined) {
        return;
      }
      let getDrawdata = await GetPlaySumPoints(data);       
      
      let getDrawdataSummary = await GetPlaySumPoints({...data,gtmid:""});    
      if(getDrawdataSummary.code===200){
     
        const gtmidSumMap = new Map();

        getDrawdataSummary?.data.forEach(item => {
          const gtmid = item.gtmid;
          const points = parseInt(item.points, 10);
          if (gtmidSumMap.has(gtmid)) {
            gtmidSumMap.set(gtmid, gtmidSumMap.get(gtmid) + points);
          } else {
            gtmidSumMap.set(gtmid, points);
          }
        });
        
        // Creating a new array of objects with gtmid and sum of points
        const resultArray = Array.from(gtmidSumMap).map(([gtmid, sumPoints]) => ({
          gtmid: gtmid,
          sumPoints: sumPoints,
        }));
        drawTableSummaryDispach({
          type: "drawmasterDetails",
          data: resultArray,
        });
      }
      if (getDrawdata.code === 200) {
      
        let tblData = [];
        let totPoints = 0;
        if (data?.gtmid === 1 || data?.gtmid === 2) {
          totPoints = getDrawdata?.data.reduce(
            (acc, cur) => Number(acc) + Number(cur?.points),
            0
          );
          numData?.numid?.map((element) => {
            let points = 0;
            const filteredData = getDrawdata?.data.filter(
              (item) => item.npoints.toString() === element?.id.toString()
            );
            points = filteredData.length > 0 ? filteredData[0]?.points : points;
            totPoints = Number(totPoints).toFixed(2);

            tblData.push({
              id: element?.id,
              points: points,
              totpoints: totPoints,
              hclm: 10,
              rrow: 1,
              rinrclm: 10,
              fclm: 10,
            });
          });
          drawTableDispach({
            type: "drawmasterDetails",
            data: tblData,
          });
        } else if (data?.gtmid === 70) {
          const sortedData = getDrawdata?.data.sort((a, b) => {
            return parseInt(a.points) - parseInt(b.points);
          });
          totPoints = getDrawdata?.data.reduce(
            (acc, cur) => Number(acc) + Number(cur?.points),
            0
          );
          const additionalData = {
            totpoints: Number(totPoints).toFixed(2),
            hclm: 2,
            rrow: sortedData.length,
            rinrclm: 2,
            fclm: 2,
          };
          const newData = sortedData.map((item) => {
            return { ...item, ...additionalData };
          });
          // console.log(newData[0]?.hclm)
           drawTableDispach({
            type: "drawmasterDetails",
            data: newData,
          });
        } else {
          const sortedData = getDrawdata?.data.sort((a, b) => {
            if (a.regpan !== b.regpan) {
              return parseInt(a.regpan) - parseInt(b.regpan); // Sort by "regpan"
            } else {
              return parseInt(a.points) - parseInt(b.points); // If "regpan" is the same, sort by "points"
            }
          });
         
          let maxRegpanCount = 0;
          let regpanWithMaxCount;
          const regpanCountMap = new Map();

          sortedData.forEach(item => {
            const regpan = item.regpan;
            regpanCountMap.set(regpan, (regpanCountMap.get(regpan) || 0) + 1);
          });
          regpanCountMap.forEach((count, regpan) => {
            if (count > maxRegpanCount) {
              maxRegpanCount = count;
              regpanWithMaxCount = regpan;
            }
          });        
          totPoints = sortedData.reduce(
            (acc, cur) => Number(acc) + Number(cur?.points),
            0
          );
          let additionalData = {
            totpoints: Number(totPoints).toFixed(2),
            hclm: 10,
            rrow: maxRegpanCount,
            rinrclm: 10,
            fclm: 10,
          };
          const newData = sortedData.map((item) => {
            return { ...item, ...additionalData };
          });

          drawTableDispach({
            type: "drawmasterDetails",
            data: newData,
          });
        }
      }
    } catch (error) {
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };

  const call_getPlaySumPoints = (gtmid) => {
    drawTableDispach({
      type: "drawmasterDetails",
      data: [],
    });

    setTimeout(() => {
      if (gmid !== "" && gmid !== undefined)
        getPlaySumPoints({
          gmid: gmid,
          pfdate: formatDatetime_YMD(new Date(), 0),
          ptdate: formatDatetime_YMD(new Date(), 1),
          gtmid: gtmid,
        });
    }, 100);
  };
  const getHourTime = (dateTime, ishour) => {
    let bal = false;
    if (ishour.toString() === "12".toString()) {
      bal = true;
    } else {
      bal = false;
    }
    let curDate = new Date(dateTime);
    const timeString = curDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: bal,
    });
    return timeString;
  };
  const handleGameDropdown = (data) => {
    // setMe] = React.useState({m:true,e:true});
    
    setGname(data?.gsubname);
    setDrawid(data?.drawid);
    setGmid(data?.gmid);    
    setDrawData(data);
    let curTime =getHourTime(new Date(),24);
    let opTime =getHourTime(data?.cutoptime,24);
    let clTime =getHourTime(data?.cutcltime,24);
    if(curTime>clTime){
      // setMe({m:false,e:false})
      drawresultmasterDispach({
        type: "drawmasterDetails",
        data: {
          m1: data?.m1?.toString(),
          m2: data?.m2?.toString(),
          m3: data?.m3?.toString(),
          m5: data?.m5?.toString(),
          m8: data?.m8?.toString(),
          m10: data?.m10?.toString(),
          m11: data?.m11?.toString(),
          m12: data?.m12?.toString(),
        },
      });      
    }else if(curTime>opTime){
      // setMe({m:false,e:true})
      drawresultmasterDispach({
        type: "drawmasterDetails",
        data: {
          m1: data?.m1?.toString(),
          m2: data?.m2?.toString(),
          m3: data?.m3?.toString(),
          m5: data?.m5?.toString(),
          m8: null,
          m10: null,
          m11: null,
          m12: null,
        },
      });
    }
    
    getPlaySumPoints({
      gmid: data?.gmid,
      pfdate: formatDatetime_YMD(new Date(), 0),
      ptdate: formatDatetime_YMD(new Date(), 1),
      gtmid: 1,
    });
  };
  const handleDefualtDrawMasterData=(data,gameData)=>{
    setGname(data?.gsubname);
    setGmid(data?.gmid);
    const filteredArray = gameData.filter(
      (item) => item?.gmid === data?.gmid
    );

    drawsearchDispach({
      type: "drawmasterDetails",
      data: filteredArray,
    });

    if (filteredArray.length > 0) {
      setDrawid(filteredArray[0]?.drawid);
      drawmasterDispach({
        type: "drawmasterDetails",
        data: filteredArray[0],
      });
      setAlldate({
        drawdate: filteredArray[0]?.drawdate,
        starttime: filteredArray[0]?.starttime,
        optime: filteredArray[0]?.optime,
        cltime: filteredArray[0]?.cltime,
        cutrestime: filteredArray[0]?.cutrestime,
        cutrescltime: filteredArray[0]?.cutrescltime,
        cutoptime: filteredArray[0]?.cutoptime,
        cutcltime: filteredArray[0]?.cutcltime,
      });
    }
  }
  const handleGameDataDropdown = (data) => {
    setGname(data?.gsubname);
    setGmid(data?.gmid);
    const filteredArray = gamesearchState.filter(
      (item) => item?.gmid === data?.gmid
    );

    drawsearchDispach({
      type: "drawmasterDetails",
      data: filteredArray,
    });

    if (filteredArray.length > 0) {
      setDrawid(filteredArray[0]?.drawid);
      drawmasterDispach({
        type: "drawmasterDetails",
        data: filteredArray[0],
      });
      setAlldate({
        drawdate: filteredArray[0]?.drawdate,
        starttime: filteredArray[0]?.starttime,
        optime: filteredArray[0]?.optime,
        cltime: filteredArray[0]?.cltime,
        cutrestime: filteredArray[0]?.cutrestime,
        cutrescltime: filteredArray[0]?.cutrescltime,
        cutoptime: filteredArray[0]?.cutoptime,
        cutcltime: filteredArray[0]?.cutcltime,
      });
    }
  };

  function formatTime(date) {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      new Date(date)
    );
    const formattedDateWithoutComma = formattedDate.replace(",", "");
    return formattedDateWithoutComma.replace(/(\s[ap]m)/i, (match) =>
      match.toUpperCase()
    );
  }

  const get12HourTime = (dateTime) => {
    let curDate = new Date(dateTime);
    const timeString = curDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return timeString;
  };

  const DrawmasterSave = async () => {    
    try {
      let data = {
        ...drawmasterstate,
        drawid: drawid,
        gname: gname,
        gmid: gmid,
        gtmid: 0,
        drawdate: alldate?.drawdate,
        starttime: alldate?.starttime,
        optime: alldate?.optime,
        cltime: alldate?.cltime,
        cutrestime: alldate?.cutrestime,
        cutrescltime: alldate?.cutrescltime,
        cutoptime: alldate?.cutoptime,
        cutcltime: alldate?.cutcltime,
        result: 0,
        loginid: localStorage.getItem("aloginid"),
        cmpid: 0,
      };
      let res="";
      let rettostmsg ="";
     
      if(getHourTime(new Date(alldate?.starttime), 24)>getHourTime(new Date(alldate?.cutoptime), 24)){
        rettostmsg = tostmsg({
          msg: "Start Time Should be less than Open CutOff Time",
          code: 100,
        });
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        return;  

      }else if(getHourTime(new Date(alldate?.starttime), 24)>getHourTime(new Date(alldate?.cutcltime), 24)){
        rettostmsg = tostmsg({
          msg: "Start Time Should be less than Close CutOff Time",
          code: 100,
        });
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        return;  

      }else if(getHourTime(new Date(alldate?.starttime), 24)>getHourTime(new Date(alldate?.cutrestime), 24)){
        rettostmsg = tostmsg({
          msg: "Start Time Should be less than Open Result Time",
          code: 100,
        });
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        return; 
      }else if(getHourTime(new Date(alldate?.starttime), 24)>getHourTime(new Date(alldate?.cutrescltime), 24)){
        rettostmsg = tostmsg({
          msg: "Start Time Should be less than Close Result Time",
          code: 100,
        });
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        return; 
      }     
      else if(getHourTime(new Date(alldate?.cutrestime), 24)<getHourTime(new Date(alldate?.cutoptime), 24)){
        rettostmsg = tostmsg({
          msg: "Open Result Time Should be greater than Open CutOff Time",
          code: 100,
        });
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        return;        
      }else if(getHourTime(new Date(alldate?.cutrescltime), 24)<getHourTime(new Date(alldate?.cutcltime), 24)){
        rettostmsg = tostmsg({
          msg: "Close Result Time Should be greater than Close CutOff Time",
          code: 100,
        });
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        return;    
      }else if(getHourTime(new Date(alldate?.cutoptime), 24) > getHourTime(new Date(alldate?.cutcltime), 24)){
        rettostmsg = tostmsg({
          msg: "Close CutOff Time Should be greater than Open CutOff Time",
          code: 100,
        });
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        return;
      }
      else{
        res = await SaveDrawmaster(data);
      }
      rettostmsg = tostmsg(res);
      if (res.code == 200 && res?.data[0]?.dt <= 0) {
        rettostmsg = tostmsg({
          msg: "You can't create or schedule a play or draw today because it has already been declared a holiday.",
          code: 100,
        });
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      }
      else if (res.code == 200 && res?.data[0]?.dt > 0) {
        rettostmsg = tostmsg({
          msg: "Today's play sumbmitted successfully.",
          code: 200,
        });
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        window.location.reload();
      } else {
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      }
    } catch (e) {
      setAlertdata({ code: 100, msg: "Frontend failed please try again" });
      setAlertopen(true);
    }
  };
  const DrawmasterDelete = async (data) => {
    try {
      const playRecordExists = await GetPlaymaster(data);
      if (playRecordExists.data != 0) {
        // alert(JSON.stringify(playRecordExists.data))
        alert("Play record exists for this draw.");
        return;
      }
      const drawRecordDeleted = await DeleteDrawmaster(data);
      let rettostmsg = tostmsg(drawRecordDeleted);
      if (drawRecordDeleted.code == 200) {
        // alert(JSON.stringify(drawRecordDeleted.code));
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      }
      window.location.reload();
    } catch (e) {
      setAlertdata({ code: 100, msg: "Frontend failed please try again" });
      setAlertopen(true);
    }
  };

  const fetchDrawmasterdata = async () => {
    try {
      let getDrawdata = await GetDrawmaster();
      if (getDrawdata.code === 200) {
        drawsearchDispach({
          type: "drawmasterDetails",
          data: getDrawdata?.data,
        });
      }
    } catch (error) {
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };

  const fetchGamemasterdata = async (data) => {
    try {
      let getGamedata = await GetGamemasterData(data);
      if (getGamedata.code === 200) {
        const updatedData = getGamedata?.data.map((item) => ({
          ...item,
          label: item?.gsubname,
        }));
        const extractedData = getGamedata?.data?.map((item) => ({
          name: item.gsubname,
        }));
        setBoxdata(extractedData);
        gamesearchDispach({
          type: "gamemasterDetails",
          data: updatedData,
        });        
        if(window.location.pathname=="/draw"){
          handleDefualtDrawMasterData(getallgameState?.[0],updatedData);
        }
      }
    } catch (error) {
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      
    }
  };

  const getAllGameData = async (data) => {
    try {
      let getGamedata = await GetAllGameData(data);
      if (getGamedata.code === 200) {
        const updatedData = getGamedata?.data.map((item) => ({
          ...item,
          label: item?.gsubname,
        }));
        getallgameDispach({
          type: "gamemasterDetails",
          data: updatedData,
        });
       
        
      }
    } catch (error) {
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };

  useEffect(() => {    
    getAllGameData();        
  }, []);
  useEffect(() => {    
    if(getallgameState.length>0)
    fetchGamemasterdata({
      fdate: formatDatetime_YMD(new Date(), 0),
      tdate: formatDatetime_YMD(new Date(), 1),
    });        
  }, [getallgameState]);
  useEffect(() => {    
    if(getallgameState.length>0 && gamesearchState.length>0){
      if(window.location.pathname=="/drawresult"){
        handleGameDropdown(gamesearchState?.[0]);
        getMETotPrediction();
      }
    }         
  }, [getallgameState,gamesearchState]);
  useEffect(() => {    
    if(drawid>0){
      if(window.location.pathname=="/drawresult"){        
        getMETotPrediction();
      }
    }         
  }, [drawid]);

  const EditDrawmaster = async (element) => {
    let dt = {
      ...element,
    };
    // alert(JSON.stringify(dt));
    setAlldate(dt);
    drawmasterDispach({
      type: "drawmasterDetails",
      data: dt,
    });
  };

  const DrawResultmasterSave = async () => {
    try {
      let validateData = false;
      let msg = "Result number is invalid";
      let openPan = `${drawresultmasterstate?.m1}${drawresultmasterstate?.m2}${drawresultmasterstate?.m3}`;

      let closePan = `${drawresultmasterstate?.m10}${drawresultmasterstate?.m11}${drawresultmasterstate?.m12}`;

      let filterData = gamesearchState.filter((item) => item.drawid >= drawid);
      let curTime = getHourTime(new Date(), 24);
      const isAtLeastOneTrue = Object.values(radioOC).some(value => value === true);
      
      if (isAtLeastOneTrue===false) {
        let rettostmsg ="";
         rettostmsg = tostmsg({
          msg: "You should select either the 'open' or 'close' mode type.",
          code: 100,
        });
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        return;        
      }
      if (filterData.length > 0) {
        let fistRes = getHourTime(new Date(filterData[0]?.cutoptime), 24);
        let secRes = getHourTime(new Date(filterData[0]?.cutcltime), 24);
        if (curTime < secRes) {
          if (curTime >= fistRes) {
            validateData = checkAscendingOrder(openPan);
          } else {
            validateData = false;
            msg = `Open result set time is after ${getHourTime(
              new Date(filterData[0]?.cutoptime),
              12
            )}`;
          }
        } else {
          if (curTime >= secRes) {
            validateData = checkAscendingOrder(openPan);
            validateData = checkAscendingOrder(closePan);
          } else {
            validateData = false;
            msg = `Close result set time is after ${getHourTime(
              new Date(filterData[0]?.cutoptime),
              12
            )}`;
          }
        }
      }

      let data = {
        ...drawresultmasterstate,
        gname: gname,
        drawid: drawid,
        loginid: localStorage.getItem("aloginid"),
        result:meState?.e===true?"1":"2",
      };

      let res = "";
      let rettostmsg = tostmsg(res);
      if (validateData == false) {
        rettostmsg = tostmsg({
          msg: msg,
          code: 100,
        });
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        return;
      } else {        
        res = await SaveDrawResultmaster(data);
      }

      if (res.code == 200 && res?.data[0]?.dt > 0) {
        rettostmsg = tostmsg({
          msg: "Draw result saved successfully",
          code: 200,
        });
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        fetchGamemasterdata({
          fdate: formatDatetime_YMD(new Date(), 0),
          tdate: formatDatetime_YMD(new Date(), 1),
        });
        setGname("");
        setDrawid("");
        drawresultmasterDispach({
          type: "drawmasterDetails",
          data: {},
        });
      } else if (res.code == 100) {
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      } else {
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      }
    } catch (e) {
      setAlertdata({ code: 100, msg: "Frontend failed please try again" });
      setAlertopen(true);
    }
  };

  const getMETotPrediction = async ()=>{
    let data={
      pfdate: formatDatetime_YMD(new Date(), 0),
      ptdate: formatDatetime_YMD(new Date(), 1),
      drawid:drawid,
    } 
    try {
      let getResData = await SearchAllPlaydata(data);
      if (getResData.code === 200) {
        playdataDispach({type:"drawmasterDetails",data:getResData?.data})
        let open=`${drawresultmasterstate?.m5}`;
        let close=`${drawresultmasterstate?.m8}`;
        let jodi=`${drawresultmasterstate?.m5}${drawresultmasterstate?.m8}`;
        let openPan = `${drawresultmasterstate?.m1}${drawresultmasterstate?.m2}${drawresultmasterstate?.m3}`;
        let closePan = `${drawresultmasterstate?.m10}${drawresultmasterstate?.m11}${drawresultmasterstate?.m12}`;
        let gunule=`${openPan}X${close}`;
        let ulta_gunule=`${open}X${closePan}`;
        let jackpot=`${openPan}X${closePan}`;
        
          const totOpen = getResData?.data.reduce((sum, item) => {
            return item.npoints.toString()===open.toString() ? sum + parseInt(item.points)*parseInt(item.price) : sum;
          }, 0);
          const totClose = getResData?.data.reduce((sum, item) => {
            return item.npoints.toString()===close.toString() ? sum + parseInt(item.points)*parseInt(item.price) : sum;
          }, 0);
          const totOpenPan = getResData?.data.reduce((sum, item) => {
            return item.npoints.toString()===openPan.toString() ? sum + parseInt(item.points)*parseInt(item.price) : sum;
          }, 0);         
          const totClosePan = getResData?.data.reduce((sum, item) => {
            return item.npoints.toString()===closePan.toString() ? sum + parseInt(item.points)*parseInt(item.price) : sum;
          }, 0);
          const totJodi = getResData?.data.reduce((sum, item) => {
            return item.npoints.toString()===jodi.toString() ? sum + parseInt(item.points)*parseInt(item.price) : sum;
          }, 0);
          const totGunule = getResData?.data.reduce((sum, item) => {
            return item.npoints.toString()===gunule.toString() ? sum + parseInt(item.points)*parseInt(item.price) : sum;
          }, 0);
          const totUltaGunule = getResData?.data.reduce((sum, item) => {
            return item.npoints.toString()===ulta_gunule.toString() ? sum + parseInt(item.points)*parseInt(item.price) : sum;
          }, 0);
          const totJackpot = getResData?.data.reduce((sum, item) => {
            return item.npoints.toString()===jackpot.toString() ? sum + parseInt(item.points)*parseInt(item.price) : sum;
          }, 0);
          setMetotprd({
            m:Number(totOpen)+Number(totOpenPan),
            e:Number(totClose)+Number(totClosePan)+Number(totJodi)+Number(totGunule)+Number(totUltaGunule)+Number(totJackpot),
            t:Number(totOpen)+Number(totClose)+Number(totOpenPan)+Number(totClosePan)+Number(totJodi)+Number(totGunule)+Number(totUltaGunule)+Number(totJackpot),
          })
          
                       
        // setMetotprd] = React.useState({m:0,e:0,t:0});
      }
    } catch (error) {
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  }
  const confirmDilog=(btnname)=>{
    if(btnname=="save"){
      setCmdinfo({msg:"Do you want to save this record?",heading:"Confirm",nolbl:"No",yeslbl:"Yes"});
      setValue(true);   
      setBtnname(btnname)
    }else if(btnname=="saveres"){
      setCmdinfo({msg:"Do you want to save this record?",heading:"Confirm",nolbl:"No",yeslbl:"Yes"});
      setValue(true);   
      setBtnname(btnname)
    }
    
  }
  const handleConfirmChange=(val)=>{
    if(val===true && btnname==="save"){        
        DrawmasterSave();
      }else if(val===true && btnname==="saveres"){
        DrawResultmasterSave();
    }
      setValue(false);
  }

  const handleRadioButtons=(e)=>{        
    let curTime =getHourTime(new Date(),24);
    let opTime =getHourTime(drawData?.cutoptime,24);
    let clTime =getHourTime(drawData?.cutcltime,24);
      if(e?.target?.name==="open"){
        setRadioOC({o:true,c:false});
        if(curTime>opTime){          
          setMe({m:false,e:true})              
        } else{
          setMe({m:true,e:true})
        }
      }else if(e?.target?.name==="close") {
        setRadioOC({c:true,o:false});
        if(curTime>clTime){
          setMe({m:true,e:false})
        }else{
          setMe({m:true,e:true})
        }
      }      

    
 

      
  }
  return {
    alertdata,
    alertopen,
    setAlertopen,
    handledrawmaster,
    drawmasterstate,
    drawsearchState,
    DrawmasterSave,
    DrawmasterDelete,
    EditDrawmaster,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    handleChangePage,
    handleChangeRowsPerPage,
    formatTime,
    gamesearchState,
    page1,
    rowsPerPage1,
    rowsPerPageOptions1,
    handleChangePage1,
    handleChangeRowsPerPage1,
    alldate,
    handleAlldate,
    handledrawresultmaster,
    drawresultmasterstate,
    DrawResultmasterSave,
    handleGameDropdown,
    gname,
    get12HourTime,
    getallgameState,
    handleGameDataDropdown,
    boxdata,
    fdate,
    setFdate,
    call_getPlaySumPoints,
    drawtableState,
    calculateDigitSum,drawtablesummaryState,meState,getMETotPrediction,metotpredState,drawid,playdataState,
    value, setValue,cmdinfo,confirmDilog,handleConfirmChange,radioOC,handleRadioButtons,gmid
  };
}

export default useDrawmaster;
