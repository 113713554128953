import { Box, Grid, Modal, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import style from "./FundRequest.module.css";
import AddIcon from "@mui/icons-material/Add";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import MobileMenu from "../MobileMenu/MobileMenu";
import useFundreqemaster from "../../API/Hooks/useFundreqmaster";
import CloseIcon from "@mui/icons-material/Close";
import { handleDecimalInputChange } from "../../MIS/Global";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteIcon from "@mui/icons-material/Delete";

const FundRequest = () => {
  const fundrequest = { ...useFundreqemaster() };

  return (
    <>
      <div className={style.fundreqcontainer}>
        <AlertMessageComp
          type={fundrequest?.alertdata.type}
          msg={fundrequest?.alertdata.msg}
          vertical={fundrequest?.alertdata.vertical}
          horizontal={fundrequest?.alertdata.horizontal}
          setOpen={fundrequest?.setAlertopen}
          open={fundrequest?.alertopen}
        />
        <div className={style.fundreq1}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              <div className={style.fundhead}>Fund Request</div>
            </Grid>
          </Grid>
        </div>
        <div className={style.fundreq2}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <div className={style.avlfundcon}>
                <div className={style.amounthead}>
                  Available Margin(Cash + Profit + Loss)
                </div>
                <div className={style.amount}>
                  {fundrequest?.fundbalanceState[0]?.balance !== undefined &&
                  !isNaN(fundrequest?.fundbalanceState[0]?.balance)
                    ? parseFloat(
                        fundrequest?.fundbalanceState[0]?.balance
                      ).toFixed(2)
                    : "0.00"}
                </div>
              </div>
            </Grid>
            <Grid item xs={6} md={4}>
              <div
                className={style.addbtncon}
                onClick={() => {
                  fundrequest?.handlemodalOpen("Add Fund");
                }}
              >
                <button type="button" className={style.addbtn}>
                  <AddIcon /> Add Fund
                </button>
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <div
                className={style.withdrawbtncon}
                onClick={() => {
                  fundrequest?.handlemodalOpen("Withdrawal");
                }}
              >
                <button type="button" className={style.addbtn}>
                  <RotateLeftIcon /> Withdraw
                </button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={style.fundreq5}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} className={style.viewallcon}>
              <div className={style.usercounttxt}>Recent Transaction</div>
              <div
                className={style.viewalltxt}
                onClick={fundrequest?.navigateFreqList}
              >
                View All
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={style.fundreq3}>
          <Grid container spacing={1} className={style.listscroll}>
            {fundrequest?.fundsearchState?.map((element, index) => {
              let bgcolor = "#F1F0E8";
              let stscolor = "#6699CC";
              if (index % 2 == 0) {
                bgcolor = "#fff";
              }
              if (element?.isstatus == 0) {
                stscolor = "#358ab7";
              } else if (element?.isstatus == 1) {
                stscolor = "#009933 ";
              } else {
                stscolor = "#FF0000";
              }

              return (
                <Grid item xs={12} md={12}>
                  <Paper
                    elevation={3}
                    className={style.fundpaper}
                    sx={{ backgroundColor: bgcolor }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={2} md={3}>
                        <div
                          className={style.addfundicon}
                          style={{
                            backgroundColor: `${
                              element?.isdrcr == 0 ? "#009933" : "#358ab7"
                            }`,
                          }}
                        >
                          {element?.isdrcr == 0 ? (
                            <AddIcon sx={{ color: "white" }} />
                          ) : (
                            <RestartAltIcon sx={{ color: "white" }} />
                          )}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={7}
                        md={3}
                        className={style.addreqcontainer}
                      >
                        <div className={style.addreq}>
                          {element?.isdrcr == 0
                            ? "Add Fund Request"
                            : "Withdrawal Request"}
                        </div>
                        <div className={style.addreqdate}>
                          {fundrequest?.formatDate(element?.edate)}
                        </div>
                      </Grid>
                      <Grid item xs={3} md={3} className={style.amountcon}>
                        <div
                          className={style.deliconcon}
                          onClick={() => {
                            fundrequest?.deleteFundRequest(element);
                          }}
                        >
                          {element?.isstatus == 0 ? (
                            <DeleteIcon
                              sx={{ fontSize: "20px", color: "#FF2400" }}
                            />
                          ) : null}
                        </div>
                        <div className={style.usercounttxt}>
                          {element?.points}
                        </div>
                        <div
                          className={style.pendingcon}
                          style={{ backgroundColor: stscolor }}
                        >
                          {element?.isstatus == 0
                            ? "Pending"
                            : element?.isstatus == 1
                            ? "Complete"
                            : element?.isstatus == 2
                            ? "Cancel"
                            : ""}
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </div>
        <div className={style.fundreq4}>
          <MobileMenu />
        </div>
      </div>
      <Modal
        open={fundrequest?.openmodal}
        onClose={fundrequest?.handlemodalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // heading={fundrequest?.modalHeading}
      >
        <Box className={style.modaladd}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {fundrequest?.modalHeading}
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                cursor: "pointer",
              }}
            >
              <CloseIcon
                onClick={() => {
                  fundrequest?.handlemodalClose();
                }}
              />
            </div>
          </Typography>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={6}>
              <TextField
                type="tel"
                id="phone"
                inputMode="numeric"
                label="Point"
                fullWidth
                size="small"
                name="points"
                onChange={fundrequest?.handlefundreq}
                InputProps={{
                  classes: {
                    input: style.textinp_left,
                  },
                  style: {
                    padding: "1px",
                  },
                  inputProps: {
                    onInput: handleDecimalInputChange,
                    maxLength: 10,
                  },
                }}
                InputLabelProps={{
                  className: style.textinplbl,
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                // mt: 2,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <div
                className={style.submitbtncon}
                onClick={() => {
                  fundrequest?.Savefund();
                }}
              >
                <button type="button" className={style.submitbtn}>
                  Submit & Request
                </button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default FundRequest;
