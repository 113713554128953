import React from "react";
import { Grid, Paper, TextField } from "@mui/material";
import style from "./AdminDraw.module.css";
import { useState } from "react";
import image from "../../Assets/gray.png";
import SearchIcon from "@mui/icons-material/Search";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { makeStyles } from "@material-ui/core";
import dayjs from "dayjs";
import DrawMasterTable from "../../Tables/DrawMasterTable";
import DrawGameMasterTable from "../../Tables/DrawGameMasterTable";
import { DatePicker } from "@mui/x-date-pickers";
import useDrawmaster from "../../API/Hooks/useDrawmaster";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import GlobalTextsuggest from "../../Textsuggestfield/GlobalTextsuggest";
import FlexibleBox from "../GlobalComponent/FlexibleBox";
import DialogBox from "../GlobalComponent/DialogBox";

const useStyles = makeStyles((theme) => ({
  smallDateTimePicker: {
    "& .MuiInputBase-input": {
      fontSize: "14px",
      fontWeight: "600 !important",
      width: "100%",
      height: "100%",
      padding: "10px",
      margin: 0,
    },
    "& .MuiInputLabel-root": {
      fontSize: "16px",
      fontWeight: "600 !important", // Adjust font size as needed
      display: "flex",
      alignItems: "center",
    },
  },
}));

const AdminDrawMaster = () => {
  const classes = useStyles();
  const [selectedImage, setSelectedImage] = useState(image);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setSelectedImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = () => {
    const fileInput = document.getElementById("fileInput");
    fileInput.click();
  };

  const currentDate = new Date(); // Get the current date

  const formattedDate = dayjs(currentDate).format("DD/MM/YY");
  const formattedTime = dayjs(currentDate).format("h:mm A");
  const formattedDateTime = `${formattedDate} ${formattedTime}`;

  const drawmasterdata = {...useDrawmaster() };

  return (
    <>
     <AlertMessageComp
        type={drawmasterdata?.alertdata.type}
        msg={drawmasterdata?.alertdata.msg}
        vertical={drawmasterdata?.alertdata.vertical}
        horizontal={drawmasterdata?.alertdata.horizontal}
        setOpen={drawmasterdata?.setAlertopen}
        open={drawmasterdata?.alertopen}
      />
       <DialogBox data={{setValue:drawmasterdata?.handleConfirmChange,value:drawmasterdata?.value,
        heading:drawmasterdata?.cmdinfo?.heading,msg:drawmasterdata?.cmdinfo?.msg,nolbl:drawmasterdata?.cmdinfo?.nolbl,
        yeslbl:drawmasterdata?.cmdinfo?.yeslbl
       }}/>
      <div className={style.drawmain}>
        <div className={style.drawpart1}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className={style.drawheading}>Draw/Play Master</div>
              <div className={style.savebtncon}
              onClick={()=>{
                drawmasterdata?.confirmDilog("save");
              }}
              >
                <button type="button" className={style.savebtn}>
                  Save
                </button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={style.drawpart2}>
          <Grid
            container
            spacing={2}
            sx={{
              height: "100%",
              overflow: "hidden",
              padding: "10px",
              margin: "0 -10px",
            }}
          >
            <Grid item xs={12} md={4} className={style.gridpapercon}>
              {/* <DrawGameMasterTable data={drawmasterdata}/> */}    
              <GlobalTextsuggest
               data={{
                shrink:true,
                options: drawmasterdata?.getallgameState,
                labelNm: drawmasterdata?.gname,
                label: "Game Name",
                handleChange: drawmasterdata?.handleGameDataDropdown,
              }}              
            />       
            <FlexibleBox data={{boxdata:drawmasterdata?.boxdata ,boxname:"Today's Created Play"}} />                
            </Grid>
             
            <Grid item md={0.1}></Grid>
            <Grid item xs={12} md={7.8} className={style.gridpapercon}>
              <Grid container spacing={1}>
                <Grid item md={3.1}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className={classes.smallDateTimePicker}
                      label="Date"
                      renderInput={(props) => (
                        <TextField {...props} variant="standard" />
                      )}
                      name="drawdate"
                      value={dayjs(`${drawmasterdata?.alldate?.drawdate}`)}
                      openTo="day"
                      format="DD/MM/YYYY"
                      onChange={(e)=>{drawmasterdata?.handleAlldate("drawdate",e)}}
                      readOnly
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={2.8}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      className={classes.smallDateTimePicker}
                      label="Start Time"
                      name="starttime"
                      value={dayjs(`${drawmasterdata?.alldate?.starttime}`)}
                      ampm={true} 
                      openTo="hours" 
                      views={["hours", "minutes"]} 
                      format="hh:mm A"
                      renderInput={(props) => (
                        <TextField {...props} variant="standard" />
                      )}
                      // format="DD/MM/YYYY hh:mm aa"
                      renderTime={(props) => (
                        <TextField
                          {...props}
                          variant="outlined"
                          helperText={null}
                        />
                      )} 
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                      }}
                      onChange={(e)=>{drawmasterdata?.handleAlldate("starttime",e)}}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={3.1} style={{display:"none"}}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      className={classes.smallDateTimePicker}
                      label="Opening Time"
                      name="optime"
                      value={dayjs(`${drawmasterdata?.alldate?.optime}`)}
                      ampm={true} 
                      openTo="hours"
                      views={["hours", "minutes"]} 
                      format="hh:mm A"
                      renderInput={(props) => (
                        <TextField {...props} variant="standard" />
                      )}
                      // format="DD/MM/YYYY hh:mm aa"
                      renderTime={(props) => (
                        <TextField
                          {...props}
                          variant="outlined"
                          helperText={null}
                        />
                      )} 
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                      }}
                      onChange={(e)=>{drawmasterdata?.handleAlldate("optime",e)}}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={2.8}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      className={classes.smallDateTimePicker}
                      label="Open CutOff Time"
                      name="cutoptime"
                      value={dayjs(`${drawmasterdata?.alldate?.cutoptime}`)}
                      ampm={true} 
                      openTo="hours" 
                      views={["hours", "minutes"]} 
                      format="hh:mm A"
                      renderInput={(props) => (
                        <TextField {...props} variant="standard" />
                      )}
                      // format="DD/MM/YYYY hh:mm aa"
                      renderTime={(props) => (
                        <TextField
                          {...props}
                          variant="outlined"
                          helperText={null}
                        />
                      )}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                      }}
                      onChange={(e)=>{drawmasterdata?.handleAlldate("cutoptime",e)}}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={3.1} style={{display:"none"}}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      className={classes.smallDateTimePicker}
                      label="Closing Time"
                      name='cltime'
                      value={dayjs(`${drawmasterdata?.alldate?.cltime}`)}
                      ampm={true} 
                      openTo="hours"
                      views={["hours", "minutes"]}
                      format="hh:mm A"
                      renderInput={(props) => (
                        <TextField {...props} variant="standard" />
                      )}
                      // format="DD/MM/YYYY hh:mm aa"
                      renderTime={(props) => (
                        <TextField
                          {...props}
                          variant="outlined"
                          helperText={null}
                        />
                      )} 
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                      }}
                      onChange={(e)=>{drawmasterdata?.handleAlldate("cltime",e)}}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={2.8}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      className={classes.smallDateTimePicker}
                      label="Close CutOff Time"
                      name="cutcltime"
                      value={dayjs(`${drawmasterdata?.alldate?.cutcltime}`)}
                      ampm={true} 
                      openTo="hours" 
                      views={["hours", "minutes"]} 
                      format="hh:mm A"
                      renderInput={(props) => (
                        <TextField {...props} variant="standard" />
                      )}
                      // format="DD/MM/YYYY hh:mm aa"
                      renderTime={(props) => (
                        <TextField
                          {...props}
                          variant="outlined"
                          helperText={null}
                        />
                      )} 
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                      }}
                      onChange={(e)=>{drawmasterdata?.handleAlldate("cutcltime",e)}}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={3.1}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      className={classes.smallDateTimePicker}
                      label="Open Result Time"
                      name="cutrestime"
                      value={dayjs(`${drawmasterdata?.alldate?.cutrestime}`)}
                      ampm={true} 
                      openTo="hours" 
                      views={["hours", "minutes"]} 
                      format="hh:mm A"
                      renderInput={(props) => (
                        <TextField {...props} variant="standard" />
                      )}
                      // format="DD/MM/YYYY hh:mm aa"
                      renderTime={(props) => (
                        <TextField
                          {...props}
                          variant="outlined"
                          helperText={null}
                        />
                      )} 
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                      }}
                      onChange={(e)=>{drawmasterdata?.handleAlldate("cutrestime",e)}}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={2.8}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      className={classes.smallDateTimePicker}
                      label="Close Result Time"
                      name="cutrescltime"
                      value={dayjs(`${drawmasterdata?.alldate?.cutrescltime}`)}
                      ampm={true} 
                      openTo="hours" 
                      views={["hours", "minutes"]} 
                      format="hh:mm A"
                      renderInput={(props) => (
                        <TextField {...props} variant="standard" />
                      )}
                      // format="DD/MM/YYYY hh:mm aa"
                      renderTime={(props) => (
                        <TextField
                          {...props}
                          variant="outlined"
                          helperText={null}
                        />
                      )} 
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                      }}
                      onChange={(e)=>{drawmasterdata?.handleAlldate("cutrescltime",e)}}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={12}>
                     <DrawMasterTable data={{tbldata:drawmasterdata?.drawsearchState,get12HourTime:drawmasterdata?.get12HourTime}} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default AdminDrawMaster;
