import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import style from "../Components/AdminGameMaster/AdminGame.module.css";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  autocompleteOption: {
    fontSize: "13px",
    fontWeight: "600px !important",
    padding: "5px 10px",
    cursor: "pointer",
    "&.Mui-focused": {
      backgroundColor: "#d9d9d9",
    },
  },
}));

export default function UserTypeTextSuggest(props) {
  const classes = useStyles();
  return (
    <div>
      <Autocomplete
        fullWidth
        disablePortal
        id="combo-box-demo"
        options={Users}
        size="small"
        value={props?.data?.selectedStatus}
        onChange={(e, newValue) => {
          props?.data?.setSelectedStatus(newValue.label);
          // alert(JSON.stringify(newValue))
        }}
        renderInput={(params) => (
          <TextField
            sx={{ backgroundColor: "white" }}
            {...params}
            label="Users Type"
            InputProps={{
              ...params.InputProps,
              classes: {
                ...params.InputProps.classes,
                input: style.textinp_left, // Apply input style
              },
            }}
            InputLabelProps={{
              ...params.InputLabelProps,
              className: style.textinplbl, // Apply the utility class
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} className={classes.autocompleteOption}>
            {option?.label}
          </li>
        )}
      />
    </div>
  );
}

const Users = [
  { label: "Active" },
  { label: "Block" },
  { label: "New User" },
  { label: "All" },
];
