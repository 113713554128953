import React from "react";
import { useEffect, useReducer, useState } from "react";
import GamemasterReducer from "../Reducer/GamemasterReducer";
import {
  DeleteGamemaster,
  GetAllGameData,
  GetGamemasterData,
  SaveGamemaster,
} from "../Model/GamemasterModel";
import { tostmsg } from "../../MIS/Global";
import TypemasterReducer from "../Reducer/TypemasterReducer";
import { GetGtypemaster } from "../Model/gtypemasterModel";

function useGameMaster() {
  const [alertdata, setAlertdata] = useState({});
  const [alertopen, setAlertopen] = useState(false);

  const [gamestate, gameDispach] = useReducer(GamemasterReducer, []);
  const [typesearchState, typesearchDispach] = useReducer(TypemasterReducer,[]);
  const [gamesearchState, gamesearchDispach] = useReducer(GamemasterReducer,[]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const rowsPerPageOptions = [
    10,
    25,
    50,
    100,
    { value: gamesearchState?.length, label: "All" },
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(+event.target.value, 10));
    setPage(0);
  };

  const handlegame = (e, value) => {
    // alert(e)
    if (e && e.target) {
      gameDispach({
        type: "Change",
        payload: { name: e.target.name, value: e.target.value },
      });
    }
  };
  function formatTime(date) {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      new Date(date)
    );
    const formattedDateWithoutComma = formattedDate.replace(",", "");
    return formattedDateWithoutComma.replace(/(\s[ap]m)/i, (match) => match.toUpperCase());
  }

 function formatDate(date) {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
    new Date(date)
  );
  const formattedDateWithoutComma = formattedDate.replace(",", "");
  return formattedDateWithoutComma.replace(/(\s[ap]m)/i, (match) => match.toUpperCase());
  }
  const GetGtypemasterdata = async () => {
    try {
      let getType = await GetGtypemaster();
      
      if (getType.code === 200) {
        typesearchDispach({
          type: "gtypeinfoDetails",
          data: getType.data,
        });
      }
    } catch (error) {
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };
  const GameSave = async () => {
    const jsonData = [];
    let gmid = 0;
    if (gamestate?.gmid) {
      gmid = gamestate?.gmid;
    }

    typesearchState?.forEach((element) => {
      jsonData.push({
        gpid: 0,
        gmid: gmid,
        gtmid: element?.gtmid,
        wnrprice: element?.winprice,
        isstatus: 0,
        loginid: localStorage.getItem("aloginid"),
        cmpid: localStorage.getItem("cmpid"),
      });
    });
    try {
      let data = {
        ...gamestate,
        loginid: localStorage.getItem("aloginid"),
        cmpid: localStorage.getItem("cmpid"),
        gmprice: jsonData,
      };
      let res = await SaveGamemaster(data);
      let rettostmsg = tostmsg(res);
      if (res.code == 200) {
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        gameDispach({
          type: "gamemasterDetails",
          data: {},
        });
        fetchGamemasterdata();
        GetGtypemasterdata();
      } else {
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      }
    } catch (e) {
      setAlertdata({ code: 100, msg: "Frontend failed please try again" });
      setAlertopen(true);
    }
  };
  const DeleteGame = async (data) => {
    try {
      const gamerowdelete = await DeleteGamemaster(data);
      let rettostmsg = tostmsg(gamerowdelete);
      if (gamerowdelete.code == 200) {
        // alert(JSON.stringify(gamerowdelete.code));
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      }
      fetchGamemasterdata();
    } catch (e) {
      setAlertdata({ code: 100, msg: "Frontend failed please try again" });
      setAlertopen(true);
    }
  };

  const fetchGamemasterdata = async () => {
    try {
      let getGamedata = await GetAllGameData();
      if (getGamedata.code === 200) {
        // alert(JSON.stringify(getGamedata));
        gamesearchDispach({
          type: "gamemasterDetails",
          data: getGamedata?.data,
        });
      }
    } catch (error) {
      // Handle any errors here
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };

  useEffect(() => {
    fetchGamemasterdata();
    GetGtypemasterdata();
  }, []);

  const GetGMasterPrice = async (data)=>{
    try {
      let getGamedata = await GetGamemasterData();
      if (getGamedata.code === 200) {
        // alert(JSON.stringify(getGamedata));
        gamesearchDispach({
          type: "gamemasterDetails",
          data: getGamedata?.data,
        });
      }
    } catch (error) {
      // Handle any errors here
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  }
  const EditGame = async (element) => {
    let dt = {
      ...element,
    };
    gameDispach({
      type: "gamemasterDetails",
      data: dt,
    });
  };

  return {
    alertdata,
    alertopen,
    setAlertopen,
    formatTime,
    formatDate,
    handlegame,
    gamestate,
    gamesearchState,
    GameSave,
    DeleteGame,
    EditGame,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    handleChangePage,
    handleChangeRowsPerPage,
    typesearchState,
  };
}

export default useGameMaster;
