import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css";
import { Grid, TableContainer, TableHead, TextField } from "@mui/material";
 

import style from "../../src/Components/UserAcLedger/UserAcLedger.module.css";
import useGameMaster from "../API/Hooks/useGameMaster";

const UserAcLedgerTable = (props) => {
    let arr=[1,2,3,4,5,6,7,8,9,10,11,12]
    const [clbal, setclbal] = useState(0);
    const gameMaster={...useGameMaster()}
  return (
    <>
      <div>
        <Paper className="">
          <TableContainer sx={{ maxHeight: "64vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableHead>
                <TableRow
                  className="tblerowdata"
                 >
                  <TableCell style={{width:"60%"}}></TableCell>
                  <TableCell style={{width:"20%"}}>Debit</TableCell>
                  <TableCell style={{width:"20%"}}>Credit</TableCell>
                </TableRow>
             
                <TableRow
                  className="tblerowdata"
                 >
                  <TableCell style={{width:"60%",fontWeight:"bold"}}>Closing</TableCell>
                  <TableCell style={{width:"20%",fontWeight:"bold"}} colSpan={2} align="center">{Number(780).toFixed(2)}</TableCell>
                 </TableRow>
              </TableHead>
              <TableBody className="bodycon">
                {arr?.map((element, index) => {
                  let bgcolor = "#F1F0E8";
                  let stscolor = "#6699CC";
                  let iscr=0;
                  let isdr=0;
                  if (index % 2 == 0) {
                    bgcolor = "#fff";
                    isdr=Math.floor(Math.random() * (1000 - 300 + 1)) + 300;
                  }else{
                    iscr=Math.floor(Math.random() * (1000 - 300 + 1)) + 300;
                  }
                   
                  return (
                    <TableRow
                      className="tblerowdata"
                      style={{ backgroundColor: bgcolor }}
                    >
                      <TableCell style={{width:"60%"}} >
                      <div className={style?.particularcon}>
                        <div>Entry {element}</div>
                        <div>{gameMaster?.formatDate(new Date())}</div>
                      </div>                        
                      </TableCell>
                      <TableCell style={{width:"20%"}} align="right" >
                          <span className={style.isdrcr}> {Number(isdr).toFixed(2)}</span>                       
                      </TableCell>
                      <TableCell style={{width:"20%"}} align="right">
                          <span className={style.isdrcr}> {Number(iscr).toFixed(2)}</span>      
                      </TableCell>
                    </TableRow>
                  );
                  
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
};
export default UserAcLedgerTable;
