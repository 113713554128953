import React from 'react'
import TradeMaster from '../../Components/TradeMaster/TradeMaster'
import Appbar from '../../Components/Appbar/Appbar'

const TradeMasterScreen = () => {
  return (
    <div>
        <Appbar />
        <TradeMaster />
    </div>
  )
}

export default TradeMasterScreen