import { Button, Grid, TextField } from "@mui/material";
import React, { useEffect } from "react";
import style from "./Holiday.module.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { makeStyles, styled } from "@material-ui/core";
import { formatDatetime_DMY, handleAlphanumericInputwithSpaceChange } from "../../MIS/Global";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector, useDispatch } from "react-redux";
import {
  actionConfirm,
  checkDate,
  delteHoliday,
  getHoliday,
  handleHolidayChange,
  saveHoliday,
} from "../../API/Reducer/holidayReducer";
import ReduxConfirmationDialog from "../ConfirmationDialog/ReduxConfirmationDialog";
import dayjs from "dayjs";
import AlertComp from "../AlertMessageCom/AlertComp";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  calendarContainer: {
    display: "flex",
    gap: "20px",
    color: "white",
    "& .MuiTypography-root, & .MuiIconButton-root, & .MuiButtonBase-root, & .MuiTypography-caption":
      {
        color: "white",
        fontSize: "16px",
      },
  },
}));

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& label": {
    color: "white",
  },
  "& fontColor": {
    color: "white",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});

const HolidayMaster = () => {
  const classes = useStyles();
  const [id, setId] = useState("")
  const dispatch = useDispatch();
  const { holiday, holidayList,delholiday } = useSelector((state) => state.holiday);
  const { resans,type } = useSelector((state) => state.confirm);
 
  useEffect(() => {
    if (resans === true && type=="save") {
      dispatch(saveHoliday(holiday));
    }else if(resans === true && type=="del"){
      dispatch(delteHoliday({hldid:id,monthname:holiday?.monthname}));      
    }
  }, [resans]);
  useEffect(() => {
    dispatch(handleHolidayChange(new Date(), "mindate"));
    dispatch(handleHolidayChange(new Date(), "monthname"));
  }, []);
  useEffect(() => {
    dispatch(getHoliday({monthname:holiday?.monthname}));
  }, [holiday?.monthname]);
  return (
    <div className={style.holidayconn}>
      <ReduxConfirmationDialog />
      <AlertComp />
      <Grid container spacing={2}>
      <Grid item xs={12} md={9}>
          <Grid container spacing={2} className={style.subconainer1}>
            {holidayList?.map((element, index) => {
              let bgcolor =
                index % 2 === 0 ? style.boxsubconn1 : style.boxsubconn;
              let sidebar =
                index % 2 === 0 ? style.sideborder1 : style.sideborder;
              return (
                <Grid item xs={12} md={8}>
                  <div className={bgcolor}>
                    <div className={sidebar}>
                      <div className={style.containtconn}>
                        <div className={style.datecon}>
                          <span>
                            <CalendarMonthIcon />
                          </span>
                          <span>{formatDatetime_DMY(element?.hdate,0)}</span>
                        </div>
                        <div className={style.infotxt}>
                          {element?.remark}
                        </div>
                        <div style={{display:`${checkDate(element)}`}} className={style.deletecon} 
                          onClick={(e)=>{
                            setId(element?.hldid);
                            dispatch(actionConfirm("del"));}}>
                          <DeleteIcon />                          
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} className={style.subconainer2}>
          <Grid container spacing={2} >
            <Grid item xs={12} md={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  className={classes.calendarContainer}
                  name="hdate"
                  onMonthChange={(e) => {
                    dispatch(handleHolidayChange(e, "monthname"));
                  }}
                  onChange={(e) => {
                    dispatch(handleHolidayChange(e, "hdate"));
                  }}
                  // minDate={dayjs(`${holiday?.mindate}`)}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={12}>
              <span style={{ color: "#fff" }}>
                {holiday?.selectdate
                  ? `You selected ${holiday?.selectdate}`
                  : ""}
              </span>
            </Grid>
            <Grid item xs={12} md={11}>
              <CssTextField
                name="remark"
                label="Remark/Narration"
                variant="outlined"
                fullWidth
                multiline
                size="small"
                onChange={(e) => {
                  dispatch(handleHolidayChange(e));
                }}
                InputProps={{
                  style: { color: "white" },
                  inputProps: {
                    onInput: handleAlphanumericInputwithSpaceChange,
                    maxLength: 250,
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={11}
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <Button
                type="button"
                sx={{ backgroundColor: "#358ab7", color: "#fff" }}
                variant="contained"
                onClick={() => {
                  dispatch(actionConfirm("save"));
                }}
              >
                Holiday Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default HolidayMaster;
