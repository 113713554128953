import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import AdminLedgerReport from '../../Components/AdminLedgerReport/AdminLedgerReport'

const AdminLedgerReportScreen = () => {
  return (
    <div>
        <Appbar />
        <AdminLedgerReport />
    </div>
  )
}

export default AdminLedgerReportScreen