import React, { useContext, useEffect, useReducer, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css";
import { TableContainer } from "@mui/material";
import { useSelector } from "react-redux";
 
const ResultTabsTable = (props) => { 
  const { predictionChartList,predictionChartSummaryList } = useSelector((state) => state.predictionchart);
  let CalcNum = 0;
  const getSum=(id)=>{
    if (Array.isArray(props?.data?.tbldatasum)) {
      let filteredData = props?.data?.tbldatasum?.filter( (item) =>item.gtmid.toString() === id.toString());
      return  filteredData.length <= 0?Number(0).toFixed(2):Number(filteredData?.[0]?.sumPoints).toFixed(2);
    }else{
       return "0.00";
    }

  
  }
   
  return (
    <>
      <div>
        <Paper className="contner1">
          <TableContainer sx={{ maxHeight: "55vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              stickyFooter
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableHead>
                <TableRow>
                  {Array.from({ length: props?.data?.tbldata?.[0]?.hclm }).map(
                    (element, index) => {
                      let txtAlign = "center";
                      if (props?.data?.name === "jackpots") {
                        CalcNum = index === 0 ? "Number" : "Points";
                        txtAlign = index === 0 ? "center" : "right";
                      } else {
                        if (element === "") {
                          CalcNum = "";
                        } else if (element !== "") {
                          CalcNum = Number(CalcNum) + Number(1);
                          CalcNum = CalcNum >= Number(10) ? 0 : CalcNum;
                        } else {
                          CalcNum = element;
                        }
                      }
                      return (
                        <>
                          {CalcNum === "" ? (
                            <TableCell
                              style={{
                                textAlign: txtAlign,
                                position: "sticky",
                                left: 0,
                                zIndex: 1,
                                backgroundColor: "#fff",
                              }}
                            >
                              {""}
                            </TableCell>
                          ) : (
                            <TableCell style={{ textAlign: txtAlign,borderRight: '1px solid #e0e0e0' }}>
                              {CalcNum}
                            </TableCell>
                          )}
                        </>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>

              <TableBody className="bodycon">
                {Array.from({ length: props?.data?.tbldata?.[0]?.rrow }).map(
                  (element, index) => {
                    let bgcolor = "#F1F0E8";
                    if (index % 2 == 0) {
                      bgcolor = "#fff";
                    }

                    return (
                      <>
                        <TableRow
                          className="tblerowdata"
                          style={{ backgroundColor: bgcolor }}
                        >
                          {Array.from({
                            length: props?.data?.tbldata[0]?.rinrclm,
                          }).map((elrow, rowindex) => {
                            let firstColumn = "";
                            let npoint = "";
                            let point = "";
                            let calcClmIndex = 0;
                            let filteredData = [];
                            let textAlign = 0;
                            if (
                              props?.data?.name === "open" ||
                              props?.data?.name === "close"
                            ) {
                              calcClmIndex = rowindex + 1;
                              calcClmIndex =
                                calcClmIndex >= 10 ? 0 : calcClmIndex;
                              filteredData = props?.data?.tbldata.filter(
                                (item) =>
                                  item.id.toString() === calcClmIndex.toString()
                              );
                            } else if (                              
                              props?.data?.name === "jackpots"
                            ) {
                              filteredData = props?.data?.tbldata;
                            } else {
                              calcClmIndex = rowindex + 1;
                              calcClmIndex =
                              calcClmIndex >= 10 ? 0 : calcClmIndex;
                              filteredData = props?.data?.tbldata.filter(
                                (item) =>
                                  item.regpan.toString() ===
                                  calcClmIndex.toString()
                              );
                              if (filteredData.length>0) {
                                npoint = filteredData[index]?.npoints?.split("X");
                                npoint=npoint.length>1?`${npoint[0]} X ${npoint[1]}`:npoint[0];
                                point = filteredData[index]?.points;
                              }
                              
                            }

                            return (
                              <>
                                {props?.data?.name === "open" ||
                                props?.data?.name === "close" ? (
                                  <TableCell style={{ textAlign: "center" ,borderRight: '1px solid #e0e0e0'}}>
                                    {filteredData[0]?.points}
                                  </TableCell>
                                ) : props?.data?.name === "jackpots" ? (
                                  <TableCell
                                    style={{
                                      textAlign: `${
                                        rowindex === 0 ? "center" : "right"
                                      }`,
                                      borderRight: '1px solid #e0e0e0'
                                    }}
                                  >
                                    {rowindex === 0
                                      ? filteredData[index]?.npoints
                                      : Number(
                                          filteredData[index]?.points
                                        ).toFixed(2)}
                                  </TableCell>
                                ) : (
                                  <TableCell style={{ textAlign: "left",borderRight: '1px solid #e0e0e0' }}>                                     
                                      <span
                                        style={{
                                          // borderBottom: "1px solid #C0C0C0",
                                        }}
                                      >
                                        {npoint}
                                      </span>
                                      <span>{point==undefined || point===""?"":" = "}</span>
                                      <span>{point}</span>
                                   </TableCell>
                                )  }
                              </>
                            );
                          })}
                        </TableRow>                        
                      </>
                    );
                  }
                )}
                <TableRow >
                  {Array.from({ length: props?.data?.tbldata?.[0]?.fclm }).map(
                    (element, index) => {
                      let totPoints = "";
                      let txtAlign = "right";
                      if (
                        props?.data?.name === "open" ||
                        props?.data?.name === "close"
                      ) {
                        totPoints =
                          index >= 9 ? props?.data?.tbldata[0].totpoints : "";
                      } else if (                        
                        props?.data?.name === "jackpots"
                      ) {
                        totPoints =
                          index === 1 ? props?.data?.tbldata[0].totpoints : "";
                      } else  {
                        totPoints = props?.data?.tbldata.reduce(
                          (total, item) => {
                            if (item.regpan === (index >= 9 ? 0 : index + 1)) {
                              return total + parseInt(item.points);
                            } else {
                              return total;
                            }
                          },
                          0
                        );
                        totPoints = Number(totPoints).toFixed(2);
                        txtAlign = "center";
                      }
                      return (
                        <>
                          <TableCell
                            style={{ textAlign: txtAlign, fontWeight: "bold",borderRight: '1px solid #e0e0e0' }}
                          >
                            {totPoints}
                          </TableCell>
                        </>
                      );
                    }
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div className="drawresulttbl">
            <Table
              size="small"
              border
              stickyHeader
              aria-label="sticky table"
              stickyFooter
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableHead>
                <TableRow >
                 <TableCell style={{backgroundColor:"#fffacd",textAlign:"center",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>Open</TableCell>
                 <TableCell style={{backgroundColor:"#fffacd",textAlign:"center",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>Close</TableCell>
                 <TableCell style={{backgroundColor:"#fffacd",textAlign:"center",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>Jodi</TableCell>
                 <TableCell style={{backgroundColor:"#fffacd",textAlign:"center",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>Open Panna</TableCell>
                 <TableCell style={{backgroundColor:"#fffacd",textAlign:"center",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>Close Panna</TableCell>
                 <TableCell style={{backgroundColor:"#fffacd",textAlign:"center",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>Gunule</TableCell>
                 <TableCell style={{backgroundColor:"#fffacd",textAlign:"center",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>Ulta Gunule</TableCell>
                 <TableCell style={{backgroundColor:"#fffacd",textAlign:"center",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>Jackpot</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              <TableRow>
                 <TableCell style={{textAlign:"right",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>{getSum(1)}</TableCell>
                 <TableCell style={{textAlign:"right",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>{getSum(2)}</TableCell>
                 <TableCell style={{textAlign:"right",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>{getSum(3)}</TableCell>
                 <TableCell style={{textAlign:"right",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>{getSum(4)}</TableCell>
                 <TableCell style={{textAlign:"right",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>{getSum(5)}</TableCell>
                 <TableCell style={{textAlign:"right",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>{getSum(6)}</TableCell>
                 <TableCell style={{textAlign:"right",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>{getSum(8)}</TableCell>
                 <TableCell style={{textAlign:"right",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>{getSum(7)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>

          <div className="drawresulttbl">
            <Table
              size="small"
              border
              stickyHeader
              aria-label="sticky table"
              stickyFooter
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableHead>
                <TableRow >
                 <TableCell style={{backgroundColor:"#eedd82 ",textAlign:"center",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>Open</TableCell>
                 <TableCell style={{backgroundColor:"#eedd82 ",textAlign:"center",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>Close</TableCell>
                 <TableCell style={{backgroundColor:"#eedd82 ",textAlign:"center",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>Jodi</TableCell>
                 <TableCell style={{backgroundColor:"#eedd82 ",textAlign:"center",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>Open Panna</TableCell>
                 <TableCell style={{backgroundColor:"#eedd82 ",textAlign:"center",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>Close Panna</TableCell>
                 <TableCell style={{backgroundColor:"#eedd82 ",textAlign:"center",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>Gunule</TableCell>
                 <TableCell style={{backgroundColor:"#eedd82 ",textAlign:"center",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>Ulta Gunule</TableCell>
                 <TableCell style={{backgroundColor:"#eedd82 ",textAlign:"center",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>Jackpot</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              <TableRow>
                 <TableCell style={{textAlign:"right",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>{predictionChartSummaryList?.[0]?.o}</TableCell>
                 <TableCell style={{textAlign:"right",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>{predictionChartSummaryList?.[0]?.c}</TableCell>
                 <TableCell style={{textAlign:"right",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>{predictionChartSummaryList?.[0]?.j}</TableCell>
                 <TableCell style={{textAlign:"right",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>{predictionChartSummaryList?.[0]?.op}</TableCell>
                 <TableCell style={{textAlign:"right",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>{predictionChartSummaryList?.[0]?.cp}</TableCell>
                 <TableCell style={{textAlign:"right",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>{predictionChartSummaryList?.[0]?.g}</TableCell>
                 <TableCell style={{textAlign:"right",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>{predictionChartSummaryList?.[0]?.ug}</TableCell>
                 <TableCell style={{textAlign:"right",fontWeight:"bold",borderRight: '1px solid #e0e0e0'}}>{predictionChartSummaryList?.[0]?.jk}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    </>
  );
};
export default ResultTabsTable;
