import { Box, Button, Grid, InputAdornment, Modal, TextField } from "@mui/material";
import React from "react";
import style from "./SignIn.module.css";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import img from "../../Assets/logo2.png";
import { useNavigate } from "react-router-dom";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import CloseIcon from "@mui/icons-material/Close";
import {
  handleAlphanumericInputChange,
  handleNumInputChange,
} from "../../MIS/Global";
import useUserSigniupnmaster from "../../API/Hooks/useUserSigniupnmaster";
import Forgotpassword from "./Forgotpassword";

const SignInPage = () => {
  const {
    handleusersignin,
    usersigninState,
    alertdata,
    alertopen,
    setAlertopen,
    UserSignin,
  } = useUserSigniupnmaster();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
  };

  return (
    <>
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
        duration={alertdata?.duration}
      />

      <div className={style.pagecontainer}>
        <Grid container spacing={2} className={style.loginmain}>
          <Grid item xs={12} md={12} className={style.logocon}>
            <div className={style.regimagecon}>
              <img src={img} className={style.regimage} />
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={style.loginsty}>Login</div>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              type="tel"
              id="phone"
              inputMode="numeric"
              label="Mobile Number"
              fullWidth
              size="small"
              name="mob"
              value={usersigninState?.mob}
              onChange={handleusersignin}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIphoneIcon
                      sx={{ fontSize: "22px", marginRight: "5px" }}
                    />
                  </InputAdornment>
                ),
                classes: {
                  input: style.textinp_left,
                },
                style: {
                  padding: "2px",
                },
                inputProps: {
                  onInput: handleNumInputChange,
                  maxLength: 10,
                },
              }}
              InputLabelProps={{
                className: style.textinplbl,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-adornment-password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              label="Password"
              fullWidth
              size="small"
              name="paswd"
              value={usersigninState?.paswd}
              onChange={handleusersignin}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockPersonIcon
                      sx={{ fontSize: "22px", marginRight: "5px" }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                classes: {
                  input: style.textinp_left,
                },
                style: {
                  padding: "2px",
                },
                inputProps: {
                  onInput: handleAlphanumericInputChange,
                  maxLength: 20,
                },
              }}
              InputLabelProps={{
                className: style.textinplbl,
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <div
              className={style.forgot}
              onClick={() => {
                handleOpen();
              }}
            >
              <div>Forgot Password?</div>
            </div>
          </Grid>
          <Grid item xs={12} md={12} className={style.loginbtn_align}>
            <div
              className={style.loginbtncon}
              onClick={() => {
                UserSignin();
              }}
            >
              <button type="button" className={style.loginbtn}>
                Login
              </button>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={style.loginfootertxt}>
              Don't have account?{" "}
              <div
                className={style.footrtxt}
                onClick={() => {
                  navigate("/register");
                  // window.location.href="/register"
                }}
              >
                Register
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={styleModal}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              marginTop:"-14px",
              paddingBottom:"10px",
              paddingRight:"-3px"
             }}
             onClick={()=>{handleClose()}}
          >
           <Button type="button" variant="contained" color="secondary" size="small"><CloseIcon/></Button>
            
          </div>
          <Forgotpassword />
        </Box>
      </Modal>
    </>
  );
};

export default SignInPage;
