import React from 'react'
import Dashboard from '../../Components/Dashboard/Dashboard'
import Appbar from '../../Components/Appbar/Appbar'

const DashboardScreen = () => {
  return (
    <div>
        <Appbar />
        <Dashboard />
    </div>
  )
}

export default DashboardScreen