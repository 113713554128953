import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';

const DialogBox = (props) => {

  return (
    <>
        <Dialog open={props?.data?.value} >
        <DialogTitle>{props?.data?.heading}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{fontWeight:"400",color:"#000"}}>
            {props?.data?.msg} 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={()=>{props?.data?.setValue(false)}} color="secondary" variant='outlined'>
          {props?.data?.nolbl}
          </Button>
          <Button type="button" onClick={()=>{props?.data?.setValue(true)}} color="primary" variant='outlined'>
          {props?.data?.yeslbl}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DialogBox