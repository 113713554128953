import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import UserPlayHistoryMaster from '../../Components/UserPlayHistory/UserPlayHistoryMaster'

const UserTodayPlayHistoryScreen = () => {
  return (
    <div>
        <Appbar />
        {/* <UserTodayPlayHistory /> */}
        <UserPlayHistoryMaster />
    </div>
  )
}

export default UserTodayPlayHistoryScreen