import { Grid, Paper, TextField } from '@mui/material'
import React from 'react'
import style from "./PlayResult.module.css"
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { makeStyles } from '@material-ui/core';
import SearchIcon from "@mui/icons-material/Search";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import MobileMenu from '../MobileMenu/MobileMenu';

import usePlayhistoryResult from '../../API/Hooks/usePlayhistoryResult';
import PlayHistoryTable from '../../Tables/PlayHistoryTable';



const PlayHistoryResult = () => {
    const useStyles = makeStyles((theme) => ({
        smallDateTimePicker: {
          "& .MuiInputBase-input": {
            fontSize: "14px",
            fontWeight: "600 !important",
            width: "100%",
            height: "100%",
            padding: "10px",
            margin: 0,
          },
          "& .MuiInputLabel-root": {
            fontSize: "16px",
            fontWeight: "600 !important", // Adjust font size as needed
            display: "flex",
            alignItems: "center",
          },
        },
      }));
      const classes = useStyles();

      const [expanded, setExpanded] = React.useState(false);
      
      const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      };

const playhistory = {...usePlayhistoryResult() };

  return (
    <div className={style.playresultmain}>
    <div className={style.playsec1}>
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
            <div className={style.playhead}>
              {window?.location?.pathname==="/position"?"Today's Profit & Loss":"Historical Profit & Loss"}              
                </div>
            </Grid>
            <Grid item xs={12}md={12}>
                <Grid container spacing={2}>
                <Grid item xs={4} md={2}>
                      <div className={style.boxconstyle}>
                        <div className={style.boxtxtcon}>
                          <span className={style.usercounttxt}>{Number(playhistory?.sumpoints).toFixed(2)}</span>
                          <span className={style.totalusertxt}>Collection</span>
                        </div>
                        <div>
                          <BookmarksIcon style={{ fontSize: "20px" }} />
                        </div>
                      </div>
                    </Grid>
                <Grid item xs={4} md={2}>
                      <div className={style.activeboxconstyle}>
                        <div className={style.boxtxtcon}>
                          <span className={style.usercounttxt}>{Number(playhistory?.profitsum).toFixed(2)}</span>
                          <span className={style.totalusertxt}>Profit</span>
                        </div>
                        <div>
                          <TrendingUpIcon style={{ fontSize: "30px"}} />
                        </div>
                      </div>
                    </Grid>
                <Grid item xs={4} md={2}>
                      <div className={style.blockboxconstyle}>
                        <div className={style.boxtxtcon}>
                          <span className={style.usercounttxt}>{Number(playhistory?.sumloss).toFixed(2)}</span>
                          <span className={style.totalusertxt}>Loss</span>
                        </div>
                        <div>
                          <TrendingDownIcon style={{ fontSize: "30px"}} />
                        </div>
                      </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={1.5} xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={playhistory?.inputCtrlSetting?.fdatedisable}
                  className={classes.smallDateTimePicker}
                  minDate={dayjs(`${playhistory?.inputCtrlSetting?.fdatemin}`)} 
                  label="From Date"
                  value={dayjs(`${playhistory?.fdate}`)}
                  onChange={(e)=>{playhistory?.handleFplaydate(e)}}
                  renderInput={(props) => (
                    <TextField {...props} variant="standard" />
                  )}
                  format="DD/MM/YYYY" // Specify the desired format for date and time
                  renderTime={(props) => (
                    <TextField
                      {...props}
                      variant="outlined"
                      helperText={null}
                    />
                  )} // Customize the time input rendering
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={1.5} xs={6} sx={{display:`${playhistory?.inputCtrlSetting?.tdatehide}`}}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className={classes.smallDateTimePicker}
                  label="To Date"
                  value={dayjs(`${playhistory?.tdate}`)}
                  onChange={(e)=>{playhistory?.handleTplaydate(e)}}
                  renderInput={(props) => (
                    <TextField {...props} variant="standard" />
                  )}
                  format="DD/MM/YYYY" // Specify the desired format for date and time
                  renderTime={(props) => (
                    <TextField
                      {...props}
                      variant="outlined"
                      helperText={null}
                    />
                  )} // Customize the time input rendering
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={9} md={6}>
              <TextField
                id="outlined-disabled"
                label="Transaction Id"
                fullWidth
                size="small"
                name='trid'
                value={playhistory?.trid}
                InputProps={{
                  classes: {
                    input: style.textinp_left,
                  },
                  style: {
                    padding: "1px",
                  },
                }}
                InputLabelProps={{
                  className: style.textinplbl,
                }}
                onChange={(e)=>{
                  playhistory?.handleplaymaster(e, e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={3} md={3} className={style.btnposition}>
            <div className={style.searchbtncon}
            onClick={(e) => {
              playhistory?.filterPlayData();
            }}
            >
                <button type="button" className={style.searchbtn}>
                 <SearchIcon style={{fontSize:"32px",fontWeight:700}}/>
                </button>
              </div>
            </Grid>
    </Grid>
    </div>
    <div className={style.playsec2}>
    <Grid container spacing={2} sx={{ padding: "5px" }}>
          {playhistory?.searchplaymasterstate?.map((element, index) => {
            let bgcolor = "#F1F0E8";
            if (index % 2 == 0) {
              bgcolor = "#fff";
            }
            let totalamount = 0;
            totalamount += element?.wamount ? parseFloat(element?.wamount) : 0;

            let totalloss = 0;
            totalloss += element?.points ? parseFloat(element?.points) : 0;
            let totpoints = 0;
            totpoints += element?.points;
            return (
              <Grid item xs={12} md={12}>
                <Accordion component={Paper} expanded={expanded === index} onChange={handleChange(index)}>
                  <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{ backgroundColor: bgcolor }}
                  >
                    <Typography style={{width:"60%"}}>
                      <div className={style.transid}>
                        #{element?.trid}-{element?.gsubname}
                      </div>
                      <div className={style.historydetime}>
                       {playhistory?.formatDate(element?.playdate)}               
                      </div>
                    </Typography>
                    <Typography style={{width:"40%"}}>
                    <div className={style.pontcontainer}>
                    <div className={style.pointtxt}>
                      {Number(totpoints).toFixed(2)}
                      </div>
                      {/* <div className={style.pointsubcon}>
                       <div className={style.lossgraph}>
                       <div>
                          <TrendingDownIcon style={{ fontSize: "20px",color:"red"}} />
                        </div>  
                       <div className={style.pointtxt}>
                          {Number(totalloss).toFixed(2)}
                        </div>  
                      </div>                  
                       <div className={style.lossgraph}>
                       <div>
                          <TrendingUpIcon style={{ fontSize: "20px",color:"green"}} />
                        </div>  
                       <div className={style.pointtxt}>
                          {Number(totalamount).toFixed(2)}
                        </div>  
                      </div>                  
                      </div> */}
              </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                           <PlayHistoryTable data={element?.entries}/>
                        </Grid>
                      </Grid>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )
          })}
        </Grid>
    </div>
    <div className={style.playsec3}>
      <MobileMenu />
    </div>
    </div>
  )
}

export default PlayHistoryResult