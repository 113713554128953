import React from 'react'
import HomePage from '../../Components/HomePage/HomePage'
import MobileMenu from '../../Components/MobileMenu/MobileMenu'

const HomePageScreen = () => {
  return (
    <div>
        {/* <Appbar /> */}
        <HomePage />
     </div>
  )
}

export default HomePageScreen