import React, { useContext, useEffect, useReducer, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css";
import {
  Checkbox,
  Grid,
  TableContainer,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles, styled } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";
import style from "../Components/UserPlayMaster/UserPlay.module.css";
import { useDispatch, useSelector } from "react-redux";
import { removeAdminPlayData } from "../API/Reducer/playReducer";

 
const useStyles = makeStyles({
  noBorder: {
    border: "none !important",
    "& .MuiInput-underline:before": {
      // borderBottom: `${props.border=="none"?"none !important":""}`,
      borderBottom: "none !important",
    },
  },
});

const AdminPlaymasterTable = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {playData} = useSelector((state) => state.play);
  return (
    <>
      <div>
        <Paper className="contner1">
          <TableContainer sx={{ maxHeight: "30vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableBody className="bodycon">
                {playData?.map((element, index) => {
                  let bgcolor = "#F1F0E8";
                  if (index % 2 == 0) {
                    bgcolor = "#fff";
                  }                                 
                  return (
                    <TableRow
                      className="tblerowdata"
                      style={{ backgroundColor: bgcolor }}
                    >
                      <TableCell>
                        {element?.gtypename}
                      </TableCell>
                      <TableCell style={{textAlign:"center"}}>
                       {element?.npointsSpace}
                      </TableCell>
                      <TableCell style={{textAlign:"right"}}>
                      {Number(element?.points).toFixed(2)}
                      </TableCell>
                      <TableCell style={{textAlign:"right"}} onClick={()=>{dispatch(removeAdminPlayData(playData,element?.id))}}>
                        <DeleteIcon/>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="collectiontbl">
            <div className="collectiontotal">Total</div>
            <div className="collectiontotal">{Number(playData?.[0]?.totpoints || 0).toFixed(2)}</div>
          </div>
        </Paper>
      </div>
    </>
  );
};
export default AdminPlaymasterTable;
