import { Grid, Paper, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import MobileMenu from "../MobileMenu/MobileMenu";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { v4 as uuidv4 } from "uuid";
const UserVoicePlay = () => {
    const [textToCopy, setTextToCopy] = useState();
    const [message, setMessage] = useState('')
    const [gameName, setGameName] = useState('open')
    const [numVarient, setNumVarient] = useState('standard');
    const [numVal, setNumVal] = useState('');
    const [pointVarient, setPointVarient] = useState('standard');
    const [pointValue, setPointValue] = useState('');
    const [playVoiceData, setPlayVoiceData] = useState([]);
    // const [isCopied, setCopied] = useClipboard(textToCopy, {
    //     successDuration:1000
    // });"filled"
    const startListening = () => SpeechRecognition.startListening({ continuous: true, language: 'en-IN' });
    useEffect(() => {
      startListening()
      }, [])
    const handlePlayData=()=>{
      let data={id:uuidv4(),gname:gameName,num:numVal,point:pointValue}
      setPlayVoiceData(prevData => [...prevData, data]);
      setNumVarient('standard');
      setPointVarient('standard');
      setPointValue('');
      setNumVal('');

    }
    
    const commands = [
                      
        {
          command: ['open'],
          callback: (command) => {
            // console.log("Recognized command:", command);
            setGameName(command); 
            resetTranscript(); 
        },
          isFuzzyMatch: true,
          fuzzyMatchingThreshold: 0.4,
          bestMatchOnly: true,
          // matchInterim: true,
        },
        {
          command: ['close'],
          callback: (command) => {
            // console.log("Recognized command:", command);
            setGameName(command); 
            resetTranscript(); 
        },
          isFuzzyMatch: true,
          fuzzyMatchingThreshold: 0.4,
          bestMatchOnly: true,
          // matchInterim: true,
        },
        {
          command: ['jodi'],
          callback: (command) => {
            // console.log("Recognized command:", command);
            setGameName(command); 
            resetTranscript(); 
        },
          isFuzzyMatch: true,
          fuzzyMatchingThreshold: 0.4,
          bestMatchOnly: true,
          // matchInterim: true,
        },
        {
          command: ['gunule','gunile'],
          callback: (command) => {
            // console.log("Recognized command:", command);
            setGameName(command); 
            resetTranscript(); 
        },
          isFuzzyMatch: true,
          fuzzyMatchingThreshold: 0.4,
          bestMatchOnly: true,
          // matchInterim: true,
        },
        // {
        //   command: ['number','Number','NUMBER'],
        //   callback: (command) => {
        //     setNumVarient('filled');
        //     setPointVarient('standard');
        //     setNumVal('');
        //     resetTranscript(); 
        //   },
        //   isFuzzyMatch: true,
        //   fuzzyMatchingThreshold: 0.4,
        //   bestMatchOnly: true,
        // },
        // {
        //   command: ['price','amount'],
        //   callback: (command) => {
        //     setNumVarient('standard');
        //     setPointVarient('filled');
        //     setPointValue('');
        //     resetTranscript(); 
        //   },
        //   isFuzzyMatch: true,
        //   fuzzyMatchingThreshold: 0.4,
        //   bestMatchOnly: true,
        // },  
        {
          command: ['clear','reset'],
          callback: (command) => {
            resetTranscript();
            setMessage('');
          }
        },
        {
          command: ['reload','refresh'],
          callback: (command) => window.location.reload()
        },
        {
          command: ['add','done'],
          callback: (command) => {
            handlePlayData();
            resetTranscript();
          }
        },

        
      ]

      const { transcript,resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition({commands });
      const isNumber=(str)=> {
        return !isNaN(str);
      }
      useEffect(() => {
        
       if(numVarient==="filled"){
         if(transcript && isNumber(transcript)===true){
          setNumVal(transcript)
          resetTranscript();
         }
        //  resetTranscript(); 
       }else if(pointVarient==="filled"){
        if(transcript && isNumber(transcript)===true){
          setPointValue(transcript)
          resetTranscript();
         }
        //  resetTranscript(); 
       }
  
      },[transcript])
    if (!browserSupportsSpeechRecognition) {
        return null
    }
    
    
  return (
    <>
      <Grid container spacing={2}>
        <Grid container item md={12} xs={12} spacing={2} sx={{ height: "20%" }} >
        <Grid item xs={12} md={12}>{gameName}</Grid>
        <Grid item xs={6} md={6}>
        <TextField  label="Number" type="number"  value={numVal} variant={numVarient} size="small" readOnly />
        </Grid>
        <Grid item xs={6} md={6}>
        <TextField  label="Points" type="number"  value={pointValue} variant={pointVarient} size="small" readOnly />
        </Grid>
        <Grid item xs={12} md={12}>{message}</Grid>
        {/* <button onClick={startListening}>Start Listening</button> */}
        {/* <button onClick={SpeechRecognition.stopListening}>Stop Listening</button> */}
        </Grid>
        <Grid container item md={12} xs={12} sx={{ height: "70%" }} spacing={2}>
        <Grid item xs={12} md={12}>{transcript}</Grid>
        <Grid container item xs={12} md={12} spacing={2}>
       
          {
            playVoiceData.map((element,index) => {              
              let bgcolor = "#F1F0E8";
                   if (index % 2 == 0) {
                    bgcolor = "#fff";
                  }
              return(
                  <>
                  <Grid  item xs={12} md={12}>
                  <Paper elevation={4} sx={{backgroundColor:bgcolor,display:"flex",justifyContent:"center",padding:"5px"}} > 
                  <Grid container item xs={12} md={12} spacing={1} >
                  <Grid item xs={4} md={12} sx={{fontWeight:"600"}}>{element?.gname}</Grid>
                  <Grid item xs={4} md={12} >{element?.num}</Grid>
                  <Grid item xs={4} md={12} sx={{textAlign:"end"}}>{Number(element?.point).toFixed(2)}</Grid>
                  </Grid>
                  </Paper>
                  </Grid>
                  </>
              )
            })
          }
          
        </Grid>

        {/* <div className="main-content" onClick={() =>  setTextToCopy(transcript)}>
                    {transcript}
                </div> */}
        </Grid>
        <Grid container item md={12} xs={12} sx={{ height: "10%" }}>
          <MobileMenu />
        </Grid>
      </Grid>
    </>
  );
};

export default UserVoicePlay;
