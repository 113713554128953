import React, { useRef } from 'react';
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";

const QRCodeGenerator = () => {
  const qrCodeRef = useRef(null);

  const handleDownload = () => {
    if (qrCodeRef.current !== null) {
      html2canvas(qrCodeRef.current).then((canvas) => {
        canvas.toBlob((blob) => {
          saveAs(blob, "qrcode.png");
        });
      });
    }
  };
  return (
    <>
      {" "}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <QRCode ref={qrCodeRef} value={"https://mavlamangoes.com/"} />
      </div>
      <button onClick={handleDownload}>Download QR Code</button>
    </>
  );
};

export default QRCodeGenerator;
