import { Grid, Paper, TextField } from "@mui/material";
import React, { useState } from "react";
import style from "./UserWalletLedger.module.css";
import MobileMenu from "../MobileMenu/MobileMenu";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ListItemIcon } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TradeNumberTextSuggest from "../../Textsuggestfield/TradeNumberTextSuggest";
import useMenumaster from "../../API/Hooks/useMenumaster";

const UserWalletLedger = () => {
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpen1 = () => setOpen1(true);
  const [editOpen, setEditOpen] = useState(false); // State to manage the visibility of the edit section

  // Function to handle opening the edit section
  const handleOpenEdit = () => {
    setEditOpen(true);
  };

  // Function to handle closing the edit section
  const handleCloseEdit = () => {
    setEditOpen(false);
  };
  const [open1, setOpen1] = React.useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const menuMaster = { ...useMenumaster() };

  return (
    <div className={style.homecontainer}>
      <div className={style.homepart1}>
        <Grid container spacing={2}>       
          <Grid item xs={12} md={4}>
            <div className={style.boxconstyle}>
              <div className={style.cardcontaint}>
                <div className={style.total}>
                  <span className={style.usercounttxt}>Current Balance</span>
                  <span className={style.totalcount}>
                   {menuMaster?.userdatastate && menuMaster?.userdatastate[0]
                    ? Number(menuMaster?.userdatastate[0]?.balance).toFixed(2)
                    : "0.00"}</span>
                </div>
                <div>
                  <AccountBalanceWalletIcon sx={{ fontSize: "34px" }} />
                </div>
              </div>
              <div className={style.cardcontaint1}>
                <div className={style.boxtxtcon}>
                  <div className={style.usercounttxt}>{menuMaster?.userdatastate && menuMaster?.userdatastate[0]
                    ? Number(menuMaster?.userdatastate[0]?.totcr).toFixed(2)
                    : "0.00"}</div>
                  <div className={style.totalusertxt}>Cr</div>
                </div>
                <div className={style.boxtxtcon}>
                  <div className={style.usercounttxt}>{menuMaster?.userdatastate && menuMaster?.userdatastate[0]
                    ? Number(menuMaster?.userdatastate[0]?.totdr).toFixed(2)
                    : "0.00"}</div>
                  <div className={style.totalusertxt}>Dr</div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={12} className={style.viewallcon}>
            <div className={style.usercounttxt}>Recent Transaction</div>
            <div className={style.viewalltxt}>View All</div>
          </Grid>
        </Grid>
      </div>
      <div className={style.homepart2}>
        <Grid container spacing={1} className={style.cardlistscroll}>
          {items?.map((element, index) => {
            let bgcolor = "#F1F0E8";
            if (index % 2 == 0) {
              bgcolor = "#fff";
            }
            return (
              <Grid item xs={12} md={4}>
                <Paper
                  elevation={3}
                  className={style.homepaper}
                  sx={{ backgroundColor: bgcolor }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4} md={6}>
                          <div>
                            <div className={style.usercounttxt}>Mumbai</div>
                            <div className={style.totalusertxt1} style={{}}>
                              Jodi
                            </div>
                            <div className={style.datetime}>10-10-2023</div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          md={6}
                          sx={{ display: "flex", justifyContent: "end" }}
                        >
                          <div className={style.gamecard2}>
                            <div>
                              <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={
                                  open ? "account-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    overflow: "visible",
                                    filter: "drop-shadow(1px 1px 1px #a6a6a6)",
                                    mt: 1.5,
                                    "& .MuiAvatar-root": {
                                      width: 32,
                                      height: 32,
                                      ml: -0.5,
                                      mr: 1,
                                    },
                                    "&:before": {
                                      content: '""',
                                      display: "block",
                                      position: "absolute",
                                      top: 10,
                                      right: -4,
                                      width: 10,
                                      height: 10,
                                      bgcolor: "background.paper",
                                      transform:
                                        "translateY(-50%) rotate(45deg)",
                                      zIndex: 0,
                                    },
                                  },
                                }}
                                transformOrigin={{
                                  horizontal: "right",
                                  vertical: "top",
                                }}
                                anchorOrigin={{
                                  horizontal: "left",
                                  vertical: "top",
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    handleOpen1();
                                    handleClose();
                                  }}
                                >
                                  <ListItemIcon>
                                    <DeleteIcon fontSize="small" />
                                  </ListItemIcon>
                                  Delete
                                </MenuItem>
                                <MenuItem
                                onClick={()=>{
                                    handleOpenEdit()
                                }}
                                >
                                  <ListItemIcon>
                                    <EditIcon fontSize="small" />
                                  </ListItemIcon>
                                  Edit
                                </MenuItem>
                              </Menu>
                            </div>
                            <div className={style.usercounttxt}>20</div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    {editOpen ? (
                    <Grid item xs={12} md={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} md={4}>
                          <TextField
                             type="tel"
                             id="phone"
                            inputMode="numeric"
                            label="Points"
                            fullWidth
                            size="small"
                            sx={{backgroundColor:"white"}}
                            InputProps={{
                              classes: {
                                input: style.textinp_left,
                              },
                              style: {
                                padding: "1px",
                                zIndex: "auto",
                              },
                            }}
                            InputLabelProps={{
                              className: style.textinplbl,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <TradeNumberTextSuggest />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          sx={{ display: "flex", justifyContent: "end" }}
                        >
                          <div className={style.paybtncon}
                          onClick={()=>{
                            handleCloseEdit()
                          }}
                          >
                            <button type="button" className={style.paybtn}>
                              Buy
                            </button>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                       ) : null}
                  </Grid>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </div>
      <div className={style.homepart3}>
        <MobileMenu />
      </div>
    </div>
  );
};

export default UserWalletLedger;
