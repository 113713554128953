import { useEffect, useReducer, useState } from "react";
import { tostmsg } from "../../MIS/Global";
import UserSignupReducer from "../Reducer/UserSignupReducer";
import {
  GetUserdata,
  SaveUsermaster,
  Signinusers,
  UpdateActiveStatus,
  UpdateBlockStatus,
} from "../Model/UserSignupModel";
import { useNavigate } from "react-router-dom";
import useMenumaster from "../../API/Hooks/useMenumaster";
import WalletmasterReducer from "../Reducer/WalletmasterReducer";
import { Savewalletmaster } from "../Model/WalletmasterModel";
import { DrawAutoEntry } from "../Model/DrawmasterModel";

function useUserSigniupnmaster() {
  const [alertdata, setAlertdata] = useState({});
  const [alertopen, setAlertopen] = useState(false);
  const [openmodal, setOpenmodal] = useState(false);
  const handlemodalOpen = () => setOpenmodal(true);
  const handlemodalClose = () => setOpenmodal(false);

  const [openmodalRpt, setOpenmodalRpt] = useState(false);
  const handlemodalOpenRpt = () => setOpenmodalRpt(true);
  const handlemodalCloseRpt = () => setOpenmodalRpt(false);

  const [uname, setUname] = useState("User Name");
  const [openmodalCutpan, setOpenmodalCutpan] = useState(false);
  const handlemodalOpenCutpan = () => setOpenmodalCutpan(true);
  const handlemodalCloseCutpan = () => setOpenmodalCutpan(false);

  const [userState, userDispatch] = useReducer(UserSignupReducer, []);
  const [usersigninState, usersigninDispatch] = useReducer(
    UserSignupReducer,
    []
  );
  const [serachuserState, searchuserDispatch] = useReducer(
    UserSignupReducer,
    []
  );
  const [serachuserorgState, searchuserorgDispatch] = useReducer(
    UserSignupReducer,
    []
  );
  const [usercountState, usercountDispatch] = useReducer(UserSignupReducer, []);
  const [userwalletState, userwalletDispatch] = useReducer(
    WalletmasterReducer,
    []
  );

  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState("");
  const [usearch, setUsearch] = useState([]);
  const [loginid, setLoginid] = useState("");
  const {navigateLogin} = useMenumaster();

  const handleuser = (e, value) => {
    // alert(e)
    // if (e && e.target) {
    userDispatch({
      type: "Change",
      payload: { name: e.target.name, value: e.target.value },
    });
  };
  const handleuserwallet = (e, value) => {
    let values = "";
    let name = "";
    if (e == "isdrcr" || e == "loginid") {
      values = value;
      name = e;
    } else {
      values = e.target.value;
      name = e.target.name;
    }
    userwalletDispatch({
      type: "Change",
      payload: { name: name, value: values },
    });
  };

  const Saveuser = async () => {
    try {
      let data = {
        ...userState,
        urole: "user",
        address: "-",
        isstatus: 0,
        edate: new Date(),
        adate: "00-00-00",
        bdate: "00-00-00",
        cmpid: 0,
      };

      let signup = await SaveUsermaster(data);
      let rettostmsg = "";
      rettostmsg = tostmsg(signup);
      if (signup.code == 200 && signup?.data[0]?.dt > 0) {
        rettostmsg = tostmsg({ msg: "Congratulations, your account has been successfully created.", code: 200 });
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        setTimeout(() => {
          navigateLogin(); // Change the message after the timeout
        }, 1000);
        
      } else if (signup?.data[0]?.dt == -1) {
        rettostmsg = tostmsg({
          msg: "User already exist; with this mobile number.",
          code: 100,
        });
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      } else {
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      }
    } catch (error) {
      console.log(error)
      setAlertdata({ code: 100, msg: "Frontend failed please try again" });
      setAlertopen(true);
    }
  };

  const handleusersignin = (e, value) => {
    // alert(e)
    if (e && e.target) {
      usersigninDispatch({
        type: "Change",
        payload: { name: e.target.name, value: e.target.value },
      });
    }
  };

  const UserSignin = async () => {
    try {
      let data = { ...usersigninState };
      let signin = await Signinusers(data);
      // alert(JSON.stringify(signin))
      let rettostmsg;
      rettostmsg = tostmsg(signin);
      if (signin.code === 200) {
        if (signin.data[0].dt == -1) {
          rettostmsg = tostmsg({
            code: 100,
            msg: "Welcome to SPGames.you are not authorized, please contact with administrator",
            duration: 300000,
          });        
          setAlertdata(rettostmsg);
          setAlertopen(true);
          return;
        } else if (signin.data[0].dt == -2) {
          rettostmsg = tostmsg({
            code: 100,
            msg: "Invalid mobile number or password",
            duration: 300000,
          });
          setAlertdata(rettostmsg);
          setAlertopen(true);
          return;
        }
        localStorage.setItem("loginId", signin?.data[0]?.loginid);
        let logData={
          loginid:signin?.data[0]?.loginid,
          uname:signin?.data[0]?.uname,
          mob:signin?.data[0]?.mob,
          isstatus:signin?.data[0]?.isstatus,
          dr:signin?.data[0]?.dr,
          cr:signin?.data[0]?.cr,
          bal:signin?.data[0]?.bal,
          adate:signin?.data[0]?.adate,
          urole:signin?.data[0]?.urole
        };
        localStorage.setItem("logindata", JSON.stringify(logData));
        navigate("/home");
      }

      setAlertdata(rettostmsg);
      setAlertopen(rettostmsg.open);
    } catch (e) {
      setAlertdata({ code: 100, msg: "Frontend failed please try again" });
      setAlertopen(true);
    }
  };

  const fetchUsersData = async () => {
    let data = { isstatus: "0" };
    if (selectedStatus !== undefined && selectedStatus !== "") {
      if (selectedStatus === "All") {
        data = {};
      } else if (selectedStatus === "New User") {
        data = { isstatus: "0" };
      } else if (selectedStatus === "Active") {
        data = { isstatus: "1" };
      } else if (selectedStatus === "Block") {
        data = { isstatus: "2" };
      }
    }
    // alert(JSON.stringify(data));

    try {
      let getuserdata = await GetUserdata(data);
      if (getuserdata.code === 200) {
        searchuserDispatch({
          type: "userDetails",
          data: getuserdata?.data,
        });
        searchuserorgDispatch({
          type: "userDetails",
          data: getuserdata?.data,
        });
      }
    } catch (error) {
      // Handle any errors here
      setAlertdata({ code: 100, msg: "Frontend failed please try again" });
      setAlertopen(true);
    }
  };

  const fetchUserscount = async () => {
    let data = { limit: "1" };

    try {
      let getuserdata = await GetUserdata(data);
      if (getuserdata.code === 200) {
        // alert(JSON.stringify(getuserdata.data))
        usercountDispatch({
          type: "userDetails",
          data: getuserdata?.data,
        });
      }
    } catch (error) {
      // Handle any errors here
      setAlertdata({ code: 100, msg: "Frontend failed please try again" });
      setAlertopen(true);
    }
  };

  useEffect(() => {
    fetchUserscount();
    fetchUsersData();
  }, [selectedStatus]);

  useEffect(() => {
    udatasearch(usearch);
  }, [usearch]);

  const ActiveUpdate = async (loginid) => {
    try {
      let data = { loginid: loginid };
      let active = await UpdateActiveStatus(data);
      //   alert(JSON.stringify(active))
      let rettostmsg;
      rettostmsg = tostmsg(active);
      if (active.code == 200 && active.data[0]?.dt > 0) {
        rettostmsg = tostmsg({
          msg: "User has been activated successfully",
          code: 200,
        });
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        fetchUserscount();
        fetchUsersData();
      } else {
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      }
    } catch (e) {
      setAlertdata({ code: 100, msg: "Frontend failed please try again" });
      setAlertopen(true);
    }
  };

  const BlockUpdate = async (loginid) => {
    try {
      let data = { loginid };
      let active = await UpdateBlockStatus(data);
      let rettostmsg;
      rettostmsg = tostmsg(active);
      if (active.code == 200 && active.data[0]?.dt > 0) {
        rettostmsg = tostmsg({
          msg: "User has been blocked successfully",
          code: 200,
        });
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        fetchUserscount();
        fetchUsersData();
      } else {
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      }
    } catch (e) {
      setAlertdata({ code: 100, msg: "Frontend failed please try again" });
      setAlertopen(true);
    }
  };

  const udatasearch = (searchstr) => {
    const keysToSearch = ["uname", "mob", "city"];
    const filteredData = serachuserorgState?.filter((item) => {
      return keysToSearch?.some((key) => {
        return item[key]
          ?.toString()
          .toLowerCase()
          ?.includes(searchstr.toString().toLowerCase());
      });
    });
    // alert(JSON.stringify(filteredData))
    searchuserDispatch({
      type: "userDetails",
      data: filteredData,
    });
  };

  const WalletSave = async () => {
    try {
      let dr = 0;
      let cr = 0;
      if (userwalletState?.isdrcr == 1) {
        dr = userwalletState?.points;
      } else {
        cr = userwalletState?.points;
      }
      let data = {
        ...userwalletState,
        dr: dr,
        cr: cr,
        edate: new Date(),
        plyid: 0,
        cmpid: 0,
      };
      // alert(JSON.stringify(data))
      let wallet = await Savewalletmaster(data);
      // alert(JSON.stringify(wallet))
      let rettostmsg = "";
      rettostmsg = tostmsg(wallet);
      if (wallet.code == 200 && wallet?.data[0]?.dt > 0) {
        rettostmsg = tostmsg({ msg: "Funds submited successfully", code: 200 });
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        fetchUsersData();
        handlemodalClose();
      } else if (wallet?.data[0]?.dt == -1) {
        rettostmsg = tostmsg({
          msg: "Insufficient balance for withdrawal",
          code: 100,
        });
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      } else {
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      }
    } catch (e) {
      setAlertdata({ code: 100, msg: "Frontend failed please try again" });
      setAlertopen(true);
    }
  };

  const drawAutoEntry = async (data) => {
    let rettostmsg;
    try {        
      let drawAuto = await DrawAutoEntry(data);            
    } catch (error) {
      // Handle any errors here
       rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };

  useEffect(() => {
    drawAutoEntry();
  }, [])
  

  return {
    handleuser,
    Saveuser,
    alertdata,
    alertopen,
    setAlertopen,
    UserSignin,
    handleusersignin,
    usersigninState,
    serachuserState,
    searchuserDispatch,
    ActiveUpdate,
    BlockUpdate,
    selectedStatus,
    setSelectedStatus,
    usearch,
    setUsearch,
    udatasearch,
    userwalletState,
    handleuserwallet,
    WalletSave,
    handlemodalOpen,
    handlemodalClose,
    openmodal,
    usercountState,
    navigate,
    openmodalRpt,
    handlemodalOpenRpt,
    handlemodalCloseRpt,
    loginid,
    setLoginid,
    handlemodalOpenCutpan,
    handlemodalCloseCutpan,
    openmodalCutpan,uname, setUname
  };
}

export default useUserSigniupnmaster;
