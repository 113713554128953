import React, { useEffect } from "react";
import style from "./AdminPlay.module.css";
import { Grid, Paper, Button } from "@mui/material";
import AdminPlayTable from "../../Tables/AdminPlayTable";
import SearchIcon from "@mui/icons-material/Search";
import PlayAdminInput from "./PlayAdminInput";
import AdminPlaymasterTable from "../../Tables/AdminPlaymasterTable";
import { useSelector, useDispatch } from "react-redux";
import { PlayBtnStatus, actionConfirmAdminPlay, getGameList, saveAdminPlay, setSelectedGame } from "../../API/Reducer/playReducer";
import { useState } from "react";
import AlertComp from "../AlertMessageCom/AlertComp";
import ReduxConfirmationDialog from "../ConfirmationDialog/ReduxConfirmationDialog";

const AdminPlaymaster = () => {
  const dispatch = useDispatch();
  const {playBtnSelect,gameList,playUser,playData,playGameList} = useSelector((state) => state.play);  
  const [userSearch, setUserSearch] = useState("");

  useEffect(() => {
    dispatch(getGameList({}))
  }, [])
  const { resans,type } = useSelector((state) => state.confirm);
 
  useEffect(() => {
    if (resans === true && type=="save") {
      dispatch(saveAdminPlay(playData,playUser,gameList));
    }else if(resans === true && type=="del"){
      // dispatch(delteHoliday({hldid:id,monthname:holiday?.monthname}));      
    }
  }, [resans]);

  return (
    <div className={style.aplaycontainer}>
     <ReduxConfirmationDialog/>
      <AlertComp/>
      <Grid container spacing={2}>
        <Grid item xs={9} md={10}>
          <div className={style.plresulthead}>Admin Playmaster</div>
        </Grid>
        <Grid item xs={3} md={2} className={style.btnposition}>
          <Button type="button" variant="contained" color="primary"  onClick={() => {
                  dispatch(actionConfirmAdminPlay("save"));
                }}>
            Save
          </Button>
          
        </Grid>
        <Grid item xs={12} md={3} className={style.adminsubconainer}>
          <Grid container spacing={2}>
            {playGameList?.map((element, index) => {
              let bgcolor = "#fff";
              if (index % 2 == 0) {
                bgcolor = "#fff";
              }
              if(element?.ischeck===true){bgcolor="#b0bed9";}
              return (
                <Grid item xs={12} md={12} onClick={()=>{dispatch(setSelectedGame(playGameList,element?.gmid))}}>
                  <Paper
                    elevation={3}
                    className={style.homepaper}
                    sx={{ backgroundColor: bgcolor }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12} >
                        <div className={style.usercounttxt}>{element?.gsubname}</div>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} className={style.homepaper}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} className={style.searchcont}>
                <div className={style.searchcon}>
                  <SearchIcon sx={{ fontSize: "22px", cursor: "pointer" }} />
                  <input
                    onChange={(e)=>{setUserSearch(e.target.value)}}
                    type="text"
                    className={style.searchfield}
                    placeholder="Search"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <AdminPlayTable data={{userSearch:userSearch}}/>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={5}>
          <Paper elevation={3} className={style.homepaper}>
            <Grid container spacing={2}>
              <Grid item xs={3} md={3}>
                <div className={playBtnSelect?.o===false?style.apaypaper : style.btnselected}
                onClick={(e)=>{dispatch(PlayBtnStatus(playBtnSelect,"o"))}}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <div className={style.usercounttxt}>Open</div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={3} md={3}>
                <div className={playBtnSelect?.c===false?style.apaypaper : style.btnselected}
                 onClick={(e)=>{dispatch(PlayBtnStatus(playBtnSelect,"c"))}}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <div className={style.usercounttxt}>Close</div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={3} md={3}>
                <div className={playBtnSelect?.j===false?style.apaypaper : style.btnselected}
                onClick={(e)=>{dispatch(PlayBtnStatus(playBtnSelect,"j"))}}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <div className={style.usercounttxt}>Jodi</div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={3} md={3}>
                <div className={playBtnSelect?.g===false?style.apaypaper : style.btnselected}
                onClick={(e)=>{dispatch(PlayBtnStatus(playBtnSelect,"g"))}}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <div className={style.usercounttxt}>Gunule</div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={4} md={3}>
                <div className={playBtnSelect?.op===false?style.apaypaper : style.btnselected}
                onClick={(e)=>{dispatch(PlayBtnStatus(playBtnSelect,"op"))}}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <div className={style.usercounttxt}>Open Panna</div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={4} md={3}>
                <div className={playBtnSelect?.cp===false?style.apaypaper : style.btnselected}
                onClick={(e)=>{dispatch(PlayBtnStatus(playBtnSelect,"cp"))}}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <div className={style.usercounttxt}>Close Panna</div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={4} md={3}>
                <div className={playBtnSelect?.jk===false?style.apaypaper : style.btnselected}
                onClick={(e)=>{dispatch(PlayBtnStatus(playBtnSelect,"jk"))}}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <div className={style.usercounttxt}>Jackpot</div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={4} md={3}>
                <div className={playBtnSelect?.ug===false?style.apaypaper : style.btnselected}
                onClick={(e)=>{dispatch(PlayBtnStatus(playBtnSelect,"ug"))}}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <div className={style.usercounttxt}>Ulta Gunule</div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <PlayAdminInput />
            </Grid>
            <Grid item xs={12} md={12}>
              <AdminPlaymasterTable/>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminPlaymaster;
