import React from "react";
import Grid from "@mui/material/Grid";
import {
  Button,
  IconButton,
  InputAdornment,
  Step,
  StepButton,
  Stepper,
  TextField,
} from "@mui/material";
import {
  handleAlphanumericInputChange,
  handleNumInputChange,
} from "../../MIS/Global";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import style from "./SignIn.module.css";
import AlertComp from "../AlertMessageCom/AlertComp";
import { useDispatch, useSelector } from "react-redux";
import {
  GenerateOTP,
  HandleFPaswdStep,
  UpdatePassword,
  VerifyOTP,
  handleAdminLoginChange,
} from "../../API/Reducer/userReducer";

const Forgotpassword = () => {
  const steps = ["Verify OTP", "Change Password"];
  const [showPassword, setShowPassword] = React.useState(false);
  const [cshowPassword, setCShowPassword] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const dispatch = useDispatch();
  const { fpaswdActiveStep, adminLogin } = useSelector((state) => state.user);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickCShowPassword = () => setCShowPassword((show) => !show);


  return (
    <>
      <AlertComp />
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <Stepper nonLinear activeStep={fpaswdActiveStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit">{label}</StepButton>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid
          container
          item
          md={12}
          xs={12}
          spacing={2}
          sx={{ display: fpaswdActiveStep === 0 ? "block" : "none" }}
        >
          <Grid item xs={12} md={12}>
            <TextField
              type="tel"
              inputMode="numeric"
              label="Mobile Number"
              fullWidth
              size="small"
              name="mob"
              value={adminLogin?.mob || ""}
              onChange={(e) => {
                dispatch(handleAdminLoginChange(e, ""));
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIphoneIcon
                      sx={{ fontSize: "22px", marginRight: "5px" }}
                    />
                  </InputAdornment>
                ),
                classes: {
                  input: style.textinp_left,
                },
                style: {
                  padding: "2px",
                },
                inputProps: {
                  onInput: handleNumInputChange,
                  maxLength: 10,
                },
              }}
              InputLabelProps={{
                className: style.textinplbl,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              onClick={() => {
                dispatch(GenerateOTP(adminLogin));
              }}
              type="button"
              size="small"
              variant="contained"
              color="secondary"
            >
              Send/Resend OTP
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              type="tel"
              inputMode="numeric"
              label="OTP"
              fullWidth
              size="small"
              name="otp"
              value={adminLogin?.otp || ""}
              onChange={(e) => {
                dispatch(handleAdminLoginChange(e, ""));
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKeyIcon sx={{ fontSize: "22px", marginRight: "5px" }} />
                  </InputAdornment>
                ),
                classes: {
                  input: style.textinp_left,
                },
                style: {
                  padding: "2px",
                },
                inputProps: {
                  onInput: handleNumInputChange,
                  maxLength: 4,
                },
              }}
              InputLabelProps={{
                className: style.textinplbl,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              onClick={() => {
                dispatch(VerifyOTP(adminLogin));
              }}
              type="button"
              size="medium"
              variant="contained"
              color="primary"
            >
              Verify
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          item
          md={12}
          xs={12}
          sx={{ display: fpaswdActiveStep === 0 ? "none" : "block" }}
          spacing={2}
        >
          <Grid item xs={12} md={12}>
            <TextField
              variant="outlined"
              type={showPassword ? "text" : "password"}
              label="Password"
              fullWidth
              size="small"
              name="paswd"
              value={adminLogin?.paswd || ""}
              onChange={(e) => {
                dispatch(handleAdminLoginChange(e, ""));
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockPersonIcon
                      sx={{ fontSize: "22px", marginRight: "5px" }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                classes: {
                  input: style.textinp_left,
                },
                style: {
                  padding: "2px",
                },
                inputProps: {
                  onInput: handleAlphanumericInputChange,
                  maxLength: 20,
                },
              }}
              InputLabelProps={{
                className: style.textinplbl,
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              variant="outlined"
              type={cshowPassword ? "text" : "password"}
              label="Confirm Password"
              fullWidth
              size="small"
              name="cpaswd"
              value={adminLogin?.cpaswd || ""}
              onChange={(e) => {
                dispatch(handleAdminLoginChange(e, ""));
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockPersonIcon
                      sx={{ fontSize: "22px", marginRight: "5px" }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickCShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                classes: {
                  input: style.textinp_left,
                },
                style: {
                  padding: "2px",
                },
                inputProps: {
                  onInput: handleAlphanumericInputChange,
                  maxLength: 20,
                },
              }}
              InputLabelProps={{
                className: style.textinplbl,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: "flex", justifyContent: "center",gap:"10px" }}
          >
           <Button
              onClick={() => {
                dispatch(HandleFPaswdStep(0));
              }}
              type="button"
              size="medium"
              variant="contained"
              color="secondary"
            >
              <ArrowCircleLeftIcon/>
            </Button>
            <Button
              onClick={() => {
                dispatch(UpdatePassword(adminLogin));
              }}
              type="button"
              size="medium"
              variant="contained"
              color="primary"
            >
              Change Password
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Forgotpassword;
