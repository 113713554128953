import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css";
import {
  Button,
  Grid,
  TableContainer,
  TableHead,
  TablePagination,
  TextField,
} from "@mui/material";

import style from "../../src/Components/AdminFundRequest/AdminFundRequestList.module.css";

const AdminFundRequestTable = (props) => {
  return (
    <>
      <div>
        <Paper className="">
          <TableContainer sx={{ maxHeight: "64vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell className={style.tblHead}>User Name</TableCell>
                  <TableCell className={style.tblHead}>Mobile</TableCell>
                  <TableCell className={style.tblHead}>Date</TableCell>
                  <TableCell className={style.tblHead}>Points</TableCell>
                  <TableCell className={style.tblHead}>Debit/Credit</TableCell>
                  <TableCell className={style.tblHead}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="bodycon">
                {props?.data?.fundsearchState?.slice(props?.data?.page * props?.data?.rowsPerPage, props?.data?.page * props?.data?.rowsPerPage + props?.data?.rowsPerPage)?.map((element, index) => {               
                  let bgcolor = "#F1F0E8";
                  let stscolor = "#6699CC";
                  if (index % 2 == 0) {
                    bgcolor = "#fff";
                  }
                  if (element?.isdrcr == 0) {
                    stscolor = "#009933";
                  } else if (element?.isdrcr == 1) {
                    stscolor = "#6699CC ";
                  }
                 
                  return (
                    <TableRow
                      className="tblerowdata"
                      style={{ backgroundColor: bgcolor }}
                    >
                      <TableCell>{element?.uname}</TableCell>
                      <TableCell>{element?.mob}</TableCell>
                      <TableCell>
                        {props?.data?.formatDate(element?.edate)}
                      </TableCell>
                      <TableCell>{element?.points}</TableCell>
                      <TableCell align="center">
                        <div
                          className={style.drcr}
                          style={{ backgroundColor: stscolor }}
                        >
                          {element?.isdrcr == 0 ? "Credit" : "Debit"}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={style.actiondrcr}>
                          <div
                            className={
                              element?.isstatus == 0?"":element?.isstatus == 2 ?style?.dblbtn_none:style?.disbutton
                            }
                            onClick={()=>{if(element?.isstatus == 0)
                            props?.data?.updateFundRequest({...element,isstatus:1})}}
                          >
                            <Button                          
                              type="button"
                              variant="contained"
                              size="small"
                              color="success"
                            >
                              Accept
                            </Button>
                          </div>
                          <div
                             className={
                              element?.isstatus == 0?"":element?.isstatus == 1 ?style?.dblbtn_none:style?.disbutton
                            }
                             onClick={() => { 
                              if(element?.isstatus == 0)  
                              props?.data?.updateFundRequest({...element,isstatus:2});
                            }}
                          >
                            <Button
                              type="button"
                              variant="contained"
                              size="small"
                              color="error"
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={props?.data?.rowsPerPageOptions}
            component="div"
            count={props?.data?.fundsearchState?.length}
            rowsPerPage={props?.data?.rowsPerPage}
            page={props?.data?.page}
            onPageChange={props?.data?.handleChangePage}
            onRowsPerPageChange={props?.data?.handleChangeRowsPerPage}
          />
        </Paper>
       </div>
    </>
  );
};
export default AdminFundRequestTable;
