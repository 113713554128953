import { createSlice } from "@reduxjs/toolkit"; 
import { setConfirmDialogData, setRetAnswer } from "./confirmDialogReducer";
import { callAjax, formatDatetime_DMY, formatDatetime_YMD } from "../../MIS/Global";
import { setAlertData } from "./alertReducer";
 

const doublePanReducer = createSlice({
    name: 'user',
    initialState: {        
        doublepanlist:[
            {"num": "112", "value": ""},
            {"num": "223", "value": ""},
            {"num": "335", "value": ""},
            {"num": "117", "value": ""},
            {"num": "441", "value": ""},
            {"num": "551", "value": ""},
            {"num": "885", "value": ""},
            {"num": "228", "value": ""}
          ],  
        doublepanmodal:true,
    },

    reducers: {
        setDoublePanList(state, action){
            state.doublepanlist=action.payload;         
         },    
         setDoublePanModal(state,action){
          state.doublepanmodal=false; 
         }
             
    }
});

export const { setDoublePanList,setDoublePanModal} = doublePanReducer.actions;
export default doublePanReducer.reducer;

export const SetDoublePanAllNum = (data,value) => {  
    return  function actionData(dispatch){          
      const updatedList = data.map(item => ({ ...item, value: value }));
      dispatch(setDoublePanList(updatedList)) ;        
    }
}
export const SetDoublePanSingleNum = (data,keyVal,value) => {  
    return  function actionData(dispatch){          
        const updatedList = data.map(item => 
            item.num.toString() === keyVal.toString() ? { ...item, value: value } : item
          );
      dispatch(setDoublePanList(updatedList));        
    }
}
export const actionDoublePanConfirm = (data) => {
  return function actionData(dispatch) {
    if (data === "save") {
      dispatch(
        setConfirmDialogData({
          type: data,
          heading: "Confirm",
          msg: "Do you want to save this record?",
          yeslbl: "Yes",
          nolbl: "No",
          openclose: true,
        })
      );
    }else if(data === "del"){
     dispatch(
        setConfirmDialogData({
            type: data,
            heading: "Confirm",
            msg: "Do you want to delete this record?",
            yeslbl: "Yes",
            nolbl: "No",
            openclose: true,
        })
        );
    }
  };
};
export const saveDoublePanUser = (data,loginarray) => {  
    const output = [];
    let filterData=data.filter(item => item.value !== "");
     return async function actionData(dispatch){          
       try {
         if (filterData.length<=0) {          
        dispatch(
          setAlertData({ msg: "Please enter at least one number value.", code: 100, open: true })
        );
        return
      }
      loginarray.forEach(item => {
        data.forEach(obj => {
            output.push({ loginid: item.loginid, cutpanvalue:obj?.value,smrtnum:obj?.num,gtmid:0,gmid:0});
        });
    });
        let ret = await callAjax("smartnumbulksave", output);
        if (ret.code == 200 && ret.data?.[0]?.dt > 0) {
          dispatch(
            setAlertData({
              msg: "Double pan data save successfully",
              code: 200,
              open: true,
            })
          );
         }
       } catch (error) {
        dispatch(
          setAlertData({
            msg: "Something went wrong! Please try again",
            code: 100,
            open: true,
          })
        );
       }  
       dispatch(setRetAnswer({ resans: false }));   
    }
}

export const saveDoublePanGame = (data,gameArray) => {  
  const output = [];
  let filterData=data.filter(item => item.value !== "");
   return async function actionData(dispatch){          
     try {
       if (filterData.length<=0) {          
      dispatch(
        setAlertData({ msg: "Please enter at least one number value.", code: 100, open: true })
      );
      return
    }
    gameArray.forEach(item => {
      data.forEach(obj => {
          output.push({cutpanvalue:obj?.value,smrtnum:obj?.num,gtmid:0,gmid:item?.gmid});
      });
  });
      let ret = await callAjax("gamesmartnumbulksave", output);
      if (ret.code == 200 && ret.data?.[0]?.dt > 0) {
        dispatch(
          setAlertData({
            msg: "Double pan data save successfully",
            code: 200,
            open: true,
          })
        );
       }
     } catch (error) {
       dispatch(
        setAlertData({
          msg: "Something went wrong! Please try again",
          code: 100,
          open: true,
        })
      );
     }  
     dispatch(setRetAnswer({ resans: false }));   
  }
}
 
export const getDoublePanUserList = (data) => {  
  const output = [];
  
   return async function actionData(dispatch){          
     try {
      let ret = await callAjax("getsmartnum", data);
      if (ret.code == 200 && ret?.data?.length > 0) {
        ret?.data.forEach(element => {
          output.push({num:element?.smrtnum,value:element?.cutpanvalue});
      });
        dispatch(setDoublePanList(output));        
      }
     } catch (error) {
      dispatch(
        setAlertData({
          msg: "Something went wrong! Please try again",
          code: 100,
          open: true,
        })
      );
     }     
  }
}

export const getDoublePanGameList = (data) => {  
  const output = [];  
   return async function actionData(dispatch){          
     try {
      let ret = await callAjax("getgamesmartnum", data);
      if (ret.code == 200 && ret?.data?.length > 0) {
        ret?.data.forEach(element => {
          output.push({num:element?.smrtnum,value:element?.cutpanvalue});
      });
        dispatch(setDoublePanList(output));        
      }
     } catch (error) {
      dispatch(
        setAlertData({
          msg: "Something went wrong! Please try again",
          code: 100,
          open: true,
        })
      );
     }     
  }
}