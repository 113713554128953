import { createSlice } from "@reduxjs/toolkit";
import { setConfirmDialogData, setRetAnswer } from "./confirmDialogReducer";
import {
  callAjax,
  formatDatetime_DMY,
  formatDatetime_YMD,
  getMonthName,
} from "../../MIS/Global";
import { setAlertData } from "./alertReducer";

const holidayReducer = createSlice({
  name: "holiday",
  initialState: {
    holiday: [],
    holidayList: [],
    delholiday:{},
  },

  reducers: {
    setHoliday(state, action) {
      state.holiday = {
        ...state.holiday,
        [action.payload.name]: action.payload.value,
      };
    },
    clearHoliday(state, action) {
      state.holiday = action.payload;
    },
    setHolidayList(state, action) {
      state.holidayList = action.payload;
    },
    setDelholiday(state, action) {
        state.delholiday = {
          ...state.delholiday,
          [action.payload.name]: action.payload.value,
        };
      },
  },
});

export const { setHoliday, clearHoliday, setHolidayList,setDelholiday } =
  holidayReducer.actions;
export default holidayReducer.reducer;

export const actionConfirm = (data) => {
  return function actionData(dispatch) {
    if (data === "save") {
      dispatch(
        setConfirmDialogData({
          type: data,
          heading: "Confirm",
          msg: "Do you want to save this record?",
          yeslbl: "Yes",
          nolbl: "No",
          openclose: true,
        })
      );
    }else if(data === "del"){
     dispatch(
        setConfirmDialogData({
            type: data,
            heading: "Confirm",
            msg: "Do you want to delete this record?",
            yeslbl: "Yes",
            nolbl: "No",
            openclose: true,
        })
        );
    }
  };
};
export const handleHolidayChange = (e, name) => {
  return function actionData(dispatch) {
    if (name == "hdate") {
      dispatch(setHoliday({ name: "hdate", value: formatDatetime_YMD(e, 0) }));
      dispatch(
        setHoliday({
          name: "selectdate",
          value: formatDatetime_DMY(e, 0, "mmm"),
        })
      );
    } else if (name == "monthname") {
      dispatch(setHoliday({ name: "monthname", value: getMonthName(e, 0) }));
    } else if (name == "mindate") {
      dispatch(
        setHoliday({
          name: "mindate",
          value: formatDatetime_YMD(new Date(), 1),
        })
      );
    } else if (e && e.target) {
      dispatch(setHoliday({ name: e.target.name, value: e.target.value }));
    }
  };
};
export const saveHoliday = (data) => {
  let curDate = formatDatetime_YMD(new Date(), 0,"ymd");
  let hdate = formatDatetime_YMD(data?.hdate, 0,"ymd");

  return async function actionData(dispatch) {
    try {
      if (!data?.hdate) {
        dispatch(
          setAlertData({ msg: "Please select date", code: 100, open: true })
        );
      } else if (hdate < curDate) {
        dispatch(
          setAlertData({
            msg: "You should be select grater than today's date",
            code: 100,
            open: true,
          })
        );
      } else {
        let ret = await callAjax("saveholiday", data);
        if (ret.code == 200 && ret.data?.[0]?.dt > 0) {
          dispatch(
            setAlertData({
              msg: "Holiday save successfully",
              code: 200,
              open: true,
            })
          );
          dispatch(getHoliday({monthname:data?.monthname}))

          dispatch(setHoliday({ name: "remark", value: "" }));
        } else if (ret.code == 200 && ret.data?.[0]?.dt <= -1) {
          dispatch(
            setAlertData({
              msg: "There is already a holiday scheduled for the selected date.",
              code: 100,
              open: true,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        setAlertData({
          msg: "Something went wrong! Please try again",
          code: 100,
          open: true,
        })
      );
    }
    dispatch(setRetAnswer({ resans: false }));
  };
};

export const getHoliday = (data) => {
  return async function actionData(dispatch) {
    try {
      let ret = await callAjax("getholiday", data);
      if (ret.code == 200) {
        dispatch(setHolidayList(ret?.data));
      }
    } catch (error) {
      dispatch(
        setAlertData({
          msg: "Something went wrong! Please try again",
          code: 100,
          open: true,
        })
      );
    }
  };
};

export const delteHoliday = (data) => {
  return async function actionData(dispatch) {
    try {
      let ret = await callAjax("delholiday", data);
      if (ret.code == 200) {
        dispatch(
            setAlertData({
              msg: "Holiday deleted successfully.",
              code: 200,
              open: true,
            })
          );
          dispatch(getHoliday({monthname:data?.monthname}))
      }
    } catch (error) {
      dispatch(
        setAlertData({
          msg: "Something went wrong! Please try again",
          code: 100,
          open: true,
        })
      );
    }
    dispatch(setRetAnswer({ resans: false }));
  };
};

export const checkDate = (data) => {
  let curDate = formatDatetime_YMD(new Date(), 0,"ymd");
  let hdate = formatDatetime_YMD(data?.hdate, 0,"ymd");
  if (hdate <= curDate) {
    return "none";
  } else {
    return "";
  }
};
