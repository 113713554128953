import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import AdminPlaymaster from '../../Components/AdminPlayMaster/AdminPlaymaster'

const AdminPlayScreen = () => {
  return (
    <div>
        <Appbar/>
        <AdminPlaymaster/>
    </div>
  )
}

export default AdminPlayScreen