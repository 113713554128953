import React, { useContext, useEffect, useReducer, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css";
import { Checkbox, TableContainer, TextField } from "@mui/material";
import style from "../Components/RegisterUsersList/RegUserlist.module.css"
import { makeStyles } from "@material-ui/core";
import { handleNumInputChange } from "../MIS/Global";


const useStyles = makeStyles({
    noBorder: {
        border: "none !important",
        "& .MuiInput-underline:before": {
        // borderBottom: `${props.border=="none"?"none !important":""}`,
        borderBottom: "none !important",
        },
    },
    });

const CutpanmasterTable = (props) => {
   const classes = useStyles(); 

  return (
    <>
      <div>
        <Paper className="contner1">
          <TableContainer sx={{ maxHeight: "50vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableHead>
                <TableRow>

                  <TableCell style={{textAlign:"left"}}>Name</TableCell>
                  <TableCell style={{textAlign:"right"}}>CutPanValue</TableCell>

                </TableRow>
              </TableHead>

              <TableBody className="bodycon">
                {props?.data?.searchcutpanState?.map((element,index) => {
                  let bgcolor = "#F1F0E8";
                  if (index % 2 == 0) {
                    bgcolor = "#fff";
                  }
                  return (
                    <TableRow className="tblerowdata" 
                    style={{backgroundColor:bgcolor}}>
                      <TableCell style={{textAlign:"left"}}>
                        {element?.gname}
                      </TableCell>
                      <TableCell component="th" scope="row" style={{textAlign:"right"}}>
                      <TextField
                          size="small"
                          variant="standard"
                          className={classes.noBorder}
                          sx={{width:"100px"}}
                          value={element?.winprice}
                          onChange={(e)=>{ props?.data?.handleUpdatePrice(element,e.target.value)}}
                          InputProps={{
                            classes: {
                              input: style.textinp,
                            },
                            style: {
                              padding: "2px",
                            },
                            inputProps: {
                                onInput: handleNumInputChange,
                                readOnly:false,
                                maxLength: 8,
                              },
                          }}
                          InputLabelProps={{
                            className: style.textinplbl,
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )
                }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
};
export default CutpanmasterTable;
