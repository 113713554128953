import { Box, Grid, Modal, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./RegUserlist.module.css";
import GroupIcon from "@mui/icons-material/Group";
import SearchIcon from "@mui/icons-material/Search";
import RegisterUserListTable from "../../Tables/RegisterUserListTable";
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import UserTypeTextSuggest from "../../Textsuggestfield/UserTypeTextSuggest";
import useUserSigniupnmaster from "../../API/Hooks/useUserSigniupnmaster";
import CutpanmasterTable from "../../Tables/CutpanmasterTable";
import CloseIcon from "@mui/icons-material/Close";

const RegisterUsersList = () => {
  const userlist={...useUserSigniupnmaster()}
  
  return (
    <>
    <div className={style.userlistmain}>
        <Grid container spacing={2}>
        <Grid item xs={12} md={12} className={style.userlistpart1}>
            <div className={style.userheading}>Register User</div>
          </Grid>
          <Grid item xs={12} md={12} className={style.userlistpart2}>
            {/* <Paper elevation={2} className={style.listpaper}> */}
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={2}>
                      <div className={style.boxconstyle}>
                        <div className={style.boxtxtcon}>
                          <span className={style.usercounttxt}>{userlist?.usercountState[0]?.total_users}</span>
                          <span className={style.totalusertxt}>Total User</span>
                        </div>
                        <div>
                          <GroupIcon style={{ fontSize: "50px" }} />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <div className={style.activeboxconstyle}>
                        <div className={style.boxtxtcon}>
                          <span className={style.usercounttxt}>{userlist?.usercountState[0]?.active_users}</span>
                          <span className={style.totalusertxt}>Active</span>
                        </div>
                        <div>
                          <PersonIcon style={{ fontSize: "50px",color:"#009900" }} />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <div className={style.blockboxconstyle}>
                        <div className={style.boxtxtcon}>
                          <span className={style.usercounttxt}>{userlist?.usercountState[0]?.blocked_users}</span>
                          <span className={style.totalusertxt}>Block</span>
                        </div>
                        <div>
                          <PersonOffIcon style={{ fontSize: "45px" }} />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={2} xs={6}>
                 <UserTypeTextSuggest  data={userlist}/>
                </Grid>
                <Grid item xs={12} md={10} className={style.searchposition}>
                  <div className={style.searchcon}>
                    <SearchIcon sx={{ fontSize: "22px", cursor: "pointer" }} />
                    <input
                      type="text"
                      className={style.searchfield}
                      placeholder="Search"
                       onChange={(e) => {
                        userlist?.setUsearch(e.target.value)
                    }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                    <RegisterUserListTable data={userlist}/>
                </Grid>
              </Grid>
            {/* </Paper> */}
          </Grid>
        </Grid>
        <Modal
        open={userlist?.openmodalCutpan}
        onClose={userlist?.handlemodalCloseCutpan}        
      >
        <Box className={style.modaladd}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
             Cutpan Master
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                cursor: "pointer",
              }}
              onClick={() => {
                  userlist?.handlemodalCloseCutpan();
                }}
            >
              <CloseIcon/>
            </div>
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <div className={style.cutpansubhead}>
                  {userlist?.uname}
                </div>
            </Grid>
            <Grid item xs={12} md={12} className={style.txtposition}>
            <TextField
                id="outlined-disabled"
                label="Cutpan"
                // fullWidth
                size="small"
                InputProps={{
                  classes: {
                    input: style.textinp_left,
                  },
                  style: {
                    padding: "1px",
                  },
                }}
                InputLabelProps={{
                  className: style.textinplbl,
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
                <CutpanmasterTable />
            </Grid>
            <Grid item xs={12} md={12}>
            <div className={style.savebtncon}>
                <button type="button" className={style.savebtn}>
                  Save
                </button>
              </div>
            </Grid>
        </Grid>
        </Box>
      </Modal>
    </div>
    </>
  );
};

export default RegisterUsersList;
