import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css";
import {
  Checkbox,
  TableContainer,
  TableHead,
  TablePagination,
} from "@mui/material";

import style from "../../src/Components/AdminFundRequest/AdminFundRequestList.module.css";
import { styled } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { GetUserList } from "../API/Reducer/userReducer";
import { useEffect } from "react";
import { SetAdminPlayUser } from "../API/Reducer/playReducer";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

const AdminPlayTable = (props) => {
  const dispatch = useDispatch();
  const { userlist } = useSelector((state) => state.user);
  const [page, setPage] = useState(0);
  const [userorg, setUserOrg] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const rowsPerPageOptions = [
    25,
    50,
    100,
    125,
    150,
    200,
    300,
    500,
    1000,
    { value: userlist?.length, label: "All" },
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(+event.target.value, 10));
    setPage(0);
  };
  const setSelectedUser = (data, id) => {
    const updatedState = data?.map((item) => {
        if (item.loginid === id) {
          return {
            ...item,
            ischeck: true,
          };
        } else {
          return {
            ...item,
            ischeck: false,
          };
        }
      });
      const filteredItems = updatedState.filter(item => item.ischeck===true);
      dispatch(SetAdminPlayUser(filteredItems?.[0]))
      setUserOrg(updatedState)
    
  };
  useEffect(() => {
    dispatch(GetUserList({ urole: "user" }));
  }, []);
  useEffect(() => {
    setUserOrg(userlist)
  }, [userlist]);

  useEffect(() => {
    const keysToSearch = ["uname", "mob"];
    const filteredData = userlist?.filter((item) => {
      return keysToSearch?.some((key) => {
        return item[key]
          ?.toString()
          .toLowerCase()
          ?.includes(props?.data?.userSearch.toString().toLowerCase());
      });
    }); 
    setUserOrg(filteredData)
  }, [props?.data?.userSearch]);
  
  return (
    <>
      <div>
        <Paper className="">
          <TableContainer sx={{ maxHeight: "60vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableHead>
                <TableRow>
                <TableCell className={style.tblHead} style={{display:window?.location?.pathname==="/adminplay"?"none":""}}></TableCell>
                  <TableCell className={style.tblHead}>User Name</TableCell>
                  <TableCell className={style.tblHead}>Mobile</TableCell>
                  <TableCell className={style.tblHead} style={{textAlign:"right"}}>Balance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="bodycon">
                {userorg
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((element, index) => {
                    let bgcolor = "#F1F0E8";
                    if (index % 2 == 0) {
                      bgcolor = "#fff";
                    }
                    if(element?.ischeck===true){bgcolor="#b0bed9";}
                    return (
                      <TableRow
                        className="tblerowdata"
                        style={{ backgroundColor: bgcolor }}
                        onClick={()=>{setSelectedUser(userlist,element?.loginid)}}
                      >
                        <TableCell
                          style={{ textAlign: "center", width: "10%",display:window?.location?.pathname==="/adminplay"?"none":"" }}
                        >
                          <Checkbox
                            sx={{
                              "&:hover": { bgcolor: "transparent" },

                            }}
                            disableRipple
                            color="default"
                            checkedIcon={<BpCheckedIcon />}
                            icon={<BpIcon />}
                            inputProps={{ "aria-label": "Checkbox demo" }}
                            {...props}
                          />
                        </TableCell>
                        <TableCell>{element?.uname}</TableCell>
                        <TableCell>{element?.mob}</TableCell>
                        <TableCell style={{textAlign:"right"}}>{Number(element?.balance).toFixed(2)}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={userlist?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  );
};
export default AdminPlayTable;
