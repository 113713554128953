import React, { useEffect } from "react";
import { Grid, InputAdornment, Paper, TextField } from "@mui/material";

import style from "./AdminLogin.module.css";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import img from "../../Assets/logo2.png";
import {
  handleAlphanumericInputChange,
  handleNumInputChange,
} from "../../MIS/Global";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetAdminLogin, handleAdminLoginChange } from "../../API/Reducer/userReducer";
import AlertComp from "../AlertMessageCom/AlertComp";

const AdminLogin = () => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { adminLogin ,adminLoginStatus} = useSelector((state) => state.user);
  useEffect(() => {
      if(adminLoginStatus===true)
      navigate("/userlist");
  },[adminLoginStatus])
  
  return (
    <>
      <AlertComp/>
      <div className={style.pagecontainer}>
        <Grid container spacing={2} className={style.loginmain}>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} className={style.adminpaper}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} className={style.logocon}>
                  <div className={style.regimagecon}>
                    <img src={img} className={style.regimage} />
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    type="tel"
                    name="id"
                    inputMode="numeric"
                    label="Mobile Number"
                    fullWidth
                    size="small"
                    value={adminLogin?.id}
                    onChange={(e)=>{dispatch(handleAdminLoginChange(e,""))}}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneIphoneIcon
                            sx={{ fontSize: "22px", marginRight: "5px" }}
                          />
                        </InputAdornment>
                      ),
                      classes: {
                        input: style.textinp_left,
                      },
                      style: {
                        padding: "2px",
                      },
                      inputProps: {
                        onInput: handleNumInputChange,
                        maxLength: 10,
                      },
                    }}
                    InputLabelProps={{
                      className: style.textinplbl,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    name="paswd"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    fullWidth
                    size="small"
                    value={adminLogin?.paswd}
                    onChange={(e)=>{dispatch(handleAdminLoginChange(e,""))}}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockPersonIcon
                            sx={{ fontSize: "22px", marginRight: "5px" }}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            // onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      classes: {
                        input: style.textinp_left,
                      },
                      style: {
                        padding: "2px",
                      },
                      inputProps: {
                        // onInput: handleAlphanumericInputChange,
                        maxLength: 20,
                      },
                    }}
                    InputLabelProps={{
                      className: style.textinplbl,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} className={style.loginbtn_align}>
                  <div
                    className={style.loginbtncon}
                    onClick={() => {
                      // navigate("/userlist");
                       dispatch(GetAdminLogin(adminLogin));
                     }}
                  >
                    <button type="button" className={style.loginbtn}>
                      Login
                    </button>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AdminLogin;
