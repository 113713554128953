import React, { useContext, useEffect, useReducer, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css";
import { Checkbox, TableContainer, TextField } from "@mui/material";
import { styled } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";

const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const GameMasterTable = (props) => {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const rowsPerPageOptions = [
    10,
    25,
    50,
    100,
    { value:props?.typesearchState.length, label: 'All' },
  ]
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(+event.target.value, 10))
    setPage(0)
  }
  
  return (
    <>
    <div>
      <Paper className="contner">
        <TableContainer sx={{ maxHeight: "60vh" }}>
          <Table
          size="small"
            stickyHeader
            aria-label="sticky table"
            style={{
              fontSize: "100pt!important",
            }}
          >
            <TableHead sx={{ backgroundColor: "red" }}>
              <TableRow>
                
                <TableCell></TableCell>
                <TableCell>Game Name</TableCell>
                <TableCell>Winner Point</TableCell>
                <TableCell>Status </TableCell>
                <TableCell>Time </TableCell>
                <TableCell>Result</TableCell>
                <TableCell>Action </TableCell>
              </TableRow>
            </TableHead>

            <TableBody className="bodycon">
            {props?.typesearchState?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((element) => {
              return(
                <TableRow className="tblerowdata">
                  <TableCell>
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                      src="https://s3.envato.com/files/416434041/preview_screenshots/14_preview14.jpg"
                      alt=""
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                  {element?.gname}
                  </TableCell>
                  <TableCell>{element?.wnrpointcalc}</TableCell>
                  <TableCell>{element?.isstatus}</TableCell>
                  <TableCell>
                    <div>AM/PM</div>
                  </TableCell>
                  <TableCell>
                    <div>AM/PM</div>
                  </TableCell>
                  <TableCell>
                    <div className="actionbtn">
                      <Tooltip title="Edit" placement="top" arrow>
                        <div className="edde">
                          <EditIcon 
                          sx={{ fontSize: "20px" }} 
                          onClick
                          />
                        </div>
                      </Tooltip>
                      <Tooltip title="Delete" placement="top" arrow>
                        <div className="edde">
                          <DeleteIcon 
                          onClick={()=>{
                            let deldata={
                              gtmid:element?.gtmid
                            }
                            props?.Deletegtype(deldata)
                          }}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={props?.typesearchState?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
    </>
  );
};
export default GameMasterTable;
