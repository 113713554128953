import React, { useContext, useEffect, useReducer, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css";
import { Checkbox, TableContainer, TextField } from "@mui/material";
import { styled } from "@material-ui/core";


const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
  }));
  
  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  });


const CutpanConfigureTable = (props) => {
  const [userState, setUserState] = useState(props?.data?.userstate);

  useEffect(() => {
    // Update userState only if it has changed
    if (props?.data?.userstate !== userState) {
      setUserState(props?.data?.userstate);
    }
  }, [props?.data?.userstate, userState]);
  

  return (
    <>
      <div>
        <Paper className="contner1">
          <TableContainer sx={{ maxHeight: "60vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableHead>
                <TableRow>

                  <TableCell style={{textAlign:"center",width:"10%"}}>
                  <Checkbox
                    sx={{
                        '&:hover': { bgcolor: 'transparent' },
                    }}
                    disableRipple
                    color="default"
                    
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    inputProps={{ 'aria-label': 'Checkbox demo' }}
                    {...props}
                    onChange={(e) => {
                        props?.data?.handleCheck([],e.target.checked,1)
                    }}
                    />
                  </TableCell>
                  <TableCell style={{textAlign:"center",width:"45%",fontWeight:"bold"}}>Name</TableCell>
                  <TableCell style={{textAlign:"center",width:"45%",fontWeight:"bold"}}>Mobile Number</TableCell>

                </TableRow>
              </TableHead>

              <TableBody className="bodycon">
                {props?.data?.userstate.map((element,index) => {
                  let bgcolor = "#F1F0E8";
                  if(element?.isexist===1){
                    bgcolor = "#ffdcbe";
                  }
                  else if (index % 2 == 0) {
                    bgcolor = "#fff";
                  }

                  return (
                    <TableRow className="tblerowdata" 
                     onDoubleClick={(e)=>{props?.data?.handleCheck(element,true,2)}}
                    style={{backgroundColor:bgcolor}}
                    key={element?.loginid}>
                    <TableCell style={{textAlign:"center"}}>
                      <Checkbox
                        sx={{
                            '&:hover': { bgcolor: 'transparent' },
                        }}
                        disableRipple
                        color="default"
                        checked={element.ischeck}
                        checkedIcon={<BpCheckedIcon />}
                        icon={<BpIcon />}
                        inputProps={{ 'aria-label': 'Checkbox demo' }}
                        {...props}
                        onChange={(e)=>{
                            props?.data?.handleCheck(element, e.target.checked,0)
                          }}
                      />
                      </TableCell>
                      <TableCell style={{textAlign:"center"}}>
                      {element?.uname}
                      </TableCell>
                      <TableCell component="th" scope="row" style={{textAlign:"center"}}>
                      {element?.mob}
                      </TableCell>
                    </TableRow>
                  )
                }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
};
export default CutpanConfigureTable;
