import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import DrawResultMaster from '../../Components/DrawResultMaster/DrawResultMaster'

const DrawResultScreen = () => {
  return (
    <div>
        <Appbar />
        <DrawResultMaster />
    </div>
  )
}

export default DrawResultScreen