import { Box, Button, Grid, Modal, TextField,InputAdornment } from "@mui/material";
import React, { useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Paper, makeStyles } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import style from "./CutpanConfig.module.css";
import CutpanmasterTable from "../../Tables/CutpanmasterTable";
import CutpanConfigureTable from "../../Tables/CutpanConfigureTable";
import useCutpanconfigure from "../../API/Hooks/useCutpanconfigure";
import { handleDecimalInputChange, handleNumInputChange } from "../../MIS/Global";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import DialogBox from "../GlobalComponent/DialogBox";
import DoublePanConfigration from "../DoublePanConfigrution/DoublePanConfigration";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";

import { setAlertData } from "../../API/Reducer/alertReducer";
import AlertComp from "../AlertMessageCom/AlertComp";
import { getDoublePanUserList } from "../../API/Reducer/doublePanReducer";
import PercentIcon from '@mui/icons-material/Percent';

const useStyles = makeStyles((theme) => ({
  smallDateTimePicker: {
    "& .MuiInputBase-input": {
      fontSize: "13px",
      fontWeight: "600 !important",
      width: "100%",
      height: "100%",
      padding: "10px",
      margin: 0,
    },
    "& .MuiInputLabel-root": {
      fontSize: "16px",
      fontWeight: "600 !important", // Adjust font size as needed
      display: "flex",
      alignItems: "center",
    },
  },
}));

const CutpanConfigure = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cutpanconfig = { ...useCutpanconfigure() };
  const [open, setOpen] = React.useState(false);
  const [loginidlist, setLoginIdList] = React.useState([]);
  const { doublepanmodal} = useSelector((state) => state.doublepan);
  
  const handleOpen = () => {
    const loginIds = cutpanconfig?.userstate
  .filter(item => item.ischeck === true)
  .map(item => ({ loginid: item.loginid }));
    if(loginIds.length<=0){    
    dispatch(
      setAlertData({ msg: "You should be select at least one user.", code: 100, open: true })
    );
   }else{
    if(loginIds.length===1){
      dispatch(getDoublePanUserList({loginid:loginIds?.[0]?.loginid}));
    }
    setLoginIdList(loginIds)
    setOpen(true)
   }
  
  };
  const handleClose = () => setOpen(false);
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
 
 

  return (
    <div className={style.adminresultmain}>
      <AlertComp/>
      
       <AlertMessageComp
        type={cutpanconfig?.alertdata.type}
        msg={cutpanconfig?.alertdata.msg}
        vertical={cutpanconfig?.alertdata.vertical}
        horizontal={cutpanconfig?.alertdata.horizontal}
        setOpen={cutpanconfig?.setAlertopen}
        open={cutpanconfig?.alertopen}
      />
      <DialogBox
        data={{
          setValue: cutpanconfig?.handleConfirmChange,
          value: cutpanconfig?.value,
          heading: cutpanconfig?.cmdinfo?.heading,
          msg: cutpanconfig?.cmdinfo?.msg,
          nolbl: cutpanconfig?.cmdinfo?.nolbl,
          yeslbl: cutpanconfig?.cmdinfo?.yeslbl,
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <div className={style.playresulthead}>Cutpan Configure</div>
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          sx={{ display: "flex", justifyContent: "end" }}
        >
         <div style={{display:"flex", gap:"10px"}}>
       
           <Button
            type="button"
            color="secondary"
            onClick={(e) => {
              handleOpen();
            }}
            variant="contained"
          >
            Double Pan
          </Button>
          <Button
            type="button"
            sx={{ backgroundColor: "#358ab7", color: "#fff" }}
            onClick={(e) => {
              cutpanconfig?.confirmDilog("save");
            }}
            variant="contained"
          >
            Save
          </Button>
          </div>
        </Grid>
        <Grid item xs={12} md={5}>
          <Paper className={style.adresultpaper}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} className={style.txtposition}>
                <div
                  className={style.clearbtncon}
                  onClick={(e) => {
                    cutpanconfig?.confirmDilog("del");
                  }}
                >
                  <button type="button" className={style.savebtn}>
                    Delete
                  </button>
                </div>
              </Grid>
              <Grid item xs={12} md={9} className={style.searchcont}>
                <div className={style.searchcon}>
                  <SearchIcon sx={{ fontSize: "22px", cursor: "pointer" }} />
                  <input
                    type="text"
                    className={style.searchfield}
                    placeholder="Search"
                    onChange={(e) => {
                      cutpanconfig?.setUsearch(e.target.value);
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <CutpanConfigureTable data={cutpanconfig} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={7}>
          <Paper className={style.adresultpaper}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ display: "" }}>
                {/* <div className={style.cutpansubhead}> */}
                <h3>{cutpanconfig?.userdet}</h3>
                {/* </div>*/}
              </Grid>
              <Grid item xs={12} md={3} className={style.txtposition}>
              <TextField
                  id="outlined-disabled"
                  label="commission (%)"
                  // placeholder="Enter a commission in percent"
                  size="small"
                  value={cutpanconfig?.usercommission}
                  onChange={(e) => {
                    cutpanconfig?.setUserCommission(e.target.value)
                  }}
                  onBlur={(e)=>{
                      if(cutpanconfig?.usercommission=="" || cutpanconfig?.usercommission==undefined){
                       cutpanconfig?.setUserCommission(0);
                      }
                    }}
                  onInput={handleDecimalInputChange}
                  InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                      <PercentIcon  sx={{fontSize:"18px"}}/>
                    </InputAdornment>
                    ),
                    classes: {
                      input: style.textinp_left,
                    },
                    style: {
                      padding: "1px",
                      
                    },
                    inputProps: {
                      maxLength:5,
                    },
                  }}
                  InputLabelProps={{
                    className: style.textinplbl,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={9} className={style.txtposition}>
                <TextField
                  id="outlined-disabled"
                  label="Cutpan"
                  // fullWidth
                  size="small"
                  onChange={(e) => {
                    cutpanconfig?.cutPanValueConfig(e.target.value);
                  }}
                  onInput={handleNumInputChange}
                  InputProps={{
                    classes: {
                      input: style.textinp_left,
                    },
                    style: {
                      padding: "1px",
                    },
                  }}
                  InputLabelProps={{
                    className: style.textinplbl,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CutpanmasterTable data={cutpanconfig} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={styleModal}>
            <div style={{width:"100%",display:"flex",justifyContent:"end",cursor:"pointer"}}
            onClick={handleClose}
            > <CloseIcon/> </div>  
           <DoublePanConfigration data={{loginidlist:loginidlist,handleClose:handleClose}}/>
        </Box>
      </Modal>
    </div>
  );
};

export default CutpanConfigure;
