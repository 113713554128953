import { Grid, Paper, TextField } from "@mui/material";
import React from "react";
import style from "./Doublepan.module.css";
import SearchIcon from "@mui/icons-material/Search";
import DoubleCutpanTable from "../../Tables/DoubleCutpanTable";
import { useState } from "react";
import { handleNumInputChange } from "../../MIS/Global";
import { useEffect } from "react";
import { actionDoublePanConfirm, getDoublePanGameList, getDoublePanUserList, saveDoublePanGame, saveDoublePanUser } from "../../API/Reducer/doublePanReducer";
import { useDispatch, useSelector } from "react-redux";
import ReduxConfirmationDialog from "../ConfirmationDialog/ReduxConfirmationDialog";

const DoublePanConfigration = (props) => {
  const [numvalue, setNumValue] = useState("");
  const dispatch = useDispatch();
  const { doublepanlist} = useSelector((state) => state.doublepan);
  const { resans,type } = useSelector((state) => state.confirm);
 
  useEffect(() => {
    if (resans === true && type=="save") {      
      if(window.location.pathname==="/gamemaster"){
      dispatch(saveDoublePanGame(doublepanlist,[props?.data?.gameData]))
      }else{
      dispatch(saveDoublePanUser(doublepanlist,props?.data?.loginidlist))
      }
      setTimeout(() => {
        props?.data?.handleClose();
      }, 3000);
    }else if(resans === true && type=="del"){       
    }
  }, [resans]);
  useEffect(() => {
    if(window.location.pathname==="/gamemaster"){
      dispatch(getDoublePanGameList({gmid:props?.data?.gameData?.gmid}))
    }else{
      // alert(JSON.stringify(props?.data?.loginidlist.length))
      if(props?.data?.loginidlist.length===1){
        dispatch(getDoublePanUserList({loginid:props?.data?.loginidlist?.[0]?.loginid}))
      }
      // getDoublePanUserList
    }
  }, [props?.data?.gameData])
  
  
  return (
    <div>
    <ReduxConfirmationDialog/>
      <div className={style.adminresultmain}>
        <Grid container spacing={2}>
          <Grid item xs={9} md={10}>            
            <div className={style.playresulthead}>Double Cutpan Configure</div>
          </Grid>
          <Grid item xs={3} md={2} className={style.btnposition}>
            <div className={style.savebtncon} onClick={()=>{
              dispatch(actionDoublePanConfirm("save"))

              }}>
              <button type="button" className={style.savebtn}>
                Save
              </button>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper className={style.adresultpaper}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sx={{display:"flex",justifyContent:"end"}}>                        
                  <TextField
                    type="text"                    
                    inputMode="numeric"
                    label="Price"                     
                    size="small"
                    variant="outlined"
                    value={numvalue}
                    onChange={(e) => {setNumValue(e.target.value);}}
                    InputProps={{
                      classes: {
                        input: style.textinp_left,
                      },
                      style: {
                        padding: "1px",
                      },
                      inputProps: {
                        maxLength: 4,
                      },
                    }}
                    InputLabelProps={{
                      className: style.textinplbl,
                    }}
                    onInput={handleNumInputChange}
                  />
                  
                </Grid>
                <Grid item xs={12} md={12}>
                  <DoubleCutpanTable data={{numall:numvalue}}/>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default DoublePanConfigration;
