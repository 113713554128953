import React, { useEffect, useState } from 'react'
import style from "./AdminPL.module.css"
import { Box, Button, CircularProgress, Grid, Paper, TextField } from '@mui/material'
import { makeStyles } from '@material-ui/core';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import SearchIcon from "@mui/icons-material/Search";
import PLstatementTable from '../../Tables/PLstatementTable';
import GlobalTextsuggest from '../../Textsuggestfield/GlobalTextsuggest';
import ReduxConfirmationDialog from '../ConfirmationDialog/ReduxConfirmationDialog';
import AlertComp from '../AlertMessageCom/AlertComp';
import { useDispatch, useSelector } from 'react-redux';
import { FilterAcStamentData, GetAcStatmentList, GetUserList } from '../../API/Reducer/userReducer';
import { formatDatetime_DMY, formatDatetime_YMD, getHourTime, isSmallScreen } from '../../MIS/Global';
import MobileMenu from '../MobileMenu/MobileMenu';
import html2pdf from 'html2pdf.js';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { setAlertData } from '../../API/Reducer/alertReducer';


const useStyles = makeStyles((theme) => ({
    smallDateTimePicker: {
      "& .MuiInputBase-input": {
        fontSize: "13px",
        fontWeight: "600 !important",
        width: "100%",
        height: "100%",
        padding: "10px",
        margin: 0,
      },
      "& .MuiInputLabel-root": {
        fontSize: "16px",
        fontWeight: "600 !important", // Adjust font size as needed
        display: "flex",
        alignItems: "center",
      },
    },
  }));

const AdminPLstatement_1 = () => {
    const classes = useStyles();
    const [useroptions, setUserOptions] = useState([])
    const [whrdata, setWhrData] = useState({loginid:0,uname:"",fdate:new Date(),tdate:new Date()})
    const dispatch = useDispatch();
    const { userlist,useraclist,useropening,usersumdrcr,useraclistorg } = useSelector((state) => state.user);
    const { resans,type } = useSelector((state) => state.confirm);
    const [loading, setLoading] = useState(false);
    
    const handleUserChange=(data)=>{
        setWhrData({...whrdata,loginid:data?.id,uname:data?.label});
    }
    useEffect(() => {
       if(userlist.length<=0 ){            
            dispatch(GetUserList({}));
        }else if(userlist.length>=0){
           const res= userlist.map(element => {
                return {
                    id:element?.loginid,
                    label:`${element?.uname} - ${element?.mob}`
                }
            });    
            setUserOptions(res);
        }       
    }, [userlist])
    useEffect(() => {
      if(window.location.pathname==="/userpl"){
       setWhrData({...whrdata,loginid:localStorage.getItem("loginId")});
        dispatch(GetAcStatmentList({...whrdata,fdate:formatDatetime_YMD(whrdata?.fdate,0,""),
      tdate:formatDatetime_YMD(whrdata?.tdate,1,""),loginid:localStorage.getItem("loginId")}));
      }
              
    }, [])
    const handleSearchButton=(data)=>{
      dispatch(GetAcStatmentList({...whrdata,fdate:formatDatetime_YMD(whrdata?.fdate,0,""),
      tdate:formatDatetime_YMD(whrdata?.tdate,1,"")}));
      
  }
  const generatePdf = () => {
    setLoading(true);
    const element = document.getElementById('acreport');
    const options = {
      margin: [10, 10, 15, 10], // [top, right, bottom, left],
      filename: `${formatDatetime_DMY(new Date(),0,"")} ${getHourTime(new Date(),12)}_AccountStatement.pdf`, // Specify custom file name
      pagebreak: { mode: ['avoid-all'] } 
    };
  
    html2pdf()
      .set(options)
      .from(element)
      .save()
      .then(() => {setLoading(false); dispatch(
        setAlertData({
          msg: "PDF genrated successfully",
          code: 200,
          open: true,
        })
      );})
      .catch(error => {
        setLoading(false);        
        dispatch(
          setAlertData({
            msg: "Something went wrong! Please try again",
            code: 100,
            open: true,
          })
        );
      });
  };

  return (
    <div className={style.adminresultmain}>    
     <Box sx={{ display:loading===true?'flex':'none', justifyContent: 'center', alignItems: 'center',zIndex:99,
      position: 'relative', top:"50%"}}>
      <CircularProgress disableShrink/>
    </Box>
      <ReduxConfirmationDialog />
      <AlertComp />
        <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <div className={style.plresulthead}>Profit/Loss Statement</div>
        </Grid> 
        <Grid item xs={12} md={3} sx={{display:isSmallScreen()===true?"none":""}}>
          <GlobalTextsuggest data={{labelNm:whrdata?.uname,options:useroptions,label:"User",handleChange:handleUserChange}}/>
        </Grid> 
        <Grid item xs={6} md={1.5}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className={classes.smallDateTimePicker}
              label="From Date"
              name="playdate"
              value={dayjs(whrdata?.fdate)}
              onChange={(e)=>{setWhrData({...whrdata,fdate:e});}}
              renderInput={(props) => (
                <TextField {...props} variant="standard" />
              )}
              openTo="day"
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} md={1.5}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className={classes.smallDateTimePicker}
              label="To Date"
              name="playdate"
              value={dayjs(whrdata?.tdate)}
              onChange={(e)=>{setWhrData({...whrdata,tdate:e});}}
              renderInput={(props) => (
                <TextField {...props} variant="standard" />
              )}
              openTo="day"
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={1} sx={{display:"flex",justifyContent:"end"}}>
            <Button type="button" size="small" variant='contained' color="secondary"
             onClick={(e)=>{handleSearchButton()}}
             ><SearchIcon/></Button>    
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper className={style.adresultpaper}>
            <Grid container spacing={1}>
             
              <Grid container item xs={12} md={12} sx={{display:"flex",justifyContent:"flex-end"}}>
              <Grid item xs={6} md={9} sx={{display:"flex",justifyContent:"flex-start"}}>
              <Button type="button" size="small" variant='outlined' color="info"
               onClick={(e)=>{generatePdf()}}
              ><PictureAsPdfIcon/></Button>  
              </Grid>
              <Grid item xs={6} md={3} >
                <div className={style.searchcon}>
                  <SearchIcon sx={{ fontSize: "22px", cursor: "pointer" }} />
                  <input
                    type="text"
                    className={style.searchfield}
                    placeholder="Search"
                    onChange={(e)=>{dispatch(FilterAcStamentData(useraclistorg,e.target.value))}}
                  />
                </div>
              </Grid>
                
              </Grid>              
              <Grid item xs={12} md={12}>
                <PLstatementTable data={useraclist} useropening={useropening} userdrcr={usersumdrcr} fdate={whrdata?.fdate} tdate={whrdata?.tdate}/>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <div style={{display:window.location.pathname==="/adminpl"?"none":""}}>        
         <MobileMenu/>
        </div>         
        </Grid>
    </div>
  )
}

export default AdminPLstatement_1