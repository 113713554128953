import React from 'react'
import style from "./AdminPlaymaster.module.css"
import { Grid, TextField } from '@mui/material'
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { makeStyles } from "@material-ui/core";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import ALedgerNameTextsuggest from '../../Textsuggestfield/ALedgerNameTextsuggest';
import SearchIcon from "@mui/icons-material/Search";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AdminPlayReportTable from '../../Tables/AdminPlayReportTable';
import APlayReportTextsuggest from '../../Textsuggestfield/APlayReportTextsuggest';


const useStyles = makeStyles((theme) => ({
    smallDateTimePicker: {
      "& .MuiInputBase-input": {
        fontSize: "14px",
        fontWeight: "600 !important",
        width: "100%",
        height: "100%",
        padding: "10px",
        margin: 0,
      },
      "& .MuiInputLabel-root": {
        fontSize: "16px",
        fontWeight: "600 !important", // Adjust font size as needed
        display: "flex",
        alignItems: "center",
      },
    },
  }));
const AdminPlayMasterReport = () => {

    const classes = useStyles();
  return (
    <div className={style.adminreportmain}>
    <div className={style.adminreport1}>
     <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
            <div className={style.drawheading}>
                Play Master Report
            </div>
        </Grid>
        <Grid item xs={6} md={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                className={classes.smallDateTimePicker}
                label="From Date"
                    renderInput={(props) => (
                        <TextField {...props} variant="standard" />
                    )}
                    value={dayjs()} 
                    openTo="day" 
                    format="DD/MM/YYYY"
                />
            </LocalizationProvider>
        </Grid>
        <Grid item xs={6} md={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                className={classes.smallDateTimePicker}
                label="To Date"
                    renderInput={(props) => (
                        <TextField {...props} variant="standard" />
                    )}
                    value={dayjs()} 
                    openTo="day" 
                    format="DD/MM/YYYY"
                />
            </LocalizationProvider>
        </Grid>
        <Grid item xs={6} md={4}>
            <APlayReportTextsuggest />
        </Grid>
        <Grid item xs={2} md={2} className={style.btnposition}>
        <div className={style.savebtncon}>
                <button type="button" className={style.savebtn}>
                 <SearchIcon /> Search
                </button>
              </div>
        </Grid>
        <Grid item xs={2} md={2} className={style.btnposition}>
        <div className={style.exportbtncon}>
                <button type="button" className={style.savebtn}>
                 <FileDownloadIcon /> Export
                </button>
              </div>
        </Grid>
     </Grid>
    </div>
    <div className={style.adminreport2}>
    <Grid container spacing={2}>
        <Grid item xs={12} md={12} className={style.searchcont}>
        <div className={style.searchcon}>
                <SearchIcon sx={{ fontSize: "22px", cursor: "pointer" }} />
                <input
                  type="text"
                  className={style.searchfield}
                  placeholder="Search"
                />
              </div>
        </Grid>
        <Grid item xs={12} md={12}>
            <AdminPlayReportTable />
        </Grid>
    </Grid>
    </div>
  </div>
  )
}

export default AdminPlayMasterReport