 
import { callAjax } from "../../MIS/Global";

export const Saveplaymaster = async (data) => {
  let ret = {};
  let url = "playsave";
  if (data.playid > 0) {
    url = "playupdate";
  }
  ret = await callAjax(url,data);
  return ret;
};

export const GetPlaymaster = async (data) => {
  let ret = {};

  let url = "getplaydata";

  ret = await callAjax(url,data);
  return ret;
};

export const Deleteplaymaster = async (data) => {
  let ret = {};
  let url = "delplay";
  ret = await callAjax(url,data);
  return ret;
};

export const SaveAllPlaymaster = async (data) => {
  let ret = {};
 if(Object.keys(data).length <= 0){
  return {msg:"At least one play is required",code:"100"}
 }
  let url = "playbulksave";

  ret = await callAjax(url,data);
  return ret;
};

export const SearchAllPlaydata = async (data) => {
  let ret = {};
  ret = await callAjax("playsearchalldata", data);
  return ret;
};

export const GetPlayData = async (data) => {
  let ret = {};
  ret = await callAjax("playdata", data);
  return ret;
};

 