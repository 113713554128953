import React from "react";
import style from "./AdminFundRequestList.module.css";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { Button, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useAdminFundReqmaster from "../../API/Hooks/useAdminFundReqMaster";
import AdminFundRequestTable from "../../Tables/AdminFundRequestTable";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";

const AdminFundRequestList = () => {
  const useStyles = makeStyles((theme) => ({
    smallDateTimePicker: {
      "& .MuiInputBase-input": {
        fontSize: "14px",
        fontWeight: "600 !important",
        width: "100%",
        height: "100%",
        padding: "10px",
        margin: 0,
      },
      "& .MuiInputLabel-root": {
        fontSize: "16px",
        fontWeight: "600 !important", // Adjust font size as needed
        display: "flex",
        alignItems: "center",
      },
    },
  }));
  const classes = useStyles();
  const fundrequest = { ...useAdminFundReqmaster() };

  return (
    <>
     <AlertMessageComp
        type={fundrequest?.alertdata.type}
        msg={fundrequest?.alertdata.msg}
        vertical={fundrequest?.alertdata.vertical}
        horizontal={fundrequest?.alertdata.horizontal}
        setOpen={fundrequest?.setAlertopen}
        open={fundrequest?.alertopen}
      />
      <div className={style.fundreqcontainer}>
        <div className={style.fundreq1}>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <span className={style?.fundhead}>Fund Request List</span>
            </Grid>
          </Grid>
        </div>
        <div className={style.fundreq1}>
          <Grid container spacing={1}>
            <Grid item md={1.5} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className={classes.smallDateTimePicker}
                  label="From Date"
                  value={dayjs(`${fundrequest?.ftdate?.fdate}`)}
                  onChange={(e) => {
                    fundrequest?.handleFTdate("fdate", e);
                  }}
                  renderInput={(props) => (
                    <TextField {...props} variant="standard" />
                  )}
                  format="DD/MM/YYYY" // Specify the desired format for date and time
                  renderTime={(props) => (
                    <TextField
                      {...props}
                      variant="outlined"
                      helperText={null}
                    />
                  )} // Customize the time input rendering
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={1.5} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className={classes.smallDateTimePicker}
                  label="To Date"
                  value={dayjs(`${fundrequest?.ftdate?.tdate}`)}
                  onChange={(e) => {
                    fundrequest?.handleFTdate("tdate", e);
                  }}
                  renderInput={(props) => (
                    <TextField {...props} variant="standard" />
                  )}
                  format="DD/MM/YYYY" // Specify the desired format for date and time
                  renderTime={(props) => (
                    <TextField
                      {...props}
                      variant="outlined"
                      helperText={null}
                    />
                  )} // Customize the time input rendering
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={1.5} xs={12}>
              <Button variant="contained" size="small" type="button" onClick={()=>{fundrequest?.fetchufundData({fdate:fundrequest?.ftdate?.fdate,tdate:fundrequest?.ftdate?.tdate})}}>
                <SearchIcon /> Search
              </Button>
            </Grid>
            <Grid item md={7.5} xs={12} className={style?.searchcont}>
              <div className={style.searchcon}>
                <SearchIcon sx={{ fontSize: "22px", cursor: "pointer" }} />
                <input
                  type="text"
                  className={style.searchfield}
                  placeholder="Search"
                    onChange={(e) => {
                      fundrequest?.setUsearch(e.target.value);
                    }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={style.fundreq2}>
        <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <AdminFundRequestTable data={fundrequest}/>          
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default AdminFundRequestList;
