import { Box, Grid } from '@mui/material'
import React from 'react'
import style from "./DrawResult.module.css"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import ResultTabsTable from '../../Tables/ResultTabsTable';
 


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

const DrawResultTabs = (props) => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
      };
  return (
    <div className={style.resultmain}>
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
            <Box sx={{ maxWidth: { xs: "100%", sm: "100%"}, bgcolor: 'whitesmoke' }}>
                <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
            
        >
            <Tab label="Open" onClick={(e)=>{props?.data?.call_getPlaySumPoints(1)}}/>
            <Tab label="Close" onClick={(e)=>{props?.data?.call_getPlaySumPoints(2)}}/>
            <Tab label="Jodi" onClick={(e)=>{props?.data?.call_getPlaySumPoints(3)}}/>
            <Tab label="Open Panna" onClick={(e)=>{props?.data?.call_getPlaySumPoints(4)}}/>
            <Tab label="Close Panna" onClick={(e)=>{props?.data?.call_getPlaySumPoints(5)}}/>
            <Tab label="Gunule" onClick={(e)=>{props?.data?.call_getPlaySumPoints(6)}}/>
            <Tab label="Ulta Gunule" onClick={(e)=>{props?.data?.call_getPlaySumPoints(8)}}/>
            <Tab label="Jackpot" onClick={(e)=>{props?.data?.call_getPlaySumPoints(7)}}/>
            </Tabs>
            </Box>
            <Box>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <ResultTabsTable data={{name:'open',tbldata:props?.data?.tbldata,tbldatasum:props?.data?.tbldatasum}} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <ResultTabsTable data={{name:'close',tbldata:props?.data?.tbldata,tbldatasum:props?.data?.tbldatasum}} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <ResultTabsTable data={{name:'jodi',tbldata:props?.data?.tbldata,tbldatasum:props?.data?.tbldatasum}} />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <ResultTabsTable data={{name:'openp',tbldata:props?.data?.tbldata,tbldatasum:props?.data?.tbldatasum}}/>
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <ResultTabsTable data={{name:'closep',tbldata:props?.data?.tbldata,tbldatasum:props?.data?.tbldatasum}}/>
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
          <ResultTabsTable data={{name:'gunule',tbldata:props?.data?.tbldata,tbldatasum:props?.data?.tbldatasum}}/>
        </TabPanel>
        <TabPanel value={value} index={6} dir={theme.direction}>
          <ResultTabsTable data={{name:'ultagunule',tbldata:props?.data?.tbldata,tbldatasum:props?.data?.tbldatasum}}/>
        </TabPanel>
        <TabPanel value={value} index={7} dir={theme.direction}>
          <ResultTabsTable data={{name:'jackpot',tbldata:props?.data?.tbldata,tbldatasum:props?.data?.tbldatasum}}/>
        </TabPanel>
     </SwipeableViews>
      </Box>
            </Grid>
        </Grid>
    </div>
  )
}

export default DrawResultTabs