import { useEffect, useReducer, useState, React } from "react";
import TypemasterReducer from "../Reducer/TypemasterReducer";
import AlertMessageComp from "../../Components/AlertMessageCom/AlertMessageComp";
import {
  DeleteGtypemaster,
  GetGtypemaster,
  SaveGtypemaster,
} from "../Model/gtypemasterModel";
import { tostmsg } from "../../MIS/Global";

function useGtypemaster() {
  const [alertdata, setAlertdata] = useState({});
  const [alertopen, setAlertopen] = useState(false);

  const [typeState, typeDispach] = useReducer(TypemasterReducer, []);
  const [typesearchState, typesearchDispach] = useReducer(
    TypemasterReducer,
    []
  );
  const [status, setStatus] = useState("Active");

  const handletype = (e, value) => {
    // alert(e)
    let values = "";
    let name = "";
    if (e == "isstatus") {
      values = value;
      name = e;
    } else {
      values = e.target.value;
      name = e.target.name;
    }
    typeDispach({
      type: "Change",
      payload: { name: name, value: values },
    });
  };
  useEffect(() => {
    handletype("isstatus", status);
  }, [status]);

  const hndleUpdatePrice = (e,gtmid) => {
    typesearchDispach({
      type: "updateganeprice",
      value: e.target.value,
      gtmid: gtmid,
    });
  };

  const Savegtype = async () => {
    try {
      let data = { ...typeState, gimg: "", cmpid: 0 };

      let res = await SaveGtypemaster(data);

      let rettostmsg = tostmsg(res);
      alert(JSON.stringify(res));
      if (res.code == 200) {
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      } else {
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      }
    } catch (e) {
      setAlertdata({ code: 100, msg: "Frontend failed please try again" });
      setAlertopen(true);
    }
  };
  const Deletegtype = async () => {
    try {
      const typedelete = await DeleteGtypemaster();
      let rettostmsg = tostmsg(typedelete);
      if (typedelete.code == 200) {
        // alert(JSON.stringify(typedelete.code));
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      }
      window.location.reload();
    } catch (e) {
      setAlertdata({ code: 100, msg: "Frontend failed please try again" });
      setAlertopen(true);
    }
  };

  const GetGtypemasterdata = async () => {
    try {
      let getType = await GetGtypemaster();
      if (getType.code === 200) {
        // alert(JSON.stringify(getType));
        typesearchDispach({
          type: "gtypeinfoDetails",
          data: getType.data,
        });
      }
    } catch (error) {
      // Handle any errors here
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };

  useEffect(() => {
    GetGtypemasterdata();
  }, []);

  return {
    handletype,
    status,
    setStatus,
    Savegtype,
    alertdata,
    alertopen,
    setAlertopen,
    typesearchState,
    Deletegtype,
    hndleUpdatePrice,
  };
}

export default useGtypemaster;
