import React from "react";
 
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { formatDatetime_DMY, getHourTime } from "../../MIS/Global";

const UserAcStatmentRpt = (props) => {
  const fSize = "12px"; 

  return (
    <>
      <div
        id="acreport"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span style={{ fontWeight: "bold", fontSize: "20px" }}>
            Account Statement
          </span>
          <span style={{fontSize:"14px",fontWeight:"bold"}}>{formatDatetime_DMY(props?.fdate,0,"")} To {formatDatetime_DMY(props?.tdate,0,"")}</span>
        </div>

        <div>
          <Table
            id="content"
            size="small"
            stickyHeader
            aria-label="sticky table"
            style={{
              fontSize: "100pt!important",
              padding:"5px"
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell width="20%" style={{fontWeight:"bold"}}>Date</TableCell>
                <TableCell width="40%" style={{fontWeight:"bold"}}>Particular</TableCell>
                <TableCell width="20%" align="right" style={{fontWeight:"bold"}}>
                  Debit
                </TableCell>
                <TableCell width="20%" align="right" style={{fontWeight:"bold"}}>
                  Credit
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="20%" style={{fontWeight:"bold",padding:"2px"}}></TableCell>
                <TableCell width="40%" align="right" style={{fontWeight:"bold",padding:"2px",fontSize:fSize}}>Opening</TableCell>
                <TableCell width="20%" align="right" style={{fontWeight:"bold",padding:"2px",fontSize:fSize}}>
                    {props?.useropening?.[0]?.opening < 0 ? Number(props?.useropening?.[0]?.opening).toFixed(2):""}
                </TableCell>
                <TableCell width="20%" align="right" style={{fontWeight:"bold",padding:"2px",fontSize:fSize}}>
                 {props?.useropening?.[0]?.opening > 0 ? Number(props?.useropening?.[0]?.opening).toFixed(2):""}
                </TableCell>
 
              </TableRow>
            </TableHead>
            <TableBody>
              {props?.data?.map((element, index) => {
                return (
                  <>
                    <TableRow>
                      <TableCell width="20%" style={{fontSize:fSize,padding:"2px"}}>
                        {formatDatetime_DMY(element?.edate,0,"")}&nbsp;&nbsp;{getHourTime(element?.edate,12)}
                      </TableCell>
                      <TableCell width="40%" style={{padding:"2px"}}>
                        <div
                          style={{ display: "flex", flexDirection: "column", fontSize:fSize}}
                        >
                          <span style={{display:window.location.pathname==="/userpl"?"none":""}}>{element?.uname}</span>
                          <span>{element?.remark}</span>
                        </div>
                      </TableCell>
                      <TableCell width="20%" align="right" style={{fontSize:fSize,padding:"2px"}}>
                        {Number(element?.dr).toFixed(2)}
                      </TableCell>
                      <TableCell width="20%" align="right" style={{fontSize:fSize,padding:"2px"}}>
                      {Number(element?.cr).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
            <TableRow>
                 <TableCell width="20%" style={{fontWeight:"bold",padding:"2px"}}></TableCell>
                <TableCell width="40%" align="right" style={{fontWeight:"bold",padding:"2px",fontSize:fSize}}></TableCell>
                <TableCell width="20%" align="right" style={{fontWeight:"bold",padding:"2px",fontSize:fSize}}>
                 {Number(props?.cldata?.dr).toFixed(2)}
                </TableCell>
                <TableCell width="20%" align="right" style={{fontWeight:"bold",padding:"2px",fontSize:fSize}}>
                  {Number(props?.cldata?.cr).toFixed(2)}
                </TableCell>
            
                </TableRow>
                <TableRow>
                <TableCell width="20%" style={{fontWeight:"bold",padding:"2px"}}></TableCell>
                <TableCell width="40%" align="right" style={{fontWeight:"bold",padding:"2px",fontSize:fSize}}>Closing</TableCell>
                <TableCell width="20%" align="right" style={{fontWeight:"bold",padding:"2px",fontSize:fSize}}>
                 {Number(props?.cldata?.drcl).toFixed(2)}
                </TableCell>
                <TableCell width="20%" align="right" style={{fontWeight:"bold",padding:"2px",fontSize:fSize}}>
                  {Number(props?.cldata?.crcl).toFixed(2)}
                </TableCell>
                  
                </TableRow>
          </Table>
        </div>
      </div>
    </>
  );
};

export default UserAcStatmentRpt;
