import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import AdminPlayList from '../../Components/AdminPlayMaster/AdminPlayList'

const AdminPlayEntryListScreen = () => {
  return (
    <div>
        <Appbar/>
        <AdminPlayList/>
    </div>
  )
}

export default AdminPlayEntryListScreen