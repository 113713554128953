import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TableContainer, TablePagination } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { GetUserList } from "../../API/Reducer/userReducer";
import style from "../../Components/AdminPlayMaster/AdminPlay.module.css";

const ForgotPasswordRequestList = () => {
  const dispatch = useDispatch();
  const { userlist } = useSelector((state) => state.user);
  const [page, setPage] = useState(0);
  const [userorg, setUserOrg] = useState([]);
  const [userSearch, setUserSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const rowsPerPageOptions = [
    25,
    50,
    100,
    125,
    150,
    200,
    300,
    500,
    1000,
    { value: userlist?.length, label: "All" },
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(+event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    dispatch(GetUserList({ urole: "user",otp:"1" }));
  }, []);
  useEffect(() => {
    setUserOrg(userlist);
  }, [userlist]);
  useEffect(() => {
    const keysToSearch = ["uname", "mob"];
    const filteredData = userlist?.filter((item) => {
      return keysToSearch?.some((key) => {
        return item[key]
          ?.toString()
          .toLowerCase()
          ?.includes(userSearch.toString().toLowerCase());
      });
    });
    setUserOrg(filteredData);
  }, [userSearch]);
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Grid item md={12} xs={12}>
          <h2>Forgot Password List</h2>
        </Grid>
        <Grid container item xs={12} md={8} className={style.searchcont}>
          <Grid item md={4} xs={12}>
            <div className={style.searchcon}>
              <SearchIcon sx={{ fontSize: "22px", cursor: "pointer" }} />
              <input
                onChange={(e) => {
                  setUserSearch(e.target.value);
                }}
                type="text"
                className={style.searchfield}
                placeholder="Search"
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={8} xs={12}>
          <Paper className="">
            <TableContainer sx={{ maxHeight: "60vh" }}>
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                style={{
                  fontSize: "100pt!important",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>User Name</TableCell>
                    <TableCell>Mobile</TableCell>
                    <TableCell style={{ textAlign: "right" }}>OTP</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="bodycon">
                  {userorg
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((element, index) => {
                      let bgcolor = "#F1F0E8";
                      if (index % 2 == 0) {
                        bgcolor = "#fff";
                      }
                      if (element?.ischeck === true) {
                        bgcolor = "#b0bed9";
                      }
                      return (
                        <TableRow
                          className="tblerowdata"
                          style={{ backgroundColor: bgcolor }}
                        >
                          <TableCell>{element?.uname}</TableCell>
                          <TableCell>{element?.mob}</TableCell>
                          <TableCell style={{ textAlign: "right" }}>
                            {element?.otp}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={userlist?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ForgotPasswordRequestList;
