import { Grid, InputAdornment, TextField } from '@mui/material'
import React from 'react'
import style from "./SignUp.module.css"
import PersonIcon from '@mui/icons-material/Person';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LockPersonIcon from "@mui/icons-material/LockPerson";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import img from "../../Assets/logo2.png"
import AlertMessageComp from '../AlertMessageCom/AlertMessageComp';
import { useNavigate } from 'react-router-dom';
import { handleAlphanumericInputChange, handleAlphanumericInputwithSpaceChange, handleNumInputChange } from '../../MIS/Global';
import useUserSigniupnmaster from '../../API/Hooks/useUserSigniupnmaster';


const SignUpPage = () => {

  const { handleuser, Saveuser, alertdata, alertopen, setAlertopen } = useUserSigniupnmaster();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const navigate = useNavigate();

  return (
    <>
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen} />
      <div className={style.pagecontainer}>
        <Grid container spacing={2} className={style.regmain}>
          <Grid item xs={12} md={12} className={style.logocon}>
            <div className={style.regimagecon}>
              <img src={img} className={style.regimage} />
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={style.registersty}>
              Register
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-disabled"
              label="User Name"
              fullWidth
              size="small"
              name='uname'
              onChange={handleuser}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon
                      sx={{ fontSize: "22px", marginRight: "5px" }}
                    />
                  </InputAdornment>
                ),
                classes: {
                  input: style.textinp_left,
                },
                style: {
                  padding: "2px",
                },
                inputProps: {
                  onInput: handleAlphanumericInputwithSpaceChange,
                  maxLength: 50,
                },
              }}
              InputLabelProps={{
                className: style.textinplbl,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              type="tel"
              id="phone"
              inputMode="numeric"
              label="Mobile Number"
              fullWidth
              size="small"
              name='mob'
              onChange={handleuser}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIphoneIcon
                      sx={{ fontSize: "22px", marginRight: "5px" }}
                    />
                  </InputAdornment>
                ),
                classes: {
                  input: style.textinp_left,
                },
                style: {
                  padding: "2px",
                },
                inputProps: {
                  onInput: handleNumInputChange,
                  maxLength: 10,
                },
              }}
              InputLabelProps={{
                className: style.textinplbl,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-adornment-password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              label="Password"
              fullWidth
              size="small"
              name='paswd'
              onChange={handleuser}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockPersonIcon
                      sx={{ fontSize: "22px", marginRight: "5px" }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    // onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                classes: {
                  input: style.textinp_left,
                },
                style: {
                  padding: "2px",
                },
                inputProps: {
                  onInput: handleAlphanumericInputChange,
                  maxLength: 20,
                },
              }}
              InputLabelProps={{
                className: style.textinplbl,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-adornment-password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              label="Confirm Password"
              fullWidth
              size="small"
              name='cpaswd'
              onChange={handleuser}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockPersonIcon
                      sx={{ fontSize: "22px", marginRight: "5px" }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    // onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                classes: {
                  input: style.textinp_left,
                },
                style: {
                  padding: "2px",
                },
                inputProps: {
                  onInput: handleAlphanumericInputChange,
                  maxLength: 20,
                },
              }}
              InputLabelProps={{
                className: style.textinplbl,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-disabled"
              label="Address"
              fullWidth
              size="small"
              name='address'
              onChange={handleuser}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOnIcon
                      sx={{ fontSize: "22px", marginRight: "5px" }}
                    />
                  </InputAdornment>
                ),
                classes: {
                  input: style.textinp_left,
                },
                style: {
                  padding: "2px",
                },
                inputProps: {
                  onInput: handleAlphanumericInputwithSpaceChange,
                  // maxLength: 50,
                },
              }}
              InputLabelProps={{
                className: style.textinplbl,
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} className={style.regbtn_align}>
            <div
              className={style.regbtncon}
              onClick={() => {
                Saveuser()
              }}
            >
              <button type="button" className={style.regbtn}>
                Register Now
              </button>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={style.regfootertxt}>
              Alrady have a account!<div className={style.footrtxt}
                onClick={() => {
                  navigate("/login")
                  // window.location.href="/login"
                }}
              >Login</div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default SignUpPage