import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import UserAcLedger from '../../Components/UserAcLedger/UserAcLedger'

const UserAcLedgerScreen = () => {
  return (
    <>
      <Appbar/>
      <UserAcLedger/>  
    </>
  )
}

export default UserAcLedgerScreen