import React, { useEffect, useState } from "react";
import MobileMenu from "../MobileMenu/MobileMenu";
import style from "./UserPlay.module.css";
import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import RecentcartMasterTable from "../../Tables/RecentcartMasterTable";
import CloseIcon from "@mui/icons-material/Close";
import PlaymasterTable from "../../Tables/PlaymasterTable";
import AddIcon from "@mui/icons-material/Add";
import usePlayMaster from "../../API/Hooks/usePlayMaster";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import UserPlaySlip from "./UserPlaySlip";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from "@material-ui/core";
import { handleNumInputChange } from "../../MIS/Global";


const StyledMenu = styled((props) => (
  <Menu
    elevation={1}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  // Your existing styling remains the same
}));
const disabledStyle = {
  opacity: 0.5,
  pointerEvents: 'none',
  backgroundColor: '#c0c0c0!important',
  color: '#fff',
};
const UserPlayMaster = () => {
  const playMaster = { ...usePlayMaster() };

  return (
    <>
      <AlertMessageComp
        type={playMaster?.alertdata.type}
        msg={playMaster?.alertdata.msg}
        vertical={playMaster?.alertdata.vertical}
        horizontal={playMaster?.alertdata.horizontal}
        setOpen={playMaster?.setAlertopen}
        open={playMaster?.alertopen}
        duration={playMaster?.alertdata?.duration}
      />
      <ConfirmationDialog cdg={playMaster.cdg} setCdg={playMaster.setCdg} />
      <div className={style.uplaycontainer}>
        <div className={style.uplaysec1}>
          <Grid container spacing={1} className={style.uplaysubconn}>
            <Grid item xs={7} md={6}>
              <div className={style.tableheading}>{playMaster?.paragname}</div>
            </Grid>
            <Grid item xs={5} md={6} sx={{display:"flex",justifyContent:"end"}}>
              <Button
                id="demo-customized-button"
                aria-controls={
                  playMaster?.open ? "demo-customized-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={playMaster?.open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={playMaster?.handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                // className={style.languagebtn}
              >
                {playMaster?.selectedOption}
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={playMaster?.anchorEl}
                open={playMaster?.open}
                onClose={playMaster?.handleClose}
              >
                <MenuItem
                  onClick={playMaster?.handleOptionClick("English")}
                  disableRipple
                >
                  English
                </MenuItem>
                <MenuItem
                  onClick={playMaster?.handleOptionClick("Marathi")}
                  disableRipple
                >
                  Marathi
                </MenuItem>
              </StyledMenu>
            </Grid>
            <Grid item xs={3} md={4}>
              <div
                style={playMaster?.opclCtrl?.op ? disabledStyle : {}}
                className={style.upaypaper}
                onClick={() => {
                  playMaster?.handlemodalOpen("Open");
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <div className={style.usercounttxt}>{playMaster?.translatedText.open}</div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={3} md={4}>
              <div
              style={playMaster?.opclCtrl?.cl ? disabledStyle : {}}
                className={style.upaypaper}
                onClick={() => {
                  playMaster?.handlemodalOpen("Close");
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <div className={style.usercounttxt}>{playMaster?.translatedText.close}</div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={3} md={4}>
              <div
                style={playMaster?.opclCtrl?.op ? disabledStyle : {}}
                className={style.upaypaper}
                onClick={() => {
                  playMaster?.handlemodalOpen("Jodi");
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <div className={style.usercounttxt}>{playMaster?.translatedText.jodi}</div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={3} md={4}>
              <div
                style={playMaster?.opclCtrl?.op ? disabledStyle : {}}
                className={style.upaypaper}
                onClick={() => {
                  playMaster?.handlemodalOpen("Gunule");
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <div className={style.usercounttxt}>{playMaster?.translatedText.gunule}</div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={4} md={4}>
              <div
                style={playMaster?.opclCtrl?.op ? disabledStyle : {}}
                className={style.upaypaper}
                onClick={() => {
                  playMaster?.handlemodalOpen("Open Panna");
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <div className={style.usercounttxt}>{playMaster?.translatedText.openPanna}</div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={4} md={4}>
              <div
                style={playMaster?.opclCtrl?.cl ? disabledStyle : {}}
                className={style.upaypaper}
                onClick={() => {
                  playMaster?.handlemodalOpen("Close Panna");
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <div className={style.usercounttxt}>{playMaster?.translatedText.closePanna}</div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={4} md={4}>
              <div
                style={playMaster?.opclCtrl?.op ? disabledStyle : {}}
                className={style.upaypaper}
                onClick={() => {
                  playMaster?.handlemodalOpen("Jackpot");
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <div className={style.usercounttxt}>{playMaster?.translatedText.jackpot}</div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={4} md={4}>
              <div
                style={playMaster?.opclCtrl?.op ? disabledStyle : {}}
                className={style.upaypaper}
                onClick={() => {
                  playMaster?.handlemodalOpen("Ulta Gunule");
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <div className={style.usercounttxt}>{playMaster?.translatedText.ultaGunule}</div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={9} md={6}>
              <div className={style.tableheading}>Recent Cart/Checkout</div>
            </Grid>
            <Grid
              item
              xs={3}
              md={6}
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <div
                className={style.savebtncon}
                onClick={() => {
                  playMaster?.PlayMasterDataSave();
                }}
              >
                <button type="button" className={style.addbtn}>
                  Save
                </button>
              </div>
              {/* <div id="printslip" style={{ display: "none" }}>
                <UserPlaySlip data={playMaster} />
              </div> */}
            </Grid>
          </Grid>
        </div>
        <div className={style.uplaysec2}>
          <Grid container spacing={2} className={style.tablemaincon}>
            <Grid item xs={12} md={12} className={style.tablesubcon1}>
              <RecentcartMasterTable data={playMaster} />
            </Grid>
            <Grid item xs={12} md={12} className={style.tablesubcon2}>
              <Paper className={style.totalconn}>
            <div className={style.collectiontbl}>
            <div className={style.collectiontotal}>Total</div>
            <div className={style.collectiontotal}>{playMaster?.playmasterstate?.reduce((acc, obj) => Number(acc) + Number(obj.points), 0).toFixed(2)}</div>
          </div>
          </Paper>
            </Grid>
          </Grid>
        </div>
        <div className={style.uplaysec3}>
          <MobileMenu />
        </div>
        <Modal
          open={playMaster?.openmodal}
          onClose={playMaster?.handlemodalClose}
        >
          <Box className={style.modaladd}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Play- {playMaster?.modalHeading}
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  cursor: "pointer",
                }}
              >
                <CloseIcon
                  onClick={() => {
                    playMaster?.handlemodalClose();
                  }}
                />
              </div>
            </Typography>
            <Grid container spacing={2} mt={1}>
              <Grid
                item
                xs={playMaster?.isnumvisible?.num1xs}
                md={6}
                sx={{
                  display: `${
                    playMaster?.isnumvisible?.num1 == true ? "" : "none"
                  }`,
                }}
              >
                <TextField
                  type="tel"
                  inputMode="numeric"
                  label="Number-1"
                  fullWidth
                  size="small"
                  variant="standard"
                  name="num1"
                  value={playMaster?.num1}
                  onChange={playMaster?.handleInputChange} // Add the onChange event here
                  InputProps={{
                    classes: {
                      input: style.textinp_left,
                    },
                    style: {
                      padding: "1px",
                    },
                  }}
                  InputLabelProps={{
                    className: style.textinplbl,
                  }}
                />
              </Grid>
              <Grid
                item
                xs={playMaster?.isnumvisible?.num2xs}
                md={6}
                sx={{
                  display: `${
                    playMaster?.isnumvisible?.num2 == true ? "" : "none"
                  }`,
                }}
              >
                <TextField
                  type="tel"
                  inputMode="numeric"
                  label="Number-2"
                  fullWidth
                  size="small"
                  variant="standard"
                  name="num2"
                  value={playMaster?.num2}
                  onChange={playMaster?.handleInputChange}
                  InputProps={{
                    classes: {
                      input: style.textinp_left,
                    },
                    style: {
                      padding: "1px",
                    },
                  }}
                  InputLabelProps={{
                    className: style.textinplbl,
                  }}
                />
              </Grid>
              <Grid item xs={playMaster?.isnumvisible?.pointsxs} md={6}>
                <TextField
                  type="tel"
                  id="phone"
                  inputMode="numeric"
                  label="Points"
                  fullWidth
                  size="small"
                  variant="standard"
                  name="points"
                  value={playMaster?.points}
                  onInput={handleNumInputChange}
                  onChange={playMaster?.handleInputChange}
                  InputProps={{
                    classes: {
                      input: style.textinp_left,
                    },
                    style: {
                      padding: "1px",
                    },
                  }}
                  InputLabelProps={{
                    className: style.textinplbl,
                  }}
                />
              </Grid>
              <Grid item xs={2} md={6}>
                <div
                  className={style.addbtncon}
                  onClick={(e) => {
                    playMaster?.handleChangePlay(1);
                  }}
                >
                  <button type="button" className={style.addbtn}>
                    <AddIcon />
                  </button>
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <PlaymasterTable data={playMaster} />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  // mt: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <div
                  className={style.submitbtncon}
                  onClick={() => {
                    playMaster?.handlemodalClose();
                  }}
                >
                  <button type="button" className={style.submitbtn}>
                    Submit
                  </button>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Dialog
        open={playMaster?.printopen}
        // onClose={playMaster?.handlePrintClose}     
       >
        <DialogTitle id="alert-dialog-title">
          {"Print"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{fontWeight:"bold"}}>
            Do you want to print this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e)=>{window.location.reload()}}>No</Button>
          <Button onClick={(e)=>{playMaster?.slipPrint(playMaster?.trid)}} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      </div>
    </>
  );
};

export default UserPlayMaster;