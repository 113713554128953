import React from 'react'

function TypemasterReducer(state,action) {
    switch(action.type){
        case 'Change':{
            return {
            ...state,
            [action.payload.name]: action.payload.value,
            };
        }
        case 'gtypeinfoDetails': {
            // alert( "23"+action.data)
            return action.data
          }
          case "updateganeprice":{
            const updatedData = state.map(item => {
              if (item.gtmid === action?.gtmid) {
                return { ...item, winprice: action?.value};
              }
              return item;
            });
            return updatedData;
          }


}
}
export default TypemasterReducer