import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import CutpanConfigure from '../../Components/CutpanConfigPage/CutpanConfigure'

const CutpanMasterScreen = () => {
  return (
    <div>
         <Appbar/>
         <CutpanConfigure/>
    </div>
  )
}

export default CutpanMasterScreen