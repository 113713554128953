import { Grid, Paper, TextField } from '@mui/material'
import React from 'react'
import style from "./Trade.module.css"
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TradeNumberTextSuggest from '../../Textsuggestfield/TradeNumberTextSuggest';
import SearchIcon from "@mui/icons-material/Search";
import MobileMenu from '../MobileMenu/MobileMenu';
import useGameMaster from '../../API/Hooks/useGameMaster';


const TradeMaster = () => {
  const items = ["Open", "Close", "PannaOpen", "PannaClose", "Jodi", "Jackpot", "Gunule"];

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const gameMaster = { ...useGameMaster() };
  return (
    <div className={style.tradecontainer}>
      <div className={style.tradepart1}>
        <Grid container spacing={0}>
          <Grid item xs={5} md={6}>
            <div className={style.gamename}>
              Play Game
            </div>
          </Grid>
          <Grid item xs={7} md={6}>
            <div className={style.searchcon}>
              <SearchIcon
                sx={{ fontSize: "22px", cursor: "pointer" }}
              />
              <input
                type="text"
                className={style.searchfield}
                placeholder="Search"
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={style.tradepart2}>
        <Grid container spacing={1} sx={{ padding: "5px" }}>
          {items?.map((element, index) => {
            let bgcolor = "#F1F0E8";
            if (index % 2 == 0) {
              bgcolor = "#fff";
            }
            return (
              <Grid item xs={12} md={12}>
                <Accordion component={Paper} expanded={expanded === index} onChange={handleChange(index)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{ backgroundColor: bgcolor }}
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                      <div className={style.treadeplace}>
                        {element}
                      </div>
                    </Typography>
                    <Typography>
                      <div className={style.treadetime}>
                        {gameMaster?.formatDate(new Date())}                        
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12} className={style.accordiondata}>
                          <div className={style.pointsty}>
                            1 Point = 99 Points
                          </div>
                          <div className={style.totalpoint} style={{fontWeight:"bold"}}>
                            Balance:100
                          </div>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <TextField
                            id="outlined-disabled"
                            label="Points"
                            fullWidth
                            size="small"
                            InputProps={{
                              classes: {
                                input: style.textinp_left,
                              },
                              style: {
                                padding: "1px",
                              },
                            }}
                            InputLabelProps={{
                              className: style.textinplbl,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <TradeNumberTextSuggest />
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ display: "flex", justifyContent: "end" }}>
                          <div className={style.paybtncon}>
                            <button type="button" className={style.paybtn}>
                              Buy
                            </button>
                          </div>
                        </Grid>
                      </Grid>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )
          })}
        </Grid>
      </div>
      <div className={style.tradepart3}>
        <MobileMenu />
      </div>
    </div>
  )
}

export default TradeMaster