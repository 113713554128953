import React, { useContext, useEffect, useReducer, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css";
import { Button, Checkbox, TableContainer, TextField } from "@mui/material";
import { makeStyles, styled } from "@material-ui/core";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";
import style from "../Components/GameMaster/GameMaster.module.css"


const useStyles = makeStyles({
  noBorder: {
      border: "none !important",
      "& .MuiInput-underline:before": {
      // borderBottom: `${props.border=="none"?"none !important":""}`,
      borderBottom: "none !important",
      },
  },
  });

const GameTypeListTable = (props) => {
  const classes = useStyles(); 
  const [data, setData] = useState([])
  useEffect(() => {
    const sortOrder = [
      "Open", "Close", "Jodi", "Open Panna", "Close Panna", "Gunule", "Ulta Gunule", "Jackpot"
  ];
  
  const sortData=props?.data?.typesearchState.sort((a, b) => {
      return sortOrder.indexOf(a.gname) - sortOrder.indexOf(b.gname);
  });
  setData(sortData)
  },[props?.data?.typesearchState])
  
   return (
    <>
      <div>
        <Paper className="contner">
          <TableContainer sx={{ maxHeight: "60vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Game Type</TableCell>
                  <TableCell align="right">Winner price</TableCell>
                </TableRow>
              </TableHead>

              <TableBody className="bodycon">
                {data?.map((element,index) => {
                    return (
                      <TableRow className="tblerowdata">
                        <TableCell component="th" scope="row" key={index}>
                          {element?.gname}
                        </TableCell>
                        <TableCell component="th" scope="row" align="right">
                          <TextField
                          size="small"
                          variant="standard"
                          className={classes.noBorder}
                          sx={{width:"100px"}}
                          name="winprice"
                          value={element?.winprice}
                          InputProps={{
                            classes: {
                              input: style.textinp,
                            },
                            style: {
                              padding: "2px",
                            },
                          }}
                          onChange={(e) => 
                            props?.data?.hndleUpdatePrice(e, element?.gtmid)
                          }
                          InputLabelProps={{
                            className: style.textinplbl,
                          }}
                        />
                        </TableCell>
                         
                      </TableRow>
                    );
                  })}
              </TableBody>
              </Table>
          </TableContainer>
         
        </Paper>
      </div>
    </>
  );
};
export default GameTypeListTable;
