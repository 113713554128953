import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import UserPlayResult from '../../Components/UserPlayResult/UserPlayResult'

const UserPlayResultScreen = () => {
  return (
    <div>
        <Appbar />
        <UserPlayResult />
    </div>
  )
}

export default UserPlayResultScreen