import React from "react";
import img from "../../Assets/logo2.png";
import usePlayslip from "../../API/Hooks/usePlayslip";

const PrintPage = () => {
  const playslip = { ...usePlayslip() };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "500px",
          height: "100%",
          border: "solid 1px black",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div style={{ height: "40px", width: "40px" }}>
            <img style={{ height: "100%", width: "100%" }} src={img} />
          </div>
          <div
            style={{
              fontSize: "22px",
              fontWeight: 800,
              marginLeft: "10px",
              marginTop: "5px",
            }}
          >
            SP Games
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            marginBottom: "10px",
          }}
        >
          <div style={{ fontSize: "16px", fontWeight: 800, width: "50%" }}>
            #{playslip?.playslipmasterstate[0]?.items[0]?.trid} -
            {playslip?.playslipmasterstate[0]?.items[0]?.gsubname}
          </div>
          <div
            style={{
              fontSize: "16px",
              fontWeight: 600,
              width: "50%",
              textAlign: "right",
            }}
          >
            {playslip?.formatDatetime_DMY(
              playslip?.playslipmasterstate[0]?.items[0]?.playdate,
              0
            )}{" "}
            &nbsp;
            {playslip?.geHourTime(
              playslip?.playslipmasterstate[0]?.items[0]?.playdate,
              12
            )}
          </div>
        </div>
        {/* <div style={{borderTop:"1px solid #d9d9d9",marginTop:"10px",marginBottom:"10px"}}></div> */}

        {playslip?.playslipmasterstate.map((element, index) => {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  borderTop: "1px solid #000",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  borderBottom: "1px solid #000",
                  marginTop:"5px"
                }}
              >
                <div
                  style={{ fontSize: "16px", fontWeight: 700, width: "50%" }}
                >
                  {element?.gname}
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: 700,
                    width: "50%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  {Number(element?.totalPoints).toFixed(2)}
                </div>
              </div>
              {element?.items?.map((elsub, indexsub) => {
                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "95%",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        borderBottom: "1px dotted #000",
                        paddingBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: 500,
                          width: "40%",
                          display: "flex",
                          justifyContent: "left",
                        }}
                      >
                        {elsub?.npoints}
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: 500,
                          width: "60%",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        {Number(elsub?.points).toFixed(2)}
                      </div>
                    </div>
                  </>
                );
              })}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default PrintPage;
