import { useEffect, useReducer, useState } from "react"; 
import { useNavigate } from "react-router-dom";
import { SearchAllPlaydata } from "../Model/PlaymasterModel";
import DrawmasterReducer from "../Reducer/DrawmasterReducer";
import { tostmsg } from "../../MIS/Global";


function useResultAnyalytics() {
  const navigate = useNavigate();
  const [playdataState, playdataDispach] = useReducer(DrawmasterReducer, [] );
  const [alertdata, setAlertdata] = useState({});
  const [alertopen, setAlertopen] = useState(false);
  const [drawid, setdrawid] = useState(0);
  const formatDatetime_YMD = (date, pmday) => {
    const currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + pmday);
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")} ${currentDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${currentDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;

    return formattedDate;
  };
  
  
  const calculateSum = (npoints,data,gtmid) => {    
    try {
      // alert(JSON.stringify(data))
      // if(npoints==="113X5" ){alert(gtmid)}
      if (data.length <= 0) {
        return 0;
      }
      const filteredArray = data.filter(item => item.gtmid.toString() === gtmid.toString());
 
      const totRes = filteredArray.reduce((sum, item) => {
        return item.npoints.toString()===npoints.toString() ? sum + parseInt(item.points)*parseInt(item.price) : sum;
      }, 0);
      return Number(totRes).toFixed(0).toString();
    } catch (error) {
      console.error('Error calculating sum:', error);
    }
    
  };



  return {
    alertdata,
    alertopen,
    setAlertopen,
    setdrawid,
    calculateSum
  };
}

export default useResultAnyalytics;
