import React from "react";
import style from "./Dashboard.module.css";
import { Grid, Paper, TextField } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import SearchIcon from "@mui/icons-material/Search";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonIcon from "@mui/icons-material/Person";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { makeStyles } from "@material-ui/core";
import PointsCollectionTable from "../../Tables/PointsCollectionTable";

const useStyles = makeStyles((theme) => ({
  smallDateTimePicker: {
    "& .MuiInputBase-input": {
      fontSize: "14px",
      fontWeight: "600 !important",
      width: "100%",
      height: "100%",
      padding: "10px",
      margin: 0,
    },
    "& .MuiInputLabel-root": {
      fontSize: "16px",
      fontWeight: "600 !important", // Adjust font size as needed
      display: "flex",
      alignItems: "center",
    },
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  return (
    <>
      <div className={style.dashboardmain}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} className={style.userlistpart1}>
            <Grid container spacing={2}>
                  <Grid item xs={12} md={2}>
                    <div className={style.boxconstyle}>
                      <div className={style.boxtxtcon}>
                        <span className={style.usercounttxt}>9</span>
                        <span className={style.totalusertxt}>Total User</span>
                      </div>
                      <div>
                        <GroupIcon style={{ fontSize: "50px" }} />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <div className={style.activeboxconstyle}>
                      <div className={style.boxtxtcon}>
                        <span className={style.usercounttxt}>8</span>
                        <span className={style.totalusertxt}>Active</span>
                      </div>
                      <div>
                        <PersonIcon
                          style={{ fontSize: "50px", color: "#009900" }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <div className={style.blockboxconstyle}>
                      <div className={style.boxtxtcon}>
                        <span className={style.usercounttxt}>1</span>
                        <span className={style.totalusertxt}>Block</span>
                      </div>
                      <div>
                        <PersonOffIcon style={{ fontSize: "45px" }} />
                      </div>
                    </div>
                  </Grid>
                </Grid>
          </Grid>
          <Grid item xs={12} md={12} className={style.userlistpart2}>
            {/* <Paper elevation={2} className={style.listpaper}> */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Paper elevation={2} className={style.listpaper}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} className={style.userlistpart1}>
                      <div className={style.collectionheading}>Points Collection</div>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className={classes.smallDateTimePicker}
                          label="CutOff Morning"
                          renderInput={(props) => (
                            <TextField {...props} variant="standard" />
                          )}
                          value={dayjs()}
                          openTo="day"
                          format="DD/MM/YYYY"
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={8} className={style.searchposition}>
                      <div className={style.searchcon}>
                        <SearchIcon
                          sx={{ fontSize: "22px", cursor: "pointer" }}
                        />
                        <input
                          type="text"
                          className={style.searchfield}
                          placeholder="Search"
                          onChange={(e) => {
                            // userlist?.setUsearch(e.target.value)
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <PointsCollectionTable />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            {/* </Paper> */}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Dashboard;
