import React, { useEffect } from "react";
import style from "./DrawResult.module.css";
import { Box, Button, FormControlLabel, Grid, Modal, Paper, Radio, RadioGroup, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Table, TableBody, TableCell, TableContainer, TableRow, makeStyles } from "@material-ui/core";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers"; 
import useDrawmaster from "../../API/Hooks/useDrawmaster";
import GlobalTextsuggest from "../../Textsuggestfield/GlobalTextsuggest";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import DrawResultTabs from "./DrawResultTabs";
import { handleNumInputChange } from "../../MIS/Global";
import ResultPredictionChart from "../ResultPrediction/ResultPredictionChart";
import CloseIcon from '@mui/icons-material/Close';
import DialogBox from "../GlobalComponent/DialogBox";
import { useDispatch, useSelector } from "react-redux";
import { getPredictionChart, getPredictionChartSummary, getPredictionMESummary } from "../../API/Reducer/predictionChartReducer";

const useStyles = makeStyles((theme) => ({
  smallDateTimePicker: {
    "& .MuiInputBase-input": {
      fontSize: "14px",
      fontWeight: "600 !important",
      width: "100%",
      height: "100%",
      padding: "10px",
      margin: 0,
    },
    "& .MuiInputLabel-root": {
      fontSize: "16px",
      fontWeight: "600 !important", // Adjust font size as needed
      display: "flex",
      alignItems: "center",
    },
  },
}));

const useStyles1 = makeStyles({
  noBorder: {
    border: "none !important",
    "& .MuiInput-underline:before": {
      // borderBottom: `${props.border=="none"?"none !important":""}`,
      borderBottom: "none !important",
    },
  },
});
const DrawResultMaster = () => {
  const drawresult = { ...useDrawmaster() };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const { predictionChartList,predictionChartSummaryList,predictionMESum } = useSelector((state) => state.predictionchart);

  useEffect(() => {
    if(drawresult?.gmid>0){
     dispatch(getPredictionChart({gmid:drawresult?.gmid}));
    }
  },[drawresult?.gmid])   
  useEffect(() => {
    if(predictionChartList.length>0){
     dispatch(getPredictionChartSummary({}));
    }
  },[predictionChartList])
  
  useEffect(() => {
    if(drawresult?.drawresultmasterstate?.m10!=undefined && drawresult?.drawresultmasterstate?.m11!=undefined && drawresult?.drawresultmasterstate?.m12!=undefined){
      let data={
        gmid:drawresult?.gmid,
        onum:drawresult?.drawresultmasterstate?.m5,
        cnum:drawresult?.drawresultmasterstate?.m8,
        jodinum:`${drawresult?.drawresultmasterstate?.m5}${drawresult?.drawresultmasterstate?.m8}`,
        opnum:`${drawresult?.drawresultmasterstate?.m1}${drawresult?.drawresultmasterstate?.m2}${drawresult?.drawresultmasterstate?.m3}`,
        cpnum:`${drawresult?.drawresultmasterstate?.m10}${drawresult?.drawresultmasterstate?.m11}${drawresult?.drawresultmasterstate?.m12}`,
        gnum:`${drawresult?.drawresultmasterstate?.m1}${drawresult?.drawresultmasterstate?.m2}${drawresult?.drawresultmasterstate?.m3}X${drawresult?.drawresultmasterstate?.m8}`,
        ugnum:`${drawresult?.drawresultmasterstate?.m5}X${drawresult?.drawresultmasterstate?.m10}${drawresult?.drawresultmasterstate?.m11}${drawresult?.drawresultmasterstate?.m12}`,
        jknum:`${drawresult?.drawresultmasterstate?.m1}${drawresult?.drawresultmasterstate?.m2}${drawresult?.drawresultmasterstate?.m3}X${drawresult?.drawresultmasterstate?.m10}${drawresult?.drawresultmasterstate?.m11}${drawresult?.drawresultmasterstate?.m12}`
      }
      dispatch(getPredictionMESummary(data));
      
    }else if(drawresult?.drawresultmasterstate?.m1!=undefined && drawresult?.drawresultmasterstate?.m2!=undefined && drawresult?.drawresultmasterstate?.m3!=undefined){
      let data={
        gmid:drawresult?.gmid,
        onum:drawresult?.drawresultmasterstate?.m5,
        cnum:"",
        jodinum:"",
        opnum:`${drawresult?.drawresultmasterstate?.m1}${drawresult?.drawresultmasterstate?.m2}${drawresult?.drawresultmasterstate?.m3}`,
        cpnum:"",
        gnum:"",
        ugnum:"",
        jknum:""
      }
      dispatch(getPredictionMESummary(data));
    }

  }, [drawresult?.drawresultmasterstate])
  
  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const classes = useStyles();
  const classes1 = useStyles1();

  return (
    <>
      <AlertMessageComp
        type={drawresult?.alertdata.type}
        msg={drawresult?.alertdata.msg}
        vertical={drawresult?.alertdata.vertical}
        horizontal={drawresult?.alertdata.horizontal}
        setOpen={drawresult?.setAlertopen}
        open={drawresult?.alertopen}
      />
      <DialogBox data={{setValue:drawresult?.handleConfirmChange,value:drawresult?.value,
        heading:drawresult?.cmdinfo?.heading,msg:drawresult?.cmdinfo?.msg,nolbl:drawresult?.cmdinfo?.nolbl,
        yeslbl:drawresult?.cmdinfo?.yeslbl
       }}/>
      <div className={style.resultmain}>
        <div className={style.resultconn1}>
          <Grid container spacing={1} paddingTop={1} paddingBottom={1} >
            <Grid item xs={12} md={6}>
              <div className={style.drawheading}>Draw Result</div>
            </Grid>
            <Grid item xs={12} md={4} sx={{display:"flex",justifyContent:"end", alignItems:"end"}}>
              <RadioGroup defaultValue="outlined" row>
              {/* radioOC,handleRadioButtons */}
                <FormControlLabel value="0" control={<Radio name="open" checked={drawresult?.radioOC?.o} onChange={(e)=>{drawresult?.handleRadioButtons(e)}} />} label="Open Save/Update" />
                <FormControlLabel value="1" control={<Radio name="close" checked={drawresult?.radioOC?.c} onChange={(e)=>{drawresult?.handleRadioButtons(e)}}/>} label="Close Save/Update" />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} md={2} sx={{display:"flex",justifyContent:"end"}}>
              <Button type="button" size="medium" color="primary" variant="contained" 
                    onClick={() => {
                      drawresult?.confirmDilog("saveres");
                    }}>
                      Save 
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className={style.resultconn2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <GlobalTextsuggest
                    data={{
                      options: drawresult?.gamesearchState,
                      labelNm: drawresult?.gname,
                      label: "Game Name",
                      handleChange: drawresult?.handleGameDropdown,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className={classes.smallDateTimePicker}
                      label="Date"
                      renderInput={(props) => (
                        <TextField {...props} variant="standard" />
                      )}
                      name="drawdate"
                      value={dayjs(`${new Date()}`)}
                      // onChange={(e)=>{
                      //   drawresult?.setFdate(e);
                      // }}
                      openTo="day"
                      format="DD/MM/YYYY"
                      readOnly
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <div className={style.Table}>
                    <div className={style.Row}>
                      <div className={style.Cell}>
                        <TextField
                        disabled={drawresult?.meState?.m}
                          size="small"
                          variant="standard"
                          className={classes1.noBorder}
                          sx={{ width: "50px" }}
                          name="m1"
                          value={`${
                            drawresult?.drawresultmasterstate?.m1 || ""
                          }`}                         
                          InputProps={{
                            inputProps: {
                              maxLength: 1,
                              tabIndex: "1" 
                            },
                            classes: {
                              input: style.textinp,
                            },
                            style: {
                              padding: "2px",
                            },
                          }}
                          InputLabelProps={{
                            className: style.textinplbl,
                          }}
                          onInput={handleNumInputChange}
                          onChange={drawresult?.handledrawresultmaster}
                          
                        />
                      </div>
                      <div className={style.Cell}>X</div>
                      <div className={style.Cell}>X</div>
                      <div className={style.Cell}>
                        <TextField
                        disabled={drawresult?.meState?.e}
                          size="small"
                          variant="standard"
                          className={classes1.noBorder}
                          sx={{ width: "50px" }}
                          name="m10"
                          value={`${
                            drawresult?.drawresultmasterstate?.m10 || ""
                          }`}
                          ref={drawresult?.m10Ref}
                          onKeyDown={drawresult?.handleKeyDown}
                          InputProps={{
                            inputProps: {
                              maxLength: 1,
                              tabIndex: "4"
                            },
                            classes: {
                              input: style.textinp,
                            },
                            style: {
                              padding: "2px",
                            },
                          }}
                          InputLabelProps={{
                            className: style.textinplbl,
                          }}
                          onInput={handleNumInputChange}
                          onChange={drawresult?.handledrawresultmaster}
                          
                        />
                      </div>
                    </div>
                    <div className={style.Row}>
                      <div className={style.Cell}>
                        <TextField
                        disabled={drawresult?.meState?.m}
                          size="small"
                          variant="standard"
                          className={classes1.noBorder}
                          sx={{ width: "50px" }}
                          name="m2"
                          value={`${
                            drawresult?.drawresultmasterstate?.m2 || ""
                          }`}                          
                          InputProps={{
                            inputProps: {
                              maxLength: 1,
                              tabIndex: "2" 
                            },
                            classes: {
                              input: style.textinp,
                            },
                            style: {
                              padding: "2px",
                            },
                          }}
                          InputLabelProps={{
                            className: style.textinplbl,
                          }}
                          onInput={handleNumInputChange}
                          onChange={drawresult?.handledrawresultmaster}
                          
                        />
                      </div>
                      <div className={style.Cell}>
                        <TextField
                          size="small"
                          variant="standard"
                          className={classes1.noBorder}
                          sx={{ width: "50px" }}
                          name="m5"
                          value={`${
                            drawresult?.drawresultmasterstate?.m5 || ""
                          }`}
                          InputProps={{
                            inputProps: {
                              maxLength: 1,    
                              readOnly:true, 
                              tabIndex: "-1"                         
                            },
                            classes: {
                              input: style.textinp,
                            },
                            style: {
                              padding: "2px",
                            
                            },
                          }}
                          InputLabelProps={{
                            className: style.textinplbl,
                          }}
                          onInput={handleNumInputChange}
                          onChange={drawresult?.handledrawresultmaster}                          
                        />
                      </div>
                      <div className={style.Cell}>
                        <TextField
                          size="small"
                          variant="standard"
                          className={classes1.noBorder}
                          sx={{ width: "50px" }}
                          name="m8"
                          value={`${
                            drawresult?.drawresultmasterstate?.m8 || ""
                          }`}
                          InputProps={{
                            inputProps: {
                              maxLength: 1,
                              readOnly:true,      
                              tabIndex: "-1"
                            },
                            classes: {
                              input: style.textinp,
                            },
                            style: {
                              padding: "2px",
                            },
                          }}
                          InputLabelProps={{
                            className: style.textinplbl,
                          }}
                          onInput={handleNumInputChange}
                          onChange={drawresult?.handledrawresultmaster}
                        />
                      </div>
                      <div className={style.Cell}>
                        <TextField
                          disabled={drawresult?.meState?.e}
                          size="small"
                          variant="standard"
                          className={classes1.noBorder}
                          sx={{ width: "50px" }}
                          name="m11"
                          value={`${
                            drawresult?.drawresultmasterstate?.m11 || ""
                          }`}                        
                          InputProps={{
                            inputProps: {
                              maxLength: 1,
                              tabIndex: "5"
                            },
                            classes: {
                              input: style.textinp,
                            },
                            style: {
                              padding: "2px",
                            },
                          }}
                          InputLabelProps={{
                            className: style.textinplbl,
                          }}
                          onInput={handleNumInputChange}
                          onChange={drawresult?.handledrawresultmaster}
                          
                        />
                      </div>
                    </div>
                    <div className={style.Row}>
                      <div className={style.Cell}>
                        <TextField
                          disabled={drawresult?.meState?.m}
                          size="small"
                          variant="standard"
                          className={classes1.noBorder}
                          sx={{ width: "50px" }}
                          name="m3"
                          value={`${
                            drawresult?.drawresultmasterstate?.m3 || ""
                          }`}                          
                          InputProps={{
                            inputProps: {
                              maxLength: 1,
                              tabIndex: "3"
                            },
                            classes: {
                              input: style.textinp,
                            },
                            style: {
                              padding: "2px",
                            },
                          }}
                          InputLabelProps={{
                            className: style.textinplbl,
                          }}
                          onInput={handleNumInputChange}
                          onChange={drawresult?.handledrawresultmaster}
                          
                        />
                      </div>
                      <div className={style.Cell}>X</div>
                      <div className={style.Cell}>X</div>
                      <div className={style.Cell}>
                        <TextField
                          disabled={drawresult?.meState?.e}
                          size="small"
                          variant="standard"
                          className={classes1.noBorder}
                          sx={{ width: "50px" }}
                          name="m12"
                          value={`${
                            drawresult?.drawresultmasterstate?.m12 || ""
                          }`}
                          
                          InputProps={{
                            inputProps: {
                              maxLength: 1,
                              tabIndex: "6"
                            },
                            classes: {
                              input: style.textinp,
                            },
                            style: {
                              padding: "2px",
                            },
                          }}
                          InputLabelProps={{
                            className: style.textinplbl,
                          }}
                          onInput={handleNumInputChange}
                          onChange={drawresult?.handledrawresultmaster}
                          
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} sx={{display:"flex",justifyContent:"space-between"}}>                                    
                 <Button type="button" size="small" color="warning" variant="contained" 
                   disabled={drawresult?.drawid>0?false:true}
                    onClick={() => {                      
                      handleOpen();
                    }}>
                      Prediction 
                    </Button>
                                     
                 </Grid>
                 <Grid item xs={12} md={12} sx={{display:"none",justifyContent:"end"}}>   
                 <Button type="button" size="small" color="secondary" variant="contained" 
                   disabled={drawresult?.drawid>0?false:true}
                    onClick={() => {
                      drawresult?.getMETotPrediction();                      
                    }}>
                      Summary Prediction 
                    </Button>      
                 </Grid>
                 <Grid item xs={12} md={12}>                                    
                 <Paper>
                 <TableContainer >
                  <Table
                    size="small"
                    stickyHeader
                    aria-label="sticky table"
                    style={{
                      fontSize: "100pt!important",
                    }}
                  >
                    <TableBody>
                    <TableRow className="">
                      <TableCell style={{fontWeight:"bold",textAlign:"left"}}>
                         Morning
                      </TableCell>
                      <TableCell style={{fontWeight:"",textAlign:"right"}}>
                         {Number(predictionMESum?.[0]?.morning || 0).toFixed(2)}
                      </TableCell>
                      </TableRow>
                      <TableRow className="">
                      <TableCell style={{fontWeight:"bold",textAlign:"left"}}>
                         Evening
                      </TableCell>
                      <TableCell style={{fontWeight:"",textAlign:"right"}}>
                       {Number(predictionMESum?.[0]?.evening || 0).toFixed(2)}
                      </TableCell>
                      </TableRow>
                      <TableRow className="">
                      <TableCell style={{fontWeight:"bold",textAlign:"left"}}>
                         Total
                      </TableCell>
                      <TableCell style={{fontWeight:"",textAlign:"right"}}>
                      {Number(predictionMESum?.[0]?.total || 0).toFixed(2)}
                      </TableCell>
                      </TableRow>
                      
                    </TableBody>
                    </Table>
                 </TableContainer>
                 </Paper>
                 </Grid>
              </Grid>
            </Grid>
          
            <Grid container item xs={12} md={9}>
            <Grid item xs={12} md={12}>
              <DrawResultTabs data={{call_getPlaySumPoints:drawresult?.call_getPlaySumPoints,tbldata:drawresult?.drawtableState,
                tbldatasum:drawresult?.drawtablesummaryState,
                }}/>
            </Grid>
       
            </Grid>
          
          </Grid>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={styleModal}>
            <div style={{width:"100%",display:"flex",justifyContent:"end",cursor:"pointer"}}
            onClick={handleClose}
            > <CloseIcon/> </div>  
            <ResultPredictionChart data={{drawid:drawresult?.drawid,tbldata:drawresult?.playdataState}} />
        </Box>
      </Modal>
    </>
  );
};

export default DrawResultMaster;
