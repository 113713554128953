import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import AdminPLstatement_1 from '../../Components/AdminPLStatment/AdminPLstatement_1'

const AdminPLScreen = () => {
  return (
    <div>
         <Appbar />
         <AdminPLstatement_1/>
    </div>
  )
}

export default AdminPLScreen