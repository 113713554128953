import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import AdminFundRequestList from '../../Components/AdminFundRequest/AdminFundRequestList'

const AdminFundRequestListScreen = () => {
  return (
    <div>
        <Appbar/>
        <AdminFundRequestList/>
    </div>
  )
}

export default AdminFundRequestListScreen