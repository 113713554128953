import { createSlice } from "@reduxjs/toolkit"; 
import { setConfirmDialogData } from "./confirmDialogReducer";
import { callAjax, formatDatetime_DMY, formatDatetime_YMD } from "../../MIS/Global";
import { setAlertData } from "./alertReducer";
  

const userReducer = createSlice({
    name: 'user',
    initialState: {
        userlist:[],
        useraclist:[],
        useraclistorg:[],            
        useropening:[{opening:0}],
        usersumdrcr:[{cr:0,dr:0}],
        adminLogin:{id:"",paswd:""},
        adminLoginStatus:false,
        fpaswdActiveStep:0,
        comisReportlist:[],
    },

    reducers: {
        setUserList(state, action){
            state.userlist=action.payload;         
         }, 
         setUserAcList(state, action){
            state.useraclist=action.payload;         
         },  
         setUserAcListOrg(state, action){
            state.useraclistorg=action.payload;              
         },
         setUserOpening(state, action){
            state.useropening=action.payload;         
         },  
         setUserSumCrDr(state, action){
            state.usersumdrcr=action.payload;         
         },    
         setAdminLogin(state, action) {
            state.adminLogin = {
              ...state.adminLogin,
              [action.payload.name]: action.payload.value,
            };
        },
        setAdminLoginStatus(state, action){
            state.adminLoginStatus=action.payload;         
         },  
         setFpaswdActiveStep(state, action){
          state.fpaswdActiveStep=action.payload;         
       }, 
       setComisReportlist(state, action){
        state.comisReportlist=action.payload;  
       }              
    }
});

export const { setUserList,setUserAcList,setUserAcListOrg,setUserOpening,setUserSumCrDr,setAdminLogin,
  setAdminLoginStatus,setFpaswdActiveStep,setComisReportlist} = userReducer.actions;
export default userReducer.reducer;

 
export const GetUserList = (data) => {  
    return  async function actionData(dispatch){
        try {
         let res=await callAjax("getuser",data);         
          dispatch(setUserList(res?.data)) ;
        } catch (error) {
            dispatch(
                setAlertData({
                  msg: "Something went wrong! Please try again",
                  code: 100,
                  open: true,
                })
              );
        }
    }
}
 
export const GetAcStatmentList = (data) => {    
    return  async function actionData(dispatch){
        try {
         let res=await callAjax("getacstatement",data);         
          dispatch(setUserAcList(res?.data));          
          dispatch(setUserAcListOrg(res?.data));
          dispatch(GetUserOpening({edate:data?.fdate,loginid:data?.loginid}))
          dispatch(GetUserSumCrDr(data))
        //   alert(JSON.stringify(res?.data))
        } catch (error) {
            dispatch(
                setAlertData({
                  msg: "Something went wrong! Please try again",
                  code: 100,
                  open: true,
                })
              );
        }
    }
}

export const FilterAcStamentData = (data,searchstr) => {    
    return  async function actionData(dispatch){
        const keysToSearch = ["uname", "mob", "remark","dr","cr"];
        const filteredData = data?.filter((item) => {
        return keysToSearch?.some((key) => {
            return item[key]
            ?.toString()
            .toLowerCase()
            ?.includes(searchstr.toString().toLowerCase());
        });
        });
        dispatch(setUserAcList(filteredData));
    }
}

export const GetUserOpening = (data) => {    
    return  async function actionData(dispatch){
        try {
         let res=await callAjax("getuseropening",data);         
        //  alert(JSON.stringify(res?.data))
          dispatch(setUserOpening(res?.data)) ;          
        } catch (error) {
            dispatch(
                setAlertData({
                  msg: "Something went wrong! Please try again",
                  code: 100,
                  open: true,
                })
              );
        }
    }
}

export const GetUserSumCrDr = (data) => {    
    return  async function actionData(dispatch){
        try {
         let res=await callAjax("getusersumdrcr",data);         
           dispatch(setUserSumCrDr(res?.data)) ;          
        } catch (error) {
            dispatch(
                setAlertData({
                  msg: "Something went wrong! Please try again",
                  code: 100,
                  open: true,
                })
              );
        }
    }
}

export const handleAdminLoginChange = (e, name) => {
    return function actionData(dispatch) {
      if (e && e.target) {
        dispatch(setAdminLogin({ name: e.target.name, value: e.target.value }));
      }
    };
  };

 export const GetAdminLogin = (data) => {  
    return  async function actionData(dispatch){
        try {
         if(data?.id==="" || data?.id===undefined){
            dispatch(
                setAlertData({ msg: "Please enter a mobile number", code: 100, open: true })
              );
              return;
         } else if(data?.id .length < 10){
            dispatch(
                setAlertData({ msg: "Invalid mobile number", code: 100, open: true })
              );
              return;
         } else if(data?.paswd==="" || data?.paswd===undefined){
            dispatch(
                setAlertData({ msg: "Please enter a password", code: 100, open: true })
              );
              return;
         }
         let res=await callAjax("adminlogin",data);         
         
          if(res.code===200 && res?.data?.length > 0){
            localStorage.setItem("loginid",res?.data?.[0]?.id);
            dispatch(setAdminLoginStatus(true))
            dispatch(setAdminLogin({ name:"id", value:""}));
            dispatch(setAdminLogin({ name:"paswd", value:""}));
            setTimeout(() => {
                dispatch(setAdminLoginStatus(false))
            }, 1000);
           }else{
            localStorage.setItem("loginid","");
            dispatch(
                setAlertData({ msg: "Invalid mobile number/password", code: 100, open: true })
              );
          }
        } catch (error) {
            dispatch(
                setAlertData({
                  msg: "Something went wrong! Please try again",
                  code: 100,
                  open: true,
                })
              );
        }
    }
}

export const HandleFPaswdStep = (data) => {  
  return  async function actionData(dispatch){
    dispatch(setFpaswdActiveStep(data));
  }
}
export const GenerateOTP = (data) => {  
  const genotp = Math.floor(Math.random() * 9000) + 1000;
   data={...data,otp:genotp}
   return  async function actionData(dispatch){
      try {
       if(data?.mob==="" || data?.mob===undefined){
          dispatch(
              setAlertData({ msg: "Please enter a mobile number", code: 100, open: true })
            );
            return;
       } else if(data?.mob .length < 10){
          dispatch(
              setAlertData({ msg: "Invalid mobile number", code: 100, open: true })
            );
            return;
       }  
       let res=await callAjax("genrateotp",data);         
       
        if(res.code===200 && res?.data?.[0]?.dt > 0){
           dispatch(
            setAlertData({ msg: "OTP request submitted successfully; please contact the administrator for the OTP", code: 200, open: true })
          );
         }else{
           dispatch(
              setAlertData({ msg: "The mobile number you have entered is not registered", code: 100, open: true })
            );
        }
      } catch (error) {
          dispatch(
              setAlertData({
                msg: "Something went wrong! Please try again",
                code: 100,
                open: true,
              })
            );
      }
  }
}

export const VerifyOTP = (data) => {   
   return  async function actionData(dispatch){
      try {
       if(data?.mob==="" || data?.mob===undefined){
          dispatch(
              setAlertData({ msg: "Please enter a mobile number", code: 100, open: true })
            );
            return;
       } else if(data?.mob .length < 10){
          dispatch(
              setAlertData({ msg: "Invalid mobile number", code: 100, open: true })
            );
            return;
       }else if(data?.otp==="" || data?.otp===undefined){
        dispatch(
            setAlertData({ msg: "Please enter a OTP", code: 100, open: true })
          );
          return;
     }  
       else if(data?.otp.length < 4){
        dispatch(
            setAlertData({ msg: "Invalid OTP", code: 100, open: true })
          );
          return;
     }  
       let res=await callAjax("verifyotp",data);         
       
        if(res.code===200 && res?.data?.[0]?.dt > 0){
           dispatch(
            setAlertData({ msg: "OTP verify successfully", code: 200, open: true })
          );
          dispatch(HandleFPaswdStep(1));
         }else{
           dispatch(
              setAlertData({ msg: "Invalid OTP", code: 100, open: true })
            );
        }
      } catch (error) {
          dispatch(
              setAlertData({
                msg: "Something went wrong! Please try again",
                code: 100,
                open: true,
              })
            );
      }
  }
}

export const UpdatePassword = (data) => {   
  return  async function actionData(dispatch){
     try {
      if(data?.paswd==="" || data?.paswd===undefined){
         dispatch(
             setAlertData({ msg: "Please enter a password", code: 100, open: true })
           );
           return;
      }else if(data?.caswd==="" || data?.cpaswd===undefined){
        dispatch(
            setAlertData({ msg: "Please enter a confirm password", code: 100, open: true })
          );
          return;
     }else if(data?.paswd!==data?.cpaswd){
      dispatch(
          setAlertData({ msg: "The password and confirm password do not match", code: 100, open: true })
        );
        return;
   }

      let res=await callAjax("updatepaswd",data);         
      
       if(res.code===200 && res?.data?.[0]?.dt > 0){
          dispatch(
           setAlertData({ msg: "Password updated successfully", code: 200, open: true })
         );
         setTimeout(() => {
          window.location.reload()
         }, 1000);
         
        }else{
          dispatch(
             setAlertData({ msg: "Invalid OTP", code: 100, open: true })
           );
       }
     } catch (error) {
         dispatch(
             setAlertData({
               msg: "Something went wrong! Please try again",
               code: 100,
               open: true,
             })
           );
     }
 }
}


export const GetCommissionReportList = (data) => {  
  return  async function actionData(dispatch){
      try {
       let res=await callAjax("commissionreport",data);         
        dispatch(setComisReportlist(res?.data)) ;
      } catch (error) {
          dispatch(
              setAlertData({
                msg: "Something went wrong! Please try again",
                code: 100,
                open: true,
              })
            );
      }
  }
}