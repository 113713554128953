import React, { useEffect, useReducer, useState } from "react";
import PlayMasterReducer from "../Reducer/PlayMasterReducer";
import { tostmsg } from "../../MIS/Global";
import { GetPlayData } from "../Model/PlaymasterModel";

function useAdminPlayHistory() {
  const [alertdata, setAlertdata] = useState({});
  const [alertopen, setAlertopen] = useState(false);

  const [expanded, setExpanded] = React.useState(false);

  const [playhistorystate, playhistoryDispach] = useReducer(
    PlayMasterReducer,
    []
  );
  const [searchplaystate, searchplayDispach] = useReducer(
    PlayMasterReducer,
    []
  );
  const [searchplayhistorystate, searchplayhistoryDispach] = useReducer(
    PlayMasterReducer,
    []
  );
  const [searchplaydatastate, searchplaydataDispach] = useReducer(
    PlayMasterReducer,
    []
  );
  const [selectedRow, setSelectedRow] = useState(null);
  const [playdate, setPlaydate] = useState(new Date());
  const [usearch, setUsearch] = useState("");
  const [gamesearch, setGamesearch] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleRowClick = (index) => {
    setSelectedRow(index === selectedRow ? null : index);
  };
  const handlePlaydate = (e, value) => {
    setPlaydate(value);
  };

  function formatDate(date) {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      new Date(date)
    );
    const formattedDateWithoutComma = formattedDate.replace(",", "");
    return formattedDateWithoutComma.replace(/(\s[ap]m)/i, (match) =>
      match.toUpperCase()
    );
  }

  const GetPlayHistorydata = async () => {
    try {
      let data = { pfdate: playdate, ptdate: playdate };
      let getplay = await GetPlayData(data);
      if (getplay.code === 200) {
        const summarizedData = getplay.data.reduce((acc, entry) => {
          const { loginid, uname, mob, points, playdate } = entry;

          if (!acc[loginid]) {
            acc[loginid] = { loginid, uname, mob, points: 0, playdate };
          }
          acc[loginid].points += points;

          return acc;
        }, {});

        const resultArray = Object.values(summarizedData);
        // alert(JSON.stringify(resultArray))
        playhistoryDispach({
          type: "details",
          data: resultArray,
        });
        searchplayDispach({
          type: "details",
          data: resultArray,
        });
      }
    } catch (error) {
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };

  // useEffect(() => {
  //   setInterval(() => {      
  //     GetPlayHistorydata();
  //   }, 180000);
  // }, [])
  const GetPlayHistorydata_SearchBtn = async (data) => {
    try {
      let getplay = await GetPlayData(data);
      if (getplay.code === 200) {
        const summarizedData = getplay.data.reduce((acc, entry) => {
          const { loginid, uname, mob, points, playdate } = entry;

          if (!acc[loginid]) {
            acc[loginid] = { loginid, uname, mob, points: 0, playdate };
          }
          acc[loginid].points += points;

          return acc;
        }, {});

        const resultArray = Object.values(summarizedData);
        // alert(JSON.stringify(resultArray))
        playhistoryDispach({
          type: "details",
          data: resultArray,
        });
        searchplayDispach({
          type: "details",
          data: resultArray,
        });
      }
    } catch (error) {
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };

  useEffect(() => {
    GetPlayHistorydata();
    searchplayhistoryDispach({
      type: "details",
      data: [],
    });
    searchplaydataDispach({
      type: "details",
      data: [],
    });
  }, [playdate]);

  const searchPlayHistory = async (data) => {
    try {
      let getplay = await GetPlayData(data);

      if (getplay.code === 200) {
        // alert(JSON.stringify(getplay));
        const groupedData = getplay?.data?.reduce((acc, entry) => {
          const { trid, playdate, gname, gsubname, npoints, points, loginid } =
            entry;

          if (!acc[trid]) {
            acc[trid] = {
              trid,
              playdate,
              gname,
              gsubname,
              npoints,
              points,
              loginid,
              entries: [],
            };
          }

          acc[trid].entries.push(entry);

          return acc;
        }, {});

        const resultArray = Object.values(groupedData); // Flatten the nested arrays
        // alert(JSON.stringify(resultArray));

        searchplayhistoryDispach({
          type: "details",
          data: resultArray,
        });
        searchplaydataDispach({
          type: "details",
          data: resultArray,
        });
      }
    } catch (error) {
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed, please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };

  const userdatasearch = (searchstr) => {
    const keysToSearch = ["uname", "mob"];
    const filteredData = searchplaystate?.filter((item) => {
      return keysToSearch?.some((key) => {
        return item[key]
          ?.toString()
          .toLowerCase()
          ?.includes(searchstr.toString().toLowerCase());
      });
    });
    // alert(JSON.stringify(filteredData))
    playhistoryDispach({
      type: "details",
      data: filteredData,
    });
  };
  useEffect(() => {
    userdatasearch(usearch);
  }, [usearch]);

  const Gamedatasearch = (searchstr) => {
    const keysToSearch = ["trid"];
    const filteredData = searchplaydatastate?.filter((item) => {
      return keysToSearch?.some((key) => {
        return item[key]
          ?.toString()
          .toLowerCase()
          ?.includes(searchstr.toString().toLowerCase());
      });
    });
    // alert(JSON.stringify(filteredData))
    searchplayhistoryDispach({
      type: "details",
      data: filteredData,
    });
  };
  useEffect(() => {
    Gamedatasearch(gamesearch);
  }, [gamesearch]);

  

  return {
    alertdata,
    alertopen,
    setAlertopen,
    GetPlayHistorydata,
    playhistorystate,
    handleRowClick,
    selectedRow,
    searchPlayHistory,
    playdate,
    handlePlaydate,
    searchplayhistorystate,
    formatDate,
    setUsearch,
    setGamesearch,
    handleChange,
    expanded,
    GetPlayHistorydata_SearchBtn,
  };
}

export default useAdminPlayHistory;
