import React, { useContext, useEffect, useReducer, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonIcon from "@mui/icons-material/Person";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./Table.css";
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Modal,
  TableContainer,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom"; 
import style from "../Components/RegisterUsersList/RegUserlist.module.css";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { handleDecimalInputChange } from "../MIS/Global";
 import AlertMessageComp from "../Components/AlertMessageCom/AlertMessageComp";
import AdminLedgerReport from "../Components/AdminLedgerReport/AdminLedgerReport";
import CopyAllIcon from '@mui/icons-material/CopyAll';
const columns = [
  { label: "User Name", minWidth: "20%" },
  { label: "Mobile Number", minWidth: "15%" },
  { label: "City", minWidth: "15%" },
  { label: "Reg Date", minWidth: "10%" },
  { label: "Active Date", minWidth: "10%" },
  { label: "Block Date", minWidth: "10%" },
  { label: "Closing", minWidth: "10%" },
  { label: "Wallet", minWidth: "10%" },
  { label: "Ledger", minWidth: "10%" },
  { label: "", minWidth: "10%" },
];

const RegisterUserListTable = (props) => {

  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [open1, setOpen1] = React.useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const rowsPerPageOptions = [
    25,
    50,
    100,
    125,
    150,
    { value: props?.data?.serachuserState?.length, label: "All" },
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(+event.target.value, 10));
    setPage(0);
  };

  function formatDate(date) {
    var options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Intl.DateTimeFormat("en-GB", options).format(new Date(date));
  }
  const formatactiveDate = (dateString) => {
    if (!dateString || dateString === "00-00-00") {
      return "-";
    }
    const dateParts = dateString.split("-");
    if (dateParts.length === 3) {
      var options = { day: "2-digit", month: "2-digit", year: "numeric" };
      return new Intl.DateTimeFormat("en-GB", options).format(
        new Date(dateString)
      );
    }
  };

  const getUname=(uname)=>{
    // alert(uname)
  }
  return (
    <>
      <AlertMessageComp
        type={props?.data?.alertdata.type}
        msg={props?.data?.alertdata.msg}
        vertical={props?.data?.alertdata.vertical}
        horizontal={props?.data?.alertdata.horizontal}
        setOpen={props?.data?.setAlertopen}
        open={props?.data?.alertopen}
      />
      <div>
        <Paper className="contner">
          <TableContainer sx={{ maxHeight: "50vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableHead >
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ width: `${column.minWidth}` }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="bodycon">
                {props?.data?.serachuserState
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((element, rowIndex) => (
                    <TableRow className="tblerowdata" key={rowIndex}>
                      {columns.map((column, columnIndex) => (
                        <TableCell
                          key={columnIndex}
                          align={column.align}
                          style={{ width: column.minWidth }}
                        >
                          {columnIndex === 0 ? (
                            element?.uname
                          ) : columnIndex === 1 ? (
                            element?.mob
                          ) : columnIndex === 2 ? (
                            element?.city
                          ) : columnIndex === 3 ? (
                            `${formatDate(element?.edate)}`
                          ) : columnIndex === 4 ? (
                            `${formatactiveDate(element?.adate)}`
                          ) : columnIndex === 5 ? (
                            `${formatactiveDate(element?.bdate)}`
                          ) : columnIndex === 6 ? (
                            element?.balance
                          ) : columnIndex === 7 ? (
                            <div
                              style={{ color: "blueviolet", cursor: "pointer" }}
                              onClick={() => {
                                props?.data?.handleuserwallet("loginid", element?.loginid);
                                props?.data?.handlemodalOpen();
                              }}
                            >
                              Wallet
                            </div>
                          ) : columnIndex === 8 ? (
                            <div
                              style={{ color: "blueviolet", cursor: "pointer" }}
                              onClick={()=>{
                                // props?.data?.navigate("/adminreport")
                                props?.data?.setLoginid(element?.loginid);
                                props?.data?.handlemodalOpenRpt();

                              }}
                            >
                              Ledger
                            </div>
                          ) : columnIndex === 9 ? (
                            <div className="actionbtn">
                              <Tooltip title="Account settings">
                                <IconButton
                                  onClick={handleClick}
                                  size="small"
                                  sx={{ ml: 2 }}
                                  aria-controls={
                                    open ? "account-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </Tooltip>
                              <Menu
                                key={element?.loginid}
                                anchorEl={anchorEl}                                
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    overflow: "visible",
                                    filter: "drop-shadow(1px 1px 1px #a6a6a6)",
                                    mt: 1.5,
                                    "& .MuiAvatar-root": {
                                      width: 32,
                                      height: 32,
                                      ml: -0.5,
                                      mr: 1,
                                    },
                                    "&:before": {
                                      content: '""',
                                      display: "block",
                                      position: "absolute",
                                      top: 10,
                                      right: -4,
                                      width: 10,
                                      height: 10,
                                      bgcolor: "background.paper",
                                      transform:
                                        "translateY(-50%) rotate(45deg)",
                                      zIndex: 0,
                                    },
                                  },
                                }}
                                transformOrigin={{
                                  horizontal: "right",
                                  vertical: "top",
                                }}
                                anchorOrigin={{
                                  horizontal: "left",
                                  vertical: "top",
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    handleOpen1();
                                    props?.data?.BlockUpdate(element?.loginid);
                                    handleClose();
                                  }}
                                >
                                  <ListItemIcon>
                                    <PersonOffIcon fontSize="small" />
                                  </ListItemIcon>
                                  Block
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    // alert(element?.loginid)
                                    props?.data?.ActiveUpdate(element?.loginid);
                                  }}
                                >
                                  <ListItemIcon>
                                    <PersonIcon fontSize="small" />
                                  </ListItemIcon>
                                  Active
                                </MenuItem>                                
                              </Menu>
                            </div>
                          ) : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={props?.data?.serachuserState?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      <Modal
        open={props?.data?.openmodal}
        onClose={props?.data?.handlemodalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={style.modaladd}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Walllet
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                cursor: "pointer",
              }}
            >
              <CloseIcon
                onClick={() => {
                  props?.data?.handlemodalClose();
                }}
              />
            </div>
          </Typography>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={12}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="dr"
                  control={
                    <Radio
                      name="dr"
                      onChange={() => {
                        props?.data?.handleuserwallet("isdrcr", 0);
                      }}
                    />
                  }
                  label="Add Fund"
                />
                <FormControlLabel
                  value="cr"
                  control={
                    <Radio
                      name="cr"
                      onChange={() => {
                        props?.data?.handleuserwallet("isdrcr", 1);
                      }}
                    />
                  }
                  label="Withdrawal Fund"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-disabled"
                label="Winner Point"
                fullWidth
                size="small"
                name="points"
                onChange={props?.data?.handleuserwallet}
                InputProps={{
                  classes: {
                    input: style.textinp_left,
                  },
                  style: {
                    padding: "1px",
                  },
                  inputProps: {
                    onInput: handleDecimalInputChange,
                    maxLength: 10,
                  },
                }}
                InputLabelProps={{
                  className: style.textinplbl,
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={3}
                placeholder="Remark"
                name="remark"
                onChange={props?.data?.handleuserwallet}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{
                  width: "97.3%",
                  // marginTop: "10px",
                  padding: "10px",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                // mt: 2,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <div
                className={style.submitbtncon}
                onClick={() => {
                  props?.data?.WalletSave();
                }}
              >
                <button type="button" className={style.submitbtn}>
                  Submit
                </button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>


      <Modal
        open={props?.data?.openmodalRpt}
        onClose={props?.data?.handlemodalCloseRpt}       
      >
        <Box className={style.modaladdrpt}>
            <AdminLedgerReport loginid={props?.data?.loginid}/>
        </Box>
      </Modal>
    </>
  );
};
export default RegisterUserListTable;
