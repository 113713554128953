import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import UserPlayMaster from '../../Components/UserPlayMaster/UserPlayMaster'

const UserPlaymasterScreen = () => {
  return (
    <div>
        <Appbar />
        <UserPlayMaster />
    </div>
  )
}

export default UserPlaymasterScreen