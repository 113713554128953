import { Grid, Paper } from "@mui/material";
import React from "react";
import style from "./FlexibleBox.module.css";
import CheckIcon from '@mui/icons-material/Check';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
const FlexibleBox = (props) => {
    const items = ["John","Jane Doe","Alice","Michael Johnson","Alice","Bob Smith"," John","Jane Doe","Michael Johnson","Alice","Bob Smith"];

  return (
    <div className={style.mainbox}>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          <Paper elevation={2} className={style.boxpapercon}>
        <fieldset>
          <legend>{props?.data?.boxname || "Box Name"}</legend>
          {props?.data?.boxdata?.map((element, index) => {
            return(
              <div className={style.name}>
                <div className={style.mainnamebox}>
                <div className={style.iconcon}>
                    <TaskAltIcon style={{fontSize:"20px"}} color="success"/>
                </div>
                <div className={style.nametxt}>
                 {element?.name}
                </div>
                </div>
                </div>
            )
          })}
          
          </fieldset>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default FlexibleBox;
