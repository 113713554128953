import React, { useContext, useEffect, useReducer, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css";
import { Box, Checkbox, Modal, TableContainer, TextField } from "@mui/material";
import { styled } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";
import Filter1Icon from '@mui/icons-material/Filter1';
import AlertComp from "../Components/AlertMessageCom/AlertComp";
import DoublePanConfigration from "../Components/DoublePanConfigrution/DoublePanConfigration";
import CloseIcon from '@mui/icons-material/Close';

 const GameTypeTable = (props) => {
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 1,
  };
  const [open, setOpen] = useState(false);
  const [gameData, setGameData] = useState({});
  const handleOpen = () => {       
   setOpen(true)
  };
  const handleClose = () => setOpen(false);
  return (
    <>
    <AlertComp/>
    <div>
      <Paper className="contner">
        <TableContainer sx={{ maxHeight: "55vh" }}>
          <Table
          size="small"
            stickyHeader
            aria-label="sticky table"
            style={{
              fontSize: "100pt!important",
            }}
          >
            <TableHead>
              <TableRow >
                <TableCell>Game Name</TableCell>
                <TableCell style={{textAlign:"end"}}>Action </TableCell>
              </TableRow>
            </TableHead>

            <TableBody className="bodycon">
            {props?.data?.gamesearchState?.slice(props?.data?.page * props?.data?.rowsPerPage, props?.data?.page * props?.data?.rowsPerPage + props?.data?.rowsPerPage)?.map((element) => {
              return(
                <TableRow className="tblerowdata">
                  <TableCell component="th" scope="row">
                  {element?.gsubname}
                  </TableCell>
                  <TableCell style={{display:"flex",justifyContent:"end"}}>
                    <div className="actionbtn">
                    <Tooltip title="Double Pan" placement="top" arrow>
                      <div className="edde" onClick={(e) =>{ setGameData({gsubname:element?.gsubname,gmid:element?.gmid});
                       handleOpen();}}>
                        <Filter1Icon/>
                        </div>
                      </Tooltip>
                      <Tooltip title="Edit" placement="top" arrow>
                        <div className="edde">
                          <EditIcon 
                          sx={{ fontSize: "20px" }} 
                          onClick={()=>{
                            let data={
                                ...element
                                // fbid:element?.fbid
                              }
                             props?.data?.EditGame(data)
                          }}
                          />
                        </div>
                      </Tooltip>
                      <Tooltip title="Delete" placement="top" arrow>
                        <div className="edde">
                          <DeleteIcon 
                         onClick={()=>{
                          let deldata={
                            gmid:element?.gmid
                          }
                          props?.data?.DeleteGame(deldata)
                        }}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={props?.data?.gamesearchState?.length}
          rowsPerPage={props?.data?.rowsPerPage}
          page={props?.data?.page}
          rowsPerPageOptions={props?.data?.rowsPerPageOptions}
          onPageChange={props?.data?.handleChangePage}
          onRowsPerPageChange={props?.data?.handleChangeRowsPerPage}
        />
      </Paper>
    </div>
    <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={styleModal}>
            <div style={{width:"100%",display:"flex",justifyContent:"space-between",cursor:"pointer"}}
            onClick={handleClose}> 

              <div style={{fontWeight:"bold",fontStyle:"italic"}}><u>{gameData?.gsubname}</u></div>
            <CloseIcon/> 
            
            </div>  
           <DoublePanConfigration data={{gameData:gameData,handleClose:handleClose}}/>
        </Box>
      </Modal>
    </>
  );
};
export default GameTypeTable;
