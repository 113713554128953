import React, { useEffect } from "react";
import {
  handleAlphanumericInputChange,
  handleAlphanumericInputwithSpaceChange,
} from "../../MIS/Global";
import style from "./GameMaster.module.css";
import { Grid, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import GameTypeTable from "../../Tables/GameTypeTable";
import GameTypeListTable from "../../Tables/GameTypeListTable";
import useGameMaster from "../../API/Hooks/useGameMaster";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import useGtypemaster from "../../API/Hooks/useGtypemaster";

const GameMaster = () => {
   const Gamemaster = { ...useGameMaster() };

  return (
    <>
      <AlertMessageComp
        type={Gamemaster?.alertdata.type}
        msg={Gamemaster?.alertdata.msg}
        vertical={Gamemaster?.alertdata.vertical}
        horizontal={Gamemaster?.alertdata.horizontal}
        setOpen={Gamemaster?.setAlertopen}
        open={Gamemaster?.alertopen}
      />
      <div className={style.gamemain}>
        <div className={style.gamepart1}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className={style.gameheading}>Game Master</div>
              <div
                className={style.savebtncon}
                onClick={() => {
                  Gamemaster?.GameSave();
                }}
              >
                <button type="button" className={style.savebtn}>
                  Save
                </button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={style.gamepart2}>
          <Grid container spacing={2} className={style.gamecontainer}>
            <Grid item xs={12} md={5} className={style.gridpapercon}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="outlined-disabled"
                    label="Game Name"
                    fullWidth
                    size="small"
                    name="gsubname"
                    value={Gamemaster?.gamestate?.gsubname || ""}
                    onChange={Gamemaster?.handlegame}
                    InputProps={{
                      classes: {
                        input: style.textinp_left,
                      },
                      style: {
                        padding: "1px",
                      },
                      inputProps: {
                        onInput: handleAlphanumericInputwithSpaceChange,
                        maxLength: 20,
                      },
                    }}
                    InputLabelProps={{
                      className: style.textinplbl,
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <GameTypeTable data={Gamemaster} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={0.1}></Grid>
            <Grid item xs={12} md={6.8} className={style.gridpapercon}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <GameTypeListTable data={Gamemaster} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default GameMaster;
