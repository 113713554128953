import { useEffect, useReducer, useState } from "react";
import { tostmsg } from "../../MIS/Global";
import numJsonData from "../../MIS/Numdata.json";
import {
  Deleteplaymaster,
  GetPlaymaster,
  SaveAllPlaymaster,
  SearchAllPlaydata,
} from "../Model/PlaymasterModel";
import PlayMasterReducer from "../Reducer/PlayMasterReducer";
import { useNavigate } from "react-router-dom";
import { FlashOnRounded } from "@mui/icons-material";

function useUserPlayHistory() {
  const navigate = useNavigate();
  const [alertdata, setAlertdata] = useState({});
  const [alertopen, setAlertopen] = useState(false);

  const [searchplaymasterState, searchplaymasterDispach] = useReducer(
    PlayMasterReducer,
    []
  );
  const [searchplaymaster_groupState, searchplaymaster_groupDispach] =
    useReducer(PlayMasterReducer, []);
  const [searchplaymaster_orgState, searchplaymaster_orgDispach] = useReducer(
    PlayMasterReducer,
    []
  );
  const [ctrlsettingState, setCtrlSetting] = useState({
    tdate: "",
    fdatedisable: false,
  });
  const [filterState, setFilter] = useState({
    pfdate: new Date(),
    ptdate: new Date(),
  });
  const slipPrint = (id)=>{
    // navigate("/payslip/"+id)
    const url = `/payslip/${id}`;    
    // Open the URL in a new tab
    window.open(url, '_blank');
  }
  const geHourTime = (dateTime,isType) => {
    let hourVal=false;
    if(isType.toString()==="12"){hourVal=true}
    let curDate=new Date(dateTime);
    const timeString = curDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: hourVal,
    });
    return timeString;
  };

const calculateDigitSum = (number,istype) => {
  if(istype.toString().toUpperCase()==="jodi".toString().toUpperCase()){
    let numberString = number.split('').toString();
    return numberString[0];
  }
    const numberString = number.toString();
    const sum = Array.from(numberString).reduce((acc, digit) => acc + parseInt(digit, 10), 0);
    if (sum >= 10) { 
     return sum % 10;
   }
   return sum;
 };

  const formatDatetime_YMD = (date, pmday) => {
    const currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + pmday);
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")} ${currentDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${currentDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;

    return formattedDate;
  };

  const formatDatetime_DMY = (date, pmday) => {
    const currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + pmday);

    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear();

    const hours = currentDate.getHours() % 12 || 12; // Convert to 12-hour format
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");
    const period = currentDate.getHours() >= 12 ? "PM" : "AM";

    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes} ${period}`;

    return formattedDate;
  };
  const groupByJsonData = (array, property) => {
    return array.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  };
  const handlePlaySearch = (istype, value) => {
    if (istype == "trid") {
      const keysToSearch = ["trid"];
      const filteredData = searchplaymaster_orgState?.filter((item) => {
        return keysToSearch?.some((key) => {
          return item[key]
            ?.toString()
            .toLowerCase()
            ?.includes(value.toString().toLowerCase());
        });
      });
      searchplaymasterDispach({
        type: "details",
        data: filteredData,
      });
    } else if (istype == "pfdate") {
      setFilter({ ...filterState, pfdate: formatDatetime_YMD(value, 0) });
    } else if (istype == "ptdate") {
      alert();
      setFilter({ ...filterState, ptdate: formatDatetime_YMD(value, 0) });
    }
  };

  const getGtmname = (id) => {
    if (id.toString() === "1") {
      return "Open";
    } else if (id.toString() === "2") {
      return "Close";
    } else if (id.toString() === "3") {
      return "Jodi";
    } else if (id.toString() === "4") {
      return "Open Panna";
    } else if (id.toString() === "5") {
      return "Close Panna";
    } else if (id.toString() === "6") {
      return "Gunule";
    } else if (id.toString() === "7") {
      return "Jackpot";
    } else if (id.toString() === "8") {
      return "Ulta Gunule";
    }
  };

  const handlePlayStateChnage = () => {
    const sortedArray = searchplaymasterState.sort((a, b) => b?.playid - a?.playid);
    const groupedData =
    sortedArray?.length > 0
        ? groupByJsonData(sortedArray, "trid")
        : [];
    searchplaymaster_groupDispach({
      type: "details",
      data: groupedData,
    });
  };
  const handleInputChange = (event,istype) => {
    const value = event.target.value; 
    const onlyNumsSingle = /^(\b|\b[0-9])$/;
    const onlyNumsDouble = /^(\b|\b[0-9]{1,2})$/;
    const onlyNumsTriple = /^(\b|\b[0-9]{1,3})$/;
    // setNum1("");setNum2("");
    if (
      ((istype === "Open" || istype === "Close") &&
        onlyNumsSingle.test(value)) ||
      event.key === "Backspace" ||
      event.key === "Delete" ||
      value == ""
    ) {
      return value;
    } else if (
      istype === "Jodi" &&
      onlyNumsDouble.test(value) &&
      event.target.name == "num1"
    ) {
      return value;
    } else if (
      istype === "Open Panna" &&
      onlyNumsTriple.test(value) &&
      event.target.name == "num1"
    ) {
      return value;
    } else if (
      istype === "Open Panna" &&
      onlyNumsTriple.test(value) &&
      event.target.name == "num2"
    ) {
      return value;
    } else if (
      istype === "Close Panna" &&
      onlyNumsTriple.test(value) &&
      event.target.name == "num1"
    ) {
      return value;
    } else if (
      istype === "Close Panna" &&
      onlyNumsTriple.test(value) &&
      event.target.name == "num2"
    ) {
      return value;
    } else if (
      istype === "Jackpot" &&
      onlyNumsTriple.test(value) &&
      event.target.name == "num1"
    ) {
      return value;
    } else if (
      istype === "Jackpot" &&
      onlyNumsTriple.test(value) &&
      event.target.name == "num2"
    ) {
      return value;
    } else if (
      istype === "Gunule" &&
      event.target.name == "num1" &&
      // onlyNumsSingle.test(value)
      onlyNumsTriple.test(value)
    ) {
      return value;
    } else if (
      istype === "Gunule" &&
      event.target.name == "num2" &&
      // onlyNumsTriple.test(value)
      onlyNumsSingle.test(value)
    ) {
      return value;
    } else if (
      istype === "Ulta Gunule" &&
      event.target.name == "num1" &&
      // onlyNumsTriple.test(value)
      onlyNumsSingle.test(value)
    ) {
      return value;
    } else if (
      istype === "Ulta Gunule" &&
      event.target.name == "num2" &&
      // onlyNumsSingle.test(value)
      onlyNumsTriple.test(value)
    ) {
      return value;
    }
  };
  const handleUpdatePlay = (id, key, value,orgnpoints,isposition,istype) => {    
    const npoints = orgnpoints.split("X");
    let regpan=0;let newvalue=value;let cutpan="";
    if (isposition=== 0 ) {
       regpan=calculateDigitSum(value,istype);
    }else{
      regpan=calculateDigitSum(npoints[0],istype);
    }
     const valueAtIndex1 = npoints?.[1];
     if(key=== "npoints"){
      if (typeof valueAtIndex1 !== 'undefined') {
        newvalue=isposition==1?`${value}X${npoints[1]}`:`${npoints[0]}X${value}`;
      }else{
        newvalue=value
      }
    }    
     searchplaymasterDispach({
      type: "updateGetPlay",
      id: id,
      name: key,
      value: newvalue,
      regpan:regpan,
      cutpan:cutpan,
    });
  };
  const handleDeletePlay = async (id,data) => {   
 
    let count=data?.cnt;
    count=count-1;
    try {
      let res = await Deleteplaymaster(data);
      let rettostmsg = tostmsg(res);
      if (res.code == 200 && res?.data[0]?.dt > 0) {
        rettostmsg = tostmsg({ msg: "Play Deleted Successfully", code: 200 });
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        searchplaymasterDispach({
          type: "deleteGetPlay",
          id: id,
        });
        searchplaymaster_orgDispach({
          type: "deleteGetPlay",
          id: id,
        });
        // if(count<=0){

        // }

      } else {
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      }
    } catch (e) {
      setAlertdata({ code: 100, msg: "Frontend failed please try again" });
      setAlertopen(true);
    }
  };
  const handleIsDisable = (id,gtmid)=>{
    const filteredArray = searchplaymaster_orgState.filter(element => element.playid === id);
    let CutOpTime=geHourTime(filteredArray[0]?.cutoptime,24);
        let CutClTime=geHourTime(filteredArray[0]?.cutcltime,24);
        let currentTime=geHourTime(new Date(),24);
        let opStatus=false;let clStatus=false;
        if(window.location.pathname=="/uplayhistory"){
          clStatus=true;
          opStatus=true;
        }else{
          if (currentTime <= CutOpTime) {
            opStatus = false;
          } else {
            opStatus = true;
          }
          if (currentTime <= CutClTime) {
            clStatus = false;
          } else {
            clStatus = true;
          }
        }
      
        // alert(gtmid)
        if(gtmid===2 || gtmid===5){
          return clStatus;
        }else{return opStatus;}
        
  }

  const getATodaysPlayHistory = async (data) => {
    try {
      let getResData = await SearchAllPlaydata(data);
      if (getResData.code === 200) {
        
        const sortedArray = getResData?.data.sort((a, b) => a.playid - b.playid);         
        searchplaymasterDispach({
          type: "details",
          data: sortedArray,
        });
        searchplaymaster_orgDispach({
          type: "details",
          data: sortedArray,
        });
      }
    } catch (error) {
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };
  
  const checkAscendingOrder = (rnum) => {
    const digits = rnum.toString().split("").map(Number);
    if (digits.length <= 2) {
      return false;
    } else if (digits[2] <= 0) {
      if (digits[0] <= digits[1]) {
        return true;
      } else {
        return false;
      }
    } else if (digits.length === 3) {
      if (digits[0] <= digits[1] && digits[1] <= digits[2]) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const PlayMasterDataSave = async (data) => {
    try {
      let res={};let rettostmsg={};let validateData=false;
      let dataArr=[];let onceFalse=false;
      const totalSum = data.reduce((accumulator, item) => {
          const points = parseInt(item.points, 10);
          return accumulator + points;
      }, 0);

      // Update each object with the total sum of points
      data.forEach((item) => {
          item.totpoints = totalSum;
      });
       data.forEach((element,index) => {
        let npoint=element?.npoints.split("X")
        if(element?.gname.toString().toUpperCase()=="open panna".toString().toUpperCase()){
           validateData=checkAscendingOrder(npoint[0])
           if(validateData===false){       
            rettostmsg = tostmsg({ msg: `Your ${element?.gname} number[${npoint[0]}] is invalid.`, code: 100 });
            setAlertdata(rettostmsg);
            setAlertopen(rettostmsg.open);
            return;}
         }else if(element?.gname.toString().toUpperCase()=="close panna".toString().toUpperCase()){
           validateData=checkAscendingOrder(npoint[0])
            if(validateData===false){       
          rettostmsg = tostmsg({ msg: `Your ${element?.gname} number[${npoint[0]}] is invalid.`, code: 100 });
          setAlertdata(rettostmsg);
          setAlertopen(rettostmsg.open);
          return;}
          }else if(element?.gname.toString().toUpperCase()=="gunule".toString().toUpperCase()){
          validateData=checkAscendingOrder(npoint[0])
          if(validateData===false){       
            rettostmsg = tostmsg({ msg: `Your ${element?.gname} number[${npoint[0]}] is invalid.`, code: 100 });
            setAlertdata(rettostmsg);
            setAlertopen(rettostmsg.open);
            return;}
         }else if(element?.gname.toString().toUpperCase()=="ulta gunule".toString().toUpperCase()){
          validateData=checkAscendingOrder(npoint[1])
          if(validateData===false){       
            rettostmsg = tostmsg({ msg: `Your ${element?.gname} number[${npoint[1]}] is invalid.`, code: 100 });
            setAlertdata(rettostmsg);
            setAlertopen(rettostmsg.open);
            return;}
        }else if(element?.gname.toString().toUpperCase()=="jackpot".toString().toUpperCase()){
          validateData=checkAscendingOrder(npoint[0]);
          if(validateData===false){       
            rettostmsg = tostmsg({ msg: `Your ${element?.gname} number[${npoint[0]}] is invalid.`, code: 100 });
            setAlertdata(rettostmsg);
            setAlertopen(rettostmsg.open);
            return;}
            validateData=checkAscendingOrder(npoint[1]);
          if(validateData===false){       
            rettostmsg = tostmsg({ msg: `Your ${element?.gname} number[${npoint[1]}] is invalid.`, code: 100 });
            setAlertdata(rettostmsg);
            setAlertopen(rettostmsg.open);
            return;}
          
        } 
      });
      
        res = await SaveAllPlaymaster(data);       
       
       rettostmsg = tostmsg(res);
       if (res.code == 200 && res?.data[0]?.dt > 0) {
        rettostmsg = tostmsg({ msg: "Play Updated Successfully", code: 200 });
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      }else if (res.data?.[0]?.dt == -1) {
        rettostmsg = tostmsg({
          code: 100,
          msg: "Insufficient balance for play",
          duration: 5000,
        });        
        setAlertdata(rettostmsg);
        setAlertopen(true);
        return;
      }       
      else {
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      }
    } catch (e) {      
      setAlertdata({ code: 100, msg: "Frontend failed please try again" });
      setAlertopen(true);
    }
  };

  const handlePlaySearchClick = () => {
    let data = {
      pfdate: filterState?.pfdate,
      ptdate: formatDatetime_YMD(filterState?.ptdate, 1),
      loginid: localStorage.getItem("loginId"),
    };
    getATodaysPlayHistory(data);
  };

  useEffect(() => {
    handlePlayStateChnage();
  }, [searchplaymasterState]);

  useEffect(() => {
    let logdata=localStorage.getItem("logindata");
    const jsonObject = JSON.parse(logdata);
    const currentPath = window.location.pathname;
    if (currentPath !== "/uplayhistory") {
      setCtrlSetting({ tdate: "none", fdatedisable: true,fmindate:formatDatetime_YMD(jsonObject?.adate,0) });
    }else{
      setCtrlSetting({ tdate: "", fdatedisable: false,fmindate:formatDatetime_YMD(jsonObject?.adate,0) });
    }
    setFilter({
      pfdate: formatDatetime_YMD(new Date(), 0),
      ptdate: formatDatetime_YMD(new Date(), 0),
      loginid: localStorage.getItem("loginId"),
    });
    getATodaysPlayHistory({
      pfdate: formatDatetime_YMD(new Date(), 0),
      ptdate: formatDatetime_YMD(new Date(), 1),
      loginid: localStorage.getItem("loginId"),
    });
  }, []);

  return {
    alertdata,
    alertopen,
    setAlertopen,
    searchplaymasterState,
    searchplaymaster_orgState,
    searchplaymaster_groupState,
    formatDatetime_DMY,
    ctrlsettingState,
    handleUpdatePlay,
    handleDeletePlay,
    handlePlayStateChnage,
    PlayMasterDataSave,
    getGtmname,
    handlePlaySearch,
    handlePlaySearchClick,
    filterState,handleIsDisable,
    slipPrint,handleInputChange,
  };
}

export default useUserPlayHistory;
