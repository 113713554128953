import { callAjax } from "../../MIS/Global";


export const SaveCutpan= async (data) => {
  let ret = {};
  ret = await callAjax("bulksave", data);
  return ret;
};

export const DeleteCutpan= async (data) => {
  let ret = {};
  ret = await callAjax("bulkdelete", data);
  return ret;
};

export const GetCupanData_wov = async (data) => {
  let ret = {};
  ret = await callAjax("searchcutpan", data);
  return ret;
}
export const GetAllCutpanData = async (data) => {
    let ret = {};
    ret = await callAjax("getallcutpan", data);
    return ret;
};