import React from 'react'
 import FundRequestList from '../../Components/FundRequest/FundRequestList'
import Appbar from '../../Components/Appbar/Appbar'
 const FundRequestListScreen = () => {
  return (
    <div>
         <Appbar/>   
         <FundRequestList/>
    </div>
  )
}

export default FundRequestListScreen