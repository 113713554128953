import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { makeStyles } from '@material-ui/core';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getPlayData } from '../../API/Reducer/playReducer';
import { formatDatetime_DMY, formatDatetime_YMD, getHourTime } from '../../MIS/Global';
import SearchIcon from "@mui/icons-material/Search";
import style from "./AdminPlay.module.css";



const AdminPlayList = () => {
  const useStyles = makeStyles((theme) => ({
    smallDateTimePicker: {
      "& .MuiInputBase-input": {
        fontSize: "13px",
        fontWeight: "600 !important",
        width: "100%",
        height: "100%",
        padding: "10px",
        margin: 0,
      },
      "& .MuiInputLabel-root": {
        fontSize: "16px",
        fontWeight: "600 !important", // Adjust font size as needed
        display: "flex",
        alignItems: "center",
      },
    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const { playList } = useSelector((state) => state.play);
  const [page, setPage] = useState(0);
  const [userorg, setUserOrg] = useState(playList);  
  const [userSearch, setUserSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [whrdata, setWhrData] = useState({pfdate:new Date(),ptdate:new Date()})
  let bgcolor="";
   const rowsPerPageOptions = [
    25,
    50,
    100,
    125,
    150,
    200,
    300,
    500,
    1000,
    { value: userorg?.length, label: "All" },
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(+event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    dispatch(getPlayData({isentry:"1",isorderplayid:"1",pfdate:formatDatetime_YMD(new Date(),0,""),ptdate:formatDatetime_YMD(new Date(),0,"")}))
  }, [])
  useEffect(() => {
     setUserOrg(playList)     
  }, [playList])
  
  useEffect(() => {
    const keysToSearch = ["uname", "mob","trid"];
    const filteredData = playList?.filter((item) => {
      return keysToSearch?.some((key) => {
        return item[key]
          ?.toString()
          .toLowerCase()
          ?.includes(userSearch.toString().toLowerCase());
      });
    }); 
    setUserOrg(filteredData)
  }, [userSearch]);

  const handleFromToDateValidation=(istypedate,edate)=>{    
    if(istypedate==="fdate"){
      let tdate= formatDatetime_YMD(whrdata?.ptdate,0,"ymd");
      let fdate= formatDatetime_YMD(edate,0,"ymd");
      if(fdate>tdate){
        let xdate= formatDatetime_YMD(edate,1,"ymd");
        setWhrData({...whrdata,ptdate:xdate})
      }
    }else if(istypedate==="tdate"){
      let tdate= formatDatetime_YMD(edate,0,"ymd");
      let fdate= formatDatetime_YMD(whrdata?.pfdate,0,"ymd");
      if(fdate>tdate){
        let xdate= formatDatetime_YMD(edate,-1,"ymd");
        setWhrData({...whrdata,pfdate:xdate})
      }
    }
  }
  return (
    <>
        <Grid container spacing={2} p={1}>
          <Grid item md={12} xs={12}><h3>Admin Play Report</h3></Grid>
          <Grid item md={1.5} xs={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className={classes.smallDateTimePicker}
              label="From Date"              
              value={dayjs(whrdata?.pfdate)}
              onChange={(e)=>{setWhrData({...whrdata,pfdate:e});handleFromToDateValidation("fdate",e)}}
              renderInput={(props) => (
                <TextField {...props} variant="standard" />
              )}
              openTo="day"
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
          </Grid>
          <Grid item md={1.5} xs={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className={classes.smallDateTimePicker}
              label="To Date"              
              value={dayjs(whrdata?.ptdate)}
              onChange={(e)=>{setWhrData({...whrdata,ptdate:e});handleFromToDateValidation("tdate",e)}}
              renderInput={(props) => (
                <TextField {...props} variant="standard" />
              )}
              openTo="day"
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
          </Grid>
          <Grid item md={9} xs={6} sx={{display:"flex",justifyContent:"end"}}>
            <Button type="button" size='small' variant='contained' color='secondary'
             onClick={()=>{
              dispatch(getPlayData({isentry:"1",isorderplayid:"1",
              pfdate:formatDatetime_YMD(whrdata?.pfdate,0,""),ptdate:formatDatetime_YMD(whrdata?.ptdate,0,"")}))
             }}
             >
             <SearchIcon sx={{ fontSize: "22px", cursor: "pointer" }} />
            </Button>
          </Grid>
          <Grid item md={12} xs={12}>
          <Paper className="">
          <Grid container item xs={12} md={12} className={style.searchcont}>
          <Grid item md={3} xs={12}>
           <div className={style.searchcon}>
                  <SearchIcon sx={{ fontSize: "22px", cursor: "pointer" }} />
                  <input
                    onChange={(e)=>{setUserSearch(e.target.value)}}
                    type="text"
                    className={style.searchfield}
                    placeholder="Search"
                  />
                </div>
          </Grid>
            
              </Grid>
           <TableContainer sx={{ maxHeight: "60vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableHead>
                <TableRow>               
                  <TableCell style={{fontWeight:"bold"}}>User Name</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Mobile</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Date</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Transaction ID</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Game Name</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Game Type</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Number</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Points</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="bodycon">
                {userorg
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((element, index) => {                    
                    
                    if(userorg?.[index-1]?.trid.toString()!==element?.trid.toString()){          
                      if(index===0){
                        bgcolor="#fff";
                      }         
                      else if(bgcolor==="#fff"){
                        bgcolor="#F1F0E8";                        
                      }else if(bgcolor==="#F1F0E8"){
                        bgcolor="#fff";
                      }
                     }
                  
                     return (
                      <TableRow
                        className="tblerowdata"
                        style={{ backgroundColor: bgcolor }}                        
                      >                       
                        <TableCell>{element?.uname}</TableCell>
                        <TableCell>{element?.mob}</TableCell>
                        <TableCell>{formatDatetime_DMY(element?.playdate,0,"")}{getHourTime(element?.playdate,12)}</TableCell>
                        <TableCell>{element?.trid}</TableCell>
                        <TableCell>{element?.gsubname}</TableCell>
                        <TableCell>{element?.gname}</TableCell>
                        <TableCell>{element?.npoints}</TableCell>
                        <TableCell>{element?.points}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={userorg?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
          </Grid>
        </Grid>
    </>
  )
}

export default AdminPlayList