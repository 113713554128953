import { Grid, Paper, TextField, Button } from "@mui/material";
import React, { useState } from "react";
import style from "./HomePage.module.css";
import MobileMenu from "../MobileMenu/MobileMenu";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import useMenumaster from "../../API/Hooks/useMenumaster";
import InfoBoxPage from "../GlobalComponent/InfoBoxPage";
import { useNavigate } from "react-router-dom";
import MicIcon from '@mui/icons-material/Mic';

const HomePage = () => {
  const navigate=useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpen1 = () => setOpen1(true);
  const [editOpen, setEditOpen] = useState(false); // State to manage the visibility of the edit section
  
  // Function to handle opening the edit section
  const handleOpenEdit = () => {
    setEditOpen(true);
  };

  // Function to handle closing the edit section
  const handleCloseEdit = () => {
    setEditOpen(false);
  };
  const [open1, setOpen1] = React.useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuMaster = { ...useMenumaster() };

  return (
    <div className={style.homecontainer}>
      <div className={style.homepart1}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <div className={style.boxconstyle}>
              <div className={style.cardcontaint}>
                <div className={style.total}>
                  <span className={style.usercounttxt}>Current Balance</span>
                  <span className={style.totalcount}>
                  {menuMaster?.userdatastate && menuMaster?.userdatastate[0]
                    ? Number(menuMaster?.userdatastate[0]?.balance).toFixed(2)
                    : "0.00"}</span>
                </div>
                <div>
                  <AccountBalanceWalletIcon sx={{ fontSize: "34px" }} />
                </div>
              </div>
              <div className={style.cardcontaint1}>
                <div className={style.boxtxtcon}>
                  <div className={style.usercounttxt}>
                  {menuMaster?.userdatastate && menuMaster?.userdatastate[0]
                    ? Number(menuMaster?.userdatastate[0]?.totcr).toFixed(2)
                    : "0.00"}</div>
                  <div className={style.totalusertxt}>Cr</div>
                </div>
                <div className={style.boxtxtcon}>
                  <div className={style.usercounttxt}>
                  {menuMaster?.userdatastate && menuMaster?.userdatastate[0]
                    ? Number(menuMaster?.userdatastate[0]?.totdr).toFixed(2)
                    : "0.00"}</div>
                  <div className={style.totalusertxt}>Dr</div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} md={12} className={style.viewallcon}>
            <div className={style.usercounttxt}>Play Game</div>
            {/* <div className={style.viewalltxt}>View All</div> */}
          </Grid>
          <Grid item xs={6} md={12} sx={{display:"flex",justifyContent:"end"}}>
          <Button variant="contained" color="secondary" size="small" onClick={()=>{navigate("/uservplay")}}>
           <MicIcon/>Play Beta
          </Button>
             
            {/* <div className={style.viewalltxt}>View All</div> */}
          </Grid>
        </Grid>
      </div>
      <div className={style.homepart2}>
        <Grid container spacing={2} className={style.cardlistscroll}>
          {menuMaster?.gamedatastate?.map((element, index) => {
            let bgcolor = "#F1F0E8";
            if (index % 2 == 0) {
              bgcolor = "#fff";
            }
            return (
              <Grid item xs={12} md={4}>
                <Paper
                  elevation={3}
                  className={style.homepaper}
                  sx={{ backgroundColor: bgcolor }}
                  onClick={()=>{
                    menuMaster?.navigateUserplayMaster(element?.gsubname);
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <div className={style.usercounttxt}>{element?.gsubname}</div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )
          })}
        
          {menuMaster?.gamedatastate.length <=0 ? ( <Grid item md={12} xs={12} p={2}>
          <InfoBoxPage data={{msg:"Today's play is not open yet."}}/>
          </Grid>):""}
        </Grid>
       </div>
      <div className={style.homepart3}>
        <MobileMenu />
      </div>
    </div>
  );
};

export default HomePage;
