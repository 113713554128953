import { Grid, Paper, TextField } from "@mui/material";
import React from "react";
import style from "./AdminGame.module.css";
import StatusTextsuggest from "../../Textsuggestfield/StatusTextsuggest";
import { useState } from "react";
import image from "../../Assets/gray.png";
import GameMasterTable from "../../Tables/GameMasterTable";
import SearchIcon from "@mui/icons-material/Search";
import {
  handleAlphanumericInputChange,
  handleNumInputChange,
} from "../../MIS/Global";
import useGtypemaster from "../../API/Hooks/useGtypemaster";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";

const AdminGameMaster = () => {
  const {
    status,
    setStatus,
    handletype,
    Savegtype,
    alertdata,
    alertopen,
    setAlertopen,
    typesearchState,
    Deletegtype,
  } = useGtypemaster();

  return (
    <>
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <div className={style.gamemain}>
        <div className={style.gamepart1}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className={style.gameheading}>Game Type Master</div>
              <div
                className={style.savebtncon}
                onClick={() => {
                  Savegtype();
                }}
              >
                <button type="button" className={style.savebtn}>
                  Save
                </button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={style.gamepart2}>
          <Grid container spacing={2} className={style.gamecontainer}>
            <Grid item xs={12} md={4} className={style.gridpapercon}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="outlined-disabled"
                    label="Game Name"
                    fullWidth
                    size="small"
                    name="gname"
                    onChange={handletype}
                    InputProps={{
                      classes: {
                        input: style.textinp_left,
                      },
                      style: {
                        padding: "1px",
                      },
                      inputProps: {
                        onInput: handleAlphanumericInputChange,
                      },
                    }}
                    InputLabelProps={{
                      className: style.textinplbl,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="outlined-disabled"
                    label="Winner Point"
                    fullWidth
                    size="small"
                    name="wnrpointcalc"
                    onChange={handletype}
                    InputProps={{
                      classes: {
                        input: style.textinp_left,
                      },
                      style: {
                        padding: "1px",
                      },
                      inputProps: {
                        // maxLength: 6,
                        onInput: handleNumInputChange,
                      },
                    }}
                    InputLabelProps={{
                      className: style.textinplbl,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <StatusTextsuggest status={status} setStatus={setStatus} />
                </Grid>
                <Grid item xs={12} md={12}>
                  <div className={style.imgconscroll}>
                    <div className={style.subimgboxstyle}>
                      <img
                        src="https://s3.envato.com/files/416434041/preview_screenshots/14_preview14.jpg"
                        className={style.subimg1}
                        alt=""
                      />
                      <div
                        className={style.uplodeimg}
                        // onClick={triggerFileInput}
                      >
                        Upload Image
                      </div>
                    </div>
                    <input
                      type="file"
                      id="fileInput"
                      accept="image/*"
                      style={{ display: "none" }}
                      // onChange={handleImageUpload}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={0.1}></Grid>
            <Grid item xs={12} md={7.8} className={style.gridpapercon}>
              <div className={style.gamesearchheight}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} className={style.searchposition}>
                    <div className={style.searchcon}>
                      <SearchIcon
                        sx={{ fontSize: "22px", cursor: "pointer" }}
                      />
                      <input
                        type="text"
                        className={style.searchfield}
                        placeholder="Search"
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={style.tableheight}>
                <GameMasterTable
                  typesearchState={typesearchState}
                  Deletegtype={Deletegtype}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default AdminGameMaster;
