import React, { useEffect, useReducer, useState } from "react";
import UserSignupReducer from "../Reducer/UserSignupReducer";
import { GetUserdata } from "../Model/UserSignupModel";
import { tostmsg } from "../../MIS/Global";
import CutpanmasterReducer from "../Reducer/CutpanmasterReducer";
import {
  DeleteCutpan,
  GetAllCutpanData,
  GetCupanData_wov,
  SaveCutpan,
} from "../Model/CutpanmasterModel";
import { GetGtypemaster } from "../Model/gtypemasterModel";

function useCutpanconfigure() {
  const [alertdata, setAlertdata] = useState({});
  const [alertopen, setAlertopen] = useState(false);

  const [userstate, userDispatch] = useReducer(CutpanmasterReducer, []);
  const [searchuserstate, searchuserDispatch] = useReducer(
    CutpanmasterReducer,
    []
  );
  const [searchcutpanState, searchcutDispatch] = useReducer(CutpanmasterReducer,[] );
  const [searchcutpan_orgState, searchcut_orgDispatch] = useReducer(CutpanmasterReducer,[] );
  const [cutpandataState, cutpandataDispatch] = useReducer(CutpanmasterReducer,[] );
  const [usearch, setUsearch] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [value, setValue] = useState(false);
  const [cmdinfo, setCmdinfo] = useState({});
  const [btnname, setBtnname] = useState("");
  const [userdet, setUserdet] = useState("");
  const [usercommission, setUserCommission] = useState(0);

  const cutPanValueConfig = (value) => {
    if (parseInt(value) === 0 || value == "" || value == undefined) {
      value = 9;
    }
    const data = searchcutpanState;
    data.forEach((element, index) => {
      if (index === 0 || index === 1) {
        element.winprice = value * 1;
      } else if (index === 2) {
        element.winprice = data[1].winprice * 10;
      } else if (index >= 3 && index <= 4) {
        element.winprice = data[2].winprice * 10;
      } else if (index >= 5 && index <= 6) {
        element.winprice = data[4].winprice * 10;
      } else if (index === 7) {
        element.winprice = data[6].winprice * 10;
        searchcutDispatch({
          type: "updateprice",
          id: element?.gtmid,
          name: "winprice",
          value: data[6].winprice * 10,
        });
      }
    });
    
  };

  const handleCheck = (edata, value, istype) => {
    let loginid=edata?.loginid;
    setUserdet("")
    if (istype === 1) {
      const data = userstate.map((obj) => ({ ...obj, ischeck: value }));
      searchcutDispatch({
        type: "cutpanDetails",
        data: searchcutpan_orgState,
      });
      setTimeout(() => {
        userDispatch({
          type: "cutpanDetails",
          data: [],
        });
        searchuserDispatch({
          type: "cutpanDetails",
          data: [],
        });
      }, 0);
      setTimeout(() => {
        userDispatch({
          type: "cutpanDetails",
          data: data,
        });
        searchuserDispatch({
          type: "cutpanDetails",
          data: data,
        });
      }, 0);
    }else if(istype===2){      
      const filteredRecords = cutpandataState.filter((record) => record.loginid === loginid && record.gtmid===1);
      if(filteredRecords.length >=0){        
        setUserdet(`${edata?.uname} - ${edata?.mob}`)
        cutPanValueConfig(filteredRecords?.[0]?.cutpanvalue)
        const cutData = searchcutpanState;
        cutData.forEach((element, index) => {
          const filterWinPrice = cutpandataState.filter((record) => record.loginid === loginid && record.gtmid===element?.gtmid);
          element.winprice = filterWinPrice?.[0]?.cutpanvalue;
          if(index===7){
            searchcutDispatch({
              type: "updateprice",
              id: element?.gtmid,
              name: "winprice",
              value: filterWinPrice?.[0]?.cutpanvalue,
            });
          }
       
        });
        const data = userstate.map((obj) => ({ ...obj, ischeck: obj.loginid===loginid?true:false }));        
        setTimeout(() => {
          userDispatch({
            type: "cutpanDetails",
            data: [],
          });
          searchuserDispatch({
            type: "cutpanDetails",
            data: [],
          });
        }, 0);
        setTimeout(() => {
          userDispatch({
            type: "cutpanDetails",
            data: data,
          });
          searchuserDispatch({
            type: "cutpanDetails",
            data: data,
          });
        }, 0);
      }
     
    } 
    else {
      setTimeout(() => {
        searchcutDispatch({
          type: "cutpanDetails",
          data: [],
        });       
      }, 0);
      setTimeout(() => {
        searchcutDispatch({
          type: "cutpanDetails",
          data: searchcutpan_orgState,
        });      
      }, 0);
    
      userDispatch({
        type: "ischeck",
        id: loginid,
        name: "ischeck",
        value: value,
        istype: istype,
      });
      searchuserDispatch({
        type: "ischeck",
        id: loginid,
        name: "ischeck",
        value: value,
        istype: istype,
      });
    }
    
  };

  const handleUpdatePrice=(eldata,winprice)=>{
    searchcutDispatch({
      type: "updateprice",
      id: eldata?.gtmid,
      name: "winprice",
      value: winprice,
    });
  }

  const getUserDetail = async () => {
    try {
      let getuserdata = await GetUserdata();
      if (getuserdata.code === 200) {
        // alert(JSON.stringify(getuserdata.data))
       let getCupanData= await GetCupanData_wov();
       if (getCupanData.code === 200) {
        const updatedArray1 = getuserdata?.data.map(item => {
          const matchingItem = getCupanData?.data.find(element => element.loginid === item.loginid);          
          if (matchingItem) {
            return { ...item, isexist: 1 };
          } else {
            return item;
          }
        });
        cutpandataDispatch({
          type: "cutpanDetails",
          data: getCupanData?.data,
        })
        userDispatch({
          type: "cutpanDetails",
          data: updatedArray1,
        });
        searchuserDispatch({
          type: "cutpanDetails",
          data: updatedArray1,
        });
       }else{
        userDispatch({
          type: "cutpanDetails",
          data: getuserdata?.data,
        });
        searchuserDispatch({
          type: "cutpanDetails",
          data: getuserdata?.data,
        });
       }
       
      }
    } catch (error) {
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };

  useEffect(() => {
    getUserDetail();
  }, []);

  const fetchCutpanData = async (data) => {
    try {
      let cutpandata = await GetGtypemaster(data);
      if (cutpandata.code === 200) {
        const customOrder = [
          "Open",
          "Close",
          "Jodi",
          "Open Panna",
          "Close Panna",
          "Gunule",
          "Ulta Gunule",
          "Jackpot",
        ];
        // Custom sorting function
        const sortedData = cutpandata?.data.sort(
          (a, b) => customOrder.indexOf(a.gname) - customOrder.indexOf(b.gname)
        );

        searchcutDispatch({
          type: "cutpanDetails",
          data: sortedData,
        });
        searchcut_orgDispatch({
          type: "cutpanDetails",
          data: sortedData,
        });
      }
    } catch (error) {
      // Handle any errors here
      setAlertdata({ code: 100, msg: "Frontend failed please try again" });
      setAlertopen(true);
    }
  };

  useEffect(() => {
    fetchCutpanData();
  }, []);

  const userdatasearch = (searchstr) => {
    const keysToSearch = ["uname", "mob"];
    const filteredData = searchuserstate?.filter((item) => {
      return keysToSearch?.some((key) => {
        return item[key]
          ?.toString()
          .toLowerCase()
          ?.includes(searchstr.toString().toLowerCase());
      });
    });
    // alert(JSON.stringify(filteredData))
    userDispatch({
      type: "cutpanDetails",
      data: filteredData,
    });
  };

  useEffect(() => {
    userdatasearch(usearch);
  }, [usearch]);

  const confirmDilog=(btnname)=>{
    if(btnname=="save"){
      setCmdinfo({msg:"Do you want to save this record?",heading:"Confirm",nolbl:"No",yeslbl:"Yes"});
      setValue(true);   
      setBtnname(btnname)
    }else if(btnname=="del"){
      setCmdinfo({msg:"Do you want to delete selected records?",heading:"Confirm",nolbl:"No",yeslbl:"Yes"});
      setValue(true);         
      setBtnname(btnname)
    }
    
  }
  const handleConfirmChange=(val)=>{
      if(val===true && btnname==="save"){        
        saveCutpan();
      }else if(val===true && btnname==="del"){
        deleteCutpan();
      }
      setValue(false);
  }
  const saveCutpan = async () => {      
    let data = [];
    let rettostmsg = {};
    const filteredRecords = userstate
      .filter((record) => record.ischeck === true)
      .map((record) => ({
        loginid: record.loginid,
      }));
    if (filteredRecords.length <= 0) {
      rettostmsg = tostmsg({
        code: 100,
        msg: "Atleast one user select.",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
      return;
    }
    if(Number(usercommission)>Number(0)){
      const isLessThan100Percent = Number(usercommission) > Number(100);      
      if(isLessThan100Percent===true){
        rettostmsg = tostmsg({
          code: 100,
          msg: "The commission should be less than 100 percent.",
        });
        setAlertdata(rettostmsg);
        setAlertopen(true);
        return;
      }
    }
    filteredRecords.forEach((melement, mindex) => {
      searchcutpanState.forEach((element, index) => {
        data.push({
          gtmid: element?.gtmid,
          cutpanvalue: element?.winprice,
          loginid: melement?.loginid,    
          comis:usercommission,
        });
      });
    });
 
    // console.log(data)
    try {
      let res = await SaveCutpan(data);
      if (res.code === 200 && res?.data?.[0]?.dt > 0) {
        rettostmsg = tostmsg({
          code: 200,
          msg: "Cutpan data saved successfully",
        });
        setAlertdata(rettostmsg);
        setAlertopen(true);
        window.location.reload();
      } else {
        rettostmsg = tostmsg({
          code: 100,
          msg: "something went wrong! please try again",
        });
        setAlertdata(rettostmsg);
        setAlertopen(true);
      }
    } catch (error) {
      rettostmsg = tostmsg({
        code: 100,
        msg: "something went wrong! please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };

  const deleteCutpan = async () => {
    let data = [];
    let rettostmsg = {};
    const filteredRecords = userstate
      .filter((record) => record.ischeck === true)
      .map((record) => ({
        loginid: record.loginid,
      }));
      data=filteredRecords;
    if (filteredRecords.length <= 0) {
      // alert()
      rettostmsg = tostmsg({
        code: 100,
        msg: "Atleast one user select.",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
      return;
    }
    try {
      let res = await DeleteCutpan(data);      
      if (res.code === 200 && res?.data?.[0]?.dt > 0) {
        rettostmsg = tostmsg({
          code: 200,
          msg: "Cutpan data deleted successfully",
        });
        setAlertdata(rettostmsg);
        setAlertopen(true);
        window.location.reload();
      } else {
        rettostmsg = tostmsg({
          code: 100,
          msg: "something went wrong! please try again",
        });
        setAlertdata(rettostmsg);
        setAlertopen(true);
      }
    } catch (error) {
      rettostmsg = tostmsg({
        code: 100,
        msg: "something went wrong! please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };

  return {
    alertdata,
    alertopen,
    setAlertopen,
    userstate,
    setUsearch,
    searchcutpanState,
    handleCheck,
    selectAll,
    cutPanValueConfig,
    saveCutpan,
    deleteCutpan,cmdinfo, setCmdinfo,value,setValue,confirmDilog,
    handleConfirmChange,userdet,handleUpdatePrice,usercommission, setUserCommission,
  };
}

export default useCutpanconfigure;
