import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useSelector, useDispatch } from 'react-redux';
import { setOpenClose, setRetAnswer } from '../../API/Reducer/confirmDialogReducer';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReduxConfirmationDialog() {  
    const dispatch = useDispatch();
    const {msg,yeslbl,nolbl,heading,openclose,setReturnAnswer,setOpen} = useSelector(state => state.confirm);

  return (
    <React.Fragment>
      <Dialog
        open={openclose}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=> {dispatch(setOpenClose(false))}}        
      >
        <DialogTitle>{heading}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{fontWeight:"bold"}}>
           {msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button type ="button" variant='outlined' onClick={(e)=>{
             dispatch(setRetAnswer({openclose:false,resans:false}))
             }}>{nolbl}</Button>
          <Button variant='outlined' onClick={(e)=>{dispatch(setRetAnswer({openclose:false,resans:true}))}}>{yeslbl}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}