import React from 'react'

function DrawmasterReducer(state,action) {
    switch(action.type){
        case 'Change':{
            return {
            ...state,
            [action.payload.name]: action.payload.value,
            };
        }
        case 'drawmasterDetails': {
            // alert( "23"+action.data)
            return action.data
          }

}
}
export default DrawmasterReducer