import { Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./AdminTodays.module.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Paper, makeStyles } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import AdminTodayplayTable from "../../Tables/AdminTodayplayTable";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AdminAccordionTable from "../../Tables/AdminAccordionTable";
import useAdminPlayHistory from "../../API/Hooks/useAdminPlayHistory";
import { formatDatetime_YMD } from "../../MIS/Global";
 
const useStyles = makeStyles((theme) => ({
  smallDateTimePicker: {
    "& .MuiInputBase-input": {
      fontSize: "13px",
      fontWeight: "600 !important",
      width: "100%",
      height: "100%",
      padding: "10px",
      margin: 0,
    },
    "& .MuiInputLabel-root": {
      fontSize: "16px",
      fontWeight: "600 !important", // Adjust font size as needed
      display: "flex",
      alignItems: "center",
    },
  },
}));

const AdminTodaysPlay = () => {
  const classes = useStyles(); 
  const historydata = {...useAdminPlayHistory() };
  const [whr, setWhr] = useState({pfdate:new Date(),ptdate:new Date()})
  const handleFromToDateValidation=(istypedate,edate)=>{    
    if(istypedate==="fdate"){
      let tdate= formatDatetime_YMD(whr?.ptdate,0,"ymd");
      let fdate= formatDatetime_YMD(edate,0,"ymd");
      if(fdate>tdate){
        let xdate= formatDatetime_YMD(edate,1,"ymd");
        setWhr({...whr,ptdate:xdate})
      }
    }else if(istypedate==="tdate"){
      let tdate= formatDatetime_YMD(edate,0,"ymd");
      let fdate= formatDatetime_YMD(whr?.pfdate,0,"ymd");
      if(fdate>tdate){
        let xdate= formatDatetime_YMD(edate,-1,"ymd");
        setWhr({...whr,pfdate:xdate})
      }
    }
  }
  return (
    <div className={style.adminresultmain}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={window.location.pathname==='/adminplayhistory'?10:7}>
          <div className={style.playresulthead}>
          {window.location.pathname==='/adminplayhistory'?"Today's Play":"Play History"} 
          </div>
        </Grid>
        <Grid item xs={12} md={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className={classes.smallDateTimePicker}
              label={window.location.pathname==='/adminplayhistory'?'Date':"From Date"}
              name="playdate"
              value={dayjs(`${window.location.pathname==='/adminplayhistory'?historydata?.playdate:whr?.pfdate}`)}
              onChange={(e)=>{
                if(window.location.pathname==='/adminplayhistory'){
                 historydata?.handlePlaydate("playdate",e)
                }else{
                  setWhr({...whr,pfdate:e})
                  handleFromToDateValidation("fdate",e);
                }                
               }}
              renderInput={(props) => (
                <TextField {...props} variant="standard" />
              )}
              openTo="day"
              format="DD/MM/YYYY"
              readOnly={window.location.pathname==='/adminplayhistory'?true:false}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={2} sx={{display:window.location.pathname==='/adminplayhistory'?"none":""}}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className={classes.smallDateTimePicker}
              label="To Date"
              name="playdate"
              value={dayjs(`${whr?.ptdate}`)}
              onChange={(e)=>{setWhr({...whr,ptdate:e});handleFromToDateValidation("tdate",e);}}
              renderInput={(props) => (
                <TextField {...props} variant="standard" />
              )}
              openTo="day"
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={1} sx={{display:window.location.pathname==='/adminplayhistory'?"none":""}}>
            <Button type="button" variant="contained" color="secondary" size="small"
             onClick={()=>{
              historydata?.GetPlayHistorydata_SearchBtn({pfdate:formatDatetime_YMD(whr?.pfdate,0,"ymd"),ptdate:formatDatetime_YMD(whr?.ptdate,0,"")})
              }}
            ><SearchIcon/></Button>
        </Grid>
        <Grid item xs={12} md={5}>
          <Paper className={style.adresultpaper}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} className={style.searchcont}>
                <div className={style.searchcon}>
                  <SearchIcon sx={{ fontSize: "22px", cursor: "pointer" }} />
                  <input
                    type="text"
                    className={style.searchfield}
                    placeholder="Search"
                    onChange={(e) => {
                      historydata?.setUsearch(e.target.value);
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <AdminTodayplayTable data={historydata} whr={whr}/>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={7}>
          <Paper className={style.adresultpaper}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} className={style.searchcont}>
                <div className={style.searchcon}>
                  <SearchIcon sx={{ fontSize: "22px", cursor: "pointer" }} />
                  <input
                    type="text"
                    className={style.searchfield}
                    placeholder="Search"
                    onChange={(e) => {
                      historydata?.setGamesearch(e.target.value);
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={12} className={style.accordioncon}>
                <Grid container spacing={1} sx={{ padding: "5px" }}>
                  {historydata?.searchplayhistorystate?.map((element, index) => {
                    let bgcolor = "#F1F0E8";
                    if (index % 2 == 0) {
                      bgcolor = "#fff";
                    }
                    // let playdate=historydata?.formatDate(element?.playdate)
                    // alert(JSON.stringify(playdate))
                    return (
                      <Grid item xs={12} md={12} key={index}>
                        <Accordion
                          component={Paper}
                          expanded={historydata?.expanded === index}
                          onChange={historydata?.handleChange(index)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            sx={{ backgroundColor: bgcolor }}
                          >
                            <Typography>
                              <div className={style.transid}>
                                Transaction Id: {element?.trid}
                              </div>
                              <div className={style.historydetime}>
                                Date & Time: {historydata?.formatDate(element?.playdate)}
                              </div>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <AdminAccordionTable data={element?.entries}/>
                                </Grid>
                              </Grid>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminTodaysPlay;
