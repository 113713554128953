import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import AdminTodaysPlay from '../../Components/AdminPlayHistory/AdminTodaysPlay'

const AdminPlayHistoryScreen = () => {
  return (
    <div>
    <Appbar/>
    <AdminTodaysPlay/>
</div>
  )
}

export default AdminPlayHistoryScreen