import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import HomeIcon from "@mui/icons-material/Home";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import MenuIcon from "@mui/icons-material/Menu";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { styled } from "@material-ui/core";
import { Grid } from "@mui/material";
import img from "../../Assets/logo2.png";
import style from "./MobileMenu.module.css";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import useMenumaster from "../../API/Hooks/useMenumaster";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import TimelineIcon from '@mui/icons-material/Timeline';
import LuggageIcon from '@mui/icons-material/Luggage';
import { useNavigate } from "react-router-dom";

const drawerWidth = 250;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const MobileMenu = () => {
  const menuMaster = { ...useMenumaster() };
  const navigate= useNavigate();
  return (
    <div>
      <Box sx={{ pb: 7 }}>
        <CssBaseline />
        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            padding: "3px",
            zIndex: 999,
          }}
          elevation={3}
        >
          <BottomNavigation
            value={menuMaster?.value}
            onChange={(event, newValue) => {
              menuMaster?.setValue(newValue);
            }}
          >
            <BottomNavigationAction
              label="Home"
              icon={<HomeIcon />}
              onClick={() => {
                menuMaster?.navigateHome();
              }}
            />
            <BottomNavigationAction label="Profile"
              onClick={menuMaster?.navigateUserProfile}
              icon={<AccountBoxIcon />} />
            <BottomNavigationAction
              onClick={() => { menuMaster?.navigateFundRequest() }}
              label="Wallet"
              icon={<CardMembershipIcon />}
            />
            <BottomNavigationAction
              label="Menu"
              icon={<MenuIcon onClick={menuMaster?.handleDrawerOpen} />}
            />
          </BottomNavigation>
        </Paper>
      </Box>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="temporary"
        anchor="left"
        open={menuMaster?.open}
        onClose={menuMaster?.handleDrawerClose}
      >
        <List>
          <ListItem disablePadding>
            <Grid container spacing={2} style={{ padding: "10px" }}>
              <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={12}>
                    <div className={style.logocon}>
                      <img src={img} className={style.logo} />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={12}
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <div
                      onClick={() => {
                        menuMaster?.handleDrawerClose();
                      }}
                    >
                      <CloseIcon style={{ fontSize: "18px" }} />
                    </div>
                  </Grid>

                  <Grid item xs={12} md={12} className={style.headingcon}>
                    {/* <div className={style.usernm}>User Name:</div> */}
                    <div className={style.usernm}>
                      {menuMaster?.userdatastate && menuMaster?.userdatastate[0]
                        ? menuMaster?.userdatastate[0]?.uname
                        : ""}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className={style.menulistcon}
                        onClick={() => {
                          menuMaster?.navigateHome();
                        }}
                      >
                        <div>
                          <DashboardIcon />
                        </div>
                        <div className={style.dashboardhead}>Dashboard</div>
                      </Grid>
                      <Grid item xs={12} md={12} className={style.menulistcon}
                      onClick={menuMaster?.navigateTodayPlay}
                      >
                        <div>
                          <TimelineIcon />
                        </div>
                        <div className={style.dashboardhead}>Today's Play</div>
                      </Grid>
                      <Grid item xs={12} md={12} className={style.menulistcon}
                        onClick={()=>{ window.location.href="/uplayresult"}}
                      >
                        <div>
                          <QuestionAnswerIcon />
                        </div>
                        <div className={style.dashboardhead}>Today's Result</div>
                      </Grid>
                      <Grid item xs={12} md={12} className={style.menulistcon}
                        onClick={menuMaster?.navigateUserPosition}
                      >
                        <div>
                          <LuggageIcon/>
                        </div>
                        <div className={style.dashboardhead}>Today's P/L</div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className={style.menulistcon}
                        onClick={() => {
                          navigate("/userpl");
                        }}
                      >
                        <div>
                          <AccountBalanceWalletIcon />
                        </div>
                        <div className={style.dashboardhead}>Wallet Ledger</div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className={style.menulistcon}
                        onClick={menuMaster?.navigateFundRequest}
                      >
                        <div>
                          <AccountBalanceWalletIcon />
                        </div>
                        <div className={style.dashboardhead}>
                          Fund Request
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12} className={style.menulistcon}
                        onClick={menuMaster?.navigateUserPlayHistory}
                      >
                        <div>
                          <WorkHistoryIcon />
                        </div>
                        <div className={style.dashboardhead}>Play History</div>
                      </Grid>
                      <Grid item xs={12} md={12} className={style.menulistcon} sx={{display:"none"}}
                        onClick={menuMaster?.navigateOrderHistory}
                      >
                        <div>
                          <LuggageIcon/>
                        </div>
                        <div className={style.dashboardhead}>Historical P/L</div>
                      </Grid>
                      <Grid item xs={12} md={12} className={style.menulistcon}
                        onClick={(e)=>{
                          window.location.href="/uplayresulthistory";
                          // navigate("/uplayresulthistory")}
                        }}
                      >
                        <div>
                          <QuestionAnswerIcon />
                        </div>
                        <div className={style.dashboardhead}>Result</div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} md={12} className={style.menulistcon}
                        onClick={menuMaster?.navigateUserProfile}
                      >
                        <div>
                          <AccountCircleIcon />
                        </div>
                        <div className={style.dashboardhead}>Profile</div>
                      </Grid>

                      <Grid item xs={12} md={12} className={style.menulistcon}>
                        <div>
                          <LogoutIcon />
                        </div>
                        <div
                          className={style.dashboardhead}
                          onClick={() => {
                            menuMaster?.navigateLogin("/login");
                          }}
                        >
                          Log Out
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
};

export default MobileMenu;
