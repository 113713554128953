 import { callAjax } from "../../MIS/Global";

export const SaveUsermaster = async (data) => {
  let ret = {};

  if (data?.uname == undefined || data?.uname == "") {
    return { msg: "Please Enter User Name", code: 100 };
  }
  if (data?.mob == "" || data?.mob.length < 10) {
    return { msg: "Please Enter Mobile Number", code: 100 };
  }
  if (data.paswd !== data.cpaswd) {
    return { msg: "Password and Confirm Password do not match", code: 100 };
  }   
  ret = await callAjax("usercreate", data);
  return ret;   
};

export const Signinusers = async (data) => {
  let ret = {};
  if (data?.mob == undefined && data?.paswd == undefined) {
    return { msg: "Please Enter Mobile Number and password.", code: 100 };
  } else if (data.mob == "" || data?.mob.length < 10) {
     return { msg: "Enter Valid Mobile Number", code: 100 };
  } else if (data.paswd == "" || data?.paswd == undefined) {
    return { msg: "Enter valid password", code: 100 };
  }
  ret = await callAjax("signin", data);
  return ret;
};

export const GetUserdata = async (data) => {
  let ret = {};
  
  ret = await callAjax("getUser", data);
  return ret;
};

export const UpdateActiveStatus = async (data) => {
  let ret = {};
  ret = await callAjax("updateactivests", data);
  return ret; 
};

export const UpdateBlockStatus = async (data) => {
  let ret = {};
  ret = await callAjax("updateblocksts", data);
  return ret;
};
