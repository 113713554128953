 
import {  callAjax } from "../../MIS/Global";

export const SaveGtypemaster = async (data) => {
  let ret = {};
  if(data?.gname==undefined || data?.gname==""){
       
    return { msg: "Please Enter Game Name", code: 100 };
  }

  if(Number(data?.wnrpointcalc)==0 && data?.wnrpointcalc!=""){
       
    return { msg: "Please enter points should be greterthan zero", code: 100 };
  }

  let url = "gtypesave";
  if (data.gtmid > 0) {
    url = "gtypeupdate";
  }
  ret = await callAjax(url,data);
  return ret;
};

export const GetGtypemaster = async (data) => {
  let ret = {};

  let url = "getgtypedata";

  ret = await callAjax(url,data);
  return ret;
};
export const DeleteGtypemaster = async (data) => {
  let ret = {};

  let url = "delgtype";

  ret = await callAjax(url,data);
  return ret;
};
