import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import UserVoicePlay from '../../Components/UserPlayMaster/UserVoicePlay'

const UserVoicePlayMasterScreen = () => {
  return (
    <>
        <Appbar/>
        <UserVoicePlay/>
    </>
  )
}

export default UserVoicePlayMasterScreen