import React from 'react';
import './index.css';
import store from "../src/API/Store/store";

 import {render} from 'react-dom'
import reportWebVitals from './reportWebVitals';
import {BrowserRouter,Routes,Route,Navigate, unstable_HistoryRouter as Router} from 'react-router-dom'
import UserContextProvider from './Context/UserContext';
import App from './App';
import SignInPage from './Components/SignInPage/SignInPage';
import SignUpPage from './Components/SignUpPage/SignUpPage';
import AdminGameMasterScreen from './Screen/AdminGameMasterScreen/AdminGameMasterScreen';
import AdminDrawmasterScreen from './Screen/AdminDrawMasterScreen/AdminDrawmasterScreen';
import RegisterUserListScreen from './Screen/RegisterUserListScreen/RegisterUserListScreen';
import HomePageScreen from './Screen/HomePageScreen/HomePageScreen';
import TradeMasterScreen from './Screen/TradeMasterScreen/TradeMasterScreen';
import FundRequestScreen from './Screen/FundRequestScreen/FundRequestScreen';
import FundRequestListScreen from './Screen/FundRequestListScreen/FundRequestListScreen';
import AdminFundRequestListScreen from './Screen/AdminFundRequestListScreen/AdminFundRequestListScreen';
import AdminMenu from './Components/AdminMenu/AdminMenu';
import Appbar from './Components/Appbar/Appbar';
import AdminLogin from './Components/AdminLogin/AdminLogin';
import GameMasterScreen from './Screen/GameMasterScreen/GameMasterScreen';
import UserWalletLedgerScreen from './Screen/UserWalletLedgerScreen/UserWalletLedgerScreen';
import UserProfileScreen from './Screen/UserProfileScreen/UserProfileScreen';
import UserAcLedgerScreen from './Screen/UserAcLedgerScreen/UserAcLedgerScreen';
import DashboardScreen from './Screen/DashboardScreen/DashboardScreen';
import AdminLedgerReportScreen from './Screen/AdminLedgerReportScreen/AdminLedgerReportScreen';
import UserPlaymasterScreen from './Screen/UserPlaymasterScreen/UserPlaymasterScreen';
import AdminPlayMasterReportScreen from './Screen/AdminPlayMasterScreen/AdminPlayMasterReportScreen';
import UserPlayHistoryMasterScreen from './Screen/UserPlayHistoryMasterScreen/UserPlayHistoryMasterScreen';
import AdminLedgerReport from './Components/AdminLedgerReport/AdminLedgerReport';
import UserTodayPlayHistoryScreen from './Screen/UserPlayHistoryMasterScreen/UserTodayPlayHistoryScreen';
import UserPlayResultScreen from './Screen/UserPlayResultScreen/UserPlayResultScreen';
import DrawResultScreen from './Screen/DrawResultScreen/DrawResultScreen';
import AdminPlayHistoryScreen from './Screen/AdminPlayHistoryScreen/AdminPlayHistoryScreen';
import PrintPage from './Components/SlipPrint/PrintPage';
import PlayWinLossHistoryScreen from './Screen/PlayWinLossHistoryScreen/PlayWinLossHistoryScreen';
import CutpanMasterScreen from './Screen/CutpanMasterScreen/CutpanMasterScreen';
import ResultPredictionScreen from './Screen/ResultPredictionScreen/ResultPredictionScreen';
import { Provider } from 'react-redux';
import HolidayMasterSceen from './Screen/HolidayMasterSceen/HolidayMasterSceen';
import AdminPLScreen from './Screen/AdminPLScreen/AdminPLScreen';
import AdminPlayScreen from './Screen/AdminPlayScreen/AdminPlayScreen';
import ForgotPsswordRequestScreen from './Screen/ForgotPsswordRequestScreen/ForgotPsswordRequestScreen';
import AdminPlayEntryListScreen from './Screen/AdminPlayScreen/AdminPlayEntryListScreen';
import UserVoicePlayMasterScreen from './Screen/UserPlaymasterScreen/UserVoicePlayMasterScreen';
import QRCodeGenerator from './Components/HomePage/QRCodeGenerator ';
import CommissionReportScreen from './Screen/AdminReportsScreen/CommissionReportScreen';
 

const root =document.getElementById('root');
render(
  <React.StrictMode>
    <Provider store = {store}>
    <BrowserRouter>
    <Routes>
        
        <Route path="/" element={<AdminLogin />} />
        <Route path="/login" element={<SignInPage />} />
        <Route path="/register" element={<SignUpPage />} />
        <Route path="/gamemastertype" element={<AdminGameMasterScreen />} />
        <Route path="/draw" element={<AdminDrawmasterScreen />} />
        <Route path="/userlist" element={<RegisterUserListScreen />} />
        <Route path="/adminwalletreqlist" element={<AdminFundRequestListScreen />} />
        <Route path="/appbar" element={<Appbar />} />
        <Route path="/home" element={<HomePageScreen />} />
        <Route path="/trade" element={<TradeMasterScreen  />} />
        <Route path="/walletreq" element={<FundRequestScreen />} />
        <Route path="/walletreqlist" element={<FundRequestListScreen />} />
        <Route path="/adminmenu" element={<AdminMenu />} />
        <Route path="/adminlogin" element={<AdminLogin />} />
        <Route path="/gamemaster" element={<GameMasterScreen  />} />
        <Route path="/userwalletledger" element={<UserWalletLedgerScreen />} />
        <Route path="/userprofile" element={<UserProfileScreen  />} />
        <Route path="/useracledger" element={<UserAcLedgerScreen  />} />
        <Route path="/dashboard" element={<DashboardScreen  />} />
        <Route path="/userplay/:gname?" element={<UserPlaymasterScreen  />} />
        <Route path="/uservplay" element={<UserVoicePlayMasterScreen  />} />
        <Route path="/adminreport" element={<AdminLedgerReportScreen  />} />
        <Route path="/ledgerreport" element={<AdminLedgerReport/>} />
        <Route path="/adminplayreport" element={<AdminPlayMasterReportScreen  />} />
        <Route path="/uplayhistory" element={<UserPlayHistoryMasterScreen  />} />
        <Route path="/todayhistory" element={<UserTodayPlayHistoryScreen  />} />
        <Route path="/uplayresult" element={<UserPlayResultScreen/>} />
        <Route path="/uplayresulthistory" element={<UserPlayResultScreen/>} />
        <Route path="/drawresult" element={<DrawResultScreen/>} />
        <Route path="/adminplayhistory" element={<AdminPlayHistoryScreen/>} />
        <Route path="/adminplayhistoryy" element={<AdminPlayHistoryScreen/>} />
        <Route path="/payslip/:id?" element={<PrintPage/>} />
        <Route path="/position" element={<PlayWinLossHistoryScreen/>} />
        <Route path="/historyposition" element={<PlayWinLossHistoryScreen/>} />
        <Route path="/cutpanconfig" element={<CutpanMasterScreen/>} />
        <Route path="/resultanalytics" element={<ResultPredictionScreen/>} />
        <Route path="/holiday" element={<HolidayMasterSceen/>} />
        <Route path="/adminpl" element={<AdminPLScreen/>} />
        <Route path="/userpl" element={<AdminPLScreen/>} />
        <Route path="/adminplay" element={<AdminPlayScreen/>} />
        <Route path="/adminplaylist" element={<AdminPlayEntryListScreen/>} />
        <Route path="/forgotpasswordlits" element={<ForgotPsswordRequestScreen/>} />
        <Route path="/commissionreport" element={<CommissionReportScreen/>} />
        <Route path="/qrcode" element={<QRCodeGenerator/>} />

    </Routes>
    </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  root
);

// s
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();