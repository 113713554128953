import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { formatNumberToIndianStyle, formatUTCDate } from "../../MIS/Global";
import { makeStyles } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import GlobalTextsuggest from "../../Textsuggestfield/GlobalTextsuggest";
import {
  GetCommissionReportList,
  GetUserList,
} from "../../API/Reducer/userReducer";

const useStyles = makeStyles((theme) => ({
  smallDateTimePicker: {
    "& .MuiInputBase-input": {
      fontSize: "13px",
      fontWeight: "600 !important",
      width: "100px",
      height: "100%",
      padding: "10px",
      margin: 0,
    },
    "& .MuiInputLabel-root": {
      fontSize: "16px",
      fontWeight: "600 !important", // Adjust font size as needed
      display: "flex",
      alignItems: "center",
    },
  },
}));

const CommissionRpt = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userlist, comisReportlist } = useSelector((state) => state.user);
  const [searchInput, setSearchInput] = useState("");
  const [totalDr, setTotalDr] = useState(0);
  const [totalCr, setTotalCr] = useState(0);
  const [totalDue, setTotalDue] = useState(0);
  const [startDate, setStartDate] = useState(
    dayjs(formatUTCDate(new Date(), "ymd", 0))
  );
  const [endDate, setEndDate] = useState(
    dayjs(formatUTCDate(new Date(), "ymd", 0))
  );
  const [useroptions, setUserOptions] = useState([]);
  const [whrdata, setWhrData] = useState({
    loginid: "",
    uname: "",
    fdate: formatUTCDate(new Date(startDate), "ymd", 0),
    tdate: formatUTCDate(new Date(endDate), "ymd", 0),
  });

  const handleUserChange = (data) => {
    setWhrData({ ...whrdata, loginid: data?.id, uname: data?.label });
  };
  const handleDateChange = (newValue, istype) => {
    if (newValue) {
      if (istype === "from") {
        setStartDate(dayjs(formatUTCDate(new Date(newValue), "ymd")));
      } else {
        setEndDate(dayjs(formatUTCDate(new Date(newValue), "ymd")));
      }
    }
  };
  

  const getCommissionRpt = () => {
    let data = {
      ...whrdata,
      fdate: formatUTCDate(new Date(startDate), "ymd", 0),
      tdate: formatUTCDate(new Date(endDate), "ymd", 1),
    };
    dispatch(GetCommissionReportList(data));
  };
  useEffect(() => {
    if (userlist.length <= 0) {
      getCommissionRpt()
      dispatch(GetUserList({}));
    } else if (userlist.length >= 0) {
      const res = userlist.map((element) => {
        return {
          id: element?.loginid,
          label: `${element?.uname} - ${element?.mob}`,
        };
      });
      setUserOptions(res);
    }
  }, [userlist]);

  useEffect(() => {
   const totalDr = comisReportlist.reduce((accumulator, currentValue) => {
         return accumulator + parseInt(currentValue.total_dr);
    }, 0);
    const totalCr = comisReportlist.reduce((accumulator, currentValue) => {
        return accumulator + parseInt(currentValue.total_cr);
   }, 0);

   const totalDue=Number(totalDr-totalCr)
   setTotalDr(totalDr);
   setTotalCr(totalCr);
   setTotalDue(totalDue);
 
  },[comisReportlist])
  

  const filteredData = comisReportlist.filter((element) => {
    const searchStr = searchInput.toLowerCase();
    return String(element.uname).toLowerCase().includes(searchStr);
  });

  return (
    <>
      <Grid container spacing={0}>
        <Grid item md={12} xs={12}>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <h3>Commission Report</h3>
            <div style={{ display: "flex", gap: "10px" }}>
              <div style={{ width: "250px" }}>
                <GlobalTextsuggest
                  data={{
                    labelNm: whrdata?.uname,
                    options: useroptions,
                    label: "User",
                    handleChange: handleUserChange,
                  }}
                />
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className={classes.smallDateTimePicker}
                  label="From Date"
                  value={startDate}
                  onChange={(newvalue) => {
                    handleDateChange(newvalue, "from");
                  }}
                  renderInput={(props) => (
                    <TextField {...props} variant="standard" />
                  )}
                  openTo="day"
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className={classes.smallDateTimePicker}
                  label="To Date"
                  value={endDate}
                  onChange={(newvalue) => {
                    handleDateChange(newvalue, "to");
                  }}
                  renderInput={(props) => (
                    <TextField {...props} variant="standard" />
                  )}
                  openTo="day"
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
              <Button
                onClick={(e) => {
                  getCommissionRpt();
                }}
                type="button"
                color="info"
                variant="contained"
              >
                <SearchIcon />
              </Button>
            </div>
          </Paper>
        </Grid>
        <Grid md={12} xs={12}>
          <Paper
            sx={{
              padding: "10px",
            }}
            elevation={3}
          >
            <TableContainer sx={{ maxHeight: "440px", position: "relative" }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Mobile Number</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell align="right">Collection</TableCell>
                    <TableCell align="right">Commission</TableCell>
                    <TableCell align="right">Due</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((row, index) => {
                    let bgcolor = "#fff";
                    if (index % 2 == 0) {
                      bgcolor = "aliceblue";
                    }
                    return (
                      <>
                        <TableRow sx={{ backgroundColor: bgcolor }}>
                          <TableCell component="th" scope="row">
                            {row?.uname}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row?.mob}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {formatUTCDate(new Date(row?.edate), "dmy")}
                          </TableCell>
                          <TableCell align="right">
                            {formatNumberToIndianStyle(row?.total_dr)}
                          </TableCell>
                          <TableCell align="right">
                            {formatNumberToIndianStyle(row?.total_cr)}
                          </TableCell>
                          <TableCell align="right">
                            {formatNumberToIndianStyle(
                              Number(row?.total_dr - row?.total_cr)
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
                <TableFooter
                  sx={{
                    position: "sticky",
                    bottom: 0,
                    backgroundColor: "#fff",
                    zIndex: 1,
                  }}
                >
                  <TableRow sx={{fontWeight:"800",color:"#000"}}>
                    <TableCell colSpan={3} sx={{fontWeight:"800",color:"#000",fontSize:"16px"}}>Total</TableCell>
                    <TableCell align="right" sx={{fontWeight:"800",color:"#000",fontSize:"16px"}}>{formatNumberToIndianStyle(totalDr)}</TableCell>
                    <TableCell align="right" sx={{fontWeight:"800",color:"#000",fontSize:"16px"}}>
                      {formatNumberToIndianStyle(totalCr)}
                    </TableCell>
                    <TableCell align="right" sx={{fontWeight:"800",color:"#000",fontSize:"16px"}}>{formatNumberToIndianStyle(totalDue)}</TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default CommissionRpt;
