import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import CommissionRpt from '../../Components/Reports/CommissionRpt'

const CommissionReportScreen = () => {
  return (
    <>
    <Appbar/>
    <CommissionRpt/>
    </>
  )
}

export default CommissionReportScreen