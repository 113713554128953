import React from "react";
import useFundreqemaster from "../../API/Hooks/useFundreqmaster";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import { Grid, TextField } from "@mui/material";
import style from "./FundRequest.module.css";
import FundRequestTable from "../../Tables/FundRequestTable";
import MobileMenu from "../MobileMenu/MobileMenu";
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core"; 
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import SearchIcon from "@mui/icons-material/Search";
const FundRequestList = () => {
  const useStyles = makeStyles((theme) => ({
    smallDateTimePicker: {
      "& .MuiInputBase-input": {
        fontSize: "14px",
        fontWeight: "600 !important",
        width: "100%",
        height: "100%",
        padding: "10px",
        margin: 0,
      },
      "& .MuiInputLabel-root": {
        fontSize: "16px",
        fontWeight: "600 !important", // Adjust font size as needed
        display: "flex",
        alignItems: "center",
      },
    },
  }));
  const classes = useStyles();
  const fundrequest = { ...useFundreqemaster() };
  return (
    <>
      <AlertMessageComp
        type={fundrequest?.alertdata.type}
        msg={fundrequest?.alertdata.msg}
        vertical={fundrequest?.alertdata.vertical}
        horizontal={fundrequest?.alertdata.horizontal}
        setOpen={fundrequest?.setAlertopen}
        open={fundrequest?.alertopen}
      />
      <div className={style.fundreqcontainer}>
        <div className={style.fundreqlist1}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              <div className={style.fundhead}>Request Transaction</div>
            </Grid>
          </Grid>
        </div>
        <div className={style.fundreqlist2}>
          <Grid container spacing={1}>
            <Grid item xs={5} md={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className={classes.smallDateTimePicker}
                  label="From Date"
                  value={dayjs(`${fundrequest?.ftdate?.fdate}`)}
                  onChange={(e)=>{fundrequest?.handleFTdate("fdate",e)}}
                  renderInput={(props) => (
                    <TextField {...props} variant="standard" />
                  )}
                  format="DD/MM/YYYY" // Specify the desired format for date and time
                  renderTime={(props) => (
                    <TextField
                      {...props}
                      variant="outlined"
                      helperText={null}
                    />
                  )} // Customize the time input rendering
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={5} md={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className={classes.smallDateTimePicker}
                  label="To Date"
                  value={dayjs(`${fundrequest?.ftdate?.tdate}`)}
                  onChange={(e)=>{fundrequest?.handleFTdate("tdate",e)}}
                  renderInput={(props) => (
                    <TextField {...props} variant="standard" />
                  )}
                  format="DD/MM/YYYY" // Specify the desired format for date and time
                  renderTime={(props) => (
                    <TextField
                      {...props}
                      variant="outlined"
                      helperText={null}
                    />
                  )} // Customize the time input rendering
                 
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1} md={12} >
              <div
                className={style.addfundicon}
                style={{
                  backgroundColor: "#358ab7",
                  right:0
                }}
                onClick={()=>{fundrequest?.fetchufundData({loginid: localStorage.getItem("loginId"),fdate:fundrequest?.ftdate?.fdate,tdate:fundrequest?.ftdate?.tdate})}}
              >
                <SearchIcon sx={{ color: "white" }} />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={style.fundreqlist3}>
          <FundRequestTable data={fundrequest} />
        </div>
        <div className={style.fundreqlist4}>
          <MobileMenu />
        </div>
      </div>
    </>
  );
};

export default FundRequestList;
