import React from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import style from "../Components/AdminGameMaster/AdminGame.module.css"
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  autocompleteOption: {
    fontSize: '13px',
    fontWeight: '600px !important',
    padding: '5px 10px',
    cursor:"pointer", 
    '&.Mui-focused': {
      backgroundColor: "#d9d9d9",
    },
  },
}));

export default function StatusTextsuggest (props) {
    
        const classes = useStyles();

  return (
    <div>
          <Autocomplete
    fullWidth
    disablePortal
    id="combo-box-demo"
    options={Status}
    size='small'
    value={props?.status}
    onChange={(e, newValue) => props?.setStatus(newValue.label)}
    renderInput={(params) => (
        <TextField
            {...params}
            label="Status"
            InputProps={{
                ...params.InputProps,
                classes: {
                    ...params.InputProps.classes,
                    input: style.textinp_left, // Apply input style
                },
            }}
            InputLabelProps={{
                ...params.InputLabelProps,
                className: style.textinplbl, // Apply the utility class
            }}
        />
    )}
    renderOption={(props, option) => (
        <li {...props} className={classes.autocompleteOption}>
          {option?.label}
        </li>
      )}
/>
    </div>
  )
}

const Status = [
  { label: 'Active' },
  { label: 'Block' },
]