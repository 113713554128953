import { Grid } from "@mui/material";
import React from "react";
import style from "./InfoBoxPage.module.css";

const InfoBoxPage = (props) => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <div className={style.boxsubconn}>
            <div className={style.sideborder}>
              <div className={style.containtconn}>
                 {props?.data?.msg}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default InfoBoxPage;
