import React, { useContext, useEffect, useReducer, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css";
import {
  Checkbox,
  Grid,
  TableContainer,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles, styled } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";
import style from "../Components/UserPlayMaster/UserPlay.module.css";
import { handleNumInputChange } from "../MIS/Global";

const useStyles = makeStyles({
  noBorder: {
    border: "none !important",
    "& .MuiInput-underline:before": {
      // borderBottom: `${props.border=="none"?"none !important":""}`,
      borderBottom: "none !important",
    },
  },
});

const UserPlayHistoryTable = (props) => {
  const classes = useStyles();
  const getMaxLenght=(istype,isposition)=>{
      if(istype.toString().toUpperCase()==="open".toString().toUpperCase() || istype.toString().toUpperCase()==="open".toString().toUpperCase()){
        return 1;
      }else if(istype.toString().toUpperCase()==="jodi".toString().toUpperCase() ){
        return 2;
      }else if(istype.toString().toUpperCase()==="jackpot".toString().toUpperCase() ){
        return 3;
      }else if(istype.toString().toUpperCase()==="open panna".toString().toUpperCase() || istype.toString().toUpperCase()==="close panna".toString().toUpperCase() ){
        return 3;
      }else if(istype.toString().toUpperCase()==="gunule".toString().toUpperCase() && isposition.toString()==="0".toString() ){
        return 3;
      }else if(istype.toString().toUpperCase()==="gunule".toString().toUpperCase() && isposition.toString()==="1".toString() ){
        return 1;
      }else if(istype.toString().toUpperCase()==="ulta gunule".toString().toUpperCase() && isposition.toString()==="0".toString() ){
        return 1;
      }else if(istype.toString().toUpperCase()==="ulta gunule".toString().toUpperCase() && isposition.toString()==="1".toString() ){
        return 3;
      }
  }
  return (
    <>
      <div>
        <Paper className="contner1">
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} className="upbtnposition">
              <div className="updatebtncon" onClick={()=>{props?.data?.PlayMasterDataSave(props?.data?.tbldata)}}>
                <button type="button" className="updatebtn">
                  Update
                </button>
              </div>
            </Grid>
          </Grid>
          <TableContainer sx={{ maxHeight: "50vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableBody className="bodycon">
                {props?.data?.tbldata?.map((element, index) => {
                  let bgcolor = "#F1F0E8";
                  if (index % 2 == 0) {
                    bgcolor = "#fff";
                  }
                  let isNumVisibal = {
                    num1: "",
                    numX: "none",
                    num2: "none",
                    num1Width: "50%",
                    num2Width: "50%",
                  };
                  const npoints = element?.npoints.split("X");
                  if (npoints.length >= 2) {
                    isNumVisibal = { num1: "", numX: "", num2: "" };
                  }
                  return (
                    <TableRow
                      className="tblerowdata"
                      style={{ backgroundColor: bgcolor }}
                    >
                      <TableCell>
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={12}>
                            <div style={{ fontSize: "14px", fontWeight: 600 }}>
                              {props?.data?.getGtmname(element?.gtmid)}                              
                            </div>
                          </Grid>
                          <Grid item xs={7} md={8}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <TextField
                                error={element?.err}
                                helperText={element?.errmsg}
                                disabled={props?.data?.handleIsDisable(element?.playid,element?.gtmid)}
                                size="small"
                                variant="standard"
                                inputMode="numeric"
                                label="Number"                                
                                value={npoints[0]}
                                onChange={(e)=>{                                                   
                                   props?.data?.handleUpdatePlay(element?.playid,"npoints",e.target.value,element?.npoints,1,element?.gname);                                                              
                                }}
                                onInput={handleNumInputChange}                               
                                sx={{
                                  marginBottom: "5px",
                                  display: isNumVisibal?.num1,
                                  textAlign: "center",
                                  
                                }}
                                InputProps={{
                                  classes: {
                                    input: style.textinp_left,                                    
                                  },                                  
                                }}
                                inputProps={{maxLength :getMaxLenght(element?.gname,0)}}
                                InputLabelProps={{
                                  className: style.textinplbl,
                                  shrink: true,                                  
                                }}
                              />
                              <Typography
                                variant="body1"
                                component="div"
                                style={{
                                  minWidth: "20px",
                                  textAlign: "center",
                                  marginTop: "12%",
                                  display: `${isNumVisibal?.numX}`,
                                }}
                              >
                                X
                              </Typography>
                              <TextField
                                disabled={props?.data?.handleIsDisable(element?.playid,element?.gtmid)}
                                size="small"
                                variant="standard"
                                inputMode="numeric"
                                label="Number"
                                value={npoints.length >= 2 ? npoints[1] : ""}
                                onChange={(e)=>{props?.data?.handleUpdatePlay(element?.playid,"npoints",e.target.value,element?.npoints,2,element?.gname);
                               }}
                                sx={{
                                  marginBottom: "5px",
                                  display: isNumVisibal?.num2,
                                  textAlign: "center",
                                }}
                                inputProps={{maxLength :getMaxLenght(element?.gname,1)}}
                                InputProps={{
                                  classes: {
                                    input: style.textinp_left,
                                  },
                                }}
                                InputLabelProps={{
                                  className: style.textinplbl,
                                  shrink: true,
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={3} md={4}>
                            <TextField                              
                              disabled={props?.data?.handleIsDisable(element?.playid,element?.gtmid)}
                              size="small"
                              variant="standard"
                              inputMode="numeric"
                              fullWidth
                              label="Points"
                              value={element?.points}
                              onChange={(e)=>{                                
                                props?.data?.handleUpdatePlay(element?.playid,"points",e.target.value,element?.npoints,0,element?.gname);
                               }}
                              InputProps={{
                                classes: {
                                  input: style.textinp,
                                },
                              }}
                              InputLabelProps={{
                                className: style.textinplbl,
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            md={2}
                            sx={{ display: "flex", justifyContent: "flex-end",
                              display:window.location.pathname===""?"none":props?.data?.handleIsDisable(element?.playid,element?.gtmid)===false?"":"none"
                             }}
                          >
                            <div className="actionbtn" onClick={(e)=>{props?.data?.handleDeletePlay(element?.playid,{playid:element?.playid,loginid:element?.loginid,cnt:props?.data?.tbldata.length})}}>
                              <Tooltip title="Delete" placement="top" arrow>
                                <div className="edde">
                                  <DeleteIcon sx={{ fontSize: "18px" }} />
                                </div>
                              </Tooltip>
                            </div>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container spacing={1}>
            <Grid item xs={10} md={10}>
              <div className="collectiontbl">
                <div className="collectiontotal">Total</div>
                <div className="collectiontotal">{Number(props?.data?.tbldata.reduce((acc, item) => Number(acc) + Number(item['points']), 0)).toFixed(2)}</div>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
};
export default UserPlayHistoryTable;
