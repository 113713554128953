import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import HolidayMaster from '../../Components/HolidayMaster/HolidayMaster'
 

const HolidayMasterSceen = () => {
  return (
    <div>
        <Appbar />
        <HolidayMaster/>
    </div>
  )
}

export default HolidayMasterSceen