import { createSlice } from "@reduxjs/toolkit";
import { setConfirmDialogData, setRetAnswer } from "./confirmDialogReducer";
import { v4 as uuidv4 } from "uuid";

import {
  callAjax,
  formatDatetime_DMY,
  formatDatetime_YMD,
} from "../../MIS/Global";
import { setAlertData } from "./alertReducer";

const playReducer = createSlice({
  name: "play",
  initialState: {
    playBtnSelect: {
      o: false,
      c: false,
      j: false,
      g: false,
      op: false,
      cp: false,
      jk: false,
      ug: false,
    },
    gameList: [],
    playGameList: [],
    playData: [],
    playList: [],
    playUser:{},
  },

  reducers: {
    setPlayBtnStatus(state, action) {
      state.playBtnSelect = action.payload;
    },
    setGameList(state, action) {
      state.gameList = action.payload;
    },
    setPlayGameList(state, action) {
      state.playGameList = action.payload;
    },    
    setPlayData(state, action) {
      state.playData = action.payload;
    },
    setPlayList(state, action) {
      state.playList = action.payload;
    },
    setPlayUser(state, action) {
      state.playUser = action.payload;
    },
  },
});

export const { setPlayBtnStatus, setGameList,setPlayData,setPlayUser,setPlayList,setPlayGameList} = playReducer.actions;
export default playReducer.reducer;

export const actionConfirmAdminPlay = (data) => {
    return function actionData(dispatch) {
      if (data === "save") {
        dispatch(
          setConfirmDialogData({
            type: data,
            heading: "Confirm",
            msg: "Do you want to save this record?",
            yeslbl: "Yes",
            nolbl: "No",
            openclose: true,
          })
        );
      }else if(data === "del"){
       dispatch(
          setConfirmDialogData({
              type: data,
              heading: "Confirm",
              msg: "Do you want to delete this record?",
              yeslbl: "Yes",
              nolbl: "No",
              openclose: true,
          })
          );
      }
    };
  };

export const PlayBtnStatus = (data, key) => {
  const newButtonState = Object.fromEntries(
    Object.keys(data).map((btn) => [btn, false])
  );
  newButtonState[key] = !data[key];
  return function actionData(dispatch) {
    dispatch(setPlayBtnStatus(newButtonState));
  };
};

export const SetAdminPlayUser = (data) => { 
  return function actionData(dispatch) {
    dispatch(setPlayUser(data));
  };
};
const calculateDigitSum = (number,istype) => {
  if(istype.toString().toUpperCase()==="jodi".toString().toUpperCase()){
    let numberString = number.split('').toString();
    return numberString[0];
  }
  const numberString = number.toString();
  const sum = Array.from(numberString).reduce(
    (acc, digit) => acc + parseInt(digit, 10),
    0
  );
  if (sum >= 10) {
    return sum % 10;
  }
  return sum;
};

export const AddBtnPlay = (data,numinput,userlist,gamelist,playBtn) => {   
  const isGameSelect = gamelist.some(item => item?.ischeck);
  const isBtnSelect = Object.values(playBtn).some(value => value);
  let numPoint="";let numPointSpace="";
   return function actionData(dispatch) {
    // alert(isGameSelect)
    if(isGameSelect===false || isGameSelect===undefined){
      dispatch(setAlertData({ msg: "Please select game name", code: 100, open: true }));      
      return;
    }else if(userlist?.loginid < 0 || userlist?.loginid===undefined){
      dispatch(setAlertData({ msg: "Please select user", code: 100, open: true }));      
      return;
    }
    else if(isBtnSelect===false){
      dispatch(setAlertData({ msg: "Please select a game type such as Open, Close, Jodi...", code: 100, open: true }));
      return;
    }
    else if(numinput?.num1===true && numinput?.num2===true){
      if(numinput?.num1len > numinput?.num1val.length){        
        dispatch(setAlertData({ msg: "Invalid number-1", code: 100, open: true }));
        return;
      }else if(numinput?.num2len > numinput?.num2val.length){
        dispatch(setAlertData({ msg: "Invalid number-2", code: 100, open: true }));
        return;
      }
      numPoint=numinput?.num1val+"X"+numinput?.num2val;
      numPointSpace=numinput?.num1val+" X "+numinput?.num2val;
    }else if(numinput?.num1===true){
      if(numinput?.num1len > numinput?.num1val.length){        
        dispatch(setAlertData({ msg: "Invalid number-1", code: 100, open: true }));
        return;
      }
      numPoint=numinput?.num1val;
      numPointSpace=numinput?.num1val;
    }
    if(numinput?.point===""){
      dispatch(setAlertData({ msg: "Please enter a points", code: 100, open: true }));
      return;
    }
    const filteredGame = gamelist.filter(item => item.ischeck === true);
    let totalPoint = data.reduce((acc, item) => parseInt(acc) + parseInt(item?.points), 0);
    totalPoint=parseInt(totalPoint)+parseInt(numinput?.point)
    if(parseInt(totalPoint) > parseInt(userlist?.balance)){      
      dispatch(setAlertData({ msg: "Your selected user balance is insufficient for their transactions", code: 100, open: true }));
      return;
    }
    
    let updatedPlayData=[];
    if(data?.length>0){
      updatedPlayData = data.map(item => ({
        ...item,
        totpoints:totalPoint,
      })); 
    }
     
    let playData={
      id: uuidv4(),
      loginid:userlist?.loginid,
      totpoints:totalPoint,
      playid:0,
      wlid:0,
      drawid:0,
      gmid:filteredGame?.[0]?.gmid,
      gname:filteredGame?.[0]?.gsubname,
      fdate:formatDatetime_YMD(new Date(),0,""),
      tdate:formatDatetime_YMD(new Date(),1,""),
      gtypename:numinput?.gname,
      gtname:numinput?.gname,
      gtmid:numinput?.gtmid,
      points:numinput?.point,
      npoints:numPoint,
      npointsSpace:numPoint,
      isdrcr:0,
      trid:'',
      playdate:formatDatetime_YMD(new Date(),0,""),
      cmpid:0,
      regpan:calculateDigitSum(numPoint,numinput?.gname),
      cutpan:'',

    }
    // alert(JSON.stringify(playData))
    dispatch(setPlayData([...updatedPlayData,playData]))    
   };
};
export const removeAdminPlayData = (data,id) => { 
  return function actionData(dispatch) {
    const updatedItems = data.filter(item => item.id !== id);
    let totalPoint = updatedItems.reduce((acc, item) => parseInt(acc) + parseInt(item?.points), 0);
    let updatedPlayData = updatedItems.map(item => ({
      ...item,
      totpoints:totalPoint,
    })); 
    dispatch(setPlayData(updatedPlayData))
  };
};
export const getGameList = (data) => {
  return async function actionData(dispatch) {
    try {
      let ret = await callAjax("searchgame", data);
      if (ret.code == 200) {
        dispatch(setGameList(ret?.data));
        dispatch(setPlayGameList(ret?.data));

      }
    } catch (error) {
      dispatch(
        setAlertData({
          msg: "Something went wrong! Please try again",
          code: 100,
          open: true,
        })
      );
    }
  };
};
export const setSelectedGame = (data, id) => {
  return async function actionData(dispatch) {
    const updatedState = data?.map((item) => {
      if (item.gmid === id) {
        return {
          ...item,
          ischeck: true,
        };
      } else {
        return {
          ...item,
          ischeck: false,
        };
      }
    });
      // alert(JSON.stringify(updatedState))
    dispatch(setGameList(updatedState));
    dispatch(setPlayGameList(updatedState));
    
  };
};

export const saveAdminPlay = (data,userlist,gamelist) => {           
    return async function actionData(dispatch) {
      try {
        if(data.length<=0){
          dispatch(setAlertData({ msg: "You have at least one play transaction", code: 100, open: true }));
          dispatch(setRetAnswer({ resans: false }));
          return;
        }       
        const filteredGame = gamelist.filter(item => item.ischeck === true);
        let updatedPlayData = data.map(item => ({
          ...item,
          loginid:userlist?.loginid,
          gmid:filteredGame?.[0]?.gmid,
          gname:filteredGame?.[0]?.gsubname,
          isentry:1,
        })); 
        alert(JSON.stringify(updatedPlayData))
        // return;
          let ret = await callAjax("playbulksave", updatedPlayData);
          if (ret.code == 200 && ret.data?.[0]?.dt > 0) {
            dispatch(
              setAlertData({
                msg: "Play submitted successfully",
                code: 200,
                open: true,
              })
            );
              setTimeout(() => {
                window.location.reload();
              }, 1000);

          } else if (ret.code == 200 && ret.data?.[0]?.dt <= -1) {
            dispatch(
              setAlertData({
                msg: "The transaction failed. Please try again.",
                code: 100,
                open: true,
              })
            );
          }
        
      } catch (error) {
        dispatch(
          setAlertData({
            msg: "Something went wrong! Please try again",
            code: 100,
            open: true,
          })
        );
      }
      dispatch(setRetAnswer({ resans: false }));
    };
  };

export const getPlayData = (data) => {
    return async function actionData(dispatch) {
      try {
        let ret = await callAjax("playdata", data);
        if (ret.code == 200) {
          dispatch(setPlayList(ret?.data));
        }
      } catch (error) {
        dispatch(
          setAlertData({
            msg: "Something went wrong! Please try again",
            code: 100,
            open: true,
          })
        );
      }
    };
  };