import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import style from "../Components/AdminGameMaster/AdminGame.module.css";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  autocompleteOption: {
    fontSize: "13px",
    fontWeight: "600px !important",
    padding: "5px 10px",
    cursor: "pointer",
    "&.Mui-focused": {
      backgroundColor: "#d9d9d9",
    },
  },
}));

export default function GlobalTextsuggest(props) {
  const classes = useStyles();

  return (
    <div>    
       <Autocomplete
        fullWidth
        disablePortal
        options={props?.data?.options}
        size="small"
        value={props?.data?.labelNm === undefined || props?.data?.labelNm === null ? null : props?.data?.labelNm}
        // inputValue={props?.data?.labelNm}
        onChange={(e,newValue)=>{
          props?.data?.handleChange(newValue)
        }}
        renderInput={(params) => (
          <TextField          
            {...params}
            label={props?.data?.label ? props?.data?.label : "Field Name"}
            InputProps={{
              ...params.InputProps,
              
              classes: {
                ...params.InputProps.classes,
                input: style.textinp_left, // Apply input style
              },
            }}
            InputLabelProps={{
              ...params.InputLabelProps,
              className: style.textinplbl, // Apply the utility class
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} className={classes.autocompleteOption}>
            {option?.label}
          </li>
        )}
      />
    </div>
  );
}
