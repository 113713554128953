import { useEffect, useReducer, useState, React } from "react";
import { tostmsg } from "../../MIS/Global";
import FundRequestReducer from "../Reducer/FundRequestReducer";
import {
  DeleteFundreq,
  GetFundreqdata,
  SaveFundreq,
} from "../Model/FundRequestModel";
import { Getwalletmaster } from "../Model/WalletmasterModel";
import { useNavigate } from "react-router-dom";

function useFundreqemaster() {
  const [alertdata, setAlertdata] = useState({});
  const [alertopen, setAlertopen] = useState(false);

  const [openmodal, setOpenmodal] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [ftdate, setFTdate] = useState({
    fdate: new Date(),
    tdate: new Date(),
  });

  const navigate = useNavigate();

  const handlemodalOpen = (heading) => {
    setModalHeading(heading);
    setOpenmodal(true);
  };

  const handlemodalClose = () => {
    setOpenmodal(false);
  };

  const [fundreqState, fundreqDispach] = useReducer(FundRequestReducer, []);
  const [fundsearchState, fundsearchDispach] = useReducer(
    FundRequestReducer,
    []
  );
  const [fundbalanceState, fundbalanceDispach] = useReducer(
    FundRequestReducer,
    []
  );
  const navigateFreqList = () => {
    navigate("/walletreqlist");
  };
  function formatDate(date) {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      new Date(date)
    );
    const formattedDateWithoutComma = formattedDate.replace(",", "");
    return formattedDateWithoutComma.replace(/(\s[ap]m)/i, (match) => match.toUpperCase());
  }

  const handlefundreq = (e, value) => {
    if (e && e.target) {
      fundreqDispach({
        type: "Change",
        payload: { name: e.target.name, value: e.target.value },
      });
    }
  };

  const handleFTdate = (e, value) => {
    // const formattedDate = new Date(value).toISOString().split("T")[0];
    setFTdate({ ...ftdate, [e]: value });
  };

  useEffect(() => {
    handlefundreq();
  }, []);

  const fetchufundData = async (data) => {
    try {
      let getfunddata = await GetFundreqdata(data);
      if (getfunddata.code === 100) {
        let rettostmsg = tostmsg(getfunddata);
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        return;
      }
      if (getfunddata.code === 200) {
        const sortData = getfunddata?.data.sort(
          (a, b) => new Date(b.edate) - new Date(a.edate)
        );
        fundsearchDispach({
          type: "fundreqDetails",
          data: sortData,
        });
      }
    } catch (error) {
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };

  const fetchUsersbalance = async () => {
    let data = {
      loginid: localStorage.getItem("loginId"),
      limit: "1",
    };

    try {
      let getufunddata = await Getwalletmaster(data);
      if (getufunddata.code === 200) {
        fundbalanceDispach({
          type: "fundreqDetails",
          data: getufunddata?.data,
        });
      }
    } catch (error) {
      // Handle any errors here
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };

  useEffect(() => {
    fetchUsersbalance();
    fetchufundData({
      loginid: localStorage.getItem("loginId"),
      trdate: `${new Date()}`,
    });
  }, []);

  const Savefund = async () => {
    try {
      let data = {
        ...fundreqState,
        edate: new Date(),
        isstatus: 0,
        cmpid: 0,
        loginid: localStorage.getItem("loginId"),
        isbal: fundbalanceState.length <= 0 ? 0 : fundbalanceState[0]?.balance,
        isdrcr: modalHeading === "Add Fund" ? 0 : 1,
      };

      let res = await SaveFundreq(data);
        // alert(JSON.stringify(res));
      let rettostmsg = tostmsg(res);
      if(res?.code==100){
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      } else if (res.code == 200 && res?.data[0]?.dt > 0) {
         rettostmsg = tostmsg({code:200,msg:"Fund request submit successfully"});
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        fetchufundData({
          loginid: localStorage.getItem("loginId"),
          trdate: `${new Date()}`,
        });
        handlemodalClose();
      }else if(res?.data[0]?.dt <= -1){
        rettostmsg = tostmsg({code:100,msg:"Insufficient balance for withdrawal"});
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      }    
      else {
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      }
    } catch (e) {
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };

  const deleteFundRequest = async (data) => {
    try {
      let res = await DeleteFundreq(data);
      let rettostmsg = tostmsg(res);
      if (res.code == 200) {
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
        fetchufundData({
          loginid: localStorage.getItem("loginId"),
          trdate: `${new Date()}`,
        });
      } else {
        setAlertdata(rettostmsg);
        setAlertopen(rettostmsg.open);
      }
    } catch (e) {
      let rettostmsg = tostmsg({
        code: 100,
        msg: "Frontend failed please try again",
      });
      setAlertdata(rettostmsg);
      setAlertopen(true);
    }
  };

  return {
    handlefundreq,
    Savefund,
    deleteFundRequest,
    alertdata,
    alertopen,
    setAlertopen,
    fundsearchState,
    fundbalanceState,
    fetchufundData,
    openmodal,
    handlemodalOpen,
    handlemodalClose,
    modalHeading,
    fundreqState,
    formatDate,
    navigateFreqList,
    handleFTdate,
    ftdate,
  };
}

export default useFundreqemaster;
 