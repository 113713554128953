 
import {  callAjax } from "../../MIS/Global";

export const Savewalletmaster = async (data) => {
  let ret = {};
  if (data?.isdrcr == undefined) {
    return { msg: "Please select any one option", code: 100 };
  }
  if (data?.points == undefined || data?.points == "") {
    return { msg: "Please Enter Points", code: 100 };
  }
  if (data?.points <= 0) {
    return { msg: "Amount must be greater than zero", code: 100 };
  }
  let url = "savewallet";
  if (data.wlid > 0) {
    url = "updatewallet";
  }

  ret= await callAjax(url,data);
  return ret;
   
};

export const Getwalletmaster = async (data) => {
  let ret = {};

  let url = "getwalletdata";

  ret= await callAjax(url,data);
  return ret;
};
export const Getwalletclosing = async (data) => {
  let ret = {};

  let url = "getwalletclosing";

  ret= await callAjax(url,data);
  return ret;
};
