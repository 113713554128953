import React from "react";
import { Grid, TextField } from "@mui/material";
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import SearchIcon from "@mui/icons-material/Search";
import MobileMenu from "../MobileMenu/MobileMenu";
import style from "./userPlayResult.module.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Divider from "@mui/material/Divider";
import useUserAdminResult from "../../API/Hooks/useUserAdminResult";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";

const useStyles = makeStyles((theme) => ({
  smallDateTimePicker: {
    "& .MuiInputBase-input": {
      fontSize: "14px",
      fontWeight: "600 !important",
      width: "100%",
      height: "100%",
      padding: "10px",
      margin: 0,
    },
    "& .MuiInputLabel-root": {
      fontSize: "16px",
      fontWeight: "600 !important", // Adjust font size as needed
      display: "flex",
      alignItems: "center",
    },
  },
}));
const UserPlayResult = () => {
  const classes = useStyles();
  const arr = [1, 2];
  const userAdminResult = { ...useUserAdminResult() };

  return (
    <>
      <AlertMessageComp
        type={userAdminResult?.alertdata.type}
        msg={userAdminResult?.alertdata.msg}
        vertical={userAdminResult?.alertdata.vertical}
        horizontal={userAdminResult?.alertdata.horizontal}
        setOpen={userAdminResult?.setAlertopen}
        open={userAdminResult?.alertopen}
      />
      <div className={style.resultcontainer}>
        <div className={style.userresult1}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              <div className={style.resulthead}>{window.location.pathname==="/uplayresult"?"Today's Play Result":"History Play Result"} </div>
            </Grid>
          </Grid>
        </div>
        <div className={style.userresult2}>
          <Grid container spacing={1}>
            <Grid item xs={5} md={5}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={window.location.pathname==="/uplayresult"?true:false}
                  maxDate={dayjs(`${new Date()}`)}
                  className={classes.smallDateTimePicker}
                  label="Date"
                  value={dayjs(`${userAdminResult?.fdate}`)}
                  name="fdate"
                  onChange={(e) => {
                    // userAdminResult?.handleFilterChnage("fdate", e);
                    // userAdminResult?.handleFilterChnage("tdate", e);
                    userAdminResult?.setFdate(e) 
                    userAdminResult?.gamesearchDispach({type: "gamemasterDetails",data:[]});
                  }}
                  renderInput={(props) => (
                    <TextField {...props} variant="standard" />
                  )}
                  format="DD/MM/YYYY" // Specify the desired format for date and time
                  renderTime={(props) => (
                    <TextField
                      {...props}
                      variant="outlined"
                      helperText={null}
                    />
                  )} // Customize the time input rendering
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={5} md={5} style={{ display: "none" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className={classes.smallDateTimePicker}
                  label="To Date"
                  value={dayjs(`${new Date()}`)}
                  renderInput={(props) => (
                    <TextField {...props} variant="standard" />
                  )}
                  format="DD/MM/YYYY" // Specify the desired format for date and time
                  renderTime={(props) => (
                    <TextField
                      {...props}
                      variant="outlined"
                      helperText={null}
                    />
                  )} // Customize the time input rendering
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={2}
              md={2}
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <div
                onClick={() => {
                  userAdminResult?.fetchGamemasterdata(
                    { fdate: userAdminResult?.formatDatetime_YMD(userAdminResult?.fdate, 0),
                      tdate: userAdminResult?.formatDatetime_YMD(userAdminResult?.fdate, 1),}
                  );
                }}
                className={style.addfundicon}
              >
                <SearchIcon sx={{ color: "white" }} />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={style.userresult3}>
           <Grid container spacing={2}>
            {userAdminResult?.gamesearchState?.map((element, index) => {
              const isLastItem = index === arr.length - 1;
              return (
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <div className={style.disdate}>
                        {/* <CalendarMonthIcon /> */}
                        {/* <span className={style.datetext}>{userAdminResult?.formatDate_dmy(element?.drawdate)}</span> */}
                        <span className={style.datetext}>{element?.gsubname}</span>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className={style.Table}>
                        <div className={style.Row}>
                          <div className={style.Cell}>{element?.m1 || ""}</div>
                          <div className={style.Cell}>X</div>
                          <div className={style.Cell}>X</div>
                          <div className={style.Cell}>{element?.m10 || ""}</div>
                        </div>
                        <div className={style.Row}>
                          <div className={style.Cell}>{element?.m2 || ""}</div>
                          <div className={style.Cell}>{element?.m5 || ""}</div>
                          <div className={style.Cell}>{element?.m8 || ""}</div>
                          <div className={style.Cell}>{element?.m11 || ""}</div>
                        </div>
                        <div className={style.Row}>
                          <div className={style.Cell}>{element?.m3 || ""}</div>
                          <div className={style.Cell}>X</div>
                          <div className={style.Cell}>X</div>
                          <div className={style.Cell}>{element?.m12 || ""}</div>
                        </div>
                      </div>
                    </Grid>
                    {/* {isLastItem ? null : (
                      <Grid item xs={12} md={12}>
                        <Divider style={{ backgroundColor: "black" }} />
                      </Grid>
                    )} */}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </div>
        <div className={style.userresult4}>
          <MobileMenu />
        </div>
      </div>
    </>
  );
};

export default UserPlayResult;
