import React from "react";

function CutpanmasterReducer(state, action) {
  switch (action.type) {
    case "Change": {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    }
    case "cutpanDetails": {
      // alert( "23"+action.data)
      return action.data;
    }
    case "updateprice": {
      let updatedState = {};
        updatedState = state?.map((item) => {
          if (item.gtmid === action.id) {
            return {
              ...item,
              [action.name]: action.value,
            };
          }
          return item;
        });      

      return updatedState;
    }
    case "ischeck": {
      let updatedState = {};
      if (action?.istype === 1) {
        return state.map(obj => ({ ...obj, [action.name]: action.value }));
      }else{
        updatedState = state?.map((item) => {
          if (item.loginid === action.id) {
            return {
              ...item,
              [action.name]: action.value,
            };
          }
          return item;
        });
      }      
      return updatedState;
    }
    
  }
}
export default CutpanmasterReducer;
