import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css";
import {
  Button,
  Grid,
  TableContainer,
  TableHead,
  TablePagination,
  TextField,
} from "@mui/material";

import style from "../../src/Components/AdminFundRequest/AdminFundRequestList.module.css";


const arr = [1, 2, 3, 4, 5, 6,7,8,9,10];
const AdminPlayReportTable = (props) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const rowsPerPageOptions = [
        25,50,100,200,300,500,1000,
        { value: arr.length, label: "All" },
      ];
      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(+event.target.value, 10));
        setPage(0);
      };

  return (
    <>
      <div>
        <Paper className="">
          <TableContainer sx={{ maxHeight: "55vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              style={{
                fontSize: "100pt!important",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell className={style.tblHead}>Name</TableCell>
                  <TableCell className={style.tblHead}>Date</TableCell>
                  <TableCell className={style.tblHead}>Game Name</TableCell>
                  <TableCell className={style.tblHead}>Type</TableCell>
                  <TableCell className={style.tblHead}>Number</TableCell>
                  <TableCell className={style.tblHead}>Points</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="bodycon">
                {arr?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((element, index) => {               
                  let bgcolor = "#F1F0E8";
                  let stscolor = "#6699CC";
                  if (index % 2 == 0) {
                    bgcolor = "#fff";
                  }
                  return (
                    <TableRow
                      className="tblerowdata"
                      style={{ backgroundColor: bgcolor }}
                    >
                      <TableCell>Ram-9145260012</TableCell>
                      <TableCell>
                        15/10/23 11:12 AM
                      </TableCell>
                      <TableCell >Mumbai</TableCell>
                      <TableCell>
                        Open
                      </TableCell>
                      <TableCell>
                       8
                      </TableCell>
                      <TableCell>100.00</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={arr?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
       </div>
    </>
  );
};
export default AdminPlayReportTable;
