import React from "react";

function PlayMasterReducer(state, action) {
  switch (action.type) {
    case "change": {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    }
    case "details": {
      return action.data;
    }
    case "addobject": {
      return [...state, action.payload];
    }
    case "deletePlay": {
      let x = state?.filter((element) => element.id !== action?.id);
      return x;
    }
    case "updatePlay": {
      const updatedState = state?.map(item => {
        if (item.id === action.id) {
          return {
            ...item,
            [action.name]: action.value,regpan:action.regpan,cutpan:action.cutpan
          };
        }
        return item;
      });
      return updatedState;
    }
    case "updateGetPlay": {
      const updatedState = state?.map(item => {
        if (item.playid === action.id) {
          return {
            ...item,
            [action.name]: action.value ,regpan:action.regpan,cutpan:action.cutpan
          };
        }
        return item;
      });
      return updatedState;
    }
    case "deleteGetPlay": {
      let x = state?.filter((element) => element.playid !== action?.id);
      return x;
    }
  }
}
export default PlayMasterReducer;
