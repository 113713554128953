import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.css";
import { TableContainer } from "@mui/material";

const arr = [1,2,3,4,5,6,7,8,9,10];
const PointsCollectionTable = (props) => {
  
  return (
    <>
    <div>
      <Paper className="contner">
        <TableContainer sx={{ maxHeight: "40vh" }}>
          <Table
          size="small"
            stickyHeader
            aria-label="sticky table"
            style={{
              fontSize: "100pt!important",
            }}
          >
            <TableHead>
              <TableRow >
                <TableCell>Game Name</TableCell>
                <TableCell style={{textAlign:"end"}}>Points </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="bodycon">
            {arr?.map((element) => {
              return(
                <TableRow className="tblerowdata">
                  <TableCell component="th" scope="row">
                  {element}
                  </TableCell>
                  <TableCell style={{display:"flex",justifyContent:"end"}}>
                  {element}
                  </TableCell>
                </TableRow>
              )}
            )}
            </TableBody>
          </Table>
        </TableContainer>
           <div className="collectiontbl">
                <div className="collectiontotal">
                    Total
                </div>
                <div className="collectiontotal">
                    98744
                </div>
           </div>
      </Paper>
    </div>
    </>
  );
};
export default PointsCollectionTable;
