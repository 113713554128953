import React from 'react'
import Appbar from '../../Components/Appbar/Appbar'
import AdminDrawMaster from '../../Components/AdminDrawMaster/AdminDrawMaster'

const AdminDrawmasterScreen = () => {
  return (
    <div>
        <Appbar />
        <AdminDrawMaster />
    </div>
  )
}

export default AdminDrawmasterScreen